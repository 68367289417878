import {
  ANIMATE_ORDERS,
  RESET_ANIMATE_ORDERS,
  ACTIVITY_VIEW_CURRENT,
  ACTIVITY_VIEW_ANIMATE,
  STORE_MENUCARD_COORDS,
  CLEAR_MENUCARD_COORDS,
  TOGGLE_PAGE_LOADER,
  SET_PROMO_POPUP_TO_DISPLAY,
  REMOVE_PROMO_POPUP_TO_DISPLAY
} from './types'

export const animateOrders = msg => ({
  type: ANIMATE_ORDERS,
  payload: { msg }
})

export const resetAnimateOrders = () => ({
  type: RESET_ANIMATE_ORDERS
})

export const cycleActivityView = value => ({
  type: ACTIVITY_VIEW_CURRENT,
  payload: value
})

export const activityViewAnimate = () => ({
  type: ACTIVITY_VIEW_ANIMATE
})

export const storeMenuCardCoords = coords => ({
  type: STORE_MENUCARD_COORDS,
  payload: coords
})

export const clearMenuCardCoords = () => ({
  type: CLEAR_MENUCARD_COORDS
})

export const togglePageLoader = isActive => ({
  type: TOGGLE_PAGE_LOADER,
  payload: isActive
})

/**
 * Promo popups
 */

export const setPromoPopupToDisplay = payload => ({
  type: SET_PROMO_POPUP_TO_DISPLAY,
  payload
})

export const removePromoPopupToDisplay = () => ({
  type: REMOVE_PROMO_POPUP_TO_DISPLAY
})
