import useLoggedInUser from 'src/hooks/storageRedux/useLoggedInUser'

import { UserRoles } from 'src/globals/enumerations'

export default function useBannerConfirmNonByer() {
  const loggedInUser = useLoggedInUser()

  let canPerformConfirmation =
    loggedInUser?.userData?.userRole === UserRoles.ADMIN ||
    loggedInUser?.userData?.userRole === UserRoles.MANAGER ||
    loggedInUser?.userData?.userRole === UserRoles.SUPERADMIN

  return canPerformConfirmation
}
