import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getLang } from 'src/state/local'
import { config } from 'src/constants'
import axios from 'axios'
import styles from './styles.module.scss'
import Spinner from 'src/components/Spinner'
import useThemeStyles from 'src/hooks/useThemeStyles'

/**
 *
 * @param {any} liqpayData
 * @param {() => void} callback
 * @param {() => void} onReady
 */

const LiqPayWidgetComponent = ({ data, signature, callback, onReady }) => {
  const [sendLogs, setSendLogs] = useState(false)
  const selectedLanguage = useSelector(getLang)
  const [isReady, setIsReady] = useState(false)
  const [isFailedToLoad, setIsFailedToLoad] = useState(false)
  const { variables } = useThemeStyles()
  const dispatch = useDispatch()
  const liqpayWait = 10
  let timeout = null

  useEffect(() => {
    checkLiqPayStatus()

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isReady])

  // useEffect(() => {
  //   if (isFailedToLoad) {
  //     setIsFailedToLoad(true)
  //     setSendLogs(false)
  //     setIsReady(false)
  //     handleLiqpayInit()
  //   }
  // }, [isFailedToLoad])

  const handleLiqpayInit = () => {
    window.LiqPayCheckoutCallback = (function() {
      window.LiqPayCheckout.init({
        data,
        signature,
        embedTo: '#liqpay_checkout',
        language: selectedLanguage,
        mode: 'embed' // embed || popup
      })
        .on('liqpay.callback', data => {
          if (callback) {
            callback(data, sendLogs)
          }
        })
        .on('liqpay.ready', () => {
          setIsReady(true)
          if (onReady) {
            onReady(sendLogs)
          }
        })
        .on('liqpay.close', function() {})
    })()
  }

  const checkLiqPayStatus = () => {
    if (!isReady) {
      timeout = setTimeout(() => {
        if (!isReady) {
          setIsFailedToLoad(true)
          setSendLogs(true)
          axios.post(
            `${config.API_URL}/${window.busytable.brandSlug}/log`,
            JSON.stringify({
              type: '[app] minor log',
              error: 'Liqpay failed to load in ' + liqpayWait + ' seconds'
            }),
            {
              headers: {
                withCredentials: true,
                'Content-Type': 'application/json',
                'x-url': window.location.href,
                credentials: 'include'
              },
              withCredentials: true
            }
          )
        }
      }, liqpayWait * 1000)
    }
  }

  useEffect(() => {
    handleLiqpayInit()
  }, [dispatch])

  return (
    <div id='liqpay_checkout' style={{ position: 'relative' }}>
      {!isReady && !isFailedToLoad && (
        <div className={styles.loaderWrapper}>
          <Spinner style={{ borderTopColor: variables.moneyColor }} />
        </div>
      )}
    </div>
  )
}

export default LiqPayWidgetComponent
