import {
  GET_APP_PREFERENCES_STARTED,
  GET_APP_PREFERENCES_ERROR,
  GET_APP_PREFERENCES_SUCCESS,
  GET_PUBLIC_BRAND_PREFERENCES,
  SET_VENUES_APP_PREFERENCES
} from './types'

export const getAppPreferencesStarted = () => ({
  type: GET_APP_PREFERENCES_STARTED
})

/**
 *
 * @param {object} error
 */
export const getBranPublicSettingsError = error => ({
  type: GET_APP_PREFERENCES_ERROR,
  error
})

/**
 *
 * @param {object} appPreferences
 */
export const getAppPreferencesSuccess = appPreferences => ({
  type: GET_APP_PREFERENCES_SUCCESS,
  appPreferences
})

/**
 *
 * @param {object} publicSettings
 */
export const getBrandPublicPreferences = publicSettings => ({
  type: GET_PUBLIC_BRAND_PREFERENCES,
  publicSettings
})

export const setVenuesAppPreferences = preferences => ({
  type: SET_VENUES_APP_PREFERENCES,
  venueAppPreferences: preferences
})
