import React from 'react'
import QrReader from 'react-qr-reader'
import { ReactComponent as TableIcon } from 'src/assets/ico-table-big.svg'
import { ReactComponent as DublClose } from 'src/assets/ico-dubl-close.svg'
import { ReactComponent as QrIcon } from 'src/assets/icons/qr_reader.svg'

import { postEvent } from 'src/api'
import { useSelector } from 'react-redux'
import { getVenueStatus, VENUE_STATUSES, getVenueId } from 'src/state/location/selectors'
import { useTranslation } from 'src/hooks/useTranslation'
import s from './styles.module.scss'
import useCheckNative from 'src/hooks/useCheckNative'
import { useLocation } from 'react-router-dom'

export const ScanView = ({ onError, onScan, setManual }) => {
  const { t } = useTranslation()
  const [tableNum, setTable] = React.useState('')

  const venue = useSelector(getVenueStatus)
  const venueId = useSelector(getVenueId)
  const isNative = useCheckNative()
  const location = useLocation()

  return (
    <>
      <div style={{ position: 'relative' }}>
        <div className={s.readerWrapper}>
          <QrIcon className={s.tableVec} />
        </div>
        <div className={s.hideScrollbar}></div>
        <QrReader
          style={
            window.innerWidth > 1025
              ? {
                  width: '400px',
                  height: '400px'
                }
              : undefined
          }
          delay={900}
          onError={onError}
          onScan={onScan}
          className={s.reader}
        />

        <div className={s.help}>{t('scanView.hint1')}</div>
      </div>
      {venueId !== 0 && (
        <div
          className={s.randomText}
          style={{ visibility: venue === VENUE_STATUSES.NOT_SELECTED ? 'hidden' : 'visible' }}
        >
          {t('scanView.manual')}
        </div>
      )}
      {venueId !== 0 && (
        <div className={s.manual} style={{ visibility: venue === VENUE_STATUSES.NOT_SELECTED ? 'hidden' : 'visible' }}>
          <form className={s.form}>
            <TableIcon width='40px' height='24px' fill='#CDCDCD' />
            <input
              className={s.formInput}
              placeholder=''
              value={tableNum}
              type='tel'
              maxLength='3'
              pattern='[0-9]*'
              onChange={e => setTable(e.target.validity.valid ? e.target.value : tableNum)}
              onSubmit={_ => setManual(tableNum)}
              onFocus={() => postEvent('scan-form-number', 'involvement')}
            />
            <DublClose
              onClick={_ => {
                setTable('')
                postEvent('scan-clear_manual_field', 'involvement')
              }}
            />
          </form>
          <button
            style={{ marginTop: '32px' }}
            className={['sbutton', tableNum > 0 ? '' : 'unpay', 'pay'].join(' ')}
            onClick={() => {
              const search = location?.search?.slice(1)

              postEvent('scan-btn_manual_continue', 'involvement', {
                value: tableNum,
                protocol: window.location.protocol,
                host: window.location.host,
                path: window.location.pathname,
                ...(search ? { props: search } : {}),
                isnative: isNative,
                userAgent: navigator.userAgent
              })
              setManual(tableNum)
            }}
          >
            {tableNum.length > 0 ? t('general.continue') : t('tableNumber.selectTableNumber')}
          </button>
        </div>
      )}
    </>
  )
}
