import { createContext } from 'react'
import { ActivityViewsValue, OrdersActionsValue } from './types'

export const OrdersActionsContext = createContext<OrdersActionsValue>({
  detailedOrders: null,
  expandedOrder: null,
  toggleDetailedOrders: () => {},
  toggleExpandedOrder: () => {}
})

export const ActivityViewsContext = createContext<ActivityViewsValue>({
  commenting: false,
  deliveryForm: null,
  codeScan: false,
  alreadyNotified: [],
  toggleCommenting: () => {},
  toggleDeliveryForm: () => {},
  toggleCodeScan: () => {},
  toggleTakeoutNotifier: () => {}
})
