import React, { useEffect } from 'react'
import { postEvent } from 'src/api'
import StatusLabel from './statusLabel'
import s from '../styles.module.scss'
import PlusButton from './PlusButton'

const ContentClosed: React.FC = () => {
  useEffect(() => {
    postEvent('cart-close')
  }, [])

  return (
    <div className={s.content}>
      <PlusButton />

      <div className={s.contentInnerWrapper}>
        <StatusLabel />
      </div>
    </div>
  )
}

export default React.memo(ContentClosed)
