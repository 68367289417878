import { useMemo, useState } from 'react'
import { IDish } from 'src/types'
import { useTranslation } from '../useTranslation'
import useCurrentDishes from '../useCurrentDishes'

type UseDishesFilterResult = {
  filteredDishes: IDish[]
  filterDishesByKeyword: (keyword: string) => void
  filterDishesByTags: (tagsIds: number[]) => void
}
type UseDishesFilterHook = (dishes: IDish[]) => UseDishesFilterResult

const useDishesFilter: UseDishesFilterHook = function(initialDishes) {
  const { t } = useTranslation()
  const { dishes } = useCurrentDishes()
  const [filteredDishes, setFilteredDishes] = useState<IDish[]>([])

  const filterDishesByKeyword = useMemo(
    () => (keyword: string) => {
      let result = [] as IDish[]
      if (keyword?.length) {
        result =
          dishes?.filter(dish =>
            t(dish.name)
              ?.toLowerCase()
              ?.includes(keyword?.toLowerCase())
          ) || []
      }
      setFilteredDishes(result)
    },
    [dishes, t]
  )

  const filterDishesByTags = useMemo(
    () => (tagsIds: number[]) => {
      const result = initialDishes?.filter(dish => tagsIds.some(tagId => dish.tags.includes(tagId)))
      setFilteredDishes(result || [])
    },
    [initialDishes]
  )

  const value = useMemo(
    () => ({
      filteredDishes: filteredDishes.length > 0 ? filteredDishes : initialDishes,
      filterDishesByKeyword,
      filterDishesByTags
    }),
    [initialDishes, filteredDishes, filterDishesByKeyword, filterDishesByTags]
  )

  return value
}

export default useDishesFilter
