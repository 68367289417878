export default [
  {
    featureType: 'administrative',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on'
      },
      {
        saturation: -100
      },
      {
        lightness: 20
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on'
      },
      {
        saturation: -100
      },
      {
        lightness: 40
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on'
      },
      {
        saturation: -10
      },
      {
        lightness: 30
      }
    ]
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified'
      },
      {
        saturation: -60
      },
      {
        lightness: 10
      }
    ]
  },
  {
    featureType: 'landscape.natural',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified'
      },
      {
        saturation: -60
      },
      {
        lightness: 60
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off'
      },
      {
        saturation: -100
      },
      {
        lightness: 60
      }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off'
      },
      {
        saturation: -100
      },
      {
        lightness: 60
      }
    ]
  }
]
