import {
  ADD_DISHES,
  TOGGLE_EDIT,
  ADD_SELECTED_OPTIONS,
  ADD_OPTIONSETS,
  ADD_SELECTED_DISH,
  UPDATE_CARD_NOW,
  RESET_SELECTED_OPTIONS,
  REMOVE_SELECTED_DISH
} from './types'
import { IDish, OptionSetType, SelectedOptionType } from '../../types'

export interface AddDishesAction {
  type: typeof ADD_DISHES
  dishes: IDish[]
}

export const addDishes = (dishes: IDish[]): MenuActionsType => ({
  type: ADD_DISHES,
  dishes
})

export interface UpdateCardNowAction {
  type: typeof UPDATE_CARD_NOW
  dishes: IDish[]
}

export const updateCardNow = (dishes: IDish[]): MenuActionsType => ({
  type: UPDATE_CARD_NOW,
  dishes
})

export interface AddOptionsetsAction {
  type: typeof ADD_OPTIONSETS
  optionsets: OptionSetType[]
}

export const addOptionsets = (optionsets: OptionSetType[]): MenuActionsType => ({
  type: ADD_OPTIONSETS,
  optionsets
})

export interface AddSelectedOptionsAction {
  type: typeof ADD_SELECTED_OPTIONS
  selectedOptions: SelectedOptionType[]
}

export const addSelectedOptions = (payload: SelectedOptionType[]): MenuActionsType => ({
  type: ADD_SELECTED_OPTIONS,
  selectedOptions: payload
})

export interface ResetSelectedOptionsAction {
  type: typeof RESET_SELECTED_OPTIONS
}

export const resetSelectedOptions = (): MenuActionsType => ({
  type: RESET_SELECTED_OPTIONS
})

export interface AddSelectedDishAction {
  type: typeof ADD_SELECTED_DISH
  selectedDish: IDish
}

export const addSelectedDish = (payload: IDish): MenuActionsType => ({
  type: ADD_SELECTED_DISH,
  selectedDish: payload
})

export interface ToggleEditAction {
  type: typeof TOGGLE_EDIT
  edit: boolean
}

export const toggleEdit = (payload: boolean): MenuActionsType => ({
  type: TOGGLE_EDIT,
  edit: payload
})

export interface RemoveSelectedDishAction {
  type: typeof REMOVE_SELECTED_DISH
}

export const removeSelectedDish = (): MenuActionsType => ({
  type: REMOVE_SELECTED_DISH
})

export type MenuActionsType =
  | ToggleEditAction
  | AddSelectedDishAction
  | AddSelectedOptionsAction
  | ResetSelectedOptionsAction
  | AddOptionsetsAction
  | UpdateCardNowAction
  | AddDishesAction
  | RemoveSelectedDishAction
