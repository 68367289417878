export const FETCH_PAID_ORDERS = 'FETCH_PAID_ORDERS'
export const REMOVE_UNPAID = 'REMOVE_UNPAID'
export const ADD_NEW_ORDER = 'ADD_NEW_ORDER'
export const REMOVE_NEW_ORDER = 'REMOVE_NEW_ORDER'
export const REMOVE_NEW_DISH = 'REMOVE_NEW_DISH'
export const REMOVE_ONE_ORDER = 'REMOVE_ONE_ORDER'
export const PROCESS_ORDER_FROM_WEBSOCKET = 'PROCESS_ORDER_FROM_WEBSOCKET'
export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE'
export const SET_TAKEOUT = 'SET_TAKEOUT'
export const UPDATE_ORDER = 'UPDATE_ORDER'
export const ADD_COMMENT = 'ADD_COMMENT'
export const REMOVE_COMMENT = 'REMOVE_COMMENT'
export const ADD_MANY_ORDERS = 'ADD_MANY_ORDERS'
export const CLEAR_NEW_ORDERS = 'CLEAR_NEW_ORDERS'
export const SET_NEW_ORDERS = 'SET_NEW_ORDERS'
