import { ADD_ROLES } from './types'

export const initialState = [
  { key: 'owner', value: 'Неплательщики - Владелец' },
  { key: 'management', value: 'Неплательщики - Менеджмент' },
  { key: 'office', value: 'Неплательщики - Сотрудник офиса' },
  { key: 'friend', value: 'Неплательщики - Друзья ресторана' },
  { key: 'manager', value: 'Персонал - Менеджер ресторана' },
  { key: 'cashier', value: 'Персонал - Кассир' },
  { key: 'runner', value: 'Персонал - Раннер / Упаковщик' },
  { key: 'waiter', value: 'Персонал - Официант' },
  { key: 'bar', value: 'Персонал - Бариста' },
  { key: 'kitchen', value: 'Персонал - Повар' }
]

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_ROLES: {
      return action.payload.roles
    }

    default:
      return state
  }
}
