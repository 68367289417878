import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { postEvent } from 'src/api'
import { useTranslation } from 'src/hooks/useTranslation'
import Background from 'src/components/ui/Background'
import { onlyUnique } from 'src/utils'
import useAppPreferences from 'src/hooks/useAppPreferences'
import useShoppingCartStatus from 'src/hooks/ShoppingCart/useShoppingCartStatus'
import useUnpaidOrders from 'src/hooks/useUnpaidOrders'
import { SHOPPING_CART_STATE } from 'src/types'
import usePaidOrders from 'src/hooks/usePaidOrders'
import { TakeoutNumber, OrdersActionsValue, UniqueOrderKey } from '../types'
import { OrdersActionsContext } from '../contexts'
import { PaymentSection } from '../PaymentSection'
import PaidOrdersSection from './PaidOrdersSection'
import { UnpaidOrdersSection } from './UnpaidOrdersSection'
import s from '../styles.module.scss'
import QrMenuActions from 'src/components/QrMenuActions'
import ShoppingCartSuggested from '../../SuggestedDishes/shoppingCartSuggested'
import { SHOPPING_CART_SUGGESTED_MAP, SuggestedCategoryKey } from '../../../constants'

export interface IContentOpenProps {
  withoutPadding?: boolean
}

export const ContentOpen: React.FC<IContentOpenProps> = ({ withoutPadding }) => {
  const { t } = useTranslation()
  const [expandedOrder, toggleExpandedOrder] = useState<UniqueOrderKey | null>(null)
  const [detailedOrders, toggleDetailedOrders] = useState<TakeoutNumber | null>(null)

  const [{ status }] = useShoppingCartStatus()
  const [{ isEmpty: unpaidOrdersEmpty, ordersWithDishes }] = useUnpaidOrders()
  const [{ groupedOrders: paidOrders }] = usePaidOrders()
  const { preferences } = useAppPreferences()

  useEffect(() => {
    postEvent('cart-open', 'ordering')
  }, [])

  const suggestedCategoriesIds = useMemo(
    () =>
      ordersWithDishes
        .map(order => (order.dish?.subcategoryId || order.dish?.categoryId) as number)
        .filter(onlyUnique)
        .filter(catId => {
          const dishIds = ordersWithDishes.map(order => order.dishId)
          return !SHOPPING_CART_SUGGESTED_MAP[catId as SuggestedCategoryKey]?.some(dishId => dishIds.includes(dishId))
        }),
    [ordersWithDishes]
  )

  const handleOuterClick = useCallback(() => {
    toggleExpandedOrder(null)
  }, [toggleExpandedOrder])

  const uniqueTakeoutNumbers = paidOrders.map(order => order.takeoutNum).filter(onlyUnique)

  const sortedPaidOrders = paidOrders.sort((b, a) => a.tempId - b.tempId)

  return (
    <div
      style={{ ...(withoutPadding ? { padding: '0 0 30px 0' } : {}) }}
      className={s.content}
      onClick={handleOuterClick}
    >
      {status !== SHOPPING_CART_STATE.EMPTY && (
        <OrdersActionsContext.Provider
          value={{ detailedOrders, expandedOrder, toggleExpandedOrder, toggleDetailedOrders } as OrdersActionsValue}
        >
          <div>
            {!unpaidOrdersEmpty && (
              <UnpaidOrdersSection newDesign={withoutPadding}>
                {!preferences.withQrMenuMode ? <PaymentSection /> : <QrMenuActions />}
              </UnpaidOrdersSection>
            )}
            {!unpaidOrdersEmpty && <ShoppingCartSuggested categoriesIds={suggestedCategoriesIds} />}
            <div className={!unpaidOrdersEmpty ? s.paidSection : s.paidSectionLight}>
              {uniqueTakeoutNumbers.map((takeoutNumber, index) => {
                return (
                  <PaidOrdersSection
                    isLastPayment={index === 0}
                    key={takeoutNumber}
                    orders={sortedPaidOrders.filter(r => r.takeoutNum === takeoutNumber)}
                  />
                )
              })}
              <Background />
            </div>
          </div>
        </OrdersActionsContext.Provider>
      )}
      {status === SHOPPING_CART_STATE.EMPTY && (
        <div className={s.openEmpty}>
          <div className={s.title}>{t('shoppingCart.contentOpen.placeholder1')}</div>
          <p>{t('shoppingCart.contentOpen.placeholder2')}</p>
        </div>
      )}
    </div>
  )
}
