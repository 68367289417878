import React from 'react'
import { config } from './constants'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // console.log(error.message)
    // console.log(error.lineNumber)
    // console.log(error.fileName)
    // console.log(error.stack.split('\n').splice(0, 5))
    fetch(
      `${config.API_URL}/${window.busytable.brandSlug}/log`,
      {
        headers: { withCredentials: true, 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          type: '[front] fall detailed: ',
          error: {
            '[error.message]': error.message,
            '[error.fileName]': error.fileName,
            '[error.lineNumber]': error.lineNumber,
            '[error.stack]': error.stack.split('\n').splice(0, 5)
          }
        })
      },
      { withCredentials: true }
    )
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI

      return (
        <div className='center'>
          <button onClick={() => window.location.reload()}>Something went wrong. Click to reload page.</button>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
