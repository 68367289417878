import axios from 'axios'

import { config } from 'src/constants'

// export async function getAppPreferences(venueSlug) {
//   return axios({
//     url: venueSlug
//       ? `${config.API_URL3}/${window.busytable.brandSlug}/${venueSlug}/appPreferences`
//       : `${config.API_URL3}/${window.busytable.brandSlug}/appPreferences`
//   })
//     .then(resp => {
//       if (resp?.data && resp?.data?.success) {
//         return resp?.data?.preferences
//       }
//     })
//     .catch(e => {
//       if (e.response.data) {
//         throw Error(e.response.data)
//       } else {
//         throw Error(e)
//       }
//     })
// }

export async function getPublicBrandSettings() {
  return axios({
    url: `${config.API_URL3}/${window.busytable.brandSlug}/brands/`
  })
    .then(resp => resp.data)
    .catch()
}
