import React from 'react'
import { IServiceStopListItem, SERVICES_TYPE } from 'src/types'
import { useSelector } from 'src/state/hooks'
import { getServicesStoplistOwnDeliveryItems } from 'src/state/services-stoplist/selectors'
import { getTimedTakeout } from 'src/state/timedTakeout'
import useUnpaidOrders from 'src/hooks/useUnpaidOrders'
import { getDelivery } from 'src/state/delivery'
import { getTakeout } from 'src/state/orders'
import { useTranslation } from 'src/hooks/useTranslation'
import useAppPreferences from 'src/hooks/useAppPreferences'
import { getVenueId } from 'src/state/location'
import { getVenueById } from 'src/state/venues'
import s from '../styles.module.scss'

const ShoppingCartDescription: React.FC = function() {
  const { t } = useTranslation()

  const venueId = useSelector(getVenueId)
  const venue = useSelector(getVenueById)(venueId)
  const timedTakeout = useSelector(getTimedTakeout)
  const delivery = useSelector(getDelivery)
  const takeout = useSelector(getTakeout)
  const ownDeliveryStopList: IServiceStopListItem[] = useSelector(getServicesStoplistOwnDeliveryItems) || []

  const { preferences } = useAppPreferences()
  const [{ totalPrice, isEmpty, isUnderMinOrderAmount }] = useUnpaidOrders()

  const isUnderCustomMinOrderAmount = !isEmpty && totalPrice > 0 ? totalPrice < preferences?.minOrderAmount : false
  const ownDeliveryStopListWarning = ownDeliveryStopList.some(ownDeliveryStopListItem => {
    return (
      timedTakeout >= new Date(ownDeliveryStopListItem.startingAt).getTime() &&
      timedTakeout <= new Date(ownDeliveryStopListItem.endingAt).getTime()
    )
  })

  return (
    <div className={s.pbDescription}>
      {isUnderMinOrderAmount && !delivery && t('shoppingCart.minimalOrderWarning.description')}
      <br />
      {takeout && (
        <>
          {delivery && (
            <>
              {delivery.type === SERVICES_TYPE.DELIVERY_NOVAPOSHTA && t('novaposhtahint')}
              {delivery.type !== SERVICES_TYPE.DELIVERY_NOVAPOSHTA && (
                <>
                  {preferences?.minOrderAmount > 0 && (
                    <>
                      {ownDeliveryStopListWarning && <b>{t('delivery.ownDeliveryInStopList')}</b>}
                      {isUnderCustomMinOrderAmount &&
                        t('branding.deliveryNotEnoughAmount', { amount: preferences?.minOrderAmount / 100 } as any)}
                      {!isUnderCustomMinOrderAmount && t('branding.deliveryValidatedDescription')}
                    </>
                  )}
                  {!preferences?.minOrderAmount && t('branding.paymentSectionDiv2Text')}
                </>
              )}
            </>
          )}
          {!delivery && (
            <>
              {t('branding.paymentSectionDiv3Text')} <strong>{t(venue?.address)}.</strong>{' '}
              {t('branding.paymentSectionDiv3Final')}
              <strong>{t('branding.paymentSectionDiv4Strong')}</strong> {t('branding.paymentSectionDiv4Final')}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ShoppingCartDescription
