import React, { useCallback, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import { postEvent } from 'src/api'
import { ReactComponent as DiesIcon } from 'src/assets/icons/ico-dies-16.svg'
import { ReactComponent as PoiIcon } from 'src/assets/icons/ico-poi-24.svg'
import { ReactComponent as PersonIcon } from 'src/assets/icons/icon-person.svg'
import { useTranslation } from 'src/hooks/useTranslation'
import { getDelivery, setDelivery } from 'src/state/delivery'
import { decoratePhone } from 'src/utils/phone'
import { MenuBar } from 'src/components/ui/MenuBar'
import { Popup } from 'src/components/ui/Popup.tsx'
import { ReactComponent as PhoneIcon } from 'src/assets/phone.svg'
import s from '../styles.module.scss'

export const DeliveryPopup = ({ handler, closeHandler, mode }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const delivery = useSelector(getDelivery)

  const [focus, setFocus] = useState('')
  const [name, setName] = useState(delivery?.name)
  const [address, setAddress] = useState(delivery?.address)
  const [phone, setPhone] = useState(delivery?.phone)

  const handleSave = useCallback(() => {
    dispatch(setDelivery({ ...delivery, name, address, phone }))
    handler()
  }, [name, address, phone, handler])

  const [ref, inView] = useInView({ threshold: 1 })

  let validateFields = false
  const isPhoneValid = validatePhone(phone)

  validateFields =
    name.length > 0 &&
    phone.length > 0 &&
    isPhoneValid &&
    (delivery.department.length > 0 || mode === 'recipient') &&
    address.length > 0

  return (
    <Popup
      fullsize
      withoutHeaderWrapper
      header={
        <MenuBar
          isHeader
          className={!inView ? s.inviewHead : ''}
          icon='cross'
          title={t(mode === 'recipient' ? 'recipientPopup' : 'deliverypopup')}
          url='/'
          eventCb={() => {
            postEvent('profile-btn_close')
          }}
          leftCb={() => closeHandler()}
          right={
            <div
              onClick={() => {
                if (validateFields === true) {
                  handleSave()
                }
              }}
              className={[validateFields ? s.saveProfile : s.unsaveProfile].join(' ')}
            >
              {t('freeForm.save')}
            </div>
          }
        />
      }
    >
      <div className={['contentWrapper', 'custom1'].join(' ')}>
        <div ref={ref} />
        <div className={s.list}>
          <div className={[s.item2, focus === '2_1' ? s.item2Active : ''].join(' ')}>
            <PersonIcon className={s.userIcon} />

            <input
              onFocus={() => {
                setFocus('2_1')
              }}
              value={name}
              onChange={e => {
                setName(e.target.value)
              }}
              placeholder={t('dostavka.name')}
              className={[s.userText, 'body1'].join(' ')}
            />
          </div>
          <div className={[s.item2, focus === '4_1' ? s.item2Active : ''].join(' ')}>
            <PoiIcon className={s.userIcon} />

            <input
              onFocus={() => {
                setFocus('4_1')
              }}
              value={address}
              autoComplete='street-address'
              type='text'
              onChange={e => {
                setAddress(e.target.value)
              }}
              placeholder={mode === 'novaposhta' ? t('dostavka.address') : t('paymentSection.recipientAddress')}
              className={[s.userText, 'body1'].join(' ')}
            />
          </div>
          {mode === 'novaposhta' && (
            <div className={[s.item2, focus === '4_1' ? s.item2Active : ''].join(' ')}>
              <DiesIcon className={s.userIcon} />

              <input
                onFocus={() => {
                  postEvent('profile-form-address', 'engaging')
                  setFocus('4_1')
                }}
                value={delivery.department}
                type='text'
                onChange={_ => {
                  dispatch(setDelivery({ ...delivery, department: _.target.value }))
                }}
                placeholder={t('dostavka.department')}
                className={[s.userText, 'body1'].join(' ')}
              />
            </div>
          )}
          <div
            className={[
              [s.item2, focus === '3_1' ? s.item2Active : '', !isPhoneValid && s.errorItem].join(' '),
              s.phoneWrap
            ].join(' ')}
          >
            <div style={{ display: 'flex' }}>
              <PhoneIcon fill={isPhoneValid ? '#383838' : '#ff0000'} width='24' />
            </div>

            <input
              onFocus={() => {
                setFocus('3_1')
              }}
              value={decoratePhone(phone)}
              onChange={e => {
                setPhone(e.target.value)
              }}
              placeholder={t('dostavka.phone')}
              type='tel'
              className={[s.userText, 'body1'].join(' ')}
              autoComplete='tel'
            />
          </div>
        </div>
      </div>
    </Popup>
  )
}

const formatPhone = rawPhone => {
  if (typeof rawPhone !== 'string' || !rawPhone) return null
  const number = rawPhone.replace(/[^\d]/g, '')
  if (/^[1-9]\d{8}$/.test(number)) return `380${number}`
  if (/^0\d{9}$/.test(number)) return `38${number}`
  if (/^80\d{9}$/.test(number)) return `3${number}`
  return number
}

const validatePhone = rawPhone => {
  if (typeof rawPhone === 'string') {
    const richPhone = rawPhone
      .split('')
      .filter(x => x !== '-' && x !== '(' && x !== ' ' && x !== ')' && x !== '+')
      .join('')

    if (richPhone.length < 1) {
      return false
    }
    return /^380\d{9}$/.test(formatPhone(richPhone))
  }
  return false
}
