import { findId } from 'src/utils'
import { AppState } from '../store'
import { IDish, OptionSetType, SelectedOptionType } from 'src/types'

export const getEdit = (store: AppState): boolean => store.menu.edit
export const getSelectedOptions = (store: AppState): SelectedOptionType[] => store.menu.selectedOptions
export const getSelectedDish = (store: AppState): IDish | null => store.menu.selectedDish
export const getDishes = (store: AppState): IDish[] => store.menu.dishes

export const getOptionsets = (store: AppState): OptionSetType[] => store.menu.optionsets

export const getDishesWithoutSizes = (store: AppState): IDish[] =>
  store.menu.dishes.filter((x: IDish) => !Number.isInteger(x.parentSizeId))

export const getDishById = (store: AppState) => (id: number | string | undefined): IDish =>
  findId(id, store.menu.dishes)

export const getOptionsetsByRecipeId = (recipeId: number | null | undefined) => (store: AppState): OptionSetType[] => {
  const optionsets = store.menu.optionsets.filter((x: OptionSetType) => x.recipeId === recipeId)

  return optionsets.sort(comparePosition)
}

const comparePosition = (a: OptionSetType, b: OptionSetType): number => {
  return Number(a.position) - Number(b.position)
}
