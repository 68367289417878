import { SERVICES_TYPE } from 'src/types'
import { SET_SERVICE_TYPE } from './ShoppingCart.types'
import { ShoppingCartActionType } from './ShoppingCart.actions'

type ShoppingCartState = Readonly<{
  activeServiceType: SERVICES_TYPE | null
}>

export const shoppingCartInitialState: ShoppingCartState = {
  activeServiceType: null
}

const shoppingCartReducer = (state: ShoppingCartState = shoppingCartInitialState, action: ShoppingCartActionType) => {
  switch (action.type) {
    case SET_SERVICE_TYPE: {
      return {
        ...state,
        activeServiceType: action.serviceType
      }
    }
    default: {
      return state
    }
  }
}

export default shoppingCartReducer
