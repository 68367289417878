import { SET_VENUE_ID, SET_TABLE_NUM, SET_VENUE_OPEN, SET_SCAN_DATE, SET_CURRENT_CATEGORY } from './types'

export const setVenueId = id => ({
  type: SET_VENUE_ID,
  payload: { id }
})

export const setTableNum = num => ({
  type: SET_TABLE_NUM,
  payload: { num }
})

export const setVenueOpen = status => ({
  type: SET_VENUE_OPEN,
  payload: { status }
})

export const setScanDate = () => ({
  type: SET_SCAN_DATE
})

export const setCurrentCategory = id => ({
  type: SET_CURRENT_CATEGORY,
  payload: { id }
})
