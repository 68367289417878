import React, { ReactNode, useState } from 'react'
import { motion, Variants } from 'framer-motion'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'src/state/hooks'
import { ANIMATED_MEDIA_REGEX } from 'src/constants'
import { getMenuCardCoords } from 'src/state/ui/selectors'
import { MediaItemType } from 'src/types'
import MediaCarousel from '../MediaCarousel'
import s from '../DishCard/styles.module.scss'

type DishMediaType = {
  media: MediaItemType[]
  img: string
  children: ReactNode
}

export const DishMedia = React.forwardRef<HTMLDivElement, DishMediaType>(({ media, img, children }, ref) => {
  const coords = useSelector(getMenuCardCoords)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const location = useLocation()
  const isSizeChanged = location.pathname.includes('size-changed')
  const handleActiveIndex = (index: number) => setActiveIndex(index)

  const singleMedia = media?.[0]
  const transition = {
    ease: 'easeOut',
    duration: isSizeChanged ? 0 : 0.4
  }

  const imgVariants = {
    init: {
      width: coords ? coords.width : null,
      height: coords ? coords.height : null,
      transition
    },
    final: {
      width: window.innerWidth > 1025 ? 400 : window.innerWidth,
      height: window.innerWidth > 1025 ? 400 : window.innerWidth,
      transition
    }
  } as Variants

  const emptyImgVariants = {
    ...imgVariants,
    final: {
      width: window.innerWidth > 1025 ? 400 : window.innerWidth,
      height: 70,
      transition
    }
  } as Variants

  return (
    <motion.div
      ref={ref}
      initial='init'
      animate='final'
      variants={img?.length || media?.length ? (coords ? imgVariants : undefined) : emptyImgVariants}
      className={s.image}
    >
      {media?.length > 1 && (
        <MediaCarousel
          indicatorDirection='horizontal'
          activeIndex={activeIndex}
          handleActiveMediaIndex={handleActiveIndex}
          isMenu={false}
          medias={media}
          isExpanded
        />
      )}
      {(media?.length < 2 && singleMedia && ANIMATED_MEDIA_REGEX.test(singleMedia.mediaType) && (
        <motion.div initial='init' animate='final' variants={coords ? imgVariants : undefined} className={s.dish_image}>
          <video style={{ width: '100%', height: '100%' }} src={singleMedia.path} autoPlay controls={false} muted />
        </motion.div>
      )) ||
        (media?.length < 2 && singleMedia && (
          <motion.div
            initial='init'
            animate='final'
            variants={coords ? imgVariants : undefined}
            style={{ backgroundImage: `url('${singleMedia?.path}')` }}
            className={s.dish_image}
          />
        ))}
      {!singleMedia && img && (
        <motion.div
          initial='init'
          animate='final'
          variants={coords ? imgVariants : undefined}
          style={{ backgroundImage: `url('/${img}')` }}
          className={s.dish_image}
        />
      )}
      {children}
    </motion.div>
  )
})
