import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'src/hooks/useTranslation'
import { useSelector } from 'src/state/hooks'
import { removePromoPopupToDisplay } from 'src/state/ui/actions'
import { getPromoPopup } from 'src/state/ui/selectors'
import { Popup } from '../ui/Popup'
import styles from './styles.module.scss'

const PromoPopup: React.FC = () => {
  const popup = useSelector(getPromoPopup)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  if (!popup) {
    return <></>
  }

  const onModalClose = () => {
    dispatch(removePromoPopupToDisplay())
  }
  return (
    <Popup style={{ zIndex: 1001 }}>
      <div className={styles.body}>
        <ReactMarkdown source={t(popup?.content?.text)} escapeHtml={false}></ReactMarkdown>
      </div>
      <div className={styles.containerButtons}>
        {popup.content?.actions.map((action: any, index: number) => (
          <div onClick={onModalClose} className={styles.button} key={index}>
            {t(action.label)}
          </div>
        ))}
      </div>
    </Popup>
  )
}

export default PromoPopup
