import axios from 'axios'

import { config } from 'src/constants'

/**
 * 
 * @param {string} userId
 * @returns manager confirms employee registration on API
 */
export async function confirmEmployeeRegistration(userId) {
    return axios({
        url: `${config.API_URL3}/${window.busytable.brandSlug}/auth/confirm/${userId}`,
        method: 'POST',
        data: {},
        withCredentials: true,
        headers: { verified: 'yes' }
    }).then(resp => {
        if (resp?.data) {
            return resp?.data
        }
    }).catch(e => {
        if (e.response.data) {
            throw Error(e.response.data)
        } else {
            throw Error(e)
        }
    })
}