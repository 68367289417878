import { SET_TIMED_TAKEOUT } from './types'

/* State Shape
bool || Date
*/

const initialState = false

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_TIMED_TAKEOUT: {
      const { data } = action.payload
      return data
    }

    default:
      return state
  }
}
