import React, { ReactNode } from 'react'
import { SelectedOptionType } from 'src/types'
import useOptionSetApi from 'src/hooks/useOptionSetApi'
import { useTranslation } from 'src/hooks/useTranslation'
import s from '../styles.module.scss'

type OrderListItemOptionsProps = {
  options: SelectedOptionType[] | undefined
  recipeId: number | undefined
  renderLabel?: (label: string) => ReactNode
}

const OrderListItemOptions: React.FC<OrderListItemOptionsProps> = ({ options, recipeId, renderLabel }) => {
  const { isOptionInStopList, findFullOption, findOptionSet } = useOptionSetApi(recipeId)
  const { t } = useTranslation()

  return (
    <>
      {options?.map(option => {
        const optionSet = findOptionSet(option.id)
        const fullOption = findFullOption(option)
        const hasWarning = !fullOption || isOptionInStopList(fullOption?.modifierId)

        return (
          <React.Fragment key={option.id}>
            {!optionSet && `${t('listSection.optionNotFound')}`}
            {optionSet && (
              <div className={s.optionContainer}>
                {renderLabel && renderLabel(`${t(optionSet?.name || '')}:`.replace('::', ':'))}
                {!renderLabel && (
                  <span
                    data-cy={`orderOptionSetName-${optionSet?.id}`}
                    style={{ ...(hasWarning ? { color: 'var(--warningColor)' } : {}) }}
                  >
                    {`${t(optionSet?.name || '')}:`.replace('::', ':')}&nbsp;
                  </span>
                )}
                <span
                  data-cy={`orderOptionName-${fullOption?.id}`}
                  className={s.optionLabel}
                  style={{ ...(hasWarning ? { color: 'var(--warningColor)' } : {}) }}
                >
                  {t(fullOption?.name || '')}
                </span>
              </div>
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default OrderListItemOptions
