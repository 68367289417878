import React from 'react'
import { find, isEmpty, propEq } from 'ramda'
import { IconType } from './components/RichMenu/components/IconType'
import { isToday, getLanguageCode } from './utils/strings'
import { SHOPPING_CART_INIT_POSITION_Y } from 'src/constants'
import { current } from '@reduxjs/toolkit'

export const findId = (id, arr) => find(propEq('id', id), arr)

export const money = amount => {
  // const currency = new Intl.NumberFormat('en-IN', {
  //   style: 'decimal',
  //   maximumFractionDigits: 2
  // }).format(amount / 100)
  const currency = amount / 100
  return currency
}

export const isVenueOpen = venue => {
  let result = false

  if (venue) {
    const daysOfWeek = {
      0: 'sun',
      1: 'mon',
      2: 'tue',
      3: 'wed',
      4: 'thu',
      5: 'fri',
      6: 'sat'
    }

    const workingHours = venue.workingHours
    const now = Date.now()
    // console.log('now: ', now, new Date(now))
    const dayOfWeek = daysOfWeek[new Date(now).getDay()]
    const [openTime, closeTime] = workingHours[dayOfWeek] || ['13:00', '14:00']
    const open = new Date(now).setHours(...openTime.split(':'), 0)
    // console.log('open: ', open, new Date(open))
    let close = new Date(now).setHours(...closeTime.split(':'), 0)
    /**
     * case example
     * open = 14:00
     * close = 02:00
     */
    if (close < open) {
      close = new Date(close).setDate(new Date(close).getDate() + 1)
    }
    result = open < now && now < close
  }
  return result
}

export const ellipsizeTextBox = (el, row) => {
  el = el.current
  const styles = window.getComputedStyle(el)
  let wordArray = el.innerHTML.split(' ')
  while (el.scrollHeight > parseFloat(styles.lineHeight) * row + 1) {
    wordArray.pop()
    el.innerHTML = wordArray.join(' ') + ' ...'
  }
}

export const combineSameDishesOld = (orders, optionsets) => {
  // TODO: теряется порядок в котором пользователь заказывал блюда
  // Нужен глобальный рефакторинг чтобы не считать стоимость опций каждый раз, а получать их уже в объекте order

  const result = orders.reduce((acc, v) => {
    const isPrice = v && v.price ? v.price : 0
    const totalPrice = isEmpty(v.selectedOptions) ? isPrice : isPrice + calcOptionsCost(v.selectedOptions, optionsets)

    const optionsHash = v.selectedOptions.reduce((acc, v) => {
      acc += v.id.toString() + v.value.toString()
      return acc
    }, '')

    const idHash = `${v.dishId}OPTIONS${optionsHash}`

    acc[idHash] = acc[idHash]
      ? { ...acc[idHash], count: acc[idHash].count + 1 }
      : { dishId: v.dishId, totalPrice: totalPrice, count: 1, selectedOptions: v.selectedOptions }
    return acc
  }, {})

  return Object.values(result)
}

export const optionsToText = (options, optionsets, t) => {
  const result = options.map(({ id, value }) => {
    const tempId = id
    const optionset = optionsets.find(({ id }) => id === parseInt(tempId))
    if (!optionset) {
      return 'Loading...'
    }
    const modifier = optionset.options.find(({ id }) => id === parseInt(value))

    return `${t(optionset.name).toLowerCase()}: ${t(modifier.name).toLowerCase()}`
  })

  return result.join(', ')
}

export const calcOptionsCost = (options, optionsets) => {
  if (!optionsets || optionsets.length === 0) {
    return 0
  }
  const total = options.reduce((acc, { id, value }) => {
    const tempId = id
    const optionset = optionsets.find(({ id }) => id === parseInt(tempId))
    // console.log(optionset, id, value, optionsets)
    const option = optionset === undefined ? { price: 0 } : optionset.options.find(({ id }) => id === parseInt(value))

    acc += parseInt(option && option.price ? option.price : 0)
    return acc
  }, 0)

  return total
}

export const comparePosition = (a, b) => {
  return parseInt(a.position) - parseInt(b.position)
}

export const translate = (id, lang, translations) => {
  const fallbacks = languageFallback(lang)

  // проверить существует ли такой объект в переводах
  if (translations.hasOwnProperty(id)) {
    const locales = translations[id]

    // проверить существует ли такой язык в переводах
    // если нет то использовать стратегии замещения языка
    if (locales.hasOwnProperty(lang)) {
      return locales[lang]
    } else {
      /* eslint-disable no-unused-vars */
      for (const fallbackLang of fallbacks) {
        if (locales.hasOwnProperty(fallbackLang)) {
          return locales[fallbackLang]
        } else {
          return 'fallback error'
        }
      }
      /* eslint-enable no-unused-vars */
    }
  } else {
    return 'empty'
  }
}

const languageFallback = lang => {
  const strategies = {
    ru: ['en'],
    ua: ['ru', 'en'],
    de: ['en'],
    default: ['en']
  }

  return strategies.hasOwnProperty(lang) ? strategies[lang] : strategies['default']
}

export const isSafari = () => {
  var ua = navigator.userAgent.toLowerCase()
  if (ua.indexOf('safari') !== -1) {
    if (ua.indexOf('chrome') > -1) {
      return false // Chrome
    } else {
      return true // Safari
    }
  }
  return false
}

//  Device: Instagram 134.0.0.25 on an Apple iPhone 7 Plus running iOS 13.3.1
// Device: Instagram 134.0.0.25 on an unrecognized device (iPhone12,5) running iOS 13.3.1

export const isInstagram = () => {
  var ua = navigator.userAgent.toLowerCase()
  if (ua.indexOf('instagram') > -1) {
    return true
  } else {
    return false
  }
}

export const isIphone = () => {
  var ua = navigator.userAgent.toLowerCase()
  if (ua.indexOf('iphone') > -1) {
    return true
  } else {
    return false
  }
}

export const isInstagramIOS = () => {
  // return true
  return isInstagram() && isIphone()
}

export const isOptionInStoplist = (stoplist, id) => {
  const { options } = stoplist
  return options.indexOf(id) >= 0
}

export const isDishInStoplist = (stoplist, id) => {
  const { dishes } = stoplist
  return dishes.indexOf(id) >= 0
}

export const getCookingTime = (dishes, oldOrders, maxCookingTime) => {
  let time = 0
  let max = 0
  // console.log(dishes, oldOrders)
  if (dishes.length === 0) {
    return { max: 0, time: '' }
  }
  const findMaxCategory = orders => {
    let cats = []
    orders.forEach(d => {
      const e = dishes.filter(dish => dish.id === d.dishId)[0]
      if (!e) {
        return []
      }
      const ww = cats.filter(c => c.id === e.catId)
      if (!e) {
        return []
      }
      if (ww.length === 0) {
        cats = [
          ...cats,
          {
            id: e.catId,
            time: e.waitingTime
          }
        ]
      } else {
        cats = cats.map(c => {
          if (c.id === e.catId) {
            return {
              ...c,
              time: c.time + e.waitingTime
            }
          } else {
            return c
          }
        })
      }
    })
    if (cats.length > 0) {
      return cats.sort((b, a) => a.time - b.time)[0].id
    } else {
      return []
    }
  }
  const findMaxTotalTime = (catId, orders) => {
    let dishArr = orders.map(o => dishes.filter(d => d.id === o.dishId)[0])
    if (dishArr) {
      dishArr = dishArr.filter(di => di && di.catId === catId)
    } else {
      return 0
    }
    const res = dishArr
      .sort((b, a) => a.waitingTime - b.waitingTime)
      .map((da, ind) => {
        if (ind === 0) {
          return da.waitingTime
        } else {
          return da.waitingTime ? da.waitingTime / 3 : 0
        }
      })
    return res.reduce((total, curr) => total + curr, 0)
  }
  // let min = findMinDish(oldOrders)
  // console.log('[findMaxCategory(oldOrders)]', findMaxCategory(oldOrders))
  max = findMaxTotalTime(findMaxCategory(oldOrders), oldOrders)
  max /= 60
  if (maxCookingTime) {
    max = max > maxCookingTime ? maxCookingTime : max
  }

  time = `${Math.round(max)} мин.`

  return { max, time }
}

export const convertTimeToMinutes = time => Math.ceil(time / 60)

export const timeRange = (t = 0) => {
  if (t <= 5) return [3, 5]
  // if (t > 15) return [20, 25]
  const ceilTo = (x, point) => Math.ceil(x / point) * point
  const min = ceilTo(t, 5)
  if (t < 30) {
    return [min, min + 5]
  }
  if (t < 60) {
    return [min, min + 10]
  }
  return [min, min + 15]
}

export const isTomorrow = (timestamp = 0) => {
  const timeDiff = timestamp - new Date().setHours(0, 0, 0, 0)
  return timeDiff > 24 * 3600 * 1000 && timeDiff < 48 * 3600 * 1000
}

export const formatterPreorder = date => {
  const dateObj = new Date(date)
  const dateObjEnd = new Date(date)
  dateObjEnd.setHours(dateObjEnd.getHours() + 1)
  const timeString = `${dateObj
    .getHours()
    .toString()
    .padStart(2, 0)}:${dateObj
    .getMinutes()
    .toString()
    .padStart(2, 0)}`
  const timeStringEnd = `${dateObjEnd
    .getHours()
    .toString()
    .padStart(2, 0)}:${dateObjEnd
    .getMinutes()
    .toString()
    .padStart(2, 0)}`
  return [timeString, `${timeString} - ${timeStringEnd}`]
}
/**
 *
 * @param {number} date
 * @param {number} timeStep // in minutes
 * @param {function} t // useTranslation hook
 * @param {number} preorderOnFutureDaysNumber
 * @param {string} currentLangague // uk || en
 */
export const formatterDelivery = (t, date, currentLangague, preorderOnFutureDaysNumber, timeStep) => {
  const dateObj = new Date(date)
  const dateObjEnd = new Date(date)
  let prefix = ''
  const isTomorrowDay = isTomorrow(dateObj)
  const isTodayDay = isToday(dateObj)
  if (isTomorrowDay && preorderOnFutureDaysNumber <= 2) {
    prefix = `${t('general.tommorow')} `
  } else if (preorderOnFutureDaysNumber && !isTodayDay) {
    const languageCode = getLanguageCode(currentLangague)
    prefix = `${dateObj.toLocaleDateString(languageCode, { weekday: 'long' })}, ${dateObj.toLocaleDateString(
      languageCode,
      {
        day: 'numeric',
        month: 'long'
      }
    )} `
  }
  dateObjEnd.setMinutes(dateObjEnd.getMinutes() + (timeStep || 60))
  const timeString = `${dateObj
    .getHours()
    .toString()
    .padStart(2, 0)}:${dateObj
    .getMinutes()
    .toString()
    .padStart(2, 0)}`
  const timeStringEnd = `${dateObjEnd
    .getHours()
    .toString()
    .padStart(2, 0)}:${dateObjEnd
    .getMinutes()
    .toString()
    .padStart(2, 0)}`
  return [`${prefix}`, `${timeString} - ${timeStringEnd}`]
}

// TODO: временное решение, переписать. Все криво.
export const formatterDeliverySelected = (timeString, timePickerStep = 60) => {
  let tomorrowString = ''
  let timeWithoutText = timeString

  if (timeString.indexOf('Завтра') >= 0) {
    tomorrowString = 'Завтра '
    timeWithoutText = timeString.split('Завтра ')[1]
  }

  const hours = Number(timeWithoutText.split(':')[0])
  const minutes = Number(timeWithoutText.split(':')[1])

  let dateObjEnd = new Date()
  dateObjEnd.setHours(hours, minutes, 0, 0)
  dateObjEnd.setMinutes(dateObjEnd.getMinutes() + (timePickerStep || 60))
  const timeStringEnd = `${dateObjEnd
    .getHours()
    .toString()
    .padStart(2, 0)}:${dateObjEnd
    .getMinutes()
    .toString()
    .padStart(2, 0)}`

  return `${timeString} - ${timeStringEnd}`
}

// TODO: костыль. Нужно глобально переделать структуру сохранения даты чтобы избавиться от такого
export const timedTakeoutFormatter = (time, preorder) => {
  if (time.indexOf('Завтра') >= 0 && preorder) {
    time = time.split('Завтра ')[1]
  }
  const date = new Date().setHours(...time.split(':'))
  return preorder ? formatterPreorder(date)[1] : time
}

export const defaultFormatter = date => {
  let prefix = ''
  const dateObj = new Date(date)
  const today = new Date().setHours(0, 0, 0, 0)
  const date0 = new Date(date).setHours(0, 0, 0, 0)
  const daysDiff = (date0 - today) / (24 * 3600 * 1000)

  if (daysDiff === 0) {
    prefix = ''
  } else if (daysDiff === 1) {
    prefix = 'Завтра '
  } else {
    // prefix = `${dateObj.toLocaleString('ru-RU', { weekday: 'short', day: 'numeric', month: 'short' })} `
    prefix = ''
  }
  const timeString = `${dateObj
    .getHours()
    .toString()
    .padStart(2, 0)}:${dateObj
    .getMinutes()
    .toString()
    .padStart(2, 0)}`

  // TODO: переписать
  // return [dateObj.toJSON(), `${prefix}${timeString}`]
  return [`${prefix}${timeString}`, `${prefix}${timeString}`]
}

const daysOfWeek = {
  0: 'sun',
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thu',
  5: 'fri',
  6: 'sat'
}

export const calculateIsCookingTimeBeforeClose = (cookingTime = 0, workingHours) => {
  const now = new Date()

  const dayOfWeek = daysOfWeek[now.getDay()]
  const [open, workingHoursClose] = workingHours ? workingHours[dayOfWeek] : ['8:00', '23:00']
  const cookingTimeAsMilliseconds = cookingTime * 60 * 1000
  const closeTime = new Date().setHours(...workingHoursClose.split(':'), 0, 0)

  if (now.getTime() + cookingTimeAsMilliseconds < closeTime) {
    return true
  }

  return false
}

export const isCookingTimeInStopList = (stopListItems = [], cookingTime = 0) => {
  const now = new Date().getTime()
  const cookingTimeAsMilliseconds = cookingTime * 60 * 1000
  const dishReadyDateTime = now + cookingTimeAsMilliseconds

  for (let i = 0; i < stopListItems.length; i++) {
    const currentStopListItem = stopListItems[i]

    const fromDate = new Date(currentStopListItem.startingAt).getTime()
    const dueDate = new Date(currentStopListItem.endingAt).getTime()

    if (dishReadyDateTime > fromDate && dishReadyDateTime < dueDate) {
      return true
    }
  }

  return false
}

export const isVenueOpenDate = (date, workingHours) => {
  const d = new Date(date)
  const dayOfWeek = daysOfWeek[d.getDay()]
  const [openTime, closeTime] = workingHours ? workingHours[dayOfWeek] : ['8:00', '23:00']
  const open = new Date(date).setHours(...openTime.split(':'), 0, 0)
  const close = new Date(date).setHours(...closeTime.split(':'), 0, 0)
  return open < d.getTime() && d.getTime() <= close
}

export const getOpenTime = (date, workingHours) => {
  const providedDate = new Date(date)
  const dayOfWeek = daysOfWeek[providedDate.getDay()]
  const [openTime] = workingHours ? workingHours[dayOfWeek] : ['8:00', '23:00']
  const [openHours, openMinutes] = openTime.split(':')
  return new Date(date).setHours(openHours, openMinutes, 0, 0)
}

export const datesRange = (workingHours, start, step = 60, toDate, cookingTime) => {
  const openDate = getOpenTime(start, workingHours)
  const limit = new Date(toDate).getTime()
  const stepMs = step * 60 * 1000
  const now = new Date().getTime()
  const cookingTimeAsMilliseconds = cookingTime * 60 * 1000
  const datesRangeRec = (dates, prev) => {
    if (prev >= limit) return dates
    const next = prev + stepMs
    if (
      prev >= start &&
      next >= start &&
      isVenueOpenDate(next, workingHours) &&
      prev >= now + cookingTimeAsMilliseconds
    ) {
      dates = [...dates, prev]
    }
    return datesRangeRec(dates, next)
  }
  return datesRangeRec([], openDate)
}

/**
 * @deprecated
 * @todo this is just draft. Needs complete rewrite.
 * @param {string|object} text - data to translate
 * @returns {string} translated text
 * @function
 */
// export const t = text => {
//   if (!text) return ''
//   if (typeof text === 'string') return text
//   if ('ru' in text) return text.ru
//   if ('en' in text) return text.en

//   return Object.values(text)[0]
// }

/**
 * @todo This is just placeholder. Rewrite.
 */
export const dishIcon = ({ dish, size = 24 }) => {
  return <IconType type={'burger'} size={size} />
}

export const uriTransformer = uri => {
  const protocols = ['http', 'https', 'mailto', 'tel', 'tg']
  const url = (uri || '').trim()
  const first = url.charAt(0)

  if (first === '#' || first === '/') {
    return url
  }

  const colon = url.indexOf(':')
  if (colon === -1) {
    return url
  }

  const length = protocols.length
  let index = -1

  while (++index < length) {
    const protocol = protocols[index]

    if (colon === protocol.length && url.slice(0, protocol.length).toLowerCase() === protocol) {
      return url
    }
  }

  index = url.indexOf('?')
  if (index !== -1 && colon > index) {
    return url
  }

  index = url.indexOf('#')
  if (index !== -1 && colon > index) {
    return url
  }

  // eslint-disable-next-line no-script-url
  return 'javascript:void(0)'
}

const _iphoneHeights = [
  { name: 'iPhone 12', width: '390', height: '844', pwidth: '1170', pHeight: '2532', ppi: '460' },
  { name: 'iPhone 12 mini', width: '360', height: '780', pwidth: '1080', pHeight: '2340', ppi: '476' },
  { name: 'iPhone 12 Pro Max', width: '428', height: '926', pwidth: '1284', pHeight: '2778', ppi: '458' },
  { name: 'iPhone 12 Pro', width: '390', height: '844', pwidth: '1170', pHeight: '2532', ppi: '460' },
  { name: 'iPhone SE 2nd gen', width: '375', height: '667', pwidth: '750', pHeight: '1334', ppi: '326' },
  { name: 'iPhone 11 Pro Max', width: '414', height: '896', pwidth: '1242', pHeight: '2688', ppi: '	458' },
  { name: 'iPhone 11 Pro', width: '375', height: '812', pwidth: '1125', pHeight: '2436', ppi: '458' },
  { name: 'iPhone 11', width: '414', height: '896', pwidth: '828', pHeight: '1792', ppi: '326' },
  { name: 'iPhone XR', width: '414', height: '896', pwidth: '828', pHeight: '1792', ppi: '326' },
  { name: 'iPhone XS Max', width: '414', height: '896', pwidth: '1242', pHeight: '2688', ppi: '458' },
  { name: 'iPhone XS', width: '375', height: '812', pwidth: '1125', pHeight: '2436', ppi: '458' },
  { name: 'iPhone X', width: '375', height: '812', pwidth: '1125', pHeight: '2436', ppi: '458' },
  { name: 'iPhone 8 Plus', width: '414', height: '736', pwidth: '1242', pHeight: '2208', ppi: '401' },
  { name: 'iPhone 8', width: '375', height: '667', pwidth: '750', pHeight: '1334', ppi: '326' },
  { name: 'iPhone 7 Plus', width: '414', height: '736', pwidth: '1242', pHeight: '2208', ppi: '401' },
  { name: 'iPhone 7', width: '375', height: '667', pwidth: '750', pHeight: '1334', ppi: '326' },
  { name: 'iPhone SE 1st gen', width: '320', height: '568', pwidth: '640', pHeight: '1136', ppi: '326' },
  { name: 'iPhone 6s', width: '375', height: '667', pwidth: '750', pHeight: '1334', ppi: '326' }
]

const _predicateWidth = x => parseInt(x.width) === window.innerWidth
const _predicateWidthHeight = x =>
  parseInt(x.width) === window.innerWidth && parseInt(x.height) === window.innerHeight - 114
const _predicateWidthHeightInstagram = x =>
  parseInt(x.width) === window.innerWidth && parseInt(x.height) === window.innerHeight - 108
export const getHeightByUA = () => {
  if (isIphone()) {
    // alert(navigator.userAgent)
    // alert([window.innerWidth, window.innerHeight].join(' '))
    const resultsCount = _iphoneHeights.filter(_predicateWidth)
    return _iphoneHeights.find(x =>
      resultsCount > 1
        ? _predicateWidth
        : isInstagram()
        ? _predicateWidthHeightInstagram || _predicateWidth
        : _predicateWidthHeight || _predicateWidth
    )
  }
  return 113
}

export const arraysEqual = (a, b) => {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

export const getTimestamp = (date, time) => {
  let resultDate = date ? new Date(date) : parseTime(time)
  let resultTime = time ? parseTime(time) : new Date()

  resultDate.setHours(
    resultTime.getHours(),
    resultTime.getMinutes(),
    resultTime.getSeconds(),
    resultTime.getMilliseconds()
  )

  return resultDate.getTime()
}

const parseTime = time => {
  let t = new Date()

  // TODO: удалить временный костыль
  if (time && time.indexOf('Завтра') >= 0) {
    const timeParts = time.split(' ')
    t.setDate(t.getDate() + 1)
    t.setHours(...timeParts[1].split(':'), 0, 0)
  } else if (time) {
    t.setHours(...time.split(':'), 0, 0)
  }

  return t
}

export const isValidJSON = jsstr => {
  if (
    /^[\],:{}\s]*$/.test(
      jsstr
        .replace(/\\["\\\/bfnrtu]/g, '@')
        .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
        .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
    )
  ) {
    //the json is ok
    return true
  } else {
    //the json is not ok
    return false
  }
}

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  var R = 6371 // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1) // deg2rad below
  var dLon = deg2rad(lon2 - lon1)
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c // Distance in km
  return d
}

const deg2rad = deg => {
  return deg * (Math.PI / 180)
}

export const onlyUnique = (value, index, self) => self.indexOf(value) === index
/**
 *
 * @param {boolean} isNewDesign
 */
export const calculateInit = isNewDesign => {
  const vh = window.innerHeight
  if (window.innerWidth > 1025) {
    return SHOPPING_CART_INIT_POSITION_Y
  } else {
    //553
    // if (isIphone() && isInstagram()) {
    if (isIphone() && isInstagram() && !isNewDesign) {
      // const isFound = getHeightByUA()
      // alert([window.innerWidth, window.innerHeight].join(' '))
      // alert([window.innerWidth, window.innerHeight].join(' '))
      // alert(JSON.stringify(isFound))
      // return isFound ? (isInstagram() ? isFound.height - 80 - 108 : isFound.height - 80 - 114) : vh - 100
      return -100
    } else if (
      window.matchMedia('(display-mode: standalone)').matches ||
      window.navigator.standalone ||
      document.referrer.includes('android-app://')
    ) {
      return SHOPPING_CART_INIT_POSITION_Y
    } else {
      return SHOPPING_CART_INIT_POSITION_Y
    }
  }
}

export const day = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][new Date().getDay()]

export const MARKER_TYPES = {
  ACTIVE: 'active_marker',
  DEFAULT: 'default_marker',
  DISABLED: 'disabled_marker'
}

export const SIZE_TYPES = {
  small: 'marker_small',
  normal: 'marker_normal',
  big: 'marker_big'
}

export const workingHoursValidator = v => {
  const workHrs = v.workingHours
  const curentHrs = new Date().getHours()
  const validDay = workHrs[day] !== undefined
  if (!workHrs[day] || !workHrs[day][0]) {
    return [validDay, false]
  }
  const validTime = workHrs[day][0].slice(0, -3) && curentHrs <= workHrs[day][1].slice(0, -3)
  return [validDay, validTime]
}

export const timeSegment = ({ range, start, end, workingHours }) => {
  return range.filter(x => x >= start && x < end && isVenueOpenDate(x, workingHours))
}

export const timestampsRange = (step, fromDate, toDate) => {
  const dayStart = new Date(fromDate).setHours(0, 0, 0, 0)
  const limit = new Date(toDate).getTime()

  const datesRangeRec = (dates, prev) => {
    if (prev >= limit) return dates
    const next = prev + step

    if (next >= dayStart) {
      dates.push(next)
    }

    return datesRangeRec(dates, next)
  }

  return datesRangeRec([dayStart], dayStart)
}

export const getDatesRangeForTakeAwayTimePicker = ({ cookingTime, workingHours, fromDate, toDate }) => {
  const steps = [
    {
      step: 30 * 60 * 1000,
      start: 240 * 60 * 1000
    },
    {
      step: 15 * 60 * 1000,
      start: 30 * 60 * 1000
    },
    {
      step: 5 * 60 * 1000,
      start: 5 * 60 * 1000
    },
    {
      step: 1 * 60 * 1000,
      start: 0
    }
  ]
  const cookingEnd = Date.now() + cookingTime * 60 * 1000
  const result = steps.reduce((acc, v) => {
    const end = acc.length ? acc[0] : 9999999999999
    const range = timestampsRange(v.step, fromDate, acc[0] || toDate)
    const segment = timeSegment({ range, start: cookingEnd + v.start, end, workingHours })
    acc = [...segment, ...acc]

    return acc
  }, [])

  return result
}

/**
 *
 * @param {any[]} orders
 * @param {any[]} optionsets
 * @returns {number}
 */
export const calculateOrdersAmount = (orders, optionsets) => {
  const ordersAmount = orders.reduce((totalAmount, order) => {
    const optionsPrice = calcOptionsCost(order.selectedOptions, optionsets)
    totalAmount += order.price + optionsPrice
    return totalAmount
  }, 0)

  return ordersAmount
}

/**
 *
 * @param {object} possibleLanguagesList
 * @param {string} brandFallbackLanguage
 * @returns {string} // en or uk or ru ..etc
 */
export const defineUserLanguage = (possibleLanguagesList, brandFallbackLanguage) => {
  const activeLanguageFromLocalStorage = localStorage.getItem('__userlang')
  let activeLanguage = activeLanguageFromLocalStorage
  const languages = possibleLanguagesList
  if (!activeLanguageFromLocalStorage) {
    const languageString = navigator.language || navigator.userLanguage
    const userLanguage =
      languageString.indexOf('-') > 0 && languageString.split('-').length > 0
        ? languageString.split('-')[0]
        : languageString
    if (languages && languages[userLanguage]) {
      activeLanguage = userLanguage
    } else {
      activeLanguage = brandFallbackLanguage
    }
  }
  return activeLanguage
}

/**
 * this function compares two arrays
 * @param {array} arr1
 * @param {array} arr2
 * @returns {boolean}
 */
export const areArrayEquals = (arr1, arr2) => {
  return JSON.stringify(arr1) === JSON.stringify(arr2)
}

export const filterExistedOptionsForDish = (options, dishRecipeId, optionSets = []) => {
  return options?.filter(option =>
    optionSets.some(
      optionSet =>
        optionSet.id === option.id &&
        dishRecipeId === optionSet.recipeId &&
        optionSet.options?.some(optionItem => optionItem.id === option.value)
    )
  )
}

/**
 *
 * @param {number} timestamp
 * @returns {string}
 * @example 2021-12-21T14:00:00
 */
export const transformTimestampToString = timestamp => {
  const hours = new Date(timestamp).getHours()
  const minutes = new Date(timestamp).getMinutes()
  const dayOfMonth = new Date(timestamp).getDate()
  const month = new Date(timestamp).getMonth() + 1 // month starts from 0
  const year = new Date(timestamp).getFullYear()
  const transformToTime = currentNumber => {
    if (currentNumber < 10) {
      return `0${currentNumber}`
    }
    return currentNumber
  }
  return `${year}-${transformToTime(month)}-${transformToTime(dayOfMonth)}T${transformToTime(hours)}:${transformToTime(
    minutes
  )}:00`
}

const isTouchDevice = function() {
  return 'ontouchstart' in window || 'onmsgesturechange' in window
}

export const isDesktop = !isTouchDevice()
