import { IDish, OptionType } from '../../types'

export const getOpenPositionValue = (): number => {
  if (window.innerWidth > 1025) {
    return -window.innerHeight * 0.86 + 100
  }

  return -window.innerHeight + 100
}

export const compareOptionsPosition = (a: OptionType, b: OptionType) => {
  return Number(a.position) - Number(b.position)
}

export const compareSizesPosition = (a: IDish, b: IDish) => {
  return Number(a.sizePosition) - Number(b.sizePosition)
}
