import React from 'react'
import { IDishTab } from '.'
import styles from './styles.module.scss'

export interface IDishTabs {
  currentActiveTab: IDishTab | null
  handleTab: (tab: IDishTab) => void
  tabs: IDishTab[]
}

const DishTabs: React.FC<IDishTabs> = ({ handleTab, tabs, currentActiveTab }) => {
  return (
    <div className={styles.dishTabsOuterWrapper}>
      <div className={styles.dishTabsWrapper}>
        <div
          style={{
            transform: `translateX(${tabs.findIndex(val => val.type === currentActiveTab?.type) * 100}%)`,
            width: `${100 / tabs.length}%`
          }}
          className={styles.dishTabIndicator}
        />
        {tabs.map(tab => {
          return (
            <div
              key={`${tab.type}`}
              onClick={() => {
                handleTab(tab)
              }}
              className={`${styles.dishTab} ${currentActiveTab?.type === tab.type ? styles.activeTab : ''}`}
            >
              {tab.value}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DishTabs
