import {
  ADD_COMMENT,
  ADD_MANY_ORDERS,
  ADD_NEW_ORDER,
  CLEAR_NEW_ORDERS,
  FETCH_PAID_ORDERS,
  PROCESS_ORDER_FROM_WEBSOCKET,
  REMOVE_COMMENT,
  REMOVE_NEW_DISH,
  REMOVE_ONE_ORDER,
  REMOVE_UNPAID,
  SET_NEW_ORDERS,
  SET_PAYMENT_TYPE,
  SET_TAKEOUT,
  UPDATE_ORDER
} from './types'
import { IOrder, NewOrderInput, PaymentMethod, SelectedOptionType } from '../../types'
import { OrdersActionType } from './actions'

export type OrdersState = Readonly<{
  new: NewOrderInput[]
  paid: IOrder[]
  paymentType?: PaymentMethod
  takeout: boolean
  comment: string
}>

const initialState: OrdersState = {
  new: [],
  paid: [],
  paymentType: undefined,
  takeout: true,
  comment: ''
}

export default function ordersReducer(state: OrdersState = initialState, action: OrdersActionType): OrdersState {
  switch (action.type) {
    case FETCH_PAID_ORDERS: {
      const orders = action.orders

      return {
        ...state,
        new: state.new.filter(x => orders.filter(order => Number(order.tempId) === x.tempId).length === 0),
        paid: orders
          .filter(e => e)
          .map(e => (e.selectedOptions === null || e.selectedOptions === undefined ? { ...e, selectedOptions: [] } : e))
      }
    }

    case ADD_COMMENT: {
      const comment = action.comment
      return {
        ...state,
        comment
      }
    }

    case REMOVE_COMMENT: {
      return {
        ...state,
        comment: ''
      }
    }

    case ADD_NEW_ORDER: {
      const orderInput = action.newOrder

      // when order made from PaymentHistory we generate tempIds there
      const newOrder = {
        tempId: orderInput.tempId ? orderInput.tempId : Date.now(),
        dishId: orderInput.id,
        price: orderInput.price,
        takeout: state.takeout,
        selectedOptions: orderInput.selectedOptions
      }

      return {
        ...state,
        new: [newOrder, ...state.new]
      }
    }

    case ADD_MANY_ORDERS: {
      return {
        ...state,
        new: [
          ...action.orders.map((orderInput, i) => {
            return {
              tempId: Date.now() + i,
              dishId: orderInput.id,
              price: orderInput.price,
              takeout: state.takeout,
              selectedOptions: orderInput.selectedOptions
            }
          }),
          ...state.new
        ]
      }
    }

    case UPDATE_ORDER: {
      const updatedOrder = action.updatedOrder

      return {
        ...state,
        new: [...state.new.filter(e => e.tempId !== updatedOrder.tempId), updatedOrder]
      }
    }

    case SET_PAYMENT_TYPE: {
      const method = action.method

      return {
        ...state,
        paymentType: method
      }
    }

    case SET_TAKEOUT: {
      const flag = action.flag

      return {
        ...state,
        takeout: flag
      }
    }

    case REMOVE_UNPAID: {
      const details = action.details

      // TODO: update hardcoded status
      const newOrders = state.new.map(o => ({
        ...o,
        paymentId: details.paymentId,
        venueSlug: details.venueSlug,
        paidAt: new Date().toJSON(),
        takeoutNum: details.takeoutNum,
        takeout: state.takeout,
        status: { paid: new Date().toISOString() },
        timedTakeout: details.timedTakeout,
        info: details.info
      }))

      console.log(newOrders, 'new orders')

      return {
        ...state,
        comment: '',
        new: [],
        paid: [...newOrders, ...state.paid] as IOrder[]
      }
    }

    case REMOVE_NEW_DISH: {
      const { dishId, options } = action.payload

      const res = state.new.filter(x => {
        const isDish = x.dishId === dishId
        const arrMatch = arraysMatch(x.selectedOptions, options)
        return !(isDish && arrMatch)
      })

      return {
        ...state,
        new: res
      }
    }

    case REMOVE_ONE_ORDER: {
      const { dishId, options } = action.payload

      let indexes = 0
      const newstate = state.new.filter(x => {
        if (indexes > 0) {
          return true
        }
        if (x.dishId === dishId && arraysMatch(x.selectedOptions, options)) {
          indexes = indexes + 1
          return false
        } else {
          return true
        }
      })

      return {
        ...state,
        new: newstate
      }
    }

    case PROCESS_ORDER_FROM_WEBSOCKET: {
      const parsedOrder = JSON.parse(action.orderMessage) as IOrder
      const order = { ...parsedOrder, selectedOptions: parsedOrder.options } as IOrder
      const paidUpdated = state.paid.filter(x => x.tempId !== order.tempId)

      return {
        ...state,
        new: state.new.filter(x => x.tempId !== Number(order.tempId)),
        paid: [
          order,
          ...paidUpdated.map(e =>
            e.selectedOptions === null || e.selectedOptions === undefined ? { ...e, selectedOptions: [] } : e
          )
        ]
      }
    }

    case CLEAR_NEW_ORDERS: {
      return {
        ...state,
        new: []
      }
    }

    case SET_NEW_ORDERS: {
      return {
        ...state,
        new: action.orders
      }
    }

    default:
      return state
  }
}

export const arraysMatch = (a: SelectedOptionType[] | undefined, b: SelectedOptionType[]) => {
  return JSON.stringify(a) == JSON.stringify(b)
}
