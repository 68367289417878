import React, { useState } from 'react'
import Slider from 'react-slick'
import MediaItem from './MediaItem'
import { MediaItemType } from '../../types'
import styles from './styles.module.scss'

type MediaCarouselIndicatorDirection = 'horizontal' | 'vertical'

type MediaCarouselProps = {
  medias: MediaItemType[]
  isMenu?: boolean
  isHorizontal?: boolean
  activeIndex: number
  handleActiveMediaIndex: (activeIndex: number) => void
  isExpanded?: boolean
  indicatorDirection?: MediaCarouselIndicatorDirection
}

const MediaCarousel: React.FC<MediaCarouselProps> = ({
  medias,
  isMenu,
  isHorizontal,
  activeIndex,
  handleActiveMediaIndex,
  isExpanded,
  indicatorDirection
}) => {
  const renderIndicator = () => (
    <div
      className={`${styles.indicatorsWrapper} ${isExpanded ? styles.indicatorsVisible : ''} ${
        indicatorDirection === 'horizontal' ? styles.indicatorsWrapperHorizontal : ''
      }`}
    >
      {medias.map((item, index) => (
        <span
          key={`mediaIndicator_${index}`}
          className={`${styles.indicator} ${index === activeIndex ? styles.indicatorActive : ''}`}
        />
      ))}
    </div>
  )

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // vertical: true,
    // verticalSwiping: true,
    nextArrow: <></>,
    prevArrow: <></>,
    beforeChange: (current: number, next: number) => handleActiveMediaIndex(next),
    adaptiveHeight: true
  }

  return (
    <div className={`${styles.outerWrapper} ${isHorizontal ? styles.outerWrapperHorizontal : ''}`}>
      {renderIndicator()}
      <Slider className={`${styles.wrapper} ${isMenu ? styles.menuWrapper : ''}`} {...settings}>
        {medias.map((media, index) => (
          <MediaItem isActive={index === activeIndex} key={media.path} data={media} />
        ))}
      </Slider>
    </div>
  )
}
export default MediaCarousel
