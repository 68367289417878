import React, { ReactNode } from 'react'
import clsx from 'classnames'
import useAppPreferences from 'src/hooks/useAppPreferences'
import { HolidayModifications } from 'src/types'

type PopupProps = {
  fullsize?: boolean
  additional?: ReactNode
  header?: ReactNode
  childStyles?: React.CSSProperties
  style?: React.CSSProperties
  withoutHeaderWrapper?: boolean
  onClick?: () => void
  onChildClick?: () => void
}

export const Popup: React.FC<PopupProps> = ({
  fullsize,
  additional,
  header,
  childStyles,
  style,
  withoutHeaderWrapper,
  onClick,
  onChildClick,
  children
}) => {
  const { holidayModifications }: { holidayModifications: HolidayModifications | undefined } = useAppPreferences()
  const hasCustomVariant: boolean =
    !!holidayModifications?.currentVariant &&
    holidayModifications.variants?.[holidayModifications.currentVariant || '']?.showCustomPopup

  if (fullsize) {
    return (
      <div className={['confirmation'].join(' ')}>
        {additional}
        {withoutHeaderWrapper ? header : <div className={'header'}>{header}</div>}
        <div className={'contentWrapper'}>
          <div className={'content'}>{children}</div>
        </div>
      </div>
    )
  }

  return (
    <div
      onClick={onClick || undefined}
      style={style || undefined}
      className={['confirmation', 'confirmationUi'].join(' ')}
    >
      <div
        onClick={onChildClick || undefined}
        style={childStyles || undefined}
        className={clsx(
          'confirmationBox',
          hasCustomVariant && `confirmationBox_${holidayModifications?.currentVariant}`
        )}
      >
        {children}
      </div>
    </div>
  )
}
