import React, { useCallback } from 'react'
import { Button } from 'src/components/ui/Button'
import { toggleCheck } from 'src/state/user'
import { postEvent } from 'src/api'
import { useDispatch } from 'src/state/hooks'
import { clearOrderCheckout, addOrderCheckout } from 'src/state/orderCheckout'
import useUnpaidOrders from '../../../hooks/useUnpaidOrders'

type PaymentButtonProps = {
  label: string
}

const PaymentButton: React.FC<PaymentButtonProps> = function ({ label }) {
  const dispatch = useDispatch()

  const [{ orders }] = useUnpaidOrders()

  const handlePayment = useCallback(() => {
    dispatch(clearOrderCheckout())
    dispatch(addOrderCheckout(orders))
    dispatch(toggleCheck(true))
    postEvent('cart-btn_checkout_normal', 'payment')
  }, [orders, dispatch])

  return (
    <Button style={{ margin: '24px auto' }} onClick={handlePayment}>
      <span>{label}</span>
    </Button>
  )
}

export default PaymentButton
