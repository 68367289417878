import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'src/state/hooks'
import { motion, useMotionValue, useTransform } from 'framer-motion'
import { activityViewAnimate } from 'src/state/ui/actions'
import { IconType } from '../RichMenu/components/IconType'
import { isDesktop } from 'src/utils'
import useAppPreferences from 'src/hooks/useAppPreferences'

type AddingAnimationProps = {
  dish: any
  clic: any
  id: any
  cb: any
  iconColor?: string
}

const AddingAnimation: React.FC<AddingAnimationProps> = ({ dish, clic, id, cb, iconColor }) => {
  const dispatch = useDispatch()
  const animatedElementRef = useRef<HTMLDivElement>(null)
  const [clicked, setClicked] = useState(clic)
  const [initialCoordinate, setInitialCoordinate] = useState<number | null>(null)
  const scale = useMotionValue(1)
  const x = useMotionValue(0)
  const { preferences } = useAppPreferences()
  const isNewDesignMode = isDesktop ? false : preferences.designVersion === 1.1
  const y = useTransform(x, value => {
    if (!isNewDesignMode) {
      return 0.05 * Math.pow(value - 50, 2) - 180
    }
    const paramA = (initialCoordinate ? initialCoordinate : 0) < 100 ? 0.015 : 0.012
    return paramA * Math.pow(value - 70, 2) - 90
  })
  let i = 0
  useEffect(() => {
    if (animatedElementRef.current && !initialCoordinate) {
      const coordinates = animatedElementRef.current.getBoundingClientRect()
      setInitialCoordinate(coordinates.y)
    }
  }, [animatedElementRef.current])
  const dishIconAnimation = useCallback(() => {
    const yPosition = y.get() + (initialCoordinate || 0)
    setClicked(true)
    x.set(i)
    isNewDesignMode ? scale.set(1 + i / 67) : scale.set(1 + i / 50)
    if (yPosition <= window.innerHeight - (isNewDesignMode ? 100 : -20)) {
      window.requestAnimationFrame(dishIconAnimation)
    } else {
      x.stop()
      scale.set(1)
      if (!isNewDesignMode) {
        dispatch(activityViewAnimate())
      }
      cb(id)
    }
    i += isNewDesignMode ? 7 : 4
  }, [initialCoordinate, animatedElementRef.current])

  initialCoordinate && !clicked && dishIconAnimation()
  return (
    <div ref={animatedElementRef}>
      <motion.div style={{ x, y, scale }} className={['iconType'].join(' ')}>
        <IconType
          // elemRef={animatedElementRef}
          style={{ backgroundColor: iconColor || 'var(--fontColor)' }}
          type={dish.icon}
          size={24}
        />
      </motion.div>
    </div>
  )
}

export default AddingAnimation
