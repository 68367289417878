import React from 'react'
import useAppPreferences from 'src/hooks/useAppPreferences'
import { ShoppingCart } from './ShoppingCart'

const ShoppingCartContainer: React.FC = () => {
  const { preferences } = useAppPreferences()

  if (preferences.withQrMenuMode && preferences.disableShoppingCart) {
    return <></>
  }

  return (
    <div>
      <ShoppingCart />
    </div>
  )
}

export default ShoppingCartContainer
