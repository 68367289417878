import { useCallback, useMemo, useState } from 'react'
import { fetchImHere, postEvent } from 'src/api'
import { useTranslation } from '../useTranslation'

type TakeoutNotifyReturnValue = {
  notify: (value: string) => void
  alreadyNotified: number[]
}

export default function useTakeoutNotify(takeoutNum: number | null): TakeoutNotifyReturnValue {
  const [alreadyNotified, setAlreadyNotified] = useState<number[]>([])

  const { t } = useTranslation()

  const notify = useCallback(
    async (value: string) => {
      if (!takeoutNum) return

      await fetchImHere({
        takeout: takeoutNum,
        text: value === '' ? t('orderSection.notificationInfo') : value
      })
      postEvent('cart-imready-btn_send', 'involvement', { value })
      setAlreadyNotified(prevState => [...prevState, takeoutNum])
    },
    [takeoutNum, t]
  )

  const state = useMemo(
    () => ({
      alreadyNotified
    }),
    [alreadyNotified]
  )

  const api = useMemo(
    () => ({
      notify
    }),
    [notify]
  )

  return {
    ...state,
    ...api
  }
}
