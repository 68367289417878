import { Dispatch } from 'redux'
import { ITagsActionsTypes, setTags } from './actions'
import { getActiveTags } from 'src/network/get/tags'

export const getActiveTagsThunk = () => async (dispatch: Dispatch<ITagsActionsTypes>) => {
  try {
    const tags = window.busytable.tags || (await getActiveTags())
    if (tags?.data) {
      dispatch(setTags(tags.data))
    }
  } catch (e) {
    //@ts-ignore
    console.log(`Error while fetching tags: ${e.message}`)
  }
}
