import React, { useEffect, useState } from 'react'
import { useTranslation } from 'src/hooks/useTranslation'
import { ReactComponent as TableIcon } from 'src/assets/ico-table-big.svg'
import { ReactComponent as BenchIcon } from 'src/assets/ico-bench.svg'
import { ReactComponent as NoTable } from 'src/assets/ico-table-clear-24.svg'
import { ReactComponent as TogoIcon } from 'src/assets/ico-togo-big.svg'
import { ReactComponent as TogoIcon2 } from 'src/assets/ico-togo-24.svg'
import { ReactComponent as DeliveryIcon } from 'src/assets/ico-delivery.svg'
import usePaidOrders from 'src/hooks/usePaidOrders'
import { isTakeoutOrder } from 'src/utils/orders'
import { ORDER_STATUS } from 'src/types'
import { useDispatch, useSelector } from 'src/state/hooks'
import { getVenues } from 'src/state/venues'
import { Popup } from '../ui/Popup.tsx'
import s from './styles.module.scss'
import { setTakeoutReady } from '../../state/local/actions'
import { getLastTakeoutReady } from '../../state/local/selectors'

export const ConfirmationView = ({ onClose, venueId, tableNum, title, subTitle, btnText, scan, children }) => {
  const { t } = useTranslation()
  return (
    <div style={{ backgroundColor: '#f2f2f2', paddingTop: '0px' }} className={'confirmation'}>
      <div style={{ backgroundColor: '#f2f2f2' }} className={'header'}>
        <div onClick={onClose} className={s.closeIcon}></div>
        {title ? title : ''}
      </div>

      <div className={'content'}>
        {scan ? <div className={'body1'}>{t('confirmationView.header')}</div> : ''}
        {tableNum ? <Table {...{ tableNum, text: subTitle ? subTitle : t('yourTable') }} /> : null}
        {children}
        <div className={s.doneWrapper}>
          <div className={s.done} onClick={onClose}>
            <div className={s.doneText}>{btnText ? btnText : t('confirmationView.button')}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ConfirmNotify = ({ className }) => {
  const { t } = useTranslation()
  return (
    <div style={{ paddingTop: '0px' }} className={[className].join(' ')}>
      <div style={{ minHeight: '144px' }} className={s.confirmationBox}>
        <div style={{ display: 'flex', marginTop: '48px', justifyContent: 'center' }}>{t('confirmNotify.body')}</div>
        <div style={{ marginTop: '48px' }} className={s.hr}></div>
        {/* <div className={s.confirmationBoxButtonWrapper}>
        <div onClick={callBack} className={s.confirmationBoxButton}>
          ОК
        </div>
      </div> */}
      </div>
    </div>
  )
}

export const ConfirmationViewNew = ({ tableNum, leftClick, rightClick, topClick, newClick }) => {
  const { t } = useTranslation()
  return (
    <Popup>
      {tableNum !== 0 ? (
        <>
          <div className={s.stolHeader}>{t('confirmationViewNew.header')}</div>
          <div style={{ display: 'flex', marginTop: '16px', justifyContent: 'center' }}>
            <TableIcon className={s.stolSelectionIcon} width='113' height='80' />
            <div style={{ display: 'flex', marginLeft: '16px', flexDirection: 'column' }}>
              <div className={s.tableFont}>{t('general.table')}</div>
              <div className={s.tableFont1}>{tableNum}</div>
            </div>
          </div>
          <div style={{ marginTop: '48px' }} className={s.confirmationBoxButtonWrapperInrapper}>
            <div onClick={newClick} className={s.confirmationBoxButton}>
              {t('confirmationViewNew.button.change')}
            </div>
            <div className={s.vr}></div>
            <div onClick={rightClick} data-cy='confirmTable' className={s.confirmationBoxButton}>
              {t('general.ok')}
            </div>
          </div>
        </>
      ) : (
        <>
          <div style={{ display: 'flex', marginTop: '48px', justifyContent: 'center' }}>
            <NoTable className={s.stolSelectionNoIcon} width='122' height='122' />
          </div>
          <div style={{ marginTop: '48px' }} className={s.hr}></div>
          <div className={s.confirmationBoxButtonWrapper}>
            <div onClick={newClick} className={s.confirmationBoxButton}>
              {t('confirmationViewNew.button.notable')}
            </div>
            <div onClick={rightClick} className={s.confirmationBoxButton}>
              {t('general.ok')}
            </div>
          </div>
        </>
      )}
    </Popup>
  )
}

export const ConfirmationBenchView = ({ tableNum, leftClick, rightClick, topClick, newClick }) => {
  const { t } = useTranslation()
  return (
    <Popup>
      <div className={s.stolHeader}>{t('confirmationViewNew.header')}</div>
      <div style={{ display: 'flex', marginTop: '16px', justifyContent: 'center', alignItems: 'center' }}>
        <BenchIcon width='106' height='105' />
        <div style={{ display: 'flex', marginLeft: '16px', flexDirection: 'column' }}>
          <div className={s.tableFont}>{t('general.bench')}</div>
          <div className={s.tableFont1}>{tableNum}</div>
        </div>
      </div>
      <div className={s.stopDesc}>{t('confirmationBenchScan.body')}</div>
      <div className={s.confirmationBoxButtonWrapper}>
        <div onClick={rightClick} className={s.confirmationBoxButton}>
          {t('general.ok')}
        </div>
      </div>
    </Popup>
  )
}

export const ConfirmationStol = ({ tableNum, rightClick = () => {}, takeoutNum }) => {
  const { t } = useTranslation()
  return (
    <Popup>
      <div style={{ display: 'flex', marginTop: '16px', justifyContent: 'center', alignItems: 'center' }}>
        <TogoIcon2 width='56' height='56' />
        <TableIcon style={{ marginLeft: '8px' }} className={s.stolSelectionIcon} width='56' height='56' />
        <div style={{ display: 'flex', marginLeft: '8px', flexDirection: 'column' }}>
          <div className={s.tableFont1}>{tableNum}</div>
        </div>
      </div>
      <div className={s.stolHeader}>{t('confirmationStol.orderReady')}</div>
      <div className={s.stopDesc}>{t('confirmationView.orderNum')}</div>
      <div style={{ display: 'flex', alignItems: 'flex-start' }} className={s.tableFont3}>
        {takeoutNum}
      </div>
      <div className={s.stopDesc}>{t('confirmationStol.desc')}</div>
      <div className={s.confirmationBoxButtonWrapper}>
        <div onClick={rightClick} className={s.confirmationBoxButton}>
          {t('general.ok')}
        </div>
      </div>
    </Popup>
  )
}

export const TakeoutReadinessPopup = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const lastTakeoutReady = useSelector(getLastTakeoutReady)

  const [showConfirmation, setShowConfirmation] = useState(false)
  const venues = useSelector(getVenues)
  const [{ lastPaidOrders }] = usePaidOrders()

  const ordersVenue = venues.find(venue => venue.slug === lastPaidOrders?.[0]?.venueSlug)
  const isTakeoutDishStatusEnabled = Boolean(ordersVenue?.settings?.dishStatuses?.takeout)

  useEffect(() => {
    if (
      isTakeoutOrder(lastPaidOrders[0]) &&
      !lastPaidOrders[0].preorder &&
      lastPaidOrders.every(order => ORDER_STATUS.READY in order.status && !(ORDER_STATUS.FINISHED in order.status)) &&
      lastTakeoutReady !== lastPaidOrders[0].takeoutNum
    ) {
      setShowConfirmation(true)
      dispatch(setTakeoutReady(lastPaidOrders[0].takeoutNum))
    }
  }, [dispatch, lastTakeoutReady, lastPaidOrders])

  return (
    showConfirmation &&
    isTakeoutDishStatusEnabled && (
      <Popup>
        <div style={{ display: 'flex', marginTop: '16px', justifyContent: 'center', alignItems: 'center' }}>
          <TogoIcon2 width='56' height='56' />
        </div>
        <div className={s.stolHeader}>{t('zakazgotov')}</div>
        <div className={s.stopDesc}>{t('confirmationView.orderNum')}</div>
        <div style={{ display: 'flex', alignItems: 'flex-start' }} className={s.tableFont3}>
          {lastPaidOrders[0].takeoutNum}
        </div>
        <div style={{ marginBottom: '0px' }} className={s.stopDesc}>
          {t('confirmationNeStol.desc')}
        </div>
        <div style={{ marginTop: '0px', fontWeight: 'bold' }} className={s.stopDesc}>
          {t(ordersVenue?.address)}
        </div>
        <div className={s.confirmationBoxButtonWrapper}>
          <div onClick={() => setShowConfirmation(false)} className={s.confirmationBoxButton}>
            {t('general.ok')}
          </div>
        </div>
      </Popup>
    )
  )
}

export const PaymentSuccessViewNew = ({
  tableNum,
  takeoutNum,
  btnClick,
  time,
  timedTakeout,
  datedTakeout,
  delivery,
  address,
  phone
}) => {
  const { t } = useTranslation()
  const takeout = !!!tableNum
  const month = t('general.longMonth').split(',')

  return (
    <div
      style={{ paddingTop: '0px', position: 'absolute', zIndex: '3456' }}
      className={['confirmation', s.confirmationUi].join(' ')}
    >
      <div style={{ position: 'relative' }} className={s.confirmationBox1}>
        <div style={{ display: 'flex', marginTop: '20px', alignItems: 'center', marginBottom: '20px' }}>
          {delivery ? (
            <DeliveryIcon className={s.deliveryIcon} width='54' height='81' />
          ) : takeout ? (
            <TogoIcon className={s.togoIcon} width='113' height='80' />
          ) : (
            <>
              <TableIcon className={s.tableIcon} width='72' height='52' />
              <div style={{ display: 'flex', marginLeft: '16px', flexDirection: 'column', alignItems: 'flex-end' }}>
                <div className={s.tableFont}>{t('general.table')}</div>
                <div className={s.tableFont1}>{tableNum}</div>
              </div>
            </>
          )}
        </div>
        <div className={s.confirmationBoxHeader}>{t('confirmationView.payment')}</div>
        <div style={{ marginTop: '16px' }} className={s.tableFont2}>
          {t('confirmationView.orderNum')}
        </div>
        <div style={{ display: 'flex', alignItems: 'flex-start' }} className={s.tableFont3}>
          <span style={{ marginTop: '24px', marginRight: '4px' }} className={s.tableFont3_1}>
            #
          </span>
          {takeoutNum}
        </div>
        <div className={s.codeText} style={{ margin: '0px 16px 80px 16px' }}>
          {delivery ? (
            <div>
              <span>
                <div className={s.boldbold}>{t('confirmationView.deliveryView.header')}</div>
                <br /> {t('confirmationView.deliveryView.body')} <strong>{' ' + phone}</strong>.{' '}
                {t('confirmationView.deliveryView.label')}
              </span>
              <strong>
                {datedTakeout
                  ? ` ${new Date(datedTakeout).getDate()} ${month[new Date(datedTakeout).getMonth()]}`
                  : '.'}
                {timedTakeout && datedTakeout ? ` в ${timedTakeout}` : ''}
              </strong>
            </div>
          ) : datedTakeout ? (
            <div>
              <span>{t('confirmationView.takeoutView.label')} </span>
              <strong>
                {datedTakeout
                  ? ` ${new Date(datedTakeout).getDate()} ${month[new Date(datedTakeout).getMonth()]}`
                  : ` ${t('general.today')}`}
                {timedTakeout ? ` ${t('general.at')} ${timedTakeout}` : ''}
              </strong>
            </div>
          ) : timedTakeout ? (
            timedTakeout.indexOf('Завтра') >= 0 ? (
              <div>
                <span>{t('confirmationView.takeoutView.label')} </span>
                <strong>{timedTakeout}</strong>
              </div>
            ) : (
              <div>
                <span>
                  {t('confirmationView.takeoutView.label')} {t('general.at')}{' '}
                </span>
                <strong>{timedTakeout}</strong>
              </div>
            )
          ) : takeout ? (
            <div>
              <span>
                {t('confirmationView.takeoutView.label')} {t('general.after')}{' '}
              </span>
              <strong>{time}</strong>
            </div>
          ) : (
            <div>
              <span>{t('confirmationView.hereView')} </span>
              <strong>{time}</strong>
            </div>
          )}
        </div>
        <div className={s.confirmationBottom}>
          <div style={{ marginTop: '20px' }} className={s.hr}></div>
          <div className={s.confirmationBoxButtonWrapper}>
            <div style={{ padding: '16px 0px' }} onClick={btnClick} className={s.confirmationBoxButton}>
              {t('general.ok')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ThankView = ({ text1, text2, buttonText, singleButton, callback }) => {
  return (
    <Popup>
      <div className={s.textwrapper}>
        <div className={s.confirmationBoxHeader}>
          <strong>{text1}</strong>
        </div>
        {singleButton ? (
          <div className={s.thankText}>{text2}</div>
        ) : (
          <>
            <div className={s.thankText}>
              <span>{text2.t1}</span>
            </div>
            {text2.t2 ? (
              <div className={s.thankText}>
                <span>{text2.t2}</span>
              </div>
            ) : (
              ''
            )}
          </>
        )}
      </div>
      {singleButton ? (
        <div className={s.confirmationBottomStatic}>
          <div style={{ marginTop: '20px' }} className={s.hr} />
          <div className={s.confirmationBoxButtonWrapper}>
            <div style={{ padding: '16px 0px' }} onClick={callback} data-cy='thankView-one' className={s.confirmationBoxButton}>
              {buttonText}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ flexDirection: 'row' }} className={s.confirmationBoxButtonWrapper}>
          <div onClick={callback.cb1} data-cy='thankView-two-1' className={s.confirmationBoxButton}>
            {buttonText.t1}
          </div>
          <div className={s.vr} />
          <div onClick={callback.cb2} data-cy='thankView-two-2' className={s.confirmationBoxButton}>
            {buttonText.t2}
          </div>
        </div>
      )}
    </Popup>
  )
}

export const ContactView = ({ rightClick, leftClick }) => {
  const { t } = useTranslation()
  return (
    <Popup>
      <div className={s.confirmationBoxHeader}>{t('contactView.header')}</div>
      <div style={{ margin: '16px 16px 80px 16px' }} className={s.tableFont2}>
        {t('contactView.body')}
      </div>
      <div className={s.confirmationBottom}>
        <div className={s.confirmationBoxButtonWrapper}>
          {/* <div className={s.vr}></div> */}
          <div onClick={rightClick} className={s.confirmationBoxButton}>
            {t('contactView.button')}
          </div>
        </div>
      </div>
    </Popup>
  )
}

export const ImHerePopup = ({ data, rightClick, leftClick }) => {
  const { t } = useTranslation()
  const [text, setText] = useState(t('imherePopup.variab'))
  return (
    <Popup>
      <div className={s.confirmationBoxHeader}>{t('imherePopup.header')}</div>
      <textarea
        placeholder={t('imherePopup.placeholder')}
        onChange={e => setText(e.target.value)}
        value={text}
        autoFocus
        style={{ margin: '16px 16px 80px 16px' }}
        className={[s.tableFont2, s.textarea].join(' ')}
      />
      <div className={s.confirmationBottom}>
        <div style={{ flexDirection: 'row' }} className={s.confirmationBoxButtonWrapper}>
          <div onClick={leftClick} className={s.confirmationBoxButton}>
            {t('imherePopup.button')}
          </div>
          <div className={s.vr} />
          <div
            onClick={() => rightClick({ takeout: data.takeout, venueSlug: data.venueSlug, text: text })}
            className={s.confirmationBoxButton}
          >
            {t('contactUs.sendButton')}
          </div>
        </div>
      </div>
    </Popup>
  )
}

export const ChangeLocationView = ({ rightClick, choice1, choice2, venue1, venue2, N, M }) => {
  const { t } = useTranslation()
  return (
    <Popup>
      <div className={s.confirmationBoxHeader}>{t('changeLocationView.header')}</div>
      <div style={{ margin: '16px' }} className={s.tableFont2}>
        {t('changeLocationView.body1')}
        <br />
        <strong>{t(venue1)}</strong>
        <br />
        <br />
        {t('changeLocationView.body2')} <br />
        <strong>{t(venue2)}</strong>
        <br />
        <br />
        {t('changeLocationView.label')}
      </div>
      <div className={s.confirmationBottomStatic}>
        <div className={s.confirmationBoxButtonWrapper}>
          <div onClick={choice1} className={[s.confirmationBoxButton, 'fcol'].join(' ')}>
            {!!N && !!M && <div className={s.smollText}>{t('changeLocationView.choice1', { N: N })}</div>}
            {/* <div className={s.smollText}>{N}</div> */}
            <div className={s.bigText}>{t(venue1)}</div>
          </div>
          <div onClick={choice2} className={[s.confirmationBoxButton, 'fcol'].join(' ')}>
            {!!N && !!M && <div className={s.smollText}>{t('changeLocationView.choice2', { N: M })}</div>}
            {/* <div className={s.smollText}>{M}</div> */}
            <div className={s.bigText}>{t(venue2)}</div>
          </div>
          <div onClick={rightClick} className={[s.confirmationBoxButton, 'fcol'].join(' ')}>
            <div className={s.bigText}>{t('changeLocationView.button')}</div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

// const __operators = ['Позвонить', 'Напишите мне в Telegram', 'Напишите мне в Viber', 'Напишите мне в WhatsUp']

export const PaymentSuccessView = ({ title, subTitle, bottomText, operators, ...props }) => {
  const { t } = useTranslation()
  const __operators = [t('contactUs.callus'), t('paymentSuccessView.variant2')]
  return (
    <div>
      {bottomText ? (
        ''
      ) : (
        <div className={s.paymentWrapper}>
          <div className={[s.paymentText, 'caption1'].join(' ')}>
            {title ? title : t('paymentSuccessView.body')}{' '}
            <strong>{subTitle ? subTitle : t('paymentSuccessView.default')}</strong>
          </div>
        </div>
      )}
      <div className={s.list}>{operators ? operators.map(Operator) : __operators.map(Operator)}</div>
      {bottomText ? (
        <div className={s.paymentWrapper}>
          <div className={[s.paymentText, 'caption1'].join(' ')}>{bottomText}</div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

const Operator = text => (
  <div className={[s.item, 'body1'].join(' ')}>
    <div className={s.telegramIcon}></div>
    <div className={[s.telegramText, 'body1']}>{text}</div>
  </div>
)

const Table = ({ tableNum, text }) => (
  <>
    <div style={{ paddingTop: '32px' }} className={'body2'}>
      {text}
    </div>
    <br />
    <div className={s.tableWrap}>
      <div className={s.table}>
        <div className={s.tableNum}>{tableNum}</div>
      </div>
    </div>
  </>
)
