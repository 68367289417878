import { useMemo } from 'react'
import { useSelector } from 'src/state/hooks'
import { getVenueId, selectCurrentCategory } from '../state/location'
import { getVenueByIdNew } from '../state/venues'
import { getCategoriesByMenuId } from '../state/categories'
import { FAVORITES_CATEGORY_ID, POPULAR_CATEGORY_ID } from '../constants'
import useAppPreferences from './useAppPreferences'
import { getHistory } from '../state/history/selectors'
import { getFavorites } from '../state/user'
import { ICategory } from '../types'

type ActiveCategoryFunc = () => {
  currentCategoryId: number | null
  currentSubcategoryId: number | null
}

type CurrentCategoriesReturnValue = {
  getActiveCategory: ActiveCategoryFunc
  categories: ICategory[]
  parentCategories: ICategory[]
}

export default function useCurrentCategories(): CurrentCategoriesReturnValue {
  const localCategoryId = useSelector(selectCurrentCategory)

  const venueId = useSelector(getVenueId)
  const venue = useSelector(getVenueByIdNew(venueId))
  const menuId = venue?.menuId
  const categories = useSelector(getCategoriesByMenuId(menuId)) as ICategory[]
  const ordersHistory = useSelector(getHistory)
  const userFavorites = useSelector(getFavorites)
  const { preferences } = useAppPreferences()
  const popularIds = preferences?.popularDishesIds
  const isExpandable = preferences?.withExpandableNavbar || false
  const isWar = true

  const parentCategories = useMemo(() => categories.filter(cat => cat.parentId == null), [categories])

  const getActiveCategory = useMemo(
    () => () => {
      const category = categories.find(x => x.id === localCategoryId)

      const isCategoryExist = localCategoryId && category && category.id === localCategoryId

      if (isCategoryExist && !category?.parentId) {
        return {
          currentCategoryId: localCategoryId,
          currentSubcategoryId: 0
        }
      } else if (isCategoryExist && category?.parentId) {
        return {
          currentCategoryId: category.parentId,
          currentSubcategoryId: localCategoryId
        }
      } else if (localCategoryId === POPULAR_CATEGORY_ID && popularIds?.length && !isWar) {
        return {
          currentCategoryId: POPULAR_CATEGORY_ID,
          currentSubcategoryId: 0
        }
      } else if (
        preferences?.withFavorites &&
        (localCategoryId === FAVORITES_CATEGORY_ID || ordersHistory?.length || userFavorites?.length) &&
        !isWar
      ) {
        return {
          currentCategoryId: FAVORITES_CATEGORY_ID,
          currentSubcategoryId: 0
        }
      } else if (popularIds?.length && !isWar) {
        return {
          currentCategoryId: POPULAR_CATEGORY_ID,
          currentSubcategoryId: 0
        }
      } else if (isExpandable) {
        return {
          currentCategoryId: null,
          currentSubcategoryId: null
        }
      } else {
        return {
          currentCategoryId: parentCategories?.[0]?.id || 0,
          currentSubcategoryId: 0
        }
      }
    },
    [isExpandable, categories, localCategoryId, popularIds, ordersHistory, userFavorites, parentCategories, preferences]
  )

  const state = useMemo(
    () => ({
      getActiveCategory,
      categories,
      parentCategories
    }),
    [getActiveCategory, categories, parentCategories]
  )

  return state
}
