import { AppState } from '../store'
import { IOrder, NewOrderInput, PaymentMethod } from '../../types'
import { OrdersState } from './reducers'

export const getOrders = (store: AppState): OrdersState => store.orders
export const getComment = (store: AppState): string => store.orders.comment

export const getOrdersNew = (store: AppState): NewOrderInput[] => store.orders.new
export const getOrdersNewInside = (store: AppState): NewOrderInput[] =>
  store.orders.new.filter(x => x.takeout === false)
export const getOrdersNewTakeout = (store: AppState): NewOrderInput[] =>
  store.orders.new.filter(x => x.takeout === true)
export const getTakeout = (store: AppState): boolean => store.orders.takeout

export const getOrdersPaid = (store: AppState): IOrder[] => store.orders.paid
export const getPaymentType = (store: AppState): PaymentMethod | undefined => store.orders.paymentType

export const getOrdersCooking = (store: AppState): IOrder[] => store.orders.paid.filter(x => x.status && x.status.paid)

export const getOrdersReady = (store: AppState): IOrder[] => store.orders.paid.filter(x => x.status && x.status.paid)
