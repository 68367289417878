import React from 'react'
import { ReactComponent as WarnIcon } from 'src/assets/ico-warn-18.svg'
import { Button } from 'src/components/ui/Button'

type WarningButtonProps = {
  label: string
}

const WarningButton: React.FC<WarningButtonProps> = function ({ label }) {
  return (
    <Button warn={true} style={{ margin: '24px auto' }}>
      <span>
        <WarnIcon style={{ marginRight: '12px' }} />
        {label}
      </span>
    </Button>
  )
}

export default WarningButton
