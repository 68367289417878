import { useSelector } from 'react-redux'
import { getUserData } from 'src/state/user'

const useAppPreferences = () => {
  const {
    appPreferences: { holidayModifications, ...rest },
    telegram,
    phone,
    name,
    customizationSettings,
    isLoading,
    error,
    venueAppPreferences
  } = useSelector(state => state.appPreferences)
  const user = useSelector(getUserData)
  return {
    preferences: {
      ...rest,
      ...venueAppPreferences,
      ...(window.busytable.brandSlug === 'hlbn' && user.userId % 2 !== 0 ? { designVersion: 1.1 } : {})
    },
    telegram,
    phone,
    name,
    customizationSettings,
    isLoading,
    holidayModifications,

    error
  }
}

export default useAppPreferences
