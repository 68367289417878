import React from 'react'
import styles from './styles.module.scss'
import { useDispatch } from 'react-redux'
import { ReactComponent as HeartBtn } from 'src/assets/heart.svg'
import { useTranslation } from 'src/hooks/useTranslation'
import { toggleLastReviewAction } from '../../state/user'

const TipsThanksView = ({ history }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleClose = () => {
    history.push('/')
    dispatch(toggleLastReviewAction(false))
  }

  return (
    <div onClick={handleClose} className={styles.modalWrapper}>
      <div className={styles.heartWrapper}>
        <p>{t('review.tipsThankText')}</p>
        <HeartBtn />
      </div>
    </div>
  )
}
export default TipsThanksView
