import React, { useCallback } from 'react'
import cx from 'classnames'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useThemeStyles from 'src/hooks/useThemeStyles'
import getThemeStyle from 'src/utils/getThemeStyle'
import { IconType } from './IconType'
import s from '../styles.module.scss'

function CatTabsItem({ isActive, data, onMakeActive, icon, label, handleFullTabBarView }) {
  const { navbarVariables, variables } = useThemeStyles()
  const { demoTheme } = useSelector(state => state.demo)
  const customStyles = getThemeStyle()
  const markerBorderRadius =
    demoTheme?.navbarVariables?.iconMarkerRadius || navbarVariables?.iconMarkerRadius || customStyles.borderRadius
  const activeIconColor =
    demoTheme?.navbarVariables?.activeIconColor || navbarVariables?.activeIconColor || customStyles.imageColor

  const tabStyle = {
    ...(/\.svg$/.test(icon)
      ? {
          backgroundColor: isActive ? activeIconColor : variables?.fontColor,
          WebkitMaskImage: `url(${icon})`,
          maskImage: `url(${icon})`
        }
      : { backgroundImage: `url('${icon}')` })
  }

  const handleMakeActive = useCallback(() => {
    onMakeActive(data)
    if (handleFullTabBarView) {
      handleFullTabBarView(true)
    }
  }, [data, onMakeActive])

  return (
    <motion.div
      animate
      transition={{ type: 'spring', stiffness: 100 }}
      onClick={handleMakeActive}
      className={cx(s.tab, isActive ? s.activeTab : '', !icon && s.noIconTab)}
      style={{ width: icon && customStyles.tabWidth }}
    >
      {icon && (
        <div className={s.iconBox}>
          <IconType style={tabStyle} type={icon} size={isActive ? 48 : 40} />
          {isActive ? (
            <motion.div style={{ borderRadius: markerBorderRadius }} className={s.marker} layoutId='active' />
          ) : (
            <div
              style={{ borderRadius: markerBorderRadius, backgroundColor: customStyles.inactiveColor }}
              className={s.markerDef}
            />
          )}
        </div>
      )}
      <motion.div
        animate
        transition={{ type: 'spring', stiffness: 100 }}
        data-cy={`catTab-${data.id}`}
        className={cx(s.iconTitle, isActive ? s.activeTitle : '', !icon && s.noIconTitle)}
      >
        {isActive && !icon && (
          <motion.div style={{ borderRadius: '28px' }} className={s.labelMarker} layoutId='cat-tab-active' />
        )}
        {label}
      </motion.div>
    </motion.div>
  )
}

CatTabsItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
  handleFullTabBarView: PropTypes.func,
  onMakeActive: PropTypes.func.isRequired,
  icon: PropTypes.string,
  label: PropTypes.node.isRequired,
  data: PropTypes.any
}

CatTabsItem.defaultProps = {
  icon: ''
}

export default React.memo(CatTabsItem)
