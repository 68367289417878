import React from 'react'
import { useSelector } from 'react-redux'
import useThemeStyles from 'src/hooks/useThemeStyles'
import { AppState } from 'src/state/store'
import styles from './styles.module.scss'

export interface IDraggerIndicatorProps {
  style?: any
  isOnBorder?: boolean
  isOnBorderBack?: boolean
  inverted?: boolean
}

const DraggerIndicator: React.FC<IDraggerIndicatorProps> = ({ style, isOnBorder, isOnBorderBack, inverted }) => {
  const { demoVariables } = useSelector((state: AppState) => state.demo)
  const { variables } = useThemeStyles()
  const areOtherColors = demoVariables?.dishCardBg || variables?.dishCardBg

  return (
    <div
      style={style}
      className={`${styles.wrapper}  ${isOnBorder ? styles.rotated : ''}  ${isOnBorderBack ? styles.rotatedBack : ''} ${
        inverted ? styles.wrapperInverted : ''
      }`}
    >
      <div
        style={{ ...(areOtherColors ? { backgroundColor: demoVariables?.fontColor || variables?.fontColor } : {}) }}
      ></div>
      <div
        style={{ ...(areOtherColors ? { backgroundColor: demoVariables?.fontColor || variables?.fontColor } : {}) }}
      ></div>
    </div>
  )
}

export default DraggerIndicator
