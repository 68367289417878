import { configureStore, ThunkAction } from '@reduxjs/toolkit'
import LogRocket from 'logrocket'
import { persistStore } from 'redux-persist'
import { combinedReducer } from './reducers'
import thunk from 'redux-thunk'
import { AnyAction } from 'redux'

const store = configureStore({
  reducer: combinedReducer,
  middleware: [LogRocket.reduxMiddleware(), thunk]
})

const persistor = persistStore(store)

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, AnyAction>

export default { store, persistor }
