import React from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import FeedbackView from '../../components/ui/FeedbackView'
import { Routes } from '../../globals/enumerations'
import { ThankView } from '../../components/CodeScan/ConfirmationView'
import { toggleLastReviewAction } from '../../state/user'
import { useTranslation } from '../../hooks/useTranslation'

export default function TipsFeedback({ history }) {
  const dispatch = useDispatch()
  const { paymentId } = useParams()

  const { t } = useTranslation()

  const handleLeaveTips = () => {
    if (paymentId) {
      history.push(`${Routes.tips}/${paymentId}`)
    }
  }

  const handleClose = () => {
    history.push('/')
    dispatch(toggleLastReviewAction(false))
  }

  const renderThankModal = () => (
    <ThankView
      text1={t('review.commentThankText')}
      text2={{
        t1: t('review.commentSecondaryText')
      }}
      buttonText={{
        t1: t('general.no'),
        t2: t('general.yes')
      }}
      callback={{
        cb1: handleClose,
        cb2: handleLeaveTips
      }}
    />
  )

  return (
    <FeedbackView
      paymentId={parseInt(paymentId)}
      renderThankModal={renderThankModal}
      thankModalFeedbackState={{ rate: 5 }}
    />
  )
}
