import { CLEAR_ADDING_ANIM, SET_ADDING_ANIM, REMOVE_ADDING_ANIM, ADD_ADDING_ANIM } from './types'

/* State Shape
[
  string
]
*/

const initialState = []

var idnum = 0

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ADDING_ANIM: {
      const { anim } = action.payload
      return anim
    }

    case ADD_ADDING_ANIM: {
      const { anim } = action.payload
      const obj = { ...anim, id: idnum }
      idnum = idnum + 1
      return [...state, obj]
    }

    case REMOVE_ADDING_ANIM: {
      const { anim } = action.payload
      return state.filter(s => s.id !== anim.id)
    }

    case CLEAR_ADDING_ANIM: {
      return []
    }

    default:
      return state
  }
}
