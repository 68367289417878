import useAppPreferences from 'src/hooks/useAppPreferences'
import { useEffect } from 'react'

const useCustomScripts = () => {
  const { preferences } = useAppPreferences()
  const customScripts = preferences?.customScripts

  useEffect(() => {
    if (customScripts) {
      const scriptsEncoded =
        typeof customScripts === 'string'
          ? JSON.parse(Buffer.from(customScripts, 'base64').toString('utf8'))
          : customScripts
      if (scriptsEncoded) {
        Object.values(scriptsEncoded).forEach(script => {
          const wrapper = document.createElement('div')
          wrapper.innerHTML = script

          const scriptTag = document.createElement('script')
          scriptTag.async = true
          scriptTag.setAttribute('type', 'text/javascript')
          scriptTag.innerHTML = wrapper.firstChild.innerHTML

          document.head.appendChild(scriptTag)
        })
      }
    }
  }, [customScripts])
}

export default useCustomScripts
