import { SET_DRINK, SET_DISH_ADDED, SET_STARTED, TOGGLE_APPLIANCE } from './types'

export const initialState = {
  started: new Date(),
  dishAdded: new Date(),
  drinkToggler: false,
  isApplianceActive: true
}

export default function statusReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DRINK: {
      return {
        ...state,
        drinkToggler: action.payload
      }
    }
    case SET_DISH_ADDED: {
      const diff = Date.now() - new Date(state.dishAdded)

      return {
        ...state,
        ...(!state.dishAdded || diff > 3600 * 1.5 ? { dishAdded: new Date() } : {})
      }
    }
    case SET_STARTED: {
      const diff = Date.now() - new Date(state.dishAdded)

      return {
        ...state,
        ...(!state.dishAdded || diff > 3600 * 1.5 ? { started: new Date() } : {})
      }
    }

    case TOGGLE_APPLIANCE: {
      return {
        ...state,
        isApplianceActive: !state.isApplianceActive
      }
    }

    default:
      return state
  }
}
