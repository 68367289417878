import { useSelector } from 'react-redux'
import { isReadable } from 'tinycolor2'
import useThemeStyles from './useThemeStyles'

export default function useFontColorReadability(backgroundColor) {
  const { variables } = useThemeStyles()
  const { demoVariables } = useSelector(state => state.demo)

  return isReadable(demoVariables?.fontColor || variables?.fontColor, backgroundColor, { level: 'AA', size: 'large' })
}
