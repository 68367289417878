import {
  getAppPreferencesStarted,
  getBranPublicSettingsError,
  getAppPreferencesSuccess,
  getBrandPublicPreferences
} from './actions'
import { getPublicBrandSettings } from 'src/network/get/settings'
import { setLanguagePreferences } from '../local/actions'
import { defineUserLanguage } from 'src/utils'

export const getAppPreferencesThunk = () => async dispatch => {
  dispatch(getAppPreferencesStarted())
  try {
    const publicBrandSettings = window.busytable?.brand || (await getPublicBrandSettings())
    if (publicBrandSettings?.appPreferences) {
      const languages = publicBrandSettings?.appPreferences?.languages
      const activeLanguage = defineUserLanguage(languages, publicBrandSettings?.appPreferences?.fallbackLanguage)
      dispatch(setLanguagePreferences(languages || [], activeLanguage))
      dispatch(getAppPreferencesSuccess(publicBrandSettings.appPreferences))
    }
    if (publicBrandSettings) {
      dispatch(getBrandPublicPreferences(publicBrandSettings))
    }
  } catch (error) {
    dispatch(getBranPublicSettingsError(error))
  }
}
