import {
  FETCH_STOPLIST_DISH,
  FETCH_STOPLIST_OPTION,
  ADD_STOPLIST_OPTION_SSE,
  ADD_STOPLIST_DISHES_SSE,
  ADD_STOPLIST_MODIFIERS
} from './types'

const state = {
  dishes: [],
  // dishes: [85, 5],
  // options: [1, 7]
  options: []
}

const initialState = state

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_STOPLIST_DISH: {
      const { stoplist } = action.payload
      return { ...state, dishes: stoplist }
    }

    case FETCH_STOPLIST_OPTION: {
      const { stoplist } = action.payload
      return { ...state, options: stoplist }
    }

    case ADD_STOPLIST_DISHES_SSE: {
      const { stoplist } = action.payload
      return { ...state, dishes: stoplist }
    }

    case ADD_STOPLIST_OPTION_SSE: {
      const { stoplist } = action.payload
      return { ...state, options: stoplist }
    }

    case ADD_STOPLIST_MODIFIERS: {
      const { items } = action.payload
      return { ...state, options: items }
    }

    default:
      return state
  }
}
