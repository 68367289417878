import React from 'react'
import { motion } from 'framer-motion'
import { IDish } from 'src/types'
import SuggestedDish from './item'
import s from './styles.module.scss'

type SuggestedDishListProps = {
  dishes: IDish[]
}

const SuggestedDishList: React.FC<SuggestedDishListProps> = function({ dishes }) {
  return (
    <motion.div layoutScroll className={s.list}>
      {dishes.map(dish => (
        <div key={dish.id} className={s.listItem}>
          <SuggestedDish dish={dish} />
        </div>
      ))}
    </motion.div>
  )
}

export default SuggestedDishList
