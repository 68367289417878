import React from 'react'
import clsx from 'classnames'
import { WithModificationVariant } from 'src/types'
import s from '../styles.module.scss'

export const DecoratedLogo: React.FC<WithModificationVariant> = ({ darkMode, variant, children }) => {

  const iconClasses = clsx(
    s.titleIcon,
    variant && s.titleIcon_defaultHoliday,
    variant && s[`titleIcon_${variant}`],
    variant && darkMode && s[`titleIcon_${variant}Light`]
  )

  return (
    <div className={clsx(s.logoWrapper, variant && s.logoWrapper_defaultHoliday, variant && s[`logoWrapper_${variant}`])}>
      <span className={iconClasses} />
      <div className={s.logoWrapperTitle}>{children}</div>
      <span className={iconClasses} />
    </div>
  )
}
