import React from 'react'
import { ReactComponent as PhoneIcon } from 'src/assets/icons/ico-phone-settings.svg'
import { ReactComponent as TelegramIcon } from 'src/assets/icons/ico-telegram.svg'
import s from '../styles.module.scss'
import useCustomIconSet from '../../../hooks/useCustomIconSet'

const IconType = ({ icon }) => (icon === 'phone' ? <PhoneIcon /> : icon === 'telegram' ? <TelegramIcon /> : '')

export const SetButton = ({ href = '#', onClick = () => {}, icon = 'phone', text = '' }) => {
  const customIcon = useCustomIconSet(icon)
  const customIconsGroup = useCustomIconSet(null, 'general')

  const colorIconStyles = {
    '-webkit-mask-image': `url(${customIcon?.icon?.default})`,
    'mask-image': `url(${customIcon?.icon?.default})`
  }

  return (
    <a
      className={s.setButton}
      href={href}
      target='_blank'
      rel='noopener noreferrer'
      onClick={() => (onClick ? onClick() : '')}
    >
      {customIcon ? (
        <div
          className={customIconsGroup?.color ? s.setButtonColorIcon : s.setButtonIcon}
          style={
            customIconsGroup?.color
              ? colorIconStyles
              : { backgroundImage: `url(${customIcon?.icon?.default || customIcon?.icon})` }
          }
        />
      ) : (
        <div className={s.setButtonIcon}>
          <IconType icon={icon} />
        </div>
      )}
      <div>{text}</div>
    </a>
  )
}
