import React from 'react'
import s from '../styles.module.scss'
import { getFavorites } from '../../../state/user'
import { useDispatch, useSelector } from 'react-redux'
import { removeFavorite } from '../../../state/user/actions'
import { ReactComponent as Icon } from '../../../assets/icons/Favorite.svg'
import { fetchFavoriteAdd, fetchFavoriteRemove, postEvent } from '../../../api'
import { getToken } from '../../../state/local'
import { FavoriteDish, SelectedOptionType } from '../../../types'
import { filterExistedOptionsForDish } from 'src/utils'
import { getOptionsets } from 'src/state/menu'

type LikeWrapperProps = {
  dishId: number
  recipeId: number
  options: SelectedOptionType[]
  className?: string
  style?: React.CSSProperties
  clickable: boolean
  stroke?: string
}

export const LikeWrapper: React.FC<LikeWrapperProps> = ({
  dishId,
  options = [],
  className = s.like,
  style,
  clickable,
  stroke,
  recipeId
}) => {
  const dispatch = useDispatch()
  const token = useSelector(getToken)
  const favoriteIds = useSelector(getFavorites) as FavoriteDish[]
  const optionSets = useSelector(getOptionsets) as any[]

  const isFavorite = favoriteIds.find(favItem => {
    if (favItem.id === dishId) {
      const dishExistedOptions = filterExistedOptionsForDish(favItem.options, recipeId, optionSets)

      if (dishExistedOptions.length === options.length) {
        let flag = 0

        for (let i = 0; i < options.length; i++) {
          for (let j = 0; j < dishExistedOptions.length; j++) {
            if (dishExistedOptions[j].id === options[i].id && options[i].value === dishExistedOptions[j].value) {
              flag++
            }
          }
        }

        return flag === options.length
      } else {
        return false
      }
    } else {
      return false
    }
  })

  return (
    <div
      style={{ ...style }}
      className={className}
      onClick={e => {
        e.stopPropagation()
        console.log(clickable)
        if (clickable) {
          if (!isFavorite) {
            fetchFavoriteAdd(dispatch, token, {
              dishId: dishId,
              options: options.map(elem => {
                return { optionsetId: elem.id, optionId: elem.value }
              })
            })
          } else {
            fetchFavoriteRemove(dispatch, token, { id: isFavorite.favoriteId })
            dispatch(removeFavorite({ id: isFavorite.favoriteId }))
          }
          e.stopPropagation()
        }
        postEvent('dishcard-btn_fav', 'involvement', { value: !isFavorite })
      }}
    >
      <div className={s.iconWrapper}>
        {isFavorite ? (
          <Icon className={s.iconSvgSmall} stroke={'#FF0000'} fill={'#FF0000'} />
        ) : (
          <Icon className={s.iconSvgSmall} stroke={stroke ? stroke : 'black'} fill={'none'} />
        )}
      </div>
    </div>
  )
}
