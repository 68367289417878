import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useSelector } from 'src/state/hooks'
import { getOptionsetsByRecipeId } from 'src/state/menu'
import { DishOptions } from './DishOptions'
import { IDish } from '../../types'
import { useTranslation } from '../../hooks/useTranslation'
import s from '../DishCard/styles.module.scss'

type DishMainTabProps = {
  dish: IDish
}

const DishMainTab: React.FC<DishMainTabProps> = ({ dish }) => {
  const { t } = useTranslation()
  const optionSets = useSelector(getOptionsetsByRecipeId(dish?.recipeId || 0)) || []

  const desc = t(dish.desc)

  return (
    <>
      {desc.length > 0 && (
        <div className={s.dishdesc} style={{ whiteSpace: 'pre-wrap' }}>
          <ReactMarkdown source={desc} />
        </div>
      )}

      {!optionSets?.length || <DishOptions dishId={dish.id} recipeId={dish.recipeId} />}
    </>
  )
}

export default DishMainTab
