import React, { ReactNode } from 'react'
import cls from 'classnames'
import useThemeStyles from '../../hooks/useThemeStyles'

type BackgroundProps = {
  withPattern?: boolean
  className?: string
  image?: string | null
  children?: ReactNode
}

export default function Background({ children, withPattern, className, image }: BackgroundProps): JSX.Element {
  const { variables } = useThemeStyles()

  const rootClass = cls('bgWrapper', className)

  return (
    <div className={rootClass}>
      {variables?.brandingBackgroundImage && (
        <div className={`bgIcon${withPattern && variables?.brandPattern ? ' bgIcon_pattern' : ''}`}>
          <img src={image || variables.brandingBackgroundImage} alt='' />
        </div>
      )}
      <div className='bgIconChildren'>{children}</div>
    </div>
  )
}
