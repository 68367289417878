import React, { useEffect, useState, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import { postEvent } from 'src/api'
import { ReactComponent as ArrowDown } from 'src/assets/arrow_drop_down-24.svg'
import { ReactComponent as EmailIcon } from 'src/assets/email.svg'
import { ReactComponent as HomeIcon } from 'src/assets/ico-home.svg'
import { ReactComponent as PassIcon } from 'src/assets/ico-pass.svg'
import { ReactComponent as PhoneIcon } from 'src/assets/phone.svg'
import { ReactComponent as VisIcon } from 'src/assets/visibility.svg'
import { useTranslation } from 'src/hooks/useTranslation'
import { getDelivery, setDelivery } from 'src/state/delivery'
import { getRoles } from 'src/state/roles'
import { getUserData } from 'src/state/user/selectors'
import { decoratePhone } from 'src/utils/phone'
import { MenuBar } from 'src/components/ui/MenuBar'
import d from '../../CodeScan/styles.module.scss'
import { Popup } from 'src/components/ui/Popup'
import { useParams } from 'react-router'
import { findRole } from 'src/utils/strings'
import { config } from 'src/constants'
import axios from 'axios'
import { addUserData } from 'src/state/user/actions'
import s from '../styles.module.scss'
import { SERVICES_TYPE } from 'src/globals/enumerations'

export const FreeForm = ({ edit, history }) => {
  const dispatch = useDispatch()
  const user = useSelector(getUserData)
  const { t } = useTranslation()
  const { venueId } = useParams()

  // console.log('[user]', user)
  const [isEmptyProfile, setIsEmpty] = useState(true)
  const [dovalidate, setdovalidate] = useState(false)
  const [saveProfile, setSaveProfile] = useState(false)
  const [showPopup, setPopup] = useState(false)
  const [isPassVal, setIsPassVal] = useState(false)
  const [firstname, setFirstname] = useState(user.firstname || '')
  const [lastname, setLastname] = useState(user.lastname || '')
  const [email, setEmail] = useState(user.email ? user.email : '')
  const [address, setAdress] = useState(user.address ? user.address : '')
  const [phone, setPhone] = useState(user.phone ? user.phone : '')
  const [password, setPassword] = useState('')
  const [role, setRole] = useState(user.userRole && user.userRole !== 'user' ? user.userRole : '')
  const [showPass, setShowPass] = useState(false)
  const unpaiders = useSelector(getRoles)
  const delivery = useSelector(getDelivery)
  const isNovaPoshta = delivery && delivery.type === SERVICES_TYPE.DELIVERY_NOVAPOSHTA
  const [focus, setFocus] = useState('')

  useEffect(() => {
    if (user) {
      if (!Number(venueId) > 0) alert(`Неверный venueId: ${venueId}`)
      setFirstname(user.firstname || '')
      setLastname(user.lastname || '')
      setEmail(user.email ? user.email : '')
      setAdress(user.address ? user.address : '')
      setPhone(user.phone ? user.phone : '')
      setRole(findRole(user.userRole))
      if (user.firstname || user.lastname || user.email || user.phone) {
        if (edit) {
          setIsEmpty(false)
        }
      }
    }
  }, [user, venueId, edit])

  const [ref, inView] = useInView({ threshold: 1 })
  async function handler() {
    if (!venueId) return
    await axios
      .patch(
        `${config.API_URL3}/${window.busytable.brandSlug}/user/special`,
        {
          email,
          firstname,
          lastname,
          phone: tryToSimplifyPhone(phone),
          address,
          userRole: role,
          allowedVenueSlug: parseInt(venueId)
        },
        {
          withCredentials: true,
          headers: { verified: 'yes', Authorization: 'Basic ' + btoa(password + ':') }
        }
      )
      .then(r => {
        if (r?.status === 200) {
          dispatch(addUserData(r.data))
          history.push('/')
        }
      })
      .catch(e => {
        if (e.response && e.response.status) {
          if (e.response.status === 403) alert('Неправильный пароль')
          // setIsPassVal(true) // нет текстов, а так нормальный попап
          throw new Error(e.response.status)
        } else {
          return null
        }
      })
  }

  const allowedRoles = useMemo(
    () => unpaiders.filter(unpdaider => unpdaider.key !== 'admin' && unpdaider.key !== 'superadmin'),
    [unpaiders]
  )

  const isPasswordValid = true
  const isEmailValid = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
  const isPhoneValid = validatePhone(phone)
  let validateFields = false
  let anothervalidateFields = false
  if (edit === true) {
    validateFields = firstname.length > 0 && isPhoneValid
    anothervalidateFields = firstname.length > 0 && validatePhonePartial(phone)
  } else {
    validateFields =
      firstname.length > 0 &&
      lastname.length > 0 &&
      isPhoneValid &&
      password.length > 0 &&
      isPasswordValid &&
      role.length > 0 &&
      role !== 'user'
    anothervalidateFields = validateFields
  }

  return (
    <Popup
      fullsize
      additional={
        showPopup ? (
          <RenderPopup
            s={s}
            phone={phone}
            okAction={() => {
              setPopup(false)
              postEvent('modal-auth-btn_ok', 'engaging')
              // if (validateFields === true) {
              // postEvent('FreeForm', 'register-success')
              handler()
              // }
            }}
            noAction={() => {
              postEvent('modal-auth-btn_cancel', 'engaging')
              setPopup(false)
            }}
          />
        ) : (
          ''
        )
      }
      withoutHeaderWrapper
      header={
        <MenuBar
          isHeader
          className={[!inView ? s.inviewHead : '', saveProfile ? 'registerHead' : ''].join(' ')}
          icon={saveProfile ? 'close' : 'cross'}
          title={t('profile')}
          url={'/'}
          eventCb={() => {
            postEvent('profile-btn_close')
          }}
          right={
            !isEmptyProfile ? (
              saveProfile === true ? (
                <div
                  onClick={() => {
                    if (anothervalidateFields) {
                      if (validateFields === true) {
                        handler()
                      } else {
                        setPopup(true)
                      }
                    }
                    setdovalidate(true)
                    postEvent('profile-btn_register', 'involvement', {
                      success: validateFields === true,
                      ...{ ...user, firstname, phone, email }
                    })
                  }}
                  className={[anothervalidateFields ? s.saveProfile : s.unsaveProfile].join(' ')}
                >
                  {t('freeForm.save')}
                </div>
              ) : (
                ''
              )
            ) : (
              <div
                onClick={() => {
                  if (anothervalidateFields) {
                    if (validateFields === true) {
                      handler()
                    } else {
                      setPopup(true)
                    }
                  }
                  setdovalidate(true)('profile-btn_save', 'involvement', {
                    success: validateFields === true,
                    ...{ ...user, firstname, phone, email }
                  })
                }}
                className={[anothervalidateFields ? s.saveProfile : s.unsaveProfile].join(' ')}
              >
                {edit ? t('freeForm.create') : anothervalidateFields === true ? t('freeForm.save') : t('freeForm.save')}
              </div>
            )
          }
        />
      }
    >
      {isPassVal ? (
        <Popup>
          <div className={s.stolHeader}>REGISTERATION FAILED</div>
          <div className={s.confirmationBoxButtonWrapper}>
            <div onClick={() => setIsPassVal(false)} className={s.confirmationBoxButton}>
              {t('general.ok')}
            </div>
          </div>
        </Popup>
      ) : (
        ''
      )}
      <div className={['contentWrapper', 'custom1'].join(' ')}>
        <div ref={ref}></div>
        <div className={s.list}>
          <div
            className={[
              s.item2,
              focus === '1' ? s.item2Active : '',
              dovalidate ? (firstname.length > 0 ? '' : s.errorItem2) : ''
            ].join(' ')}
          >
            <div className={s.userIcon2}></div>

            <input
              onFocus={_ => {
                postEvent('profile-form-name', 'engaging')
                setFocus('1')
              }}
              value={firstname}
              onChange={_ => {
                setSaveProfile(true)
                setFirstname(_.target.value)
                setdovalidate(false)
              }}
              placeholder={t('freeForm.namePlaceholder')}
              className={[s.userText, 'body1'].join(' ')}
              autoComplete='given-name'
              id='firstname'
              name='firstname'
            />
          </div>
          {edit ? (
            ''
          ) : (
            <div className={[s.item2, focus === '2' ? s.item2Active : ''].join(' ')}>
              <div className={s.userIcon2}></div>

              <input
                onFocus={_ => {
                  postEvent('profile-form-surename', 'engaging')
                  setFocus('2')
                }}
                value={lastname}
                onChange={_ => {
                  setSaveProfile(true)
                  setLastname(_.target.value)
                }}
                placeholder={t('freeForm.lastnamePlaceholder')}
                className={[s.userText, 'body1'].join(' ')}
                autoComplete='family-name'
                id='lastname'
                name='lastname'
              />
            </div>
          )}
          <div
            className={[
              [s.item2, focus === '4' ? s.item2Active : '', dovalidate ? (isPhoneValid ? '' : s.errorItem) : ''].join(
                ' '
              ),
              s.phoneWrap
            ].join(' ')}
          >
            {/* {validatePhone(phone) ? '' : <div className={s.errorPas}>{t('freeForm.phoneError')}</div>} */}
            <div style={{ display: 'flex' }}>
              <PhoneIcon fill={isPhoneValid ? '#383838' : '#ff0000'} width='24' />
              {edit ? '' : ''}
            </div>

            <input
              onFocus={_ => {
                postEvent('profile-form-tel', 'engaging')
                setFocus('4')
              }}
              value={decoratePhone(phone)}
              onChange={_ => {
                setSaveProfile(true)
                setPhone(_.target.value)
                setdovalidate(false)
              }}
              placeholder={t('freeForm.phonePlaceholder')}
              type='tel'
              className={[s.userText, 'body1'].join(' ')}
              autoComplete='tel'
              id='tel'
              name='tel'
            />
          </div>
          {edit && dovalidate ? (
            validatePhone(phone) ? (
              ''
            ) : (
              <div className={s.errorPas2}>{t('profile.wecant')}</div>
            )
          ) : (
            ''
          )}
          {edit ? (
            ''
          ) : (
            <div
              className={[
                s.item2,
                focus === '3' ? s.item2Active : '',
                dovalidate && !isEmailValid ? s.errorItem : ''
              ].join(' ')}
            >
              <EmailIcon
                fill={dovalidate && !isEmailValid ? '#ff0000' : '#383838'}
                className={[s.userIcon].join(' ')}
              />
              {dovalidate && !isEmailValid ? <div className={s.errorPas}>{t('freeForm.emailError')}</div> : ''}
              <input
                onFocus={_ => {
                  postEvent('profile-form-email', 'engaging')
                  setFocus('3')
                }}
                value={email}
                autoComplete='email'
                type='email'
                id='email'
                name='email'
                onChange={_ => {
                  setSaveProfile(true)
                  setEmail(_.target.value)
                }}
                placeholder={t('freeForm.emailPlaceholder')}
                className={[s.userText, 'body1'].join(' ')}
              />
            </div>
          )}
          {!delivery ? (
            ''
          ) : (
            <div className={[s.item2, focus === '12' ? s.item2Active : ''].join(' ')}>
              <HomeIcon fill={`rgba(0, 0, 0, 0.3)`} className={s.userIcon} />

              <input
                onFocus={_ => {
                  postEvent('profile-form-address', 'engaging')
                  setFocus('12')
                }}
                value={address}
                autoComplete='street-address'
                type='text'
                onChange={_ => {
                  setSaveProfile(true)
                  setAdress(_.target.value)
                }}
                placeholder={t('general.adress')}
                className={[s.userText, 'body1'].join(' ')}
              />
            </div>
          )}
          {/* NOVAPOSHTA */}
          {edit && isNovaPoshta ? (
            <div className={[s.item2, focus === '2_1' ? s.item2Active : ''].join(' ')}>
              <div className={s.userIcon2}></div>

              <input
                onFocus={_ => {
                  setFocus('2_1')
                }}
                value={delivery.name}
                onChange={_ => {
                  setSaveProfile(true)
                  dispatch(setDelivery({ ...delivery, name: _.target.value }))
                }}
                placeholder={t('dostavka.name')}
                className={[s.userText, 'body1'].join(' ')}
              />
            </div>
          ) : (
            ''
          )}
          {edit && isNovaPoshta ? (
            <div
              className={[
                [
                  s.item2,
                  focus === '3_1' ? s.item2Active : '',
                  dovalidate ? (isPhoneValid ? '' : s.errorItem) : ''
                ].join(' '),
                s.phoneWrap
              ].join(' ')}
            >
              {/* {validatePhone(phone) ? '' : <div className={s.errorPas}>{t('freeForm.phoneError')}</div>} */}
              <div style={{ display: 'flex' }}>
                <PhoneIcon fill={isPhoneValid ? '#383838' : '#ff0000'} width='24' />
                {edit ? '' : ''}
              </div>

              <input
                onFocus={_ => {
                  // postEvent('profile-form-tel', 'engaging')
                  setFocus('3_1')
                }}
                value={decoratePhone(delivery.phone)}
                onChange={_ => {
                  setSaveProfile(true)
                  dispatch(setDelivery({ ...delivery, phone: _.target.value }))
                  setdovalidate(false)
                }}
                placeholder={t('dostavka.phone')}
                type='tel'
                className={[s.userText, 'body1'].join(' ')}
                autoComplete='tel'
              />
            </div>
          ) : (
            ''
          )}
          {edit && dovalidate && delivery ? (
            validatePhone(delivery.phone) ? (
              ''
            ) : (
              <div className={s.errorPas2}>{t('profile.wecant')}</div>
            )
          ) : (
            ''
          )}
          {edit && isNovaPoshta ? (
            <div className={[s.item2, focus === '4_1' ? s.item2Active : ''].join(' ')}>
              <HomeIcon fill={`rgba(0, 0, 0, 0.3)`} className={s.userIcon} />

              <input
                onFocus={_ => {
                  setFocus('4_1')
                }}
                value={delivery.address}
                autoComplete='street-address'
                type='text'
                onChange={_ => {
                  setSaveProfile(true)
                  dispatch(setDelivery({ ...delivery, address: _.target.value }))
                }}
                placeholder={t('dostavka.address')}
                className={[s.userText, 'body1'].join(' ')}
              />
            </div>
          ) : (
            ''
          )}
          {edit && isNovaPoshta ? (
            <div className={[s.item2, focus === '4_1' ? s.item2Active : ''].join(' ')}>
              <HomeIcon fill={`rgba(0, 0, 0, 0.3)`} className={s.userIcon} />

              <input
                onFocus={_ => {
                  postEvent('profile-form-address', 'engaging')
                  setFocus('4_1')
                }}
                value={delivery.department}
                type='text'
                onChange={_ => {
                  setSaveProfile(true)
                  dispatch(setDelivery({ ...delivery, department: _.target.value }))
                }}
                placeholder={t('dostavka.department')}
                className={[s.userText, 'body1'].join(' ')}
              />
            </div>
          ) : (
            ''
          )}
          {/* NOVAPOSHTA */}
          {edit ? (
            ''
          ) : (
            <div
              className={[s.item2, focus === '5' ? s.item2Active : '', isPasswordValid ? '' : s.errorItem].join(' ')}
            >
              <PassIcon fill={isPasswordValid ? '#383838' : '#ff0000'} width='24' className={s.userIcon} />
              {isPasswordValid ? '' : <div className={s.errorPas}>{t('freeForm.passwordError')}</div>}

              {!showPass ? (
                <input
                  onFocus={_ => {
                    postEvent('profile-form-password', 'engaging')
                    setFocus('5')
                  }}
                  value={password}
                  onChange={_ => setPassword(_.target.value)}
                  placeholder={t('freeForm.passwordPlaceholder')}
                  type='password'
                  className={[s.userText, 'body1'].join(' ')}
                />
              ) : (
                <input
                  onFocus={_ => {
                    postEvent('profile-form-password', 'engaging')
                    setFocus('5')
                  }}
                  value={password}
                  onChange={_ => setPassword(_.target.value)}
                  placeholder={t('freeForm.passwordPlaceholder')}
                  type='text'
                  className={[s.userText, 'body1'].join(' ')}
                />
              )}
              <VisIcon
                onClick={() => {
                  postEvent('profile-form-password-show_pass', 'engaging')
                  postEvent('FreeForm', 'show-pass ' + showPass)
                  setShowPass(!showPass)
                }}
                width='24'
              />
            </div>
          )}
          {edit ? (
            ''
          ) : (
            <div
              style={{ padding: '0px' }}
              className={[s.item2, focus === '7' ? s.item2Active : '', s.selectWrapper].join(' ')}
            >
              {role === 'admin' || role === 'superadmin' ? null : (
                <>
                  <div className={role ? s.selectedLabel : s.unselectedLabel}>{t('freeForm.unpaiderPlaceholder')}</div>
                  <select
                    style={{ padding: '16px 16px 16px 8px', height: '56px' }}
                    id='second'
                    onFocus={_ => {
                      postEvent('profile-form-role', 'engaging')
                      setFocus('7')
                    }}
                    value={role}
                    className={s.dropdp}
                    onChange={_ => {
                      const isUnpaider = unpaiders.find(e => e.key === _.target.value)
                      setSaveProfile(true)
                      setRole(isUnpaider ? isUnpaider.key : 'user')
                    }}
                    required
                  >
                    <option value='' disabled hidden>
                      {t('freeForm.unpaiderPlaceholder')}
                    </option>
                    {allowedRoles.map(r => (
                      <option key={r.key} value={r.key}>
                        {r.value}
                      </option>
                    ))}
                  </select>
                  <div className={s.selectArrow}>
                    <ArrowDown />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </Popup>
  )
}

const RenderPopup = ({ phone, okAction, noAction, s }) => {
  const { t } = useTranslation()
  return (
    <div
      style={{ paddingTop: '0px', position: 'absolute', zIndex: '3456' }}
      className={['confirmation', d.confirmationUi].join(' ')}
    >
      <div style={{ position: 'relative' }} className={d.confirmationBox1}>
        <div style={{ marginTop: '24px' }} className={d.confirmationBoxHeader}>
          {t('profile.yourdata')}
        </div>
        <div style={{ margin: '16px 16px 0px 16px' }} className={s.confirmationBoxHeader2}>
          {t('profile.wecant')}
        </div>
        <div style={{ margin: '16px 16px 80px 16px' }} className={d.confirmationBoxHeader}>
          {decoratePhone(phone)}
        </div>
        <div className={d.confirmationBottom}>
          <div style={{ marginTop: '20px' }} className={s.hr}></div>
          <div className={s.confirmationBoxButtonWrapper}>
            <div style={{ padding: '16px 0px' }} onClick={noAction} className={s.confirmationBoxButton}>
              {t('confirmationViewNew.button.change')}
            </div>
            <div className={s.vr}></div>
            <div style={{ padding: '16px 0px' }} onClick={okAction} className={s.confirmationBoxButton}>
              {t('general.ok')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const formatPhone = rawPhone => {
  if (typeof rawPhone !== 'string' || !rawPhone) return null
  const number = rawPhone.replace(/[^\d]/g, '')
  if (/^[1-9]\d{8}$/.test(number)) return '380' + number
  if (/^0\d{9}$/.test(number)) return '38' + number
  if (/^80\d{9}$/.test(number)) return '3' + number
  return number
}

const validatePhone = rawPhone => {
  if (typeof rawPhone === 'string') {
    const richPhone = rawPhone
      .split('')
      .filter(x => x !== '-' && x !== '(' && x !== ' ' && x !== ')' && x !== '+')
      .join('')

    if (richPhone.length < 1) {
      return false
    }

    return /^380\d{9}$/.test(formatPhone(richPhone))
  }
  return false
}

const validatePhonePartial = rawPhone => {
  if (typeof rawPhone === 'string') {
    const richPhone = rawPhone
      .split('')
      .filter(x => x !== '-' && x !== '(' && x !== ' ' && x !== ')' && x !== '+')
      .join('')
    return richPhone.length > 0
  }
  return false
}

const tryToSimplifyPhone = rawStr => {
  if (typeof rawStr === 'string') {
    return rawStr
      .split('')
      .filter(x => x !== '-' && x !== '(' && x !== ' ' && x !== ')' && x !== '+')
      .join('')
  } else {
    return ''
  }
}
