import React, { useMemo } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as FileIcon } from 'src/assets/ico-attach-wrap.svg'
import { UserFile } from 'src/types'
import useFileUploadApi from 'src/hooks/useFileUploadApi'

export interface IFileItemProps {
  progress: number
  data: UserFile
  handleRemove: () => void
}

const FileItem: React.FC<IFileItemProps> = ({ data, progress, handleRemove }) => {
  const isLoaded = useMemo(() => progress === 100, [progress])

  return (
    <div className={styles.fileItemWrapper}>
      <div className={styles.fileItemIcon}>
        {data?.mediaType?.includes('image') && data.path ? <img src={data.path} alt='attached file' /> : <FileIcon />}
      </div>
      <div className={`${styles.fileProgressWrapper} ${isLoaded ? styles.fileProgressLoaded : ''}`}>
        <span
          style={{
            transform: `translateX(${progress}%)`
          }}
          className={styles.progressItem}
        />
        {isLoaded && data.originalName}
      </div>
      {isLoaded && <div onClick={handleRemove} className={styles.fileDelete}></div>}
    </div>
  )
}

export default FileItem
