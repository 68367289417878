import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'src/state/hooks'
import { getTableNum, getVenueId, getWorkingHoursFull } from 'src/state/location'
import { getTakeout } from 'src/state/orders'
import { getTimedTakeout } from 'src/state/timedTakeout'
import { getDelivery } from 'src/state/delivery'
import { getVenueById } from 'src/state/venues'
import { getVenueStatus, VENUE_STATUSES } from 'src/state/location/selectors'
import { useTranslation } from 'src/hooks/useTranslation'
import { calculateIsCookingTimeBeforeClose, isCookingTimeInStopList, isTomorrow } from 'src/utils'
import useUnpaidOrders from 'src/hooks/useUnpaidOrders'
import { getUserData } from 'src/state/user'
import useShoppingCartStatus from 'src/hooks/ShoppingCart/useShoppingCartStatus'
import { getServicesStopList } from 'src/state/services-stoplist/selectors'
import { postEvent } from 'src/api'
import useCheckNative from 'src/hooks/useCheckNative'
import { useVenueOpenAt } from '../useVenueOpenAt'
import { ActivityViewsContext } from '../contexts'
import WarningButton from './WarningButton'
import DisabledButton from './DisabledButton'
import PaymentButton from './PaymentButton'
import CommonButton from './CommonButton'
import useAppPreferences from 'src/hooks/useAppPreferences'
import { SERVICES_TYPE } from '../../../types'

enum SUBMITTING_STATUS {
  WARNING = 'warning',
  DISABLED = 'disabled',
  NO_VENUE = 'no_venue',
  NO_TABLE = 'no_table',
  PAY = 'pay'
}

type SubmitSectionProps = {
  agreed: boolean
}

const SubmitSection: React.FC<SubmitSectionProps> = ({ agreed }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const venueId = useSelector(getVenueId)
  const venue = useSelector(getVenueById)(venueId)
  const timedTakeout = useSelector(getTimedTakeout)
  const delivery = useSelector(getDelivery)
  const takeout = useSelector(getTakeout)
  const tableNum = useSelector(getTableNum)
  const workingHours = useSelector(getWorkingHoursFull)
  const venueStatus = useSelector(getVenueStatus)
  const userData = useSelector(getUserData)
  const servicesStopList = useSelector(getServicesStopList)
  const { activeServiceType } = useSelector(store => store.shoppingCard)

  const { preferences } = useAppPreferences()
  const [{ preorderInfo, isUnderMinOrderAmount, cookingTime }] = useUnpaidOrders()
  const [{ hasWarning }] = useShoppingCartStatus()
  const isVenueOpen = useVenueOpenAt(timedTakeout ? timedTakeout : undefined)
  const isNative = useCheckNative()

  const { toggleCodeScan } = useContext(ActivityViewsContext)

  const [submittingStatus, setSubmittingStatus] = useState<SUBMITTING_STATUS>(SUBMITTING_STATUS.DISABLED)
  const [submittingLabel, setSubmittingLabel] = useState<string>('')

  const warning: boolean = useMemo(() => {
    if ((delivery || takeout) && !timedTakeout) {
      if (preorderInfo && activeServiceType !== SERVICES_TYPE.DELIVERY_NOVAPOSHTA) {
        return true
      }

      if (!calculateIsCookingTimeBeforeClose(cookingTime, workingHours)) {
        return true
      }

      if (isCookingTimeInStopList(servicesStopList, cookingTime) && delivery) {
        return true
      }
    }

    if (isUnderMinOrderAmount) {
      return true
    }

    if (delivery && !agreed) {
      return true
    }

    if ((preorderInfo || preferences.takeawayPhoneRequired) && !userData.phone) {
      return true
    }

    if (!isVenueOpen) {
      return true
    }

    return hasWarning || (!(timedTakeout && isTomorrow(timedTakeout)) && hasWarning)
  }, [
    delivery,
    activeServiceType,
    takeout,
    timedTakeout,
    preorderInfo,
    agreed,
    preferences,
    isUnderMinOrderAmount,
    userData,
    isVenueOpen,
    hasWarning,
    cookingTime,
    workingHours,
    servicesStopList
  ])

  useEffect(() => {
    if (venueStatus === VENUE_STATUSES.ERROR || venueStatus === VENUE_STATUSES.DISABLED) {
      setSubmittingStatus(SUBMITTING_STATUS.DISABLED)
    } else if (warning) {
      setSubmittingStatus(SUBMITTING_STATUS.WARNING)
    } else if (!venue) {
      setSubmittingStatus(SUBMITTING_STATUS.NO_VENUE)
    } else if (!takeout && tableNum === 0) {
      setSubmittingStatus(SUBMITTING_STATUS.NO_TABLE)
    } else {
      setSubmittingStatus(SUBMITTING_STATUS.PAY)
    }
  }, [venueStatus, warning, venue, takeout, tableNum])

  useEffect(() => {
    if (venueStatus === VENUE_STATUSES.ERROR) {
      setSubmittingLabel(t('tableNumber.networkError'))
    } else if (venueStatus === VENUE_STATUSES.DISABLED) {
      setSubmittingLabel(t('tableNumber.disabledApp'))
    }

    if (submittingStatus === SUBMITTING_STATUS.NO_VENUE) {
      setSubmittingLabel(t('paymentSection.chooseVenue'))
    } else if (submittingStatus === SUBMITTING_STATUS.NO_TABLE) {
      setSubmittingLabel(t('general.chooseTable'))
    } else {
      setSubmittingLabel(t('general.pay'))
    }
  }, [venueStatus, submittingStatus, t])

  const handleVenueSelect = useCallback(() => {
    history.push('/venues')
    postEvent('cart-btn_venue_select', 'engaging')
  }, [history])

  const handleTableSelect = useCallback(() => {
    if (isNative) {
      history.push(`/v/${venueId}`)
    } else {
      toggleCodeScan(true)
      postEvent('cart-btn_table_select', 'engaging')
    }
  }, [toggleCodeScan, isNative, venueId, history])

  if (submittingStatus === SUBMITTING_STATUS.DISABLED) {
    return <DisabledButton label={submittingLabel} />
  }

  if (submittingStatus === SUBMITTING_STATUS.WARNING) {
    return <WarningButton label={submittingLabel} />
  }

  if (submittingStatus === SUBMITTING_STATUS.NO_VENUE) {
    return <CommonButton label={submittingLabel} handleClick={handleVenueSelect} />
  }

  if (submittingStatus === SUBMITTING_STATUS.NO_TABLE) {
    return <CommonButton label={submittingLabel} handleClick={handleTableSelect} />
  }

  return <PaymentButton label={submittingLabel} />
}

export default SubmitSection
