import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'src/state/hooks'
import { SHOPPING_CART_STATE, ShoppingCartStatus } from 'src/types'
import useUnpaidOrders from '../useUnpaidOrders'
import usePaidOrders from '../usePaidOrders'
import { getVenueById } from 'src/state/venues'
import { getVenueId } from 'src/state/location'
import { getTakeout } from '../../state/orders'
import { getDelivery } from '../../state/delivery'
import useOrdersWarnings from './useOrdersWarnings'

type ShoppingCartHookState = {
  status: ShoppingCartStatus
  hasWarning: boolean
  cookingOrdersCount: number
}
type ShoppingCartHookApi = {}
type ShoppingCartHookValue = [ShoppingCartHookState, ShoppingCartHookApi]

export default function useShoppingCartStatus(): ShoppingCartHookValue {
  const venueId = useSelector(getVenueId)
  const venue = useSelector(getVenueById)(venueId)
  const isDeliveryMode = useSelector(getDelivery)
  const isTakeoutMode = useSelector(getTakeout)

  const [status, setStatus] = useState<ShoppingCartStatus>(SHOPPING_CART_STATE.EMPTY)

  const [{ isEmpty: isEmptyNewOrders, isUnderMinOrderAmount, orders }] = useUnpaidOrders()
  const { hasWarnings: ordersWarnings } = useOrdersWarnings(orders)
  const [{ isEmpty: isEmptyPaidOrders, orders: paidOrders }] = usePaidOrders()

  const venueOrderStatuses = venue?.settings?.dishStatuses || {}
  const possibleWaiting =
    (venueOrderStatuses.takeout && isTakeoutMode) ||
    (venueOrderStatuses.delivery && isDeliveryMode) ||
    (venueOrderStatuses.here && !isTakeoutMode && !isDeliveryMode)

  const cookingOrdersCount = useMemo(
    () => (possibleWaiting ? paidOrders.length - paidOrders.filter(order => order.status?.ready).length : 0),
    [paidOrders, possibleWaiting]
  )

  useEffect(() => {
    if (!isEmptyNewOrders) {
      setStatus(SHOPPING_CART_STATE.NEW)
    } else if (!isEmptyPaidOrders) {
      setStatus(SHOPPING_CART_STATE.PAID)
    } else {
      setStatus(SHOPPING_CART_STATE.EMPTY)
    }
  }, [isEmptyNewOrders, isEmptyPaidOrders])

  const state = useMemo(
    () => ({
      status,
      cookingOrdersCount,
      hasWarning: ordersWarnings || isUnderMinOrderAmount || false
    }),
    [status, isUnderMinOrderAmount, ordersWarnings, cookingOrdersCount]
  )

  const api = useMemo(() => ({}), [])

  return [state, api]
}
