import React, { useCallback, useState } from 'react'
import { motion } from 'framer-motion'
import { IDish } from 'src/types'
import useDishesTags from 'src/hooks/useDishesTags'
import { useTranslation } from 'src/hooks/useTranslation'
import TagButton from './TagButton'
import s from '../styles.module.scss'

type TagsFilterProps = {
  dishes: IDish[]
  onChange: (tagsIds: number[]) => void
}

const variants = {
  visible: {
    transition: {
      staggerChildren: 0.04
    }
  },
  hidden: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1
    }
  }
}

const TagsFilter: React.FC<TagsFilterProps> = function({ dishes, onChange }) {
  const { t } = useTranslation()

  const [tagsToFilter, setTagsToFilter] = useState<number[]>([])

  const { tags } = useDishesTags(dishes)

  const handleTagClick = useCallback(
    (tagId: number) => () => {
      let updatedTagsToFilter
      if (tagsToFilter.includes(tagId)) {
        updatedTagsToFilter = tagsToFilter.filter(id => id !== tagId)
        setTagsToFilter(updatedTagsToFilter)
      } else {
        updatedTagsToFilter = [...tagsToFilter, tagId]
        setTagsToFilter(updatedTagsToFilter)
      }
      onChange(updatedTagsToFilter)
    },
    [tagsToFilter, onChange]
  )

  if (tags.length > 0) {
    return (
      <motion.div variants={variants} initial='hidden' animate='visible' className={s.tagsFilter}>
        {tags.map(tag => (
          <TagButton
            key={tag.id}
            className={s.tagsFilterButton}
            label={t(tag.name)}
            onClick={handleTagClick(tag.id)}
            isActive={tagsToFilter.includes(tag.id)}
          />
        ))}
      </motion.div>
    )
  }

  return null
}

export default TagsFilter
