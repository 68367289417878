import axios from 'axios'

import { config } from 'src/constants'
/**
 *
 * @param {string} venueSlug // required
 * @param {string} fromDate // required
 * @param {string} type // optional
 * @param {string} toDate // optional
 * @returns Promise<ServicesStoplist[]>
 */
export async function getServicesStoplist(venueSlug, fromDate, type, toDate) {
  return axios({
    url: `${config.API_URL3}/${window.busytable.brandSlug}/${venueSlug}/servicesStoplist?fromDate=${fromDate}${
      type ? `&type=${type}` : ''
    }${toDate ? `&toDate=${toDate}` : ''}`
  })
    .then(resp => {
      if (resp?.data && resp?.data?.success) {
        return resp?.data?.data
      }
    })
    .catch(e => {
      if (e.response.data) {
        throw Error(e.response.data)
      } else {
        throw Error(e)
      }
    })
}
