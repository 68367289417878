export const SET_NOT_FIRST_TIME = 'SET_NOT_FIRST_TIME'
export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE'
export const SET_TAKEOUT = 'SET_TAKEOUT'
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE'

export const ADD_FAVORITE = 'ADD_FAVORITE'
export const SET_FAVORITES = 'SET_FAVORITES'

export const ADD_USERDATA = 'ADD_USERDATA'
export const ADD_USER_DATA_STARTED = 'ADD_USER_DATA_STARTED'
export const ADD_USER_DATA_ERROR = 'ADD_USER_DATA_ERROR'
export const REMOVE_FAVORITE = 'REMOVE_FAVORITE'
export const TOGGLE_CHECK = 'TOGGLE_CHECK'
export const SET_IP = 'SET_IP'
export const SET_RATE = 'SET_RATE'
export const TOGGLE_SHOW_BANNER = 'TOGGLE_SHOW_BANNER'
export const TOGGLE_SHOW_TIPS = 'TOGGLE_SHOW_TIPS'
export const TOGGLE_LAST_REVIEW = 'TOGGLE_LAST_REVIEW'
export const START_USER_CONFIRMATION = 'START_USER_CONFIRMATION'
export const FINISH_USER_CONFIRMATION = 'FINISH_USER_CONFIRMATION'
