import { SHOW_TABLE, HIDE_TABLE } from './types'

export const showTable = num => ({
  type: SHOW_TABLE,
  payload: { num }
})

export const hideTable = () => ({
  type: HIDE_TABLE
})
