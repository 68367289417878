import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { postEvent } from 'src/api'
import useCheckNative from 'src/hooks/useCheckNative'
import { useTranslation } from 'src/hooks/useTranslation'
import useUnpaidOrders from 'src/hooks/useUnpaidOrders'
import { sendMessage } from 'src/network/sendingData/messages'
import { getTableNum, getVenueId } from 'src/state/location'

import { QR_MENU_MESSAGE_TYPE } from 'src/types'
import CommonButton from '../ShoppingCart/components/CommonButton'
import { ActivityViewsContext } from '../ShoppingCart/contexts'
import styles from './styles.module.scss'

export interface IQrMenuActionsProps {}
const PAUSE_AFTER_API_CALL = 30 * 1000 // miliseconds

const QrMenuActions: React.FC<IQrMenuActionsProps> = ({}) => {
  const [isPaymentTypeOpen, setIsOpenTypeOpen] = useState<boolean>(false)
  const [disabledType, setDisabledType] = useState<QR_MENU_MESSAGE_TYPE[]>([])
  const tableNum = useSelector(getTableNum)
  const venueSlug = useSelector(getVenueId)
  const { t } = useTranslation()
  const history = useHistory()
  const { toggleCodeScan } = useContext(ActivityViewsContext)
  const isNative = useCheckNative()
  const [{ totalPrice }] = useUnpaidOrders()
  async function sendNewMessage(type: QR_MENU_MESSAGE_TYPE) {
    try {
      await sendMessage({ type, tableNum, orderAmount: totalPrice / 100 }, venueSlug)
      setDisabledType(prevState => [...prevState, type])

      if (type === QR_MENU_MESSAGE_TYPE.CHECK_CASH || type === QR_MENU_MESSAGE_TYPE.CHECK_CARD) {
        handleOpenPaymentType()
      }
      setTimeout(() => {
        setDisabledType(state => state.filter(filterType => filterType !== type))
      }, PAUSE_AFTER_API_CALL)
    } catch (e) {
      console.log('Error while sending message')
    }
  }

  const handleOpenPaymentType = () => {
    setIsOpenTypeOpen(!isPaymentTypeOpen)
  }

  const isReciptDisabled = useMemo(
    () =>
      disabledType?.some(type => type === QR_MENU_MESSAGE_TYPE.CHECK_CARD || type === QR_MENU_MESSAGE_TYPE.CHECK_CASH),
    [disabledType]
  )

  const isCallStuffDisabled = useMemo(() => disabledType?.some(type => type === QR_MENU_MESSAGE_TYPE.NEW_ORDER), [
    disabledType
  ])
  const handleTableSelect = useCallback(() => {
    if (isNative) {
      history.push(`/v/${venueSlug}`)
    } else {
      toggleCodeScan(true)
      postEvent('cart-btn_table_select', 'engaging')
    }
  }, [toggleCodeScan, isNative, venueSlug, history])

  if (!tableNum) {
    return <CommonButton label={t('general.chooseTable')} handleClick={handleTableSelect} />
  }

  return (
    <div className={styles.wrapper}>
      <button
        onClick={() => sendNewMessage(QR_MENU_MESSAGE_TYPE.NEW_ORDER)}
        disabled={isCallStuffDisabled}
        className={`${styles.callStuffButton}`}
      >
        {t('qrmenuActions.callWaiter')}
      </button>
      <div
        className={`${styles.paymentMethodButtonsWrapper} 
        ${isPaymentTypeOpen ? styles.paymentMethodButtonsWrapperActive : ''}`}
      >
        <button
          className={isPaymentTypeOpen ? '' : styles.callStuffButtonActive}
          disabled={isReciptDisabled}
          onClick={handleOpenPaymentType}
        >
          {t('qrmenuActions.check')}
        </button>
        <button onClick={() => sendNewMessage(QR_MENU_MESSAGE_TYPE.CHECK_CASH)}>{t('qrmenuActions.check_cash')}</button>
        <button onClick={() => sendNewMessage(QR_MENU_MESSAGE_TYPE.CHECK_CARD)}>{t('qrmenuActions.check_card')}</button>
      </div>
    </div>
  )
}

export default QrMenuActions
