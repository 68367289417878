import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { postEvent } from 'src/api'
import { useTranslation } from 'src/hooks/useTranslation'
import { useSelector } from 'src/state/hooks'
import { getDelivery } from 'src/state/delivery'
import { getUserData } from 'src/state/user'
import { decoratePhone } from 'src/utils/phone'
import { ReactComponent as UserIcon } from 'src/assets/person.svg'
import { ReactComponent as PoiIcon } from 'src/assets/icons/ico-poi-24.svg'
import { ReactComponent as PhoneIcon } from 'src/assets/phone.svg'
import { ActivityViewsContext } from '../contexts'
import PaymentSectionField from './PaymentSectionField'

type RecipientFormInputsProps = {
  invalidFields: string[]
}

const RecipientFormInputs: React.FC<RecipientFormInputsProps> = ({ invalidFields }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const delivery = useSelector(getDelivery)
  const userData = useSelector(getUserData)

  const { toggleDeliveryForm } = useContext(ActivityViewsContext)

  return (
    <>
      <PaymentSectionField
        icon={<UserIcon width={16} height={16} />}
        label={t('recipient.name')}
        hasError={invalidFields.includes('delivery.name')}
        value={delivery?.name}
        placeholder={<span>{t('paymentSection.recipientNamePlaceholder')}</span>}
        handleClick={() => {
          toggleDeliveryForm('recipient')
          postEvent('cart-form-delivery-name', 'engaging')
        }}
      />
      <PaymentSectionField
        icon={<PoiIcon width={16} height={16} />}
        label={t('recipient.address')}
        hasError={invalidFields.includes('delivery.address')}
        value={delivery?.address}
        placeholder={<span>{t('paymentSection.recipientAddressPlaceholder')}</span>}
        handleClick={() => {
          toggleDeliveryForm('recipient')
          postEvent('cart-form-address', 'engaging')
        }}
      />
      <PaymentSectionField
        icon={<PhoneIcon width={16} height={16} />}
        label={t('recipient.phone')}
        hasError={invalidFields.includes('delivery.phone')}
        value={decoratePhone(delivery?.phone)}
        placeholder={<span>{t('paymentSection.recipientPhonePlaceholder')}</span>}
        handleClick={() => {
          toggleDeliveryForm('recipient')
          postEvent('cart-form-delivery-phone', 'engaging')
        }}
      />
      <PaymentSectionField
        icon={<UserIcon width={16} height={16} />}
        label={t('paymentSection.myNameLabel')}
        hasError={delivery && invalidFields.includes('name')}
        value={userData?.name}
        placeholder={<span>{t('paymentSection.namePlaceholder')}</span>}
        handleClick={() => {
          history.push('/profile')
          postEvent('cart-form-name', 'engaging')
        }}
      />
      <PaymentSectionField
        icon={<PhoneIcon width={16} height={16} />}
        label={t('paymentSection.myPhoneLabel')}
        hasError={delivery && invalidFields.includes('phone')}
        value={decoratePhone(userData?.phone)}
        placeholder={<span>{t('paymentSection.phonePlaceholder')}</span>}
        handleClick={() => {
          history.push('/profile')
          postEvent('cart-form-phone', 'engaging')
        }}
      />
    </>
  )
}

export default RecipientFormInputs
