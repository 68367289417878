/**
 * COMMON TYPES
 * */
export type DateTime = string
export type ServiceType = SERVICES_TYPE
export type IAllowedLanguages = 'en' | 'ru' | 'uk' | 'ar'
export type AddOnsTypes = 'tips'

export enum ORDER_STATUS {
  ADDED = 'added',
  READY = 'ready',
  PAID = 'paid',
  FINISHED = 'finished'
}

export enum QR_MENU_MESSAGE_TYPE {
  NEW_ORDER = 'new-order',
  CHECK_CARD = 'check-card',
  CHECK_CASH = 'check-cash'
}

export enum PAYMENT_METHOD {
  APPLE_PAY = 'apple-pay',
  GOOGLE_PAY = 'g-pay',
  CARD = 'card'
}

export type PaymentMethod = PAYMENT_METHOD

export type IOrderStatus = {
  [key in ORDER_STATUS]: DateTime
}

export type ASSET_TYPE = 'common' | 'icons' | 'dishes' | 'brand' | 'reviews'

export type ITranslationsInput = {
  [key in IAllowedLanguages]: string
}

export interface CaloriesType {
  carbs: number
  fat: number
  kcal: number | string
  protein: number
}

export interface MediaItemType {
  id: number
  mediaType: string
  path: string
}

/**
 * DISHES
 * */
export interface IDish {
  id: number
  name: ITranslationsInput
  desc: ITranslationsInput
  categoryId: number
  calories: CaloriesType | null
  categoryName: ITranslationsInput
  subcategoryId: number | null
  subcategoryName: ITranslationsInput | null
  img: string
  imgWide: string
  parentSizeId: number | null
  preorderStart: DateTime | null
  media: MediaItemType[]
  previewMedia: MediaItemType[]
  price: number
  size: boolean
  sizePosition: number
  sizeNameT: number
  sizeName?: ITranslationsInput
  start: DateTime | null
  end: DateTime | null
  icon: string
  unit: string
  volume: number
  waitingTime: number
  recipeId: number
  tags: number[]
}

export type FavoriteDish = {
  id: number
  favoriteId: number
  options: SelectedOptionType[]
}

/**
 * TAGS
 */

export interface Tag {
  id: number
  name?: ITranslationsInput
  rank: number
  color?: string
  isFilter: boolean
  isVisible: boolean
  fontColor?: string
}

/**
 * OPTIONS
 * */
export interface OptionType {
  id: number
  name: ITranslationsInput
  nameCustom: ITranslationsInput | null
  nameCustomT: string | null
  position: number | string
  label: string
  modifierId: number
  default: boolean
  price: number
  volume: number
  unit: string | null
}

export interface IModifierType {
  id: number
  brandId: number
  label: string
  name: string
  nameT: number
  createdAt: DateTime
  updatedAt: DateTime
  deletedAt: DateTime
  unit: string
  nameLangs: ITranslationsInput | null
}

export interface OptionSetType {
  id: number
  nameT: string
  name: ITranslationsInput
  position: number | string
  recipeId: number
  showDefault: boolean
  options: OptionType[]
}

export interface SelectedOptionType {
  id: number
  value: number
}

/**
 * CATEGORIES
 * */
export interface ICategory {
  id: number
  parentId: number | null
  slug: string
  menuId: number | null
  icon: string | null
  dishIds: number[]
  defaultMenu: boolean
  flatmenu: boolean
  name: ITranslationsInput
}

/**
 * VENUES
 * */
export interface IVenue {
  id: number
  name: ITranslationsInput
  address: ITranslationsInput
  disabled: boolean
  hidden: boolean
  slug: string
  phone: string
  menuId: number
  ip: string
  lat: number
  lng: number
  here: boolean
  takeout: boolean
  settings: any
  workingHours: any
  brand: any
  desc: string
  appPreferences: any
}

/**
 * STOPLISTS
 * */

export enum SERVICES_TYPE {
  HERE = 'here',
  TAKEAWAY = 'takeaway',
  DELIVERY_TAXI = 'delivery_taxi',
  DELIVERY_NOVAPOSHTA = 'delivery_novaposhta',
  DELIVERY_OWN = 'delivery_own',
  DELIVERY_UKLON = 'delivery_uklon'
}

export enum VENUE_SERVICES_TYPE {
  HERE = 'here',
  TAKEOUT = 'takeout',
  DELIVERY = 'delivery'
}

export type ServicesType = SERVICES_TYPE
export type VenueServicesType = VENUE_SERVICES_TYPE

export interface IServiceStopListItem {
  id: number
  type: ServicesType
  endingAt: DateTime
  startingAt: DateTime
}

/**
 * ORDERS
 * */

export enum PAYMENT_ADDON_TYPE {
  TIPS = 'tips'
}

export interface IPaymentAddon {
  id: number
  amountPaid: number
  type: PAYMENT_ADDON_TYPE
}

export interface IPayment {
  id: number
  addOns: IPaymentAddon[]
  amountPaid: number
  amountBeforeDiscount: number
  paid: DateTime
  takeout: boolean
  timedTakeout: DateTime | null
  takeoutNum: number
  tableNum: number
  venueId: number
  comment: string
  orders: Partial<IOrder>[]
  venue: Partial<IVenue>
}

export interface IOrder {
  paymentId: number
  tempId: number
  venueSlug: string
  dishId: number
  options?: SelectedOptionType[]
  takeout: boolean
  tableNum: number
  delivery: any
  info: any
  preorder: boolean
  comment: string
  takeoutNum: number
  dishPrice: number
  optionsPrice: number
  paidAt: DateTime | null
  timedTakeout: DateTime | null
  status: IOrderStatus
  serviceType: ServiceType
  selectedOptions?: SelectedOptionType[]
  count?: number
  totalPrice?: number
}

export type NewOrderInput = Partial<IDish> & Partial<IOrder>

export type PaymentExtraInfo = {
  cookingTime: number
}

export type UnpaidDetails = {
  venueSlug: string | number
  paymentId: number
  takeoutNum: number
  timedTakeout: DateTime | null
  info: PaymentExtraInfo
}

export enum SHOPPING_CART_STATE {
  PAID = 'paid',
  NEW = 'new',
  CAN_TAKEOUT = 'canTakeout',
  EMPTY = 'empty'
}

export type ShoppingCartStatus = SHOPPING_CART_STATE

/**
 * THEMES
 * */

export type WithModificationVariant = {
  variant: string | null
  darkMode?: boolean
}

export type AppModificationsVariant = {
  showCustomPopup: boolean
  shoppingCartDecoration: boolean
  navbarDecoration: boolean
  navbarDecorationDarkMode?: boolean
  addingToCartAnimation: boolean
  foregroundAnimation: boolean
  customBackground: boolean
}

export interface HolidayModifications {
  currentVariant: string | null
  variants: any
}

export type ThemeVariables = {
  [key in string]: any
}

export type ThemeIcons = {
  [key in string]: any
}

export interface ITheme {
  id?: number
  variables?: ThemeVariables | null
  navbarVariables?: ThemeVariables | null
}

export interface UserFile {
  id?: number
  originalName: string
  mediaType: string
  path: string
}
