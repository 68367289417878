import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getOrdersNew, clearNewOrders } from 'src/state/orders'
import useAppPreferences from 'src/hooks/useAppPreferences'

const useClearOrders = () => {
  const newOrders = useSelector(getOrdersNew)
  const { preferences } = useAppPreferences()
  const dispatch = useDispatch()
  const timeToClear = 3600 * 1000 // 1 hour

  useEffect(() => {
    if (newOrders.length && preferences.withQrMenuMode) {
      const isSomeOrderDateAfterEnd = newOrders.some(
        order => new Date(order.tempId).getTime() + timeToClear < new Date().getTime()
      )
      if (isSomeOrderDateAfterEnd) {
        dispatch(clearNewOrders())
      }
    }
  }, [newOrders.length, preferences.withQrMenuMode])
}

export default useClearOrders
