import { SET_STARTED, SET_DISH_ADDED, SET_DRINK, TOGGLE_APPLIANCE } from './types'

export const setStarted = () => {
  return {
    type: SET_STARTED
  }
}

export const setDishAdded = () => {
  return {
    type: SET_DISH_ADDED
  }
}

export const setDrink = payload => {
  return {
    type: SET_DRINK,
    payload
  }
}

export const toggleAppliance = () => {
  return {
    type: TOGGLE_APPLIANCE
  }
}
