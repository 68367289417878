import { useTranslation } from './useTranslation'

export default function useFormatWaitingTime(time: number) {
  const { t } = useTranslation()

  if (time < 86400) {
    const minutes = Math.ceil(time / 60)
    if (minutes === 0) {
      return ''
    }
    if (minutes < 60) {
      return `${minutes} ${t('waitingTimeBadge.time')}`
    } else {
      let hours = Math.ceil(minutes / 60)
      let leftMinutes = minutes % 60

      if (leftMinutes > 30) {
        hours += 1
        leftMinutes = 0
      }

      return `${hours}${leftMinutes > 0 ? `.5` : ''} ${t('waitingTimeBadge.hours')}`
    }
  } else {
    return `${Math.floor(time / 86400)} ${t('waitingTimeBadge.days')}`
  }
}
