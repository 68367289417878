import React, { useCallback, useEffect, useState, useRef } from 'react'
import { cycleActivityView } from 'src/state/ui'
import s from 'src/components/Profile/styles.module.scss'
import globalStyles from 'src/styles/App.global.scss'
import { useTranslation } from 'src/hooks/useTranslation'
import ActivityView from 'src/components/ui/ActivityView'
import { useDispatch, useSelector } from 'react-redux'
import { addUserData, startUserConfirmation } from '../../state/user/actions'
import { Button } from '../ui/Button'
import { CONFIRMATION_CODE_LENGTH } from '../../constants'
import { fetchConfirmationCode, finishUserConfirmationThunk, submitConfirmationCode } from '../../api'
import { getUserData } from '../../state/user'
import { getUserConfirmationExpireDate } from '../../state/user/selectors'
import { togglePageLoader } from '../../state/ui/actions'

const EMPTY_INPUT_MASK = '•'

const objectFromLength = Object.fromEntries(
  Array.from({ length: CONFIRMATION_CODE_LENGTH }).map((_, index) => [index, ''])
)

export default function MergeProfileView() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const confirmationExpireDate = useSelector(getUserConfirmationExpireDate)
  const user = useSelector(getUserData)

  const [confirmationCode, setConfirmationCode] = useState(objectFromLength)
  const [code, setCode] = useState('')
  const [confirmationRetry, setConfirmationRetry] = useState(false)
  const [confirmationError, setConfirmationError] = useState(null)

  const inputRef = useRef()

  const initPositionY = 0
  const openPositionY = -window.innerHeight + 100

  useEffect(() => {
    dispatch(cycleActivityView({ id: 3, current: openPositionY }))
  }, [dispatch, openPositionY])

  const handleCodeInput = useCallback(
    e => {
      const value = e.target.value.slice(0, 6)

      setCode(value)

      const codeMap = [...value.split(''), ...Array.from({ length: CONFIRMATION_CODE_LENGTH - value.length })].reduce(
        (res, curr, index) => ({ ...res, [index]: curr }),
        {}
      )

      setConfirmationCode(codeMap)
    },
    [setConfirmationCode]
  )

  const handleCancel = useCallback(() => {
    dispatch(cycleActivityView({ id: 3, current: initPositionY }))
    setTimeout(() => {
      dispatch(addUserData({ ...user, profilesCount: null }))
    }, 300)
  }, [dispatch, user, initPositionY])

  const focusInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef])

  const handleSubmitCode = useCallback(
    async code => {
      dispatch(togglePageLoader(true))
      try {
        const result = await submitConfirmationCode(code)
        if (result.status === 200) {
          handleCancel()
          await dispatch(finishUserConfirmationThunk())
        }
      } catch (e) {
        if (e.response?.status === 400) {
          setConfirmationError('profile.confirmationWrongCode')
        } else if (e.response?.status === 403) {
          setConfirmationError('profile.confirmationCodeExpired')
        } else {
          setConfirmationError('profile.unexpectedError')
        }
        dispatch(togglePageLoader(false))
      }
    },
    [dispatch, handleCancel]
  )

  useEffect(() => {
    if (code.length === CONFIRMATION_CODE_LENGTH) {
      handleSubmitCode(code)
    }
  }, [code, handleSubmitCode])

  useEffect(() => {
    let timeout = 0
    const distance = new Date(confirmationExpireDate).getTime() - new Date().getTime()

    if (confirmationExpireDate) {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        setConfirmationRetry(true)
      }, distance)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [confirmationExpireDate, dispatch])

  useEffect(() => {
    if (confirmationExpireDate) {
      focusInput()
    }
  }, [focusInput, confirmationExpireDate])

  const handleGetConfirmationCode = () => {
    setConfirmationRetry(false)
    setConfirmationError(null)
    fetchConfirmationCode()
      .then(result => {
        dispatch(startUserConfirmation(result?.data))
      })
      .catch()
    setConfirmationCode(objectFromLength)
  }

  return (
    <ActivityView
      id={3}
      shader
      closeHandler={handleCancel}
      initPositionY={initPositionY}
      openPositionY={openPositionY}
      closeState
      shaderTapHandler={handleCancel}
    >
      <div className={s.mergeProfile}>
        <strong className={[globalStyles.H4, s.mergeProfileTitle].join(' ')}>{t('profile.confirmationTitle')}</strong>
        <p className={[globalStyles.body1, s.mergeProfileText].join(' ')}>
          {t('profile.confirmationDescription', { phone: user.phone })}
        </p>
        {!confirmationExpireDate && (
          <Button width='auto' className={s.mergeProfileButton} onClick={handleGetConfirmationCode}>
            {t('profile.getConfirmationCode')}
          </Button>
        )}
        {confirmationExpireDate && (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <fieldset className={s.mergeProfileCodeForm} onClick={focusInput}>
            <div className={s.mergeProfileInputsRow}>
              {Object.values(confirmationCode).map((digit, i) => (
                <input
                  key={`num-${i}`}
                  type='text'
                  placeholder={EMPTY_INPUT_MASK}
                  className={s.mergeProfileCodeInput}
                  value={digit || EMPTY_INPUT_MASK}
                  readOnly
                />
              ))}
            </div>
            <input
              ref={inputRef}
              type='tel'
              className={s.mergeProfileHiddenInput}
              value={code}
              onChange={handleCodeInput}
            />
          </fieldset>
        )}
        {confirmationError && <div className={s.errorConfirmation}>{t(confirmationError)}</div>}
        <Button
          disabled={!confirmationRetry}
          width='auto'
          className={s.mergeProfileResendBtn}
          onClick={handleGetConfirmationCode}
        >
          {t('profile.getConfirmationCodeOnceMore')}
        </Button>
      </div>
    </ActivityView>
  )
}
