import { IOrder, NewOrderInput, SelectedOptionType, SERVICES_TYPE } from '../types'

export type GroupedOrders = {
  [key in string]: IOrder | NewOrderInput
}

export const getOrderUniqueKey = (order: IOrder | NewOrderInput) => {
  const orderOptions = order.options || order.selectedOptions || []
  const orderOptionsString = JSON.stringify(orderOptions.sort((a, b) => a.id - b.id))
  return `${order.takeoutNum || ''}${order.dishId}_${orderOptionsString}`
}

export const getUniqueKeyByDishAndOptions = (dishId: number, options: SelectedOptionType[]) => {
  const optionsString = JSON.stringify(options.sort((a, b) => a.id - b.id))
  return `${dishId}_${optionsString}`
}

export const parseUniqueKeyByDishAndOptions = (key: string) => {
  if (!key?.length) {
    return null
  }
  const dishId = key.split('_')[0]
  const options = JSON.parse(key.split('_')[1])
  return { dishId, options }
}

export const sameOrdersReducer = (result: GroupedOrders, order: IOrder | NewOrderInput) => {
  const orderOptions = order.options || order.selectedOptions || []
  const orderOptionsString = JSON.stringify(orderOptions.sort((a, b) => a.id - b.id))
  const uniqueKey = `${order.takeoutNum || ''}${order.dishId}_${orderOptionsString}`

  const existedOrder = result[uniqueKey]
  const existedOrderOptions = existedOrder?.options || existedOrder?.selectedOptions || []
  const existedOrderOptionsString = JSON.stringify(existedOrderOptions.sort((a, b) => a.id - b.id))
  const sameTakeoutNum = existedOrder && existedOrder.takeoutNum === order.takeoutNum

  if (sameTakeoutNum && existedOrder && orderOptionsString === existedOrderOptionsString) {
    return { ...result, [uniqueKey]: { ...order, count: (existedOrder?.count || 0) + 1 } }
  }

  return { ...result, [uniqueKey]: { ...order, count: 1 } }
}

export const combinedOrderKeyFrom = (order: IOrder | NewOrderInput) => `${order.dishId}_${order}`

export const isTakeoutOrder = (order: IOrder): boolean => order && order.serviceType === SERVICES_TYPE.TAKEAWAY
export const isDeliveryOrder = (order: IOrder): boolean =>
  order &&
  [SERVICES_TYPE.DELIVERY_TAXI, SERVICES_TYPE.DELIVERY_NOVAPOSHTA, SERVICES_TYPE.DELIVERY_OWN].includes(
    order.serviceType
  )
export const isTakeoutOrDeliveryOrder = (order: IOrder): boolean => isTakeoutOrder(order) || isDeliveryOrder(order)
