import { SET_VENUE_ID, SET_TABLE_NUM, SET_VENUE_OPEN, SET_SCAN_DATE, SET_CURRENT_CATEGORY } from './types'

/* State Shape
{
  venueId: int,
  tableNum: int,
  open: bool
}
*/

const initialState = {
  venueId: 0,
  tableNum: 0,
  open: false,
  currentCategory: null,
  lastChangeDate: new Date(),
  lastScanDate: new Date()
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SCAN_DATE: {
      return {
        ...state,
        lastScanDate: new Date(state.lastScanDate.getTime() - 2 * 3600 * 1000)
      }
    }
    case SET_VENUE_ID: {
      const { id } = action.payload

      return {
        ...state,
        venueId: id,
        lastChangeDate: new Date(),
        lastScanDate: new Date()
      }
    }

    case SET_TABLE_NUM: {
      const { num } = action.payload

      return {
        ...state,
        tableNum: num,
        lastChangeDate: new Date(),
        lastScanDate: new Date()
      }
    }

    case SET_VENUE_OPEN: {
      const { status } = action.payload

      return {
        ...state,
        open: status
      }
    }

    case SET_CURRENT_CATEGORY: {
      const { id } = action.payload

      return {
        ...state,
        currentCategory: id
      }
    }

    default:
      return state
  }
}
