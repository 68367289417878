import { AppState } from '../store'
import { Tag } from 'src/types'

export const getTagsByIds = (store: AppState) => (tagsIds: number[]): Tag[] =>
  store.tags.tags?.filter(tag => tagsIds?.includes(tag?.id))

export const getVisibleTagsByIds = (store: AppState) => (tagsIds: number[]): Tag[] =>
  store.tags.tags?.filter(tag => tag?.isVisible && tagsIds?.includes(tag?.id))

export const getFilterableTags = (store: AppState) => store.tags.tags?.filter(tag => tag.isFilter)
