import { GET_SERVICES_STOPLIST_STARTED, GET_SERVICES_STOPLIST_ERROR, GET_SERVICES_STOPLIST_SUCCESS } from './types'

export const getServicesStoplistStarted = () => ({
  type: GET_SERVICES_STOPLIST_STARTED
})

/**
 *
 * @param {object} error
 */
export const getServicesStoplistError = error => ({
  type: GET_SERVICES_STOPLIST_ERROR,
  error
})

/**
 *
 * @param {object} servicesStoplist
 */
export const getServicesStoplistSuccess = servicesStoplist => ({
  type: GET_SERVICES_STOPLIST_SUCCESS,
  servicesStoplist
})
