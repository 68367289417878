import { ADD_ORDER_CHECKOUT, CLEAR_ORDER_CHECKOUT } from './types'

/* State Shape
{

}
*/

export const initialState = []

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_ORDER_CHECKOUT: {
      const { order } = action.payload
      return [...state, ...order]
    }

    case CLEAR_ORDER_CHECKOUT: {
      return []
    }

    default:
      return state
  }
}
