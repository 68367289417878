import React from 'react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as CrossIcon } from 'src/assets/btn-back-24.svg'
import { ReactComponent as CloseIcon } from 'src/assets/ico-close-24.svg'

export const MenuBar = ({ url, icon, title, right, className, arrowClass, leftCb, ...props }) => {
  const history = useHistory()
  return (
    <div className={['header', className].join(' ')}>
      {icon === 'close' ? (
        <CloseIcon
          onClick={() => {
            if (props.eventCb) {
              props.eventCb()
            }
            if (leftCb) {
              leftCb()
            } else if (url) {
              history.push(url)
            }
          }}
          className={['brandCloseIcon', 'closeIcon', arrowClass].join(' ')}
        />
      ) : icon === 'cross' ? (
        <CrossIcon
          onClick={() => {
            if (props.eventCb) {
              props.eventCb()
            }
            if (leftCb) {
              leftCb()
            } else if (url) {
              history.push(url)
            }
          }}
          data-cy='settingsCloseButton'
          className={['brandCrossIcon', 'closeIcon', arrowClass].join(' ')}
        />
      ) : (
        ''
      )}
      <div data-cy='settingsMenuTitle' className={'menuTitle'}>
        {title}
      </div>
      {right ? right : ''}
    </div>
  )
}
