import React, { useEffect, useRef, useState } from 'react'
import { motion, useAnimation, useMotionValue } from 'framer-motion'
import { useSelector } from 'react-redux'
import smoothscroll from 'smoothscroll-polyfill'
import { useStyles } from 'src/hooks/useStyles'
import { useTranslation } from 'src/hooks/useTranslation'
import { getVenueStatus, VENUE_STATUSES } from 'src/state/location/selectors'
import useAppPreferences from 'src/hooks/useAppPreferences'
import useCurrentCategories from 'src/hooks/useCurrentCategories.ts'
import useThemeStyles from 'src/hooks/useThemeStyles'
import CatTabsItem from './CatTabsItem'
import {
  FAVORITES_CATEGORY_DATA,
  FAVORITES_CATEGORY_ID,
  POPULAR_CATEGORY_DATA,
  POPULAR_CATEGORY_ID
} from '../../../constants'
import s from '../styles.module.scss'
import DraggerIndicator from 'src/components/DraggerIndicator'
import { getCategories } from 'src/state/categories'

// kick off the polyfill!
smoothscroll.polyfill()
// import { ReactComponent as Icon } from '../../assets/icons/icon.svg'

const CatTabs = ({ handleTabClick, activeTab, venueId, inView, isFullTabBarView, handleFullTabBarView }) => {
  const { preferences } = useAppPreferences()
  const { parentCategories: cats, categories } = useCurrentCategories()
  const [isOnBorder, setIsOnBorder] = useState(false)
  const [prevScroll, setPrevScroll] = useState(0)
  const { t } = useTranslation()
  const y = useMotionValue(0)
  const isExpandable = preferences?.withExpandableNavbar || false
  // console.log(cats, 'cats ')
  useStyles()
  const popularIds = preferences?.popularDishesIds

  // const showActiveTab = catId => (catId === activeTab ? s.marker : '')
  const scrollRef = useRef()
  const isClosed = useSelector(getVenueStatus)

  const { icons } = useThemeStyles()

  useEffect(() => {
    if (isFullTabBarView && isOnBorder) {
      setIsOnBorder(false)
    }
  }, [isFullTabBarView, isOnBorder])

  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      let leftScroll = 0
      const extraCategories = [...(popularIds.length ? [0] : []), ...(preferences?.withFavorites ? [-1] : [])]
      const categoriesIds = [...extraCategories, ...cats.map(tab => tab.id)]

      let prevTabsWidth = 0

      categoriesIds.forEach((categoryId, index) => {
        const tabWidth = scrollRef.current.childNodes[index]?.offsetWidth
        prevTabsWidth += tabWidth + 8

        if (categoryId === activeTab) {
          const containerWidth = scrollRef.current.offsetWidth
          const halfOfView = containerWidth / 2 - tabWidth / 2

          leftScroll = prevTabsWidth - tabWidth - halfOfView
          // **
          // * uncomment when like category uncommented!
          // **
          // leftScroll = 68 * (index + 1) - window.innerWidth / 2 - 20
        }
      })
      if (isExpandable) {
        scrollRef.current.scrollTo({ left: leftScroll, behavior: leftScroll !== prevScroll ? 'smooth' : 'auto' })
        if (leftScroll) {
          setPrevScroll(leftScroll)
        }
      } else {
        scrollRef.current.scrollTo({ left: leftScroll, behavior: 'smooth' })
      }
    }
  }, [activeTab, isFullTabBarView]) // eslint-disable-line

  // if (!venue) return null
  if (cats.length < 2) {
    return ''
  }
  return (
    <motion.div
      dragConstraints={{
        top: isFullTabBarView ? -100 : 0,
        bottom: 0
      }}
      style={{ y }}
      dragTransition={{ timeConstant: 240, power: 0.39 }}
      dragElastic={{ top: isFullTabBarView ? 0.1 : 0, bottom: isFullTabBarView ? 0 : 0.1 }}
      dragSnapToOrigin={!isFullTabBarView}
      className={`${s.catTabsDragWrapper} ${!inView ? s.dropshadow : ''}`}
      onDragEnd={(e, i) => {
        if (i.offset.y > 100 && !isFullTabBarView) {
          handleFullTabBarView(true)
        } else if (isFullTabBarView && y.get() < -100) {
          handleFullTabBarView(false)
        }
      }}
      onDrag={(e, i) => {
        if (i.offset.y > 100 && !isOnBorder) {
          setIsOnBorder(true)
        } else if (isOnBorder && i.offset.y < 100) {
          setIsOnBorder(false)
        }
      }}
      drag={isExpandable && !isFullTabBarView ? 'y' : false}
    >
      <div
        ref={scrollRef}
        className={[
          s.catTabs,
          inView || isExpandable ? '' : s.dropshadow,
          isClosed === VENUE_STATUSES.OPEN || isClosed === VENUE_STATUSES.NOT_SELECTED ? '' : s.menuClosed
        ].join(' ')}
      >
        {/* <div style={{ width: 'calc(50vw - 60px)' }} className={s.tab} key='-3'>
          <div className={cx(s.iconBox, s.hiddenBox)}></div>
          <div className={cx(s.iconTitle, s.hiddenBox)}>2</div>
        </div> */}

        {popularIds?.length > 0 && (
          <CatTabsItem
            isActive={activeTab === POPULAR_CATEGORY_ID}
            onMakeActive={handleTabClick}
            handleFullTabBarView={handleFullTabBarView}
            icon={icons.popularIcon}
            data={POPULAR_CATEGORY_DATA}
            label={popularIds?.length > 0 ? t('general.popularLabel') : t('general.allDishes')}
          />
        )}
        {preferences?.withFavorites && (
          <CatTabsItem
            isActive={activeTab === FAVORITES_CATEGORY_ID}
            onMakeActive={handleTabClick}
            handleFullTabBarView={handleFullTabBarView}
            data={FAVORITES_CATEGORY_DATA}
            icon={icons.favoriteIcon}
            label={t('general.favoriteLabel')}
          />
        )}

        {(!isExpandable || !isFullTabBarView) &&
          cats.map(cat => (
            <CatTabsItem
              key={cat.id}
              isActive={cat.id === activeTab}
              handleFullTabBarView={handleFullTabBarView}
              onMakeActive={handleTabClick}
              data={cat}
              icon={cat.icon}
              label={t(cat.name)}
            />
          ))}

        {/* {isFullTabBarView && (
          <>
            {cats
              .filter(cat => categories.some(childCategory => childCategory.parentId === cat.id))
              .map(cat => {
                return (
                  <TabbarItem
                    key={cat.id}
                    activeTab={activeTab}
                    activeSubTab={activeSubtab}
                    handleTabClick={handleTabClick}
                    handleSubTabClick={handleSubtabClick}
                    handleFullTabBarView={handleFullTabBarView}
                    data={cat}
                  />
                )
              })}
          </>
        )} */}

        {/* <div style={{ width: 'calc(50vw - 30px)' }} className={s.tab} key='-2'>
          <div className={cx(s.iconBox, s.hiddenBox)}></div>
          <div className={cx(s.iconTitle, s.hiddenBox)}>2</div>
        </div> */}
      </div>
      {isExpandable && (
        <div
          onClick={() => {
            if (isFullTabBarView) {
              handleFullTabBarView(false)
            }
          }}
          className={`${s.draggerWrapper}`}
        >
          <DraggerIndicator isOnBorder={isOnBorder} style={{ top: '50%', transform: `translate(-50%, -50%)` }} />
        </div>
      )}
    </motion.div>
  )
}

export default React.memo(CatTabs)
