import { SET_TAGS } from './types'
import { setTags, ITagsActionsTypes } from './actions'
import { Tag } from 'src/types'

export type TagsState = Readonly<{
  tags: Tag[]
}>

export const initialState: TagsState = {
  tags: []
}

export default function tagsReducer(state: TagsState = initialState, action: ITagsActionsTypes): TagsState {
  switch (action.type) {
    case SET_TAGS: {
      return {
        ...state,
        tags: action.tags
      }
    }
    default:
      return state
  }
}
