import React, { ReactNode } from 'react'
import cx from 'classnames'
import { ReactComponent as RrowIcon } from 'src/assets/ico-rrow.svg'
import s from '../styles.module.scss'

type PaymentSectionFieldProps = {
  label: ReactNode
  value: string
  placeholder: ReactNode
  hasError: boolean
  handleClick: () => void
  icon: ReactNode
}

const PaymentSectionField: React.FC<PaymentSectionFieldProps> = ({
  icon,
  value,
  placeholder,
  label,
  hasError,
  handleClick
}) => {
  return (
    <div onClick={handleClick} className={s.paymentBlock}>
      <div className={s.pbElem}>
        <div className={s.pbIcon}>{icon}</div>
        <div className={s.pbLabel}>{label}</div>
      </div>
      <div className={s.pbVr} />
      <div className={[s.pbElem, s.pbElem2].join(' ')}>
        <div className={cx(s.pbText, hasError && s.invalidLabel)}>{value || placeholder}</div>
        <div className={s.yellowPb}>
          <RrowIcon className={s.rrowIcon} />
        </div>
      </div>
    </div>
  )
}

export default PaymentSectionField
