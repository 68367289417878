// import './wdyr'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './state/store'
import './index.css'
import { App } from './App'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter as Router } from 'react-router-dom'
import { isDesktop } from 'src/utils'

ReactDOM.render(
  <Provider store={store.store}>
    <PersistGate loading={null} persistor={store.persistor}>
      <Router>
        {isDesktop && window === window.parent && !window.cypress_test ? (
          <iframe src={window.location.href} className={'content-frame'}></iframe>
        ) : (
          <App />
        )}
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/sw.js').then(
      function(registration) {
        // Registration was successful
        console.log('ServiceWorker registration successful with scope: ', registration.scope)
      },
      function(err) {
        // registration failed  :(
        console.log('ServiceWorker registration failed: ', err)
        console.error(
          'Не удалось запустить ServiceWorker. Возможно, вы открыли приложение в режиме предпросмотра внутри другого приложения, или в вашем браузере включен приватный режим?'
        )
      }
    )
  })
}

// Разнообразные хаки. Если что-то глючит по непонятной причине, закомментируйте всё что ниже

// temporary fix for drag not registering after scroll
// document.body.addEventListener(
//   'touchmove',
//   function(event) {
//     event.preventDefault()
//   },
//   false
// )

// Disable scroll restoration for SPA
window.history.scrollRestoration = 'manual'

// Experimental: disable pinch to zoom in Safari
// document.addEventListener('gesturestart', function(e) {
//   e.preventDefault()
// })

// Experimental: disable double tap to zoom in Safari
// @ts-ignore
document.getElementById('root').addEventListener('click', () => {})

// scroll leak fix
// К сожалению, в standalone режиме все классические проблемы с протеканием скролла возвращаются.
// Этот кусок кода фиксит скролл в ричменю, но на дишкарде свой собственный враппер, и проблема с протеканием
// возникает снова.
/* if (!window.busytable) {
  window.busytable = { brandSlug: 'hlbn' }
} */
// @ts-ignore
window.scrollFix = () => {
  // @ts-ignore
  if (window.navigator.standalone && window.navigator.platform && /iPhone/.test(window.navigator.platform)) {
    // @ts-ignore
    const wrapper = document.querySelectorAll('[class^=ui_wrapper]') //document.getElementById('root').firstChild
    if (wrapper.length > 0) {
      // @ts-ignore
      let _clientY = null
      // @ts-ignore
      wrapper[0].addEventListener('touchstart', e => (_clientY = e.targetTouches[0].clientY), false)
      wrapper[0].addEventListener(
        'touchmove',
        e => {
          // @ts-ignore
          let clientY = e.targetTouches[0].clientY - _clientY
          if (wrapper[0].scrollTop === 0 && clientY > 0) e.preventDefault()
          if (isOverscroll() && clientY < 0) e.preventDefault()
        },
        false
      )
      const isOverscroll = () => wrapper[0].scrollHeight - wrapper[0].scrollTop <= wrapper[0].clientHeight
    }
  }
}
