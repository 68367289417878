export const getUser = store => store.user

export const getFirstTime = store => store.user.firstTime

export const getUserId = store => store.user.id
export const getUserData = store => store.user.userData
export const getUserLoading = store => store.user.isUserLoading

export const getFavorites = store => store.user.favorites

export const getVenueId = store => store.user.venueId

export const getTableNum = store => store.user.venueId
export const getCheck = store => store.user.check
export const getUserIp = store => store.user.ip
export const getUserLang = store => store.user.lang

export const getUserConfirmationExpireDate = store => store.user.confirmationExpireDate
