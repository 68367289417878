import { AnyAction } from 'redux'
import { SERVICES_TYPE } from 'src/types'
import { SET_SERVICE_TYPE } from './ShoppingCart.types'

type SetServiceTypeAction = {
  type: typeof SET_SERVICE_TYPE
  serviceType: SERVICES_TYPE | null
}

export const setServiceTypeAction = (serviceType: SERVICES_TYPE | null): ShoppingCartActionType => {
  return {
    serviceType,
    type: SET_SERVICE_TYPE
  }
}

export type ShoppingCartActionType = SetServiceTypeAction | AnyAction
