import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import useCustomPages from '../../hooks/useCustomPages'
import { ReactComponent as CloseIcon } from '../../assets/ico-close-24.svg'
import Background from '../ui/Background'
import { Popup } from '../ui/Popup'
import s from './styles.module.scss'
import { useTranslation } from '../../hooks/useTranslation'

export default function CustomPage() {
  const { slug } = useParams()
  const history = useHistory()
  const page = useCustomPages(slug)
  const { t } = useTranslation()

  return (
    <div>
      <Popup
        fullsize
        header={
          <>
            <CloseIcon onClick={() => history.push('/settings')} className='closeIcon' />
            <div>{page && t(page.label)}</div>
          </>
        }
      >
        <div className={s.wrapper}>{page && <ReactMarkdown escapeHtml={false}>{t(page.content)}</ReactMarkdown>}</div>
        <Background />
      </Popup>
    </div>
  )
}
