import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as MyLocation } from 'src/assets/icons/myLocation.svg'
import mapStyles from 'src/components/VenueSelect/mapStyles'
import { cycleActivityView } from 'src/state/ui/actions'
import GoogleMapReact from 'google-map-react'
import { getLang } from 'src/state/local'
import MarkerContainer from 'src/components/VenueSelect/MarkerContainer'
import s from '../styles.module.scss'

const Map = ({
  selectedVenue,
  setSelectedVenue,
  icoFunction = () => '',
  venues,
  centerLocation,
  zoom,
  userLocation,
  setCenterLocation
}) => {
  const dispatch = useDispatch()
  const lang = useSelector(getLang) || window.busytable.fallbackLang
  const kyivCenterPlaceholder = { lat: 50.449839, lng: 30.523068 }
  const openPositionY = -292

  const markerOnclick = venue => {
    // if (key == -1) return //case of click on user location marker
    setCenterLocation({ lat: Number.parseFloat(venue.lat), lng: Number.parseFloat(venue.lng) })
    dispatch(cycleActivityView({ id: 0, current: openPositionY }))
    setSelectedVenue(venue)
  }

  function createMapOptions(maps) {
    // Gogle Maps API props are exposed at maps
    // https://developers.google.com/maps/documentation/javascript/reference#MapOptions
    return {
      // panControl: false,
      // mapTypeControl: false,
      clickableIcons: false,
      disableDefaultUI: true,
      styles: mapStyles
    }
  }

  return (
    <GoogleMapReact
      // resetBoundsOnResize
      // onGoogleApiLoaded={({ map, maps }) => console.log(map, maps)}
      bootstrapURLKeys={{
        key: process.env.REACT_APP_GOOGLE_KEY,
        language: lang
      }}
      defaultCenter={kyivCenterPlaceholder}
      defaultZoom={14}
      zoom={zoom}
      center={centerLocation}
      options={createMapOptions}
      onChange={() => {
        setCenterLocation(null)
      }}
    >
      {venues
        .filter(x => x && x.lat && x.lng)
        .map(venue => {
          return (
            <MarkerContainer
              selectedVenue={selectedVenue}
              icoFunction={icoFunction}
              // state={state}
              markerOnclick={markerOnclick}
              venue={venue}
              key={venue.id}
              lat={venue.lat}
              lng={venue.lng}
            />
          )
        })}
      {userLocation && <UserLocationComponent s={s} lat={userLocation.lat} lng={userLocation.lng} />}
    </GoogleMapReact>
  )
}

const UserLocationComponent = ({ s }) => {
  return <MyLocation className={s.userPosition} />
}

export default Map
