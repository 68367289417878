import {
  SET_CONFIRMED_PAYMENT,
  SET_FIRSTTIME,
  SET_LANG,
  SET_TOKEN,
  SET_LANGUAGE_PREFERENCES,
  SET_TAKEOUT_READY
} from './types'
const initialState = {
  token: null,
  isFirstTime: true,
  lang: 'uk',
  lastConfirmedPayment: '2020-05-12T14:45:00.000Z',
  lastTakeoutReady: null,
  languages: {}
}

// if (!localStorage.getItem('__userlang')) localStorage.setItem('__userlang', initialState.lang)

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LANGUAGE_PREFERENCES: {
      if (action.activeLanguage) {
        localStorage.setItem('__userlang', action.activeLanguage)
      }
      return {
        ...state,
        lang: action.activeLanguage,
        languages: action.languagesList
      }
    }
    case SET_TOKEN: {
      const { token } = action.payload
      return { ...state, token }
    }

    case SET_FIRSTTIME: {
      const { firstTime } = action.payload
      return { ...state, isFirstTime: firstTime }
    }

    case SET_LANG: {
      const { lang } = action.payload
      localStorage.setItem('__userlang', lang)
      return { ...state, lang: lang }
    }

    case SET_CONFIRMED_PAYMENT: {
      const { item } = action.payload
      return { ...state, lastConfirmedPayment: item }
    }

    case SET_TAKEOUT_READY: {
      return { ...state, lastTakeoutReady: action.takeoutNum }
    }

    default:
      return state
  }
}
