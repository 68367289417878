import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'classnames'

import { ReactComponent as SettingsIcon } from 'src/assets/btn-menu-new.svg'
import { ReactComponent as SearchIcon } from 'src/assets/btn-search-24.svg'
import { ReactComponent as CloseIcon } from 'src/assets/ico-close-24.svg'
import { getVenueId } from 'src/state/location'
import { getWorkingHours, getVenueStatus, VENUE_STATUSES } from 'src/state/location/selectors'
import { getVenueById, getVenues } from 'src/state/venues'
import { postEvent } from 'src/api'
import { useTranslation } from 'src/hooks/useTranslation'
import useFontColorReadability from '../../../hooks/useFontColorReadability'
import useAppPreferences from '../../../hooks/useAppPreferences'
import useThemeStyles from '../../../hooks/useThemeStyles'
import { DecoratedLogo } from './theme-decorating.tsx'
import s from '../styles.module.scss'

export const MenuNavbar = ({
  handleRightClick,
  handleLeftClick = null,
  search = false,
  quitSearch,
  quitSearchHeader,
  handleClose,
  ...props
}) => {
  const { t } = useTranslation()
  const { ref } = props
  const venueId = useSelector(getVenueId)
  const venues = useSelector(getVenues)
  const venue = useSelector(getVenueById)(venueId)
  const [searchText, setSearch] = useState(false)
  const [searchVal, setSearchVal] = useState('')
  const workingHours = useSelector(getWorkingHours)
  const venueStatus = useSelector(getVenueStatus)
  const { customizationSettings, name: brandName, holidayModifications } = useAppPreferences()
  const { navbarTitle } = useThemeStyles()

  const visibleVenues = venues?.filter(v => !v.hidden)

  const isInputReadable = useFontColorReadability('#fff')
  const hasHolidayVariantLogo =
    holidayModifications?.currentVariant &&
    holidayModifications?.variants?.[holidayModifications.currentVariant]?.navbarDecoration

  // console.log(s.menuSearch)
  if (searchText) {
    // postEvent('Search', searchVal)
    return (
      <nav
        className={[s.menuSearch, venueStatus === VENUE_STATUSES.OPEN ? '' : s.menuClosed].join(' ')}
        style={{
          '--fontCaptionColor': !isInputReadable ? '#000' : null
        }}
      >
        <div className={s.menuNavbarInput} style={{ '--fontColor': !isInputReadable ? '#000' : null }}>
          <SearchIcon className={s.searchIconClass} />
          <input
            autoFocus
            placeholder={t('menuNavbar.allDishes')}
            value={searchVal}
            onChange={_ => {
              setSearchVal(_.target.value)

              if (props.setSearchKeyword) {
                props.setSearchKeyword(_.target.value)
              }
            }}
          />
        </div>
        <CloseIcon
          onClick={() => {
            postEvent('rrichmenu-navbar-btn_search_cancel', 'searching')
            setSearch(false)
            if (props.setIsSearch) {
              props.setIsSearch(false)
            }
            setSearchVal('')
            if (props.setSearchKeyword) {
              props.setSearchKeyword('')
            }
          }}
          className={[s.right, s.iconClass].join(' ')}
        />
      </nav>
    )
  }

  const LogoWrapper = ({ children }) =>
    hasHolidayVariantLogo ? (
      <DecoratedLogo
        darkMode={Boolean(holidayModifications.variants[holidayModifications.currentVariant].navbarDecorationDarkMode)}
        variant={holidayModifications?.currentVariant}
      >
        {children}
      </DecoratedLogo>
    ) : (
      <div className={s.logoWrapper}>{children}</div>
    )

  // console.log(window.getComputedStyle(document.querySelector(`.${s.menuNavbar}`)))
  return (
    <nav className={[s.menuNavbar, venueStatus === VENUE_STATUSES.OPEN ? '' : s.menuClosed].join(' ')}>
      {quitSearchHeader}

      {search ? (
        <div ref={ref} onClick={handleLeftClick} data-cy='settings' className={s.left}>
          <SettingsIcon className={s.iconClass} />
        </div>
      ) : (
        ''
      )}
      {!quitSearch && (
        <LogoWrapper>
          <div className={s.title}>
            {navbarTitle === 'brand_sign' && customizationSettings?.logo && (
              <img src={customizationSettings.logo} alt='' />
            )}
            {navbarTitle === 'brand_sign' && !customizationSettings?.logo && brandName}
            {navbarTitle === 'brand_name' && brandName}
          </div>
          {venues.length > 1 && (
            <div
              data-cy='navbarSubtitle'
              className={clsx(
                s.subtitle,
                hasHolidayVariantLogo && s.subtitleHoliday,
                [VENUE_STATUSES.CLOSED, VENUE_STATUSES.DISABLED].includes(venueStatus) && s.subtitleHolidayClosed
              )}
            >
              {!venue && t('tableNumber.notSelectedVenue')}
              {venue && visibleVenues?.length > 1 && t(venue.name)}
              {venueStatus !== VENUE_STATUSES.OPEN && venueStatus !== VENUE_STATUSES.NOT_SELECTED && (
                <span
                  style={{
                    display: hasHolidayVariantLogo ? 'block' : null,
                    color: 'var(--warningColor)',
                    fontWeight: 'bold',
                    marginLeft: '4px'
                  }}
                >
                  {venueStatus === VENUE_STATUSES.CLOSED
                    ? workingHours.join('-')
                    : venueStatus === VENUE_STATUSES.DISABLED
                    ? t('general.offline')
                    : venueStatus === VENUE_STATUSES.ERROR
                    ? t('tableNumber.networkError')
                    : ''}
                </span>
              )}
            </div>
          )}
        </LogoWrapper>
      )}

      <div className={s.rightMarg}>
        <div
          style={{ position: 'absolute', right: '16px' }}
          onClick={() => {
            setSearch(true)
            if (props.setIsSearch) {
              props.setIsSearch(true)
            }
          }}
        >
          {/* <SearchIcon /> */}
          <SearchIcon className={s.iconClass} />
        </div>
        {/* <div
          style={{ position: 'absolute', right: '16px', opacity: '0.25' }}
          onClick={() => {
            handleRightClick()
          }}
          className={s.rightMarg}
        >
          <GiftIcon />
        </div> */}
      </div>
    </nav>
  )
}
