import {
  ADD_DISHES,
  ADD_OPTIONSETS,
  ADD_SELECTED_DISH,
  ADD_SELECTED_OPTIONS,
  RESET_SELECTED_OPTIONS,
  TOGGLE_EDIT,
  REMOVE_SELECTED_DISH
} from './types'
import { IDish, OptionSetType, SelectedOptionType } from '../../types'
import { MenuActionsType } from './actions'

export type MenuState = Readonly<{
  dishes: IDish[]
  edit: boolean
  selectedOptions: SelectedOptionType[]
  selectedDish: IDish | null
  optionsets: OptionSetType[]
}>

export const initialState: MenuState = {
  dishes: [],
  edit: false,
  selectedOptions: [],
  selectedDish: null,
  optionsets: []
}

export default function menuReducer(state: MenuState = initialState, action: MenuActionsType): MenuState {
  switch (action.type) {
    case ADD_DISHES: {
      return {
        ...state,
        dishes: action.dishes
      }
    }

    case ADD_OPTIONSETS: {
      return {
        ...state,
        optionsets: action.optionsets || []
      }
    }

    case ADD_SELECTED_OPTIONS: {
      return {
        ...state,
        selectedOptions: action.selectedOptions
      }
    }

    case RESET_SELECTED_OPTIONS: {
      return {
        ...state,
        selectedOptions: []
      }
    }

    case ADD_SELECTED_DISH: {
      if (!action.selectedDish) {
        return {
          ...state,
          selectedOptions: [],
          selectedDish: null
        }
      }
      return {
        ...state,
        selectedDish: action.selectedDish
      }
    }

    case TOGGLE_EDIT: {
      return {
        ...state,
        edit: action.edit
      }
    }

    case REMOVE_SELECTED_DISH: {
      return {
        ...state,
        selectedDish: null
      }
    }

    default:
      return state
  }
}
