import React, { useMemo } from 'react'
import styles from './styles.module.scss'
import { IDish, Tag } from 'src/types'
import { useTranslation } from 'src/hooks/useTranslation'
import DishTag from '../RichMenu/components/DishTag'
import useFormatWaitingTime from 'src/hooks/useFormatWaitingTime'
import { useSelector } from 'src/state/hooks'
import { getVisibleTagsByIds } from 'src/state/tags/selectors'

interface IDishesTags {
  dish: IDish
  wrapperClassName?: string
  withoutCategoryTag?: boolean
  isDisabledDish: boolean
  disableTagLarge?: boolean
}

const DishesTags: React.FC<IDishesTags> = ({
  dish,
  wrapperClassName,
  isDisabledDish,
  withoutCategoryTag,
  disableTagLarge
}) => {
  const { t } = useTranslation()
  const formattedWaitingTime = useFormatWaitingTime(dish?.waitingTime)
  const dishTags = useSelector(getVisibleTagsByIds)(dish?.tags)

  const dishesTags = useMemo(() => {
    const tags: React.ReactNode[] = []

    if (isDisabledDish) {
      const disabledDishTag: Partial<Tag> = {
        color: 'var(--warningColor)',
        fontColor: '#ffffff'
      }

      return (
        <div className={`${styles.tagsWrapper} ${wrapperClassName || ''}`}>
          <DishTag disableTagLarge={disableTagLarge} tag={disabledDishTag} label={t('waitingTimeBadge.outOfStock')} />
        </div>
      )
    }

    if (dish?.preorderStart) {
      const preorderTag: Partial<Tag> = {
        color: `var(--attentionBgColor)`,
        fontColor: '#ffffff'
      }
      tags.push(<DishTag tag={preorderTag} label={t('menuCard.limited')} />)
    }

    if (dish?.categoryName && !withoutCategoryTag) {
      tags.push(<DishTag tag={{}} label={t(dish.categoryName)} />)
    }

    if (dish?.waitingTime && !isDisabledDish) {
      tags.push(<DishTag tag={{}} label={formattedWaitingTime} />)
    }
    dishTags?.forEach(tag => tags.push(<DishTag label={t(tag.name)} tag={tag} />))
    return tags
  }, [dish, formattedWaitingTime, isDisabledDish, withoutCategoryTag])

  return <div className={`${styles.tagsWrapper} ${wrapperClassName || ''}`}>{dishesTags}</div>
}

export default DishesTags
