import axios, { AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { fetchUserAsync } from 'src/api'
import { config } from 'src/constants'
import { setToken } from 'src/state/local'

/**
 * Util function to return a promise which is resolved in provided milliseconds
 */
function waitFor(millSeconds: number) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(undefined)
    }, millSeconds)
  })
}

export const getToken = async (): Promise<string | undefined> => {
  const transferToken = window.busytable.transferToken
  if (transferToken) {
    const [brandSlug, token] = transferToken.split('_')
    if (brandSlug === window.busytable.brandSlug) {
      return token
    }
  }
  return await fetchTokenFromCache()
}

export const fetchTokenFromCache = async (): Promise<string | undefined> => {
  try {
    const tokenResponse = await fetch('/tokencache')
    const parsedToken = await tokenResponse.json()
    return parsedToken.token
  } catch (e) {
    console.log(`Error while getting token from cache `)
  }
}

export const registerUserThunk = () => async (dispatch: Dispatch<any>) => {
  let token = await getToken()
  if (token) {
    try {
      await setTokenOnApi(token)
    } catch (e) {
      const registerUserResponse = await registerUserRequest()
      token = registerUserResponse.headers.get('authorization') as string
    }
  } else {
    const registerUserResponse = await registerUserRequest()
    token = registerUserResponse.headers.get('authorization') as string
  }
  setTokenToCache(token)
  dispatch(setToken(true))

  dispatch(fetchUserAsync())
}

export const setTokenOnApi = async (token: string) => {
  return await axios.post(
    `${config.API_URL3}/${window.busytable.brandSlug}/auth/token/set`,
    {},
    {
      headers: {
        verified: 'yes',
        'x-url': window.location.href,
        token
      },
      withCredentials: true
    }
  )
}

export const setTokenToCache = async (token: string) => {
  try {
    await fetch('/tokencache', {
      method: 'POST',
      body: JSON.stringify({ token })
    })
    console.log('Token is set to cache')
  } catch (e) {
    console.log(`Error while setting token to cache`)
  }
}

export const registerUserRequest = async (): Promise<Response> => {
  try {
    const timestamp = new Date().getTime()
    const response = await fetch(`${config.API_URL3}/${window.busytable.brandSlug}/auth/register?${timestamp}`, {
      method: 'POST',
      credentials: 'include'
    })
    if (!response) {
      await waitFor(2000)
      return registerUserRequest()
    }
    return response
  } catch (e) {
    console.log('Error while registering user')
    await waitFor(2000)
    return registerUserRequest()
  }
}
