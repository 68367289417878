import React from 'react'
import ReactMarkdown from 'react-markdown'
import { ReactComponent as CloseIcon } from 'src/assets/ico-close-24.svg'
import { useTranslation } from 'src/hooks/useTranslation'
import { uriTransformer } from 'src/utils'
import { Popup } from 'src/components/ui/Popup'
import Background from 'src/components/ui/Background'
import s from './styles.module.scss'

const Job = ({ history }) => {
  const { t } = useTranslation()

  return (
    <Popup
      fullsize
      header={
        <>
          <CloseIcon onClick={() => history.push('/settings')} className={'closeIcon'}></CloseIcon>
          <div>{t('aboutApplication')}</div>
        </>
      }
    >
      <div className={s.wrapper}>
        <ReactMarkdown transformLinkUri={uriTransformer}>{t('aboutText')}</ReactMarkdown>
      </div>

      <Background>
        <div className={s.terms}>
          <a href='/terms_of_use.pdf'>{t('termsOfUse')}</a> <br />
          <a href='/privacy_policy.pdf'>{t('privacyPolicy')}</a>
        </div>
      </Background>
    </Popup>
  )
}

export default Job
