import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'src/state/hooks'
import cx from 'classnames'
import { postEvent } from 'src/api'
import { ReactComponent as CheckBox } from 'src/assets/Checkbox.svg'
import { ReactComponent as HomeIcon } from 'src/assets/ico-home.svg'
import { ReactComponent as LocationIcon } from 'src/assets/ico-location.svg'
import { ReactComponent as RrowIcon } from 'src/assets/ico-rrow.svg'
import { ReactComponent as UserIcon } from 'src/assets/person.svg'
import { ReactComponent as PhoneIcon } from 'src/assets/phone.svg'
import { ReactComponent as DiesIcon } from 'src/assets/icons/ico-dies-16.svg'
import { ReactComponent as PersonIcon } from 'src/assets/icons/icon-person.svg'
import { ReactComponent as PoiIcon } from 'src/assets/icons/ico-poi-24.svg'
import { ReactComponent as UnCheckBox } from 'src/assets/Uncheckbox.svg'
import { useTranslation } from 'src/hooks/useTranslation'
import { getDelivery, setDelivery } from 'src/state/delivery'
import { getVenueId } from 'src/state/location'
import { getComment, getTakeout } from 'src/state/orders/selectors'
import { getTimedTakeout } from 'src/state/timedTakeout'
import { getUserData } from 'src/state/user'
import { getVenueById } from 'src/state/venues'
import strings from 'src/strings'
import { decoratePhone } from 'src/utils/phone'
import { setDrink, toggleAppliance } from 'src/state/status/actions'
import useAppPreferences from 'src/hooks/useAppPreferences'
import ShoppingCartTimePicker from '../ShoppingCartTimePicker'
import { PurchaseType } from 'src/globals/enumerations'
import { setServiceTypeAction } from 'src/state/shoppingCard/ShoppingCart.actions'
import useUnpaidOrders from 'src/hooks/useUnpaidOrders'
import { ActivityViewsContext } from './contexts'
import ShoppingCartDescription from './components/ShoppingCartDescription'
import s from './styles.module.scss'
import SubmitSection from './components/SubmitSection'
import { SERVICES_TYPE } from '../../types'
import RadioOption from '../ui/RadioOption'
import PaymentSectionField from './components/PaymentSectionField'
import RecipientFormInputs from './components/RecipientFormInputs'
import SegmentSwitch from '../ui/formControls/SegmentSwitch'

type RecipientType = 'me' | 'someone' | null
export const PaymentSection: React.FC = () => {
  const { preferences } = useAppPreferences()
  const history = useHistory()
  const comment = useSelector(getComment)
  const { t } = useTranslation()
  const venueId = useSelector(getVenueId)
  const venue = useSelector(getVenueById)(venueId)
  const takeout = useSelector(getTakeout)
  const dispatch = useDispatch()
  const status = useSelector(state => state.status)
  const deliveryData = useSelector(getDelivery)
  const { activeServiceType } = useSelector(store => store.shoppingCard)
  const isDelivery = [SERVICES_TYPE.DELIVERY_TAXI, SERVICES_TYPE.DELIVERY_NOVAPOSHTA].includes(
    activeServiceType || SERVICES_TYPE.TAKEAWAY
  )
  const active = status.drinkToggler
  const [recipientType, setRecipientType] = React.useState<RecipientType>(
    deliveryData?.isRecipientSomeone ? 'someone' : 'me'
  )
  const [checkAgreed, setCheckAgreed] = React.useState<boolean>(false)
  const timedTakeout = useSelector(getTimedTakeout)
  const userData = useSelector(getUserData)
  const [invalidFields, setInvalidFields] = useState<string[]>([])

  const [{ orders, includesDrinks, includesCutleryDishes, cookingTime, preorderInfo }] = useUnpaidOrders()
  const { toggleCommenting, toggleDeliveryForm } = useContext(ActivityViewsContext)

  const takeoutPhoneRequired = activeServiceType === SERVICES_TYPE.TAKEAWAY && preferences.takeawayPhoneRequired

  useEffect(() => {
    if (!includesDrinks) {
      dispatch(setDrink(false))
    }
  }, [includesDrinks, dispatch])

  useEffect(() => {
    if (!isDelivery) {
      setRecipientType('me')
      setCheckAgreed(false)
    }
  }, [isDelivery])

  const handleApplianceClick = () => dispatch(toggleAppliance())

  const renderApplianceCheckbox = () => {
    if (includesCutleryDishes && (deliveryData || takeout) && preferences.withCutlery) {
      return (
        <div
          onClick={handleApplianceClick}
          className={`${s.checkControl} ${status.isApplianceActive ? s.checkControlActive : ''}  ${
            s.applianceCheckbox
          }`}
          style={{ marginTop: '16px' }}
        >
          {status.isApplianceActive ? <CheckBox /> : <UnCheckBox />}
          <div className={s.checkLabel}>{t('shoppingCart.addApplianceCheckbox')}</div>
        </div>
      )
    }
  }

  const handleCheckbox = () => {
    postEvent('cart-form-checkbox_agreed', 'engaging', { value: !checkAgreed })
    if (validateForm()) {
      setCheckAgreed(!checkAgreed)
    } else {
      setCheckAgreed(false)
    }
  }

  const handleCheckRecipientMe = useCallback(() => {
    setRecipientType('me')
    dispatch(setDelivery({ ...deliveryData, isRecipientSomeone: false }))
  }, [deliveryData])

  const handleCheckRecipientSomeone = useCallback(() => {
    setRecipientType('someone')
    dispatch(setDelivery({ ...deliveryData, isRecipientSomeone: true }))
  }, [deliveryData])

  const validateForm = useCallback(() => {
    let invalidFields: string[] = []
    let deliveryDataInvalidFields: string[] = []
    let novaPoshtaInvalidFields: string[] = []
    let userInvalidFields: string[] = []

    if (!deliveryData?.name || deliveryData.name.length < 1) {
      deliveryDataInvalidFields = [...deliveryDataInvalidFields, 'delivery.name']
    }

    if (!deliveryData?.address || deliveryData.address.length < 2) {
      deliveryDataInvalidFields = [...deliveryDataInvalidFields, 'delivery.address']
    }

    if (!deliveryData?.phone || deliveryData.phone.length < 9) {
      deliveryDataInvalidFields = [...deliveryDataInvalidFields, 'delivery.phone']
    }
    if (!deliveryData?.department || deliveryData.department.length < 1) {
      novaPoshtaInvalidFields = [...novaPoshtaInvalidFields, 'delivery.department']
    }

    if (!userData?.name || userData.name.length < 1) {
      userInvalidFields = [...userInvalidFields, 'name']
    }

    if (recipientType === 'me' && (!userData?.address || userData.address.length < 6)) {
      userInvalidFields = [...userInvalidFields, 'address']
    }

    if (!userData?.phone || userData.phone.length < 9) {
      userInvalidFields = [...userInvalidFields, 'phone']
    }

    if (isDelivery) {
      invalidFields = [...invalidFields, ...userInvalidFields]

      if (recipientType !== 'me') {
        invalidFields = [...invalidFields, ...deliveryDataInvalidFields]
      }

      if (activeServiceType === SERVICES_TYPE.DELIVERY_NOVAPOSHTA) {
        invalidFields = [...invalidFields, ...deliveryDataInvalidFields, ...novaPoshtaInvalidFields]
      }
    } else if (userData) {
      invalidFields = [...invalidFields, ...userInvalidFields]

      if (preorderInfo && timedTakeout === false) {
        invalidFields = [...invalidFields, 'time']
      }
    }

    setInvalidFields(invalidFields)

    return invalidFields.length === 0
  }, [userData, preorderInfo, timedTakeout, deliveryData, isDelivery, activeServiceType, recipientType])

  useEffect(() => {
    // валидировать данные формы при изменении данных
    // но не валидировать если данные еще пустые
    if (userData && userData.phone) {
      validateForm()
    }
  }, [userData, validateForm])

  if (orders.length === 0) {
    return null
  }

  const handleChangeDelivery = (newServiceType: SERVICES_TYPE) => {
    dispatch(setServiceTypeAction(newServiceType))
    setRecipientType('me')
    dispatch(setDelivery({ type: newServiceType, name: '', address: '', phone: '', department: '' }))
  }
  return (
    <div className={s.payWrapper}>
      {isDelivery && activeServiceType !== SERVICES_TYPE.DELIVERY_NOVAPOSHTA && (
        <div className={s.recipientRadioGroup}>
          <RadioOption
            label={t('recipient.me')}
            value={'me'}
            checked={recipientType === 'me'}
            name='recipientType'
            onChecked={handleCheckRecipientMe}
          />
          <RadioOption
            label={t('recipient.someone')}
            value={'someone'}
            checked={recipientType === 'someone'}
            name='recipientType'
            onChecked={handleCheckRecipientSomeone}
          />
        </div>
      )}
      {preorderInfo && isDelivery && preferences?.withPostofficeDelivery && (
        <div className={s.paymentBlock} style={{ position: 'relative', width: '100%' }}>
          <select
            className={s.uxissue}
            value={activeServiceType || undefined}
            onChange={e => handleChangeDelivery(e.target.value as SERVICES_TYPE)}
          >
            <option value={SERVICES_TYPE.DELIVERY_TAXI}>{t('deliveryTaxi')}</option>
            <option value={SERVICES_TYPE.DELIVERY_NOVAPOSHTA}>{t('deliveryNovaposhta')}</option>
          </select>
          <div className={s.pbElem}>
            <div className={s.pbIcon}>
              <LocationIcon />
            </div>
            <div className={s.pbLabel}>{t('tipdostavki')}</div>
          </div>
          <div className={s.pbVr} />
          <div className={[s.pbElem, s.pbElem2].join(' ')}>
            <div className={s.pbText}>
              <div className={s.timedInput}>
                {activeServiceType === SERVICES_TYPE.DELIVERY_TAXI ? t('deliveryTaxi') : t('deliveryNovaposhta')}
              </div>
            </div>
            <div className={s.yellowPb}>
              <RrowIcon className={s.rrowIcon} />
            </div>
          </div>
        </div>
      )}
      {(isDelivery && activeServiceType === SERVICES_TYPE.DELIVERY_NOVAPOSHTA) || (
        <div
          onClick={_ => {
            history.push('/venues')
            postEvent('cart-form-venue', 'engaging')
          }}
          className={s.paymentBlock}
        >
          <div className={s.pbElem}>
            <div className={s.pbIcon}>
              <LocationIcon />
            </div>
            <div className={s.pbLabel}>
              {isDelivery ? t('paymentSection.addressTo') : t('paymentSection.addressFrom')}
            </div>
          </div>
          <div className={s.pbVr} />
          <div className={[s.pbElem, s.pbElem2].join(' ')}>
            <div className={s.pbText}>{!venue ? strings.defaultVenue : t(venue.name)}</div>
            <div className={s.yellowPb}>
              <RrowIcon className={s.rrowIcon} />
            </div>
          </div>
        </div>
      )}

      {!(isDelivery && activeServiceType === SERVICES_TYPE.DELIVERY_NOVAPOSHTA) &&
        (takeout && (isDelivery || preorderInfo) ? (
          <ShoppingCartTimePicker
            type={isDelivery ? PurchaseType.DELIVERY : PurchaseType.TAKEAWAY}
            cookingTime={cookingTime}
            preorderInfo={preorderInfo}
          />
        ) : (
          (takeout || isDelivery) && (
            <ShoppingCartTimePicker
              type={isDelivery ? PurchaseType.DELIVERY : PurchaseType.TAKEAWAY}
              cookingTime={cookingTime}
              preorderInfo={preorderInfo}
            />
          )
        ))}

      {takeoutPhoneRequired && (
        <PaymentSectionField
          icon={<PhoneIcon width={16} height={16} />}
          label={t('paymentSection.phoneLabel')}
          hasError={invalidFields.includes('phone')}
          value={userData?.phone}
          placeholder={<span>{t('paymentSection.phonePlaceholder')}</span>}
          handleClick={() => {
            history.push('/profile')
            postEvent('cart-form-phone', 'engaging')
          }}
        />
      )}

      {recipientType === 'me' && (
        <>
          {isDelivery && !(activeServiceType === SERVICES_TYPE.DELIVERY_NOVAPOSHTA) && (
            <PaymentSectionField
              icon={<HomeIcon width={16} height={16} />}
              label={t('general.adress')}
              hasError={invalidFields.includes('address')}
              value={userData?.address}
              placeholder={<span>{t('paymentSection.addressPlaceholder')}</span>}
              handleClick={() => {
                history.push('/profile')
                postEvent('cart-form-address', 'engaging')
              }}
            />
          )}
          {(isDelivery || (preorderInfo && takeout)) &&
            !(isDelivery && activeServiceType === SERVICES_TYPE.DELIVERY_NOVAPOSHTA) && (
              <PaymentSectionField
                icon={<UserIcon width={16} height={16} />}
                label={t('paymentSection.nameLabel')}
                hasError={isDelivery && invalidFields.includes('name')}
                value={userData?.name}
                placeholder={<span>{t('paymentSection.namePlaceholder')}</span>}
                handleClick={() => {
                  history.push('/profile')
                  postEvent('cart-form-name', 'engaging')
                }}
              />
            )}
          {(isDelivery || (preorderInfo && takeout)) &&
            !(isDelivery && activeServiceType === SERVICES_TYPE.DELIVERY_NOVAPOSHTA) && (
              <PaymentSectionField
                icon={<PhoneIcon width={16} height={16} />}
                label={t('paymentSection.phoneLabel')}
                hasError={isDelivery && invalidFields.includes('phone')}
                value={decoratePhone(userData?.phone)}
                placeholder={<span>{t('paymentSection.phonePlaceholder')}</span>}
                handleClick={() => {
                  history.push('/profile')
                  postEvent('cart-form-phone', 'engaging')
                }}
              />
            )}
        </>
      )}
      {recipientType === 'someone' && <RecipientFormInputs invalidFields={invalidFields} />}
      {/* NOVA POSHTA */}
      {isDelivery && activeServiceType === SERVICES_TYPE.DELIVERY_NOVAPOSHTA && (
        <div
          onClick={() => {
            toggleDeliveryForm('novaposhta')
            postEvent('cart-form-name', 'engaging')
          }}
          className={s.paymentBlock}
        >
          <div className={s.pbElem}>
            <div className={s.pbIcon}>
              <PersonIcon width={16} height={16} />
            </div>
            <div className={s.pbLabel}>{t('dostavka.nameLabel')}</div>
          </div>
          <div className={s.pbVr} />
          <div className={[s.pbElem, s.pbElem2].join(' ')}>
            <div className={cx(s.pbText, deliveryData && invalidFields.includes('delivery.name') && s.invalidLabel)}>
              {!deliveryData?.name ? <span>{t('dostavka.namePlaceholder')}</span> : deliveryData.name}
            </div>
            <div className={s.yellowPb}>
              <RrowIcon className={s.rrowIcon} />
            </div>
          </div>
        </div>
      )}
      {isDelivery && activeServiceType === SERVICES_TYPE.DELIVERY_NOVAPOSHTA && (
        <div
          onClick={() => {
            toggleDeliveryForm('novaposhta')
            postEvent('cart-form-address', 'engaging')
          }}
          className={s.paymentBlock}
        >
          <div className={s.pbElem}>
            <div className={s.pbIcon}>
              <PoiIcon width={16} height={16} />
            </div>
            <div className={s.pbLabel}>{t('dostavka.adress')}</div>
          </div>
          <div className={s.pbVr} />
          <div className={[s.pbElem, s.pbElem2].join(' ')}>
            <div className={cx(s.pbText, invalidFields.includes('delivery.address') && s.invalidLabel)}>
              {!deliveryData?.address ? <span>{t('dostavka.addressPlaceholder')}</span> : deliveryData.address}
            </div>
            <div className={s.yellowPb}>
              <RrowIcon className={s.rrowIcon} />
            </div>
          </div>
        </div>
      )}
      {isDelivery && activeServiceType === SERVICES_TYPE.DELIVERY_NOVAPOSHTA && (
        <div
          onClick={() => {
            toggleDeliveryForm('novaposhta')
            postEvent('cart-form-address', 'engaging')
          }}
          className={s.paymentBlock}
        >
          <div className={s.pbElem}>
            <div className={s.pbIcon}>
              <DiesIcon width={16} height={16} />
            </div>
            <div className={s.pbLabel}>{t('dostavka.department')}</div>
          </div>
          <div className={s.pbVr} />
          <div className={[s.pbElem, s.pbElem2].join(' ')}>
            <div className={cx(s.pbText, invalidFields.includes('delivery.department') && s.invalidLabel)}>
              {!deliveryData?.department ? <span>{t('dostavka.departmentPlaceholder')}</span> : deliveryData.department}
            </div>
            <div className={s.yellowPb}>
              <RrowIcon className={s.rrowIcon} />
            </div>
          </div>
        </div>
      )}
      {isDelivery && activeServiceType === SERVICES_TYPE.DELIVERY_NOVAPOSHTA && (
        <div
          onClick={() => {
            toggleDeliveryForm('novaposhta')
            postEvent('cart-form-phone', 'engaging')
          }}
          className={s.paymentBlock}
        >
          <div className={s.pbElem}>
            <div className={s.pbIcon}>
              <PhoneIcon width={16} height={16} />
            </div>
            <div className={s.pbLabel}>{t('dostavka.phoneLabel')}</div>
          </div>
          <div className={s.pbVr} />
          <div className={[s.pbElem, s.pbElem2].join(' ')}>
            <div className={cx(s.pbText, deliveryData && invalidFields.includes('delivery.phone') && s.invalidLabel)}>
              {!deliveryData?.phone ? <span>{t('dostavka.phonePlaceholder')}</span> : decoratePhone(deliveryData.phone)}
            </div>
            <div className={s.yellowPb}>
              <RrowIcon className={s.rrowIcon} />
            </div>
          </div>
        </div>
      )}

      {takeout && !isDelivery && includesDrinks && (
        <div className={s.togglerContainer}>
          <div className={s.togglerHeader}>{t('paymentSection.gotovitNapitki')}</div>
          <div className={s.togglerControl}>
            <SegmentSwitch
              options={[
                {
                  value: true,
                  label: t('paymentSection.gotovitNapitkiDa')
                },
                {
                  value: false,
                  label: t('paymentSection.gotovitNapitkiNet')
                }
              ]}
              value={active}
              onChange={value => dispatch(setDrink(value))}
            />
          </div>
        </div>
      )}

      {renderApplianceCheckbox()}

      <ShoppingCartDescription />

      <div
        onClick={_ => {
          toggleCommenting(true)
          postEvent('cart-form-comment', 'involvement')
        }}
        data-cy='sCardCommentArea'
        className={cx(s.paymentBlock, s.pbTextarea)}
      >
        <textarea placeholder={t('paymentSection.commentPlaceholder')} value={comment} readOnly />
      </div>
      {isDelivery && (
        <div
          onClick={handleCheckbox}
          className={`${s.checkControl} ${checkAgreed ? s.checkControlActive : ''}`}
          style={{ marginTop: '16px' }}
        >
          {checkAgreed ? <CheckBox /> : <UnCheckBox />}
          <div className={s.checkLabel}>{t('paymentSection.agreed')}</div>
        </div>
      )}

      <SubmitSection agreed={checkAgreed && invalidFields.length === 0} />
    </div>
  )
}
