import { createSlice, createSelector } from '@reduxjs/toolkit'
import axios from 'axios'
import { config } from '../constants'
// import languages from '../languages.json'
// import translations from '../translations.json'
import { getVenueId } from './location'
import { getVenueById } from 'src/state/venues'

const initialState = {
  languages: window.busytable.languages,
  main: {},
  byVenue: {}
}

const slice = createSlice({
  name: 'texts',
  initialState,
  reducers: {
    setTexts(state, action) {
      const { brand, venues } = action.payload

      if (typeof venues === 'object') {
        state.byVenue = venues
      }

      if (brand && typeof brand === 'object') {
        Object.entries(brand).forEach(([key, val]) => {
          state.main[key] = { ...state.main[key], ...val }
        })
      }
    }
  }
})

export const texts = slice.reducer

/**
 * @todo: draft, rewrite to something useful
 */
const errorMsg = (error, status = 404) => {
  console.log(`Status: ${status}, Error: ${error.title}`)
  return null
}

/**
 * fetch data and process errors
 * in case of error return null
 * @todo Move to shared
 */
const fetchData = async url => {
  try {
    const response = await axios.get(url)
    if (!response.data.data) return errorMsg({ title: 'Wrong response format, no "data" key in object' }, 422)
    return response.data.data
  } catch (error) {
    const status = error?.response?.status
    const errors = error?.response?.data?.errors

    if (errors) {
      errors.forEach(e => {
        errorMsg(e, status)
      })
    }
    return null
  }
}

/**
 * обновляет только тексты которые изменились
 * если какие то языки отсутствуют, то они остаются неизменными
 */
export const fetchDynamicTexts = async (dispatch, fromChannel) => {
  const data =
    (!fromChannel && window.busytable && window.busytable.translations) ||
    (await fetchData(`${config.API_URL3}/${window.busytable.brandSlug}/translations`))

  if (data) {
    localStorage.setItem('lastchange_brand', Date.now())
    dispatch(slice.actions.setTexts(data))
  }
}

export const getDynamicTexts = async (dispatch, data) => {
  dispatch(slice.actions.setTexts(data))
}

const getVenueIdFromStore = store => {
  const venueId = getVenueId(store)
  const currentVenue = getVenueById(store)(parseInt(venueId))

  return currentVenue?.id || ''
}

const mergeVenueTexts = ({ main, byVenue }, venueId) => {
  let result = { ...main }
  const venueIdInt = Number(venueId)

  if (byVenue && typeof byVenue === 'object' && byVenue[venueIdInt]) {
    Object.entries(byVenue[venueIdInt]).forEach(([key, val]) => {
      result[key] = { ...result[key], ...val }
    })
  }

  return result
}

export const selectTextsSlice = state => state.texts

export const selectTexts = createSelector(selectTextsSlice, getVenueIdFromStore, mergeVenueTexts)

export const selectLanguages = state => {
  return state.local.languages
}
