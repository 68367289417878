import React from 'react'

export const Button = ({
  onClick = () => {},
  disabled = false,
  warn = false,
  className = '',
  style = {},
  children
}) => (
  <button
    style={style}
    data-cy='sCardButtonPay'
    className={['sbutton', !disabled ? (warn ? 'warn' : 'pay') : 'unpay', className].join(' ')}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
)
