import { useCallback, useEffect, useState } from 'react'
import tinycolor from 'tinycolor2'
import { useSelector } from 'react-redux'

import { getActiveThemeSelector } from 'src/state/themes/selectors'
import { THEME_MODE } from 'src/globals/enumerations'

const useThemeStyles = () => {
  const activeTheme = useSelector(getActiveThemeSelector)?.result[0]
  const { demoVariables, demoTheme } = useSelector(state => state.demo)
  const variables = activeTheme?.variables
  const navbarVariables = activeTheme?.navbarVariables
  const [customStyle, setCustomStyle] = useState({
    style: {},
    variables,
    icons: {},
    navbarVariables: {},
    navbarTitle: '',
    dishCardBranding: null,
    mode: null
  })

  const getForegroundColor = useCallback(
    color =>
      tinycolor(color).isDark() || activeTheme?.mode === THEME_MODE.DARK
        ? tinycolor(variables?.fontColor)
            .lighten(98)
            .toString()
        : variables?.fontColor,
    [variables, activeTheme]
  )

  useEffect(() => {
    const icons = {
      favoriteIcon: demoTheme?.favoriteIcon || activeTheme?.favoriteIcon,
      popularIcon: demoTheme?.popularIcon || activeTheme?.popularIcon
    }

    const style = {
      '--accentColor': demoVariables?.accentColor || variables?.accentColor,
      '--accentColorDarken': tinycolor(demoVariables?.accentColor || variables?.accentColor).darken(10),
      '--mainBg': demoVariables?.mainBg || variables?.mainBg,
      '--viewBg': demoVariables?.viewBg || variables?.viewBg,
      '--fontColor': demoVariables?.fontColor || variables?.fontColor,
      '--moneyColor': demoVariables?.moneyColor || variables?.moneyColor || variables?.accentColor,
      '--loaderBackground': tinycolor(demoVariables?.viewBg || variables?.viewBg)
        .setAlpha(0.75)
        .toString(),
      '--attentionBgColor':
        demoVariables?.attentionBgColor ||
        variables?.attentionBgColor ||
        demoVariables?.accentColor ||
        variables?.accentColor,
      '--shoppingCartBgColor':
        demoVariables?.shoppingCartBgColor || variables?.shoppingCartBgColor || variables?.accentColor,
      '--fontInvertedColor':
        activeTheme?.mode === THEME_MODE.LIGHT
          ? demoVariables?.fontColor || variables?.fontColor
          : tinycolor(demoVariables?.fontColor || variables?.fontColor)
              .lighten(98)
              .toString(),
      '--borderRadius': activeTheme?.variables?.borderRadius,
      '--warningColor': demoVariables?.warningColor || variables?.warningColor,
      '--successColor': demoVariables?.successColor || variables?.successColor,
      '--fontCaptionColor': tinycolor(demoVariables?.fontColor || variables?.fontColor)
        .setAlpha(0.5)
        .toString(),
      '--brandingBackgroundImage': `url(${variables?.brandingBackgroundImage})`,
      '--viewBgLighten': tinycolor(demoVariables?.viewBg || variables?.viewBg)
        .setAlpha(0.8)
        .toString(),
      '--viewBgExtraLight': tinycolor(demoVariables?.viewBg || variables?.viewBg)
        .setAlpha(0.25)
        .toString(),
      '--shoppingCartColor': getForegroundColor(demoVariables?.accentColor || variables?.accentColor),
      '--shoppingCartEmptyColor': getForegroundColor(demoVariables?.fontColor || variables?.fontColor),
      '--shoppingCartEmptyBg': `url(${demoVariables?.shoppingCartEmptyBg ||
        variables?.shoppingCartEmptyBg ||
        ''})`.replace('url()', ''),
      '--brandPattern': `url(${demoVariables?.brandPattern || variables?.brandPattern})`,
      '--richMenuMarkerBg':
        demoTheme?.navbarVariables?.iconMarkerBg ||
        navbarVariables?.iconMarkerBg ||
        demoVariables?.accentColor ||
        variables?.accentColor,
      '--richMenuMarkerShadow':
        demoTheme?.navbarVariables?.iconMarkerShadow || navbarVariables?.iconMarkerShadow || 'none'
    }

    setCustomStyle({
      variables,
      style,
      icons,
      navbarVariables: demoTheme?.navbarVariables || activeTheme?.navbarVariables,
      navbarTitle: demoTheme?.navbarTitle || activeTheme?.navbarTitle,
      dishCardBranding: demoTheme?.dishCardBranding || activeTheme?.dishCardBranding,
      mode: activeTheme?.mode
    })
  }, [activeTheme, variables, demoVariables, demoTheme, getForegroundColor, navbarVariables])

  return customStyle
}

export default useThemeStyles
