import React from 'react'
import useAppPreferences from 'src/hooks/useAppPreferences'
import { ShoppingCart } from './ShoppingCart'

export interface INewShoppingCartContainerProps {}

const NewShoppingCartContainer: React.FC<INewShoppingCartContainerProps> = () => {
  const { preferences } = useAppPreferences()

  if (preferences.withQrMenuMode && preferences.disableShoppingCart) {
    return <React.Fragment />
  }

  return <ShoppingCart />
}

export default NewShoppingCartContainer
