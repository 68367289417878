import { SET_DEMO_THEME, SET_DEMO_VARIABLES, SET_DEMO_ICONS, SET_DEMO_THEME_SETTING } from './types'
import { ITheme, ThemeIcons, ThemeVariables } from '../../types'

type SetDemoThemeAction = {
  type: typeof SET_DEMO_THEME
  theme: ITheme
}
export const setDemoTheme = (theme: ITheme): DemoThemeActionsType => ({
  type: SET_DEMO_THEME,
  theme
})

type SetDemoVariablesAction = {
  type: typeof SET_DEMO_VARIABLES
  variables: ThemeVariables
}
export const setDemoVariables = (variables: ThemeVariables): DemoThemeActionsType => ({
  type: SET_DEMO_VARIABLES,
  variables
})

type SetDemoIconsAction = {
  type: typeof SET_DEMO_ICONS
  icons: ThemeIcons
}
export const setDemoIcons = (icons: ThemeIcons): DemoThemeActionsType => ({
  type: SET_DEMO_ICONS,
  icons
})

type SetDemoThemeSettingAction = {
  type: typeof SET_DEMO_THEME_SETTING
  setting: Partial<ITheme>
}
export const setDemoThemeSetting = (setting: Partial<ITheme>): DemoThemeActionsType => ({
  type: SET_DEMO_THEME_SETTING,
  setting
})

export type DemoThemeActionsType = SetDemoThemeAction | SetDemoVariablesAction | SetDemoIconsAction | SetDemoThemeSettingAction