import React from 'react'

/**
 * Logic and data
 */
import { useTranslation } from 'src/hooks/useTranslation'

import { useHistory, useParams, Link } from 'react-router-dom'

import { confirmEmployeeRegistration } from 'src/network/sendingData/confirmEmployee'

import useBannerConfirmNonByer from './useBannerConfirmNonByer'

/**
 * Components and styling
 */
import { Popup } from '../ui/Popup'

import s from './styles.module.scss'

export default function BannerConfirmNonByer() {
  /**
   * Hooks
   */
  const { t } = useTranslation()

  const history = useHistory()

  const { userId } = useParams()

  const canPerformConfirmation = useBannerConfirmNonByer()

  /**
   * Event handlers
   */
  async function handlePressYes() {
    try {
      await confirmEmployeeRegistration(userId)
    } catch (e) {
      alert(`Error while confirming the employee: ${e.message}`)
    }

    history.push('/')
  }

  if (canPerformConfirmation) {
    return (
      <Popup>
        <div className={s.header}>{t('bannerAuth.employeeConfirmation')}</div>
        <div className={s.containerButtons}>
          <Link className={s.button} to='/'>
            {t('general.no')}
          </Link>
          <a className={s.button} onClick={handlePressYes}>
            {t('general.yes')}
          </a>
        </div>
      </Popup>
    )
  } else {
    return (
      <Popup>
        <div className={s.header}>{t('bannerConfirmNonByer.notLoggedInError')}</div>
      </Popup>
    )
  }
}
