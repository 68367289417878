import React, { useContext } from 'react'
import { postEvent } from 'src/api'
import { useTranslation } from 'src/hooks/useTranslation'
import { ActivityViewsContext } from '../contexts'
import s from '../styles.module.scss'
import { TakeoutNumber } from '../types'

type ReadyTakeoutButtonProps = {
  takeoutNumber: TakeoutNumber
}

const ReadyTakeoutButton: React.FC<ReadyTakeoutButtonProps> = ({ takeoutNumber }) => {
  const { t } = useTranslation()
  const { toggleTakeoutNotifier, alreadyNotified } = useContext(ActivityViewsContext)
  const isNotified = alreadyNotified.includes(takeoutNumber)

  return (
    <>
      <div
        style={{ margin: '8px 0px' }}
        className={s.disabledPay}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <div
          style={{ margin: '8px 0px' }}
          className={[isNotified ? s.disUnpay : s.iamhere].join(' ')}
          onClick={e => {
            postEvent('cart-btn_imready', 'involvement', { value: true })
            e.stopPropagation()
            if (!isNotified) {
              toggleTakeoutNotifier(takeoutNumber)
            }
          }}
        >
          {isNotified ? t('orderSection.notificationSent') : t('orderSection.notificationInfo')}
        </div>
      </div>
    </>
  )
}

export default ReadyTakeoutButton
