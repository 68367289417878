import { useState, useCallback, useMemo } from 'react'
import { createReview, updateReviewById } from '../network/sendingData/reviews'

export default function useFeedbackApi({ rate, paymentId, anonymous, venue }) {
  const [reviewData, setReviewData] = useState({
    ...(paymentId ? { paymentId } : {}),
    ...{ anonymous: anonymous || false }
  })
  const [reviewLoading, setReviewLoading] = useState(false)
  const [reviewError, setReviewError] = useState(null)
  const [activeRate, setActiveRate] = useState(rate || 0)
  const [isAnonymous, setIsAnonymous] = useState(anonymous || false)

  const create = useCallback(
    async data => {
      try {
        if (data.message) {
          setReviewLoading(true)
        }
        const resp = await createReview({
          ...reviewData,
          ...(venue ? { venueSlug: venue } : {}),
          rate: activeRate,
          anonymous: isAnonymous,
          ...data
        })
        if (resp) {
          setReviewData(resp)
        }
      } catch (err) {
        console.log(err)
        setReviewError(err.message)
      } finally {
        if (data.message) {
          setReviewLoading(false)
        }
      }
    },
    [reviewData, venue, isAnonymous, activeRate, setReviewData, setReviewError, setReviewLoading]
  )

  const update = useCallback(
    async data => {
      try {
        if (data.message) {
          setReviewLoading(true)
        }
        const resp = await updateReviewById(reviewData.id, {
          rate: activeRate,
          anonymous: isAnonymous,
          ...data
        })
        if (resp) {
          setReviewData(resp)
        }
      } catch (err) {
        setReviewError(err.message)
      } finally {
        if (data.message) {
          setReviewLoading(false)
        }
      }
    },
    [reviewData, activeRate, isAnonymous, setReviewData, setReviewError, setReviewLoading]
  )

  const api = useMemo(
    () => ({
      create,
      update,
      setRate: setActiveRate,
      setAnonymous: setIsAnonymous
    }),
    [create, update, setActiveRate]
  )

  const state = useMemo(
    () => ({
      loading: reviewLoading,
      data: reviewData,
      error: reviewError,
      rate: activeRate,
      anonymous: isAnonymous
    }),
    [reviewLoading, reviewData, reviewError, activeRate, isAnonymous]
  )

  return [state, api]
}
