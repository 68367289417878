import {
  FETCH_PAID_ORDERS,
  ADD_NEW_ORDER,
  PROCESS_ORDER_FROM_WEBSOCKET,
  SET_PAYMENT_TYPE,
  SET_TAKEOUT,
  UPDATE_ORDER,
  ADD_COMMENT,
  REMOVE_UNPAID,
  REMOVE_NEW_DISH,
  REMOVE_ONE_ORDER,
  REMOVE_COMMENT,
  ADD_MANY_ORDERS,
  CLEAR_NEW_ORDERS,
  SET_NEW_ORDERS
} from './types'

import { IOrder, NewOrderInput, PaymentMethod, SelectedOptionType, UnpaidDetails } from '../../types'

type SetPaidOrdersAction = {
  type: typeof FETCH_PAID_ORDERS
  orders: IOrder[]
}

export const setPaidOrders = (orders: IOrder[]): OrdersActionType => ({
  type: FETCH_PAID_ORDERS,
  orders
})

type AddNewOrderAction = {
  type: typeof ADD_NEW_ORDER
  newOrder: NewOrderInput
}

export const addNewOrder = (newOrder: NewOrderInput): OrdersActionType => ({
  type: ADD_NEW_ORDER,
  newOrder
})

type UpdateOrderAction = {
  type: typeof UPDATE_ORDER
  updatedOrder: Partial<IOrder>
}

export const updateOrder = (updatedOrder: Partial<IOrder>): OrdersActionType => ({
  type: UPDATE_ORDER,
  updatedOrder
})

type RemoveNewDishPayload = {
  dishId: number | undefined
  options: SelectedOptionType[]
}

type RemoveNewDishAction = {
  type: typeof REMOVE_NEW_DISH
  payload: RemoveNewDishPayload
}

export const removeNewDish = (dishId: number | undefined, options: SelectedOptionType[]): OrdersActionType => ({
  type: REMOVE_NEW_DISH,
  payload: { dishId, options }
})

type RemoveOneOrderPayload = {
  dishId: number | undefined
  options: SelectedOptionType[]
}

type RemoveOneOrderAction = {
  type: typeof REMOVE_ONE_ORDER
  payload: RemoveOneOrderPayload
}

export const removeOneOrder = (dishId: number | undefined, options: SelectedOptionType[]): OrdersActionType => ({
  type: REMOVE_ONE_ORDER,
  payload: { dishId, options }
})

type RemoveUnpaidAction = {
  type: typeof REMOVE_UNPAID
  details: UnpaidDetails
}

export const removeUnpaid = (details: UnpaidDetails): OrdersActionType => ({
  type: REMOVE_UNPAID,
  details
})

type ProcessWebsocketOrder = {
  type: typeof PROCESS_ORDER_FROM_WEBSOCKET
  orderMessage: string
}

export const processWebsocketOrder = (orderMessage: string): OrdersActionType => ({
  type: PROCESS_ORDER_FROM_WEBSOCKET,
  orderMessage
})

type SetPaymentMethod = {
  type: typeof SET_PAYMENT_TYPE
  method: PaymentMethod
}
export const setPaymentType = (method: PaymentMethod): OrdersActionType => ({
  type: SET_PAYMENT_TYPE,
  method
})

type SetTakeoutAction = {
  type: typeof SET_TAKEOUT
  flag: boolean
}

export const setTakeout = (flag: boolean): OrdersActionType => ({
  type: SET_TAKEOUT,
  flag
})

type AddCommentAction = {
  type: typeof ADD_COMMENT
  comment: string
}

export const addComment = (comment: string): OrdersActionType => ({
  type: ADD_COMMENT,
  comment
})

type RemoveCommentAction = {
  type: typeof REMOVE_COMMENT
}

export const removeComment = (): OrdersActionType => ({
  type: REMOVE_COMMENT
})

type AddManyOrdersAction = {
  type: typeof ADD_MANY_ORDERS
  orders: NewOrderInput[]
}

export const addManyOrders = (orders: IOrder[]): OrdersActionType => ({
  type: ADD_MANY_ORDERS,
  orders
})

type ClearNewOrdersAction = {
  type: typeof CLEAR_NEW_ORDERS
}

export const clearNewOrders = (): OrdersActionType => ({
  type: CLEAR_NEW_ORDERS
})

type SetNewOrders = {
  type: typeof SET_NEW_ORDERS
  orders: NewOrderInput[]
}

export const setNewOrders = (orders: NewOrderInput[]): OrdersActionType => ({
  type: SET_NEW_ORDERS,
  orders
})

export type OrdersActionType =
  | ClearNewOrdersAction
  | AddManyOrdersAction
  | RemoveCommentAction
  | AddCommentAction
  | SetTakeoutAction
  | SetPaymentMethod
  | ProcessWebsocketOrder
  | RemoveUnpaidAction
  | RemoveOneOrderAction
  | RemoveNewDishAction
  | UpdateOrderAction
  | AddNewOrderAction
  | SetPaidOrdersAction
  | SetNewOrders
