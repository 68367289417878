import React from 'react'
import s from '../DishCard/styles.module.scss'
import { useTranslation } from '../../hooks/useTranslation'
import { RadioGroupData } from './RadioSet'
import RadioOption from './RadioOption'

type RadioGroupProps = {
  handleChange: (value: number) => void
  text: RadioGroupData
  radio: number
  disabled: boolean | undefined
  name: string
}

const RadioGroup: React.FC<RadioGroupProps> = ({ handleChange, name, radio, text, disabled }) => {
  const { t } = useTranslation()

  return (
    <div className={s.form_group}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div className={[s.option__title].join(' ')}>{t(text.title)}</div>
      </div>
      {text.options?.map(option => (
        <RadioOption
          key={option.id}
          value={radio}
          disabled={disabled}
          option={option}
          name={name}
          onChecked={handleChange}
        />
      ))}
    </div>
  )
}

export default RadioGroup
