import React from 'react'
import { Button } from 'src/components/ui/Button'

type DisabledButtonProps = {
  label: string
}

const DisabledButton: React.FC<DisabledButtonProps> = function ({ label }) {
  return (
    <Button style={{ margin: '24px auto' }} disabled={true}>
      <span>{label}</span>
    </Button>
  )
}

export default DisabledButton
