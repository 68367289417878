import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import { postEvent } from 'src/api'
import { ReactComponent as HomeIcon } from 'src/assets/ico-home.svg'
import { ReactComponent as PhoneIcon } from 'src/assets/phone.svg'
import { ReactComponent as UserIcon } from 'src/assets/person.svg'
import { config } from 'src/constants'
import { useTranslation } from 'src/hooks/useTranslation'
import { getDelivery, setDelivery } from 'src/state/delivery'
import { addUserData } from 'src/state/user/actions'
import { getUserData, getUserLoading } from 'src/state/user/selectors'
import { decoratePhone } from 'src/utils/phone'
import { MenuBar } from 'src/components/ui/MenuBar'
import { Popup } from 'src/components/ui/Popup.tsx'
import { SERVICES_TYPE } from 'src/globals/enumerations'
import d from '../CodeScan/styles.module.scss'
import s from './styles.module.scss'
import MergeProfileView from './MergeProfileView'

const Profile = ({ history }) => {
  const dispatch = useDispatch()
  const user = useSelector(getUserData)
  const isUserLoading = useSelector(getUserLoading)
  const { t } = useTranslation()
  const [isEmptyProfile, setIsEmpty] = useState(null)
  const [dovalidate, setdovalidate] = useState(false)
  const [saveProfile, setSaveProfile] = useState(false)
  const [showPopup, setPopup] = useState(false)
  const [isPassVal, setIsPassVal] = useState(false)
  const [firstname, setFirstname] = useState(user.name || '')
  const [email, setEmail] = useState(user.email ? user.email : '')
  const [address, setAdress] = useState(user.address ? user.address : '')
  const [phone, setPhone] = useState(user.phone ? user.phone : '')
  const delivery = useSelector(getDelivery)
  const isNovaPoshta = delivery && delivery.type === SERVICES_TYPE.DELIVERY_NOVAPOSHTA

  const [focus, setFocus] = useState('')

  useEffect(() => {
    if (user) {
      setFirstname(user.name || '')
      setEmail(user.email ? user.email : '')
      setAdress(user.address ? user.address : '')
      setPhone(user.phone ? user.phone : '')
      if (user.name || user.email || user.phone) {
        setIsEmpty(false)
      } else if (!isUserLoading) {
        setIsEmpty(true)
      }
    }
  }, [user, isUserLoading])

  const [ref, inView] = useInView({ threshold: 1 })

  async function handler() {
    try {
      const newUser = await axios
        .patch(
          `${config.API_URL3}/${window.busytable.brandSlug}/user`,
          {
            firstname: firstname.split(' ')[0],
            lastname: firstname
              .split(' ')
              .slice(1)
              .join(' '),
            phone: tryToSimplifyPhone(phone),
            address
          },
          {
            withCredentials: true
          }
        )
        .catch(e => {
          if (e.response && e.response.status) {
            throw new Error(e.response.status)
          } else {
            return null
          }
        })
      if (newUser !== null) {
        dispatch(addUserData(newUser.data))
      }
      if (newUser.data?.profilesCount < 2) {
        history.push('/')
      } else {
        setSaveProfile(false)
      }
    } catch (err) {}
  }

  const isPhoneValid = validatePhone(phone)

  let validateFields = false
  let anothervalidateFields = false
  validateFields = firstname.length > 0 && isPhoneValid
  anothervalidateFields = firstname.length > 0 && validatePhonePartial(phone)

  const renderNavBarButton = () => {
    if (isEmptyProfile === false) {
      if (saveProfile) {
        return (
          <div
            onClick={() => {
              if (anothervalidateFields) {
                if (validateFields === true) {
                  handler()
                } else {
                  setPopup(true)
                }
              }
              setdovalidate(true)
              postEvent('profile-btn_register', 'involvement', {
                success: validateFields === true,
                ...{ ...user, firstname, phone, email }
              })
            }}
            data-cy={anothervalidateFields ? 'saveProfileButton' : 'unsaveProfileButton'}
            className={[anothervalidateFields ? s.saveProfile : s.unsaveProfile].join(' ')}
          >
            {t('freeForm.save')}
          </div>
        )
      }
    } else if (!isUserLoading && isEmptyProfile) {
      return (
        <div
          onClick={() => {
            if (anothervalidateFields) {
              if (validateFields === true) {
                handler()
              } else {
                setPopup(true)
              }
            }
            setdovalidate(true)
            postEvent('profile-btn_save', 'involvement', {
              success: validateFields === true,
              ...{ ...user, firstname, phone, email }
            })
          }}
          data-cy={anothervalidateFields ? 'saveProfileButton' : 'unsaveProfileButton'}
          className={[anothervalidateFields ? s.saveProfile : s.unsaveProfile].join(' ')}
        >
          {t('freeForm.create')}
        </div>
      )
    }
  }
  return (
    <Popup
      fullsize
      additional={
        showPopup ? (
          <RenderPopup
            s={s}
            phone={phone}
            okAction={() => {
              setPopup(false)
              postEvent('modal-auth-btn_ok', 'engaging')
              handler()
            }}
            noAction={() => {
              postEvent('modal-auth-btn_cancel', 'engaging')
              setPopup(false)
            }}
          />
        ) : (
          ''
        )
      }
      withoutHeaderWrapper
      header={
        <MenuBar
          isHeader
          className={[!inView ? s.inviewHead : '', saveProfile ? 'registerHead' : ''].join(' ')}
          icon={saveProfile ? 'close' : 'cross'}
          title={t('profile')}
          url='/'
          eventCb={() => {
            postEvent('profile-btn_close')
          }}
          right={renderNavBarButton()}
        />
      }
    >
      {isPassVal ? (
        <Popup>
          <div className={s.stolHeader}>REGISTERATION FAILED</div>
          <div className={s.confirmationBoxButtonWrapper}>
            <div onClick={() => setIsPassVal(false)} className={s.confirmationBoxButton}>
              {t('general.ok')}
            </div>
          </div>
        </Popup>
      ) : (
        ''
      )}
      <div className={['contentWrapper', 'custom1'].join(' ')}>
        <div ref={ref} />
        <div className={s.list}>
          <div
            className={[
              s.item2,
              focus === '1' ? s.item2Active : '',
              dovalidate ? (firstname.length > 0 ? '' : s.errorItem2) : ''
            ].join(' ')}
          >
            <div className={s.userIcon2}>
              <UserIcon fill='var(--fontColor)' />
            </div>

            <input
              data-cy='settingsNameField'
              onFocus={_ => {
                postEvent('profile-form-name', 'engaging')
                setFocus('1')
              }}
              value={firstname}
              onChange={_ => {
                setSaveProfile(true)
                setFirstname(_.target.value)
                setdovalidate(false)
              }}
              placeholder={t('freeForm.namePlaceholder')}
              className={[s.userText, 'body1'].join(' ')}
              autoComplete='given-name'
            />
          </div>
          <div
            className={[
              [s.item2, focus === '4' ? s.item2Active : '', dovalidate ? (isPhoneValid ? '' : s.errorItem) : ''].join(
                ' '
              ),
              s.phoneWrap
            ].join(' ')}
          >
            <div style={{ display: 'flex' }}>
              <PhoneIcon fill={isPhoneValid ? 'var(--generalIconColor)' : '#ff0000'} width='24' />
            </div>

            <input
              data-cy='settingsPhoneField'
              onFocus={_ => {
                postEvent('profile-form-tel', 'engaging')
                setFocus('4')
              }}
              value={decoratePhone(phone)}
              onChange={_ => {
                setSaveProfile(true)
                setPhone(_.target.value)
                setdovalidate(false)
              }}
              placeholder={t('freeForm.phonePlaceholder')}
              type='tel'
              className={[s.userText, 'body1'].join(' ')}
              autoComplete='tel'
            />
          </div>
          {dovalidate ? validatePhone(phone) ? '' : <div className={s.errorPas2}>{t('profile.wecant')}</div> : ''}

          {!delivery ? (
            ''
          ) : (
            <div className={[s.item2, focus === '12' ? s.item2Active : ''].join(' ')}>
              <HomeIcon fill='var(--generalIconColor)' className={s.userIcon} width={24} />

              <input
                onFocus={_ => {
                  postEvent('profile-form-address', 'engaging')
                  setFocus('12')
                }}
                value={address}
                autoComplete='street-address'
                type='text'
                onChange={_ => {
                  setSaveProfile(true)
                  setAdress(_.target.value)
                }}
                placeholder={t('general.adress')}
                className={[s.userText, 'body1'].join(' ')}
              />
            </div>
          )}
          {/* NOVAPOSHTA */}
          {isNovaPoshta ? (
            <div className={[s.item2, focus === '2_1' ? s.item2Active : ''].join(' ')}>
              <div className={s.userIcon2}>
                <UserIcon fill='var(--fontColor)' />
              </div>

              <input
                onFocus={_ => {
                  setFocus('2_1')
                }}
                value={delivery.name}
                onChange={_ => {
                  setSaveProfile(true)
                  dispatch(setDelivery({ ...delivery, name: _.target.value }))
                }}
                placeholder={t('dostavka.name')}
                className={[s.userText].join(' ')}
              />
            </div>
          ) : (
            ''
          )}
          {isNovaPoshta ? (
            <>
              <div
                className={[
                  [
                    s.item2,
                    focus === '3_1' ? s.item2Active : '',
                    dovalidate ? (isPhoneValid ? '' : s.errorItem) : ''
                  ].join(' '),
                  s.phoneWrap
                ].join(' ')}
              >
                {/* {validatePhone(phone) ? '' : <div className={s.errorPas}>{t('freeForm.phoneError')}</div>} */}
                <div style={{ display: 'flex' }}>
                  <PhoneIcon fill={isPhoneValid ? 'var(--generalIconColor)' : '#ff0000'} width='24' />
                </div>

                <input
                  onFocus={_ => {
                    // postEvent('profile-form-tel', 'engaging')
                    setFocus('3_1')
                  }}
                  value={decoratePhone(delivery.phone)}
                  onChange={_ => {
                    setSaveProfile(true)
                    dispatch(setDelivery({ ...delivery, phone: _.target.value }))
                    setdovalidate(false)
                  }}
                  placeholder={t('dostavka.phone')}
                  type='tel'
                  className={[s.userText, 'body1'].join(' ')}
                  autoComplete='tel'
                />
              </div>
              {dovalidate && delivery ? (
                validatePhone(delivery.phone) ? (
                  ''
                ) : (
                  <div className={s.errorPas2}>{t('profile.wecant')}</div>
                )
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
          {isNovaPoshta ? (
            <div className={[s.item2, focus === '4_1' ? s.item2Active : ''].join(' ')}>
              <HomeIcon fill='var(--generalIconColor)' className={s.userIcon} width={24} />

              <input
                onFocus={_ => {
                  setFocus('4_1')
                }}
                value={delivery.address}
                autoComplete='street-address'
                type='text'
                onChange={_ => {
                  setSaveProfile(true)
                  dispatch(setDelivery({ ...delivery, address: _.target.value }))
                }}
                placeholder={t('dostavka.address')}
                className={[s.userText, 'body1'].join(' ')}
              />
            </div>
          ) : (
            ''
          )}
          {isNovaPoshta ? (
            <div className={[s.item2, focus === '4_1' ? s.item2Active : ''].join(' ')}>
              <HomeIcon fill='var(--generalIconColor)' className={s.userIcon} width={24} />

              <input
                onFocus={_ => {
                  postEvent('profile-form-address', 'engaging')
                  setFocus('4_1')
                }}
                value={delivery.department}
                type='text'
                onChange={_ => {
                  setSaveProfile(true)
                  dispatch(setDelivery({ ...delivery, department: _.target.value }))
                }}
                placeholder={t('dostavka.department')}
                className={[s.userText, 'body1'].join(' ')}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {user.profilesCount > 1 && <MergeProfileView />}
    </Popup>
  )
}

const RenderPopup = ({ phone, okAction, noAction, s }) => {
  const { t } = useTranslation()
  return (
    <div
      style={{ paddingTop: '0px', position: 'absolute', zIndex: '3456' }}
      className={['confirmation', d.confirmationUi].join(' ')}
    >
      <div style={{ position: 'relative' }} className={d.confirmationBox1}>
        <div style={{ marginTop: '24px' }} className={d.confirmationBoxHeader}>
          {t('profile.yourdata')}
        </div>
        <div style={{ margin: '16px 16px 0px 16px' }} className={s.confirmationBoxHeader2}>
          {t('profile.wecant')}
        </div>
        <div style={{ margin: '16px 16px 80px 16px' }} className={d.confirmationBoxHeader}>
          {decoratePhone(phone)}
        </div>
        <div className={d.confirmationBottom}>
          <div style={{ marginTop: '20px' }} className={s.hr} />
          <div className={s.confirmationBoxButtonWrapper}>
            <div style={{ padding: '16px 0px' }} onClick={noAction} className={s.confirmationBoxButton}>
              {t('confirmationViewNew.button.change')}
            </div>
            <div className={s.vr} />
            <div style={{ padding: '16px 0px' }} onClick={okAction} className={s.confirmationBoxButton}>
              {t('general.ok')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const formatPhone = rawPhone => {
  if (typeof rawPhone !== 'string' || !rawPhone) return null
  const number = rawPhone.replace(/[^\d]/g, '')
  if (/^[1-9]\d{8}$/.test(number)) return `380${number}`
  if (/^0\d{9}$/.test(number)) return `38${number}`
  if (/^80\d{9}$/.test(number)) return `3${number}`
  return number
}

const validatePhone = rawPhone => {
  if (typeof rawPhone === 'string') {
    const richPhone = rawPhone
      .split('')
      .filter(x => x !== '-' && x !== '(' && x !== ' ' && x !== ')' && x !== '+')
      .join('')
    const number = parseInt(richPhone) == richPhone
    if (number) {
      if (richPhone.length < 1) {
        return false
      }
      return /^380\d{9}$/.test(formatPhone(richPhone))
    }
    return false
  }
  return false
}

const validatePhonePartial = rawPhone => {
  if (typeof rawPhone === 'string') {
    const richPhone = rawPhone
      .split('')
      .filter(x => x !== '-' && x !== '(' && x !== ' ' && x !== ')' && x !== '+')
      .join('')
    return parseInt(richPhone) == richPhone && richPhone.length > 0
  }
  return false
}

const tryToSimplifyPhone = rawStr => {
  if (typeof rawStr === 'string') {
    return rawStr
      .split('')
      .filter(x => x !== '-' && x !== '(' && x !== ' ' && x !== ')' && x !== '+')
      .join('')
  }
  return ''
}

export default Profile
