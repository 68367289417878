import React, { useRef } from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'src/hooks/useTranslation'
import { ReactComponent as CrossIcon } from 'src/assets/ico-minicros.svg'
import { IconType } from 'src/components/RichMenu/components/IconType'
import { money } from 'src/utils'
import { getOptionsets } from 'src/state/menu/selectors.ts'
import { addManyOrders } from 'src/state/orders/actions.ts'
import { useDispatch, useSelector } from 'react-redux'
import { postEvent } from 'src/api'
import useThemeStyles from 'src/hooks/useThemeStyles'
import usePaidOrders from 'src/hooks/usePaidOrders.ts'
import styles from './styles.module.scss'
import { getOrderUniqueKey } from '../../../../utils/orders'

const OrdersCard = ({ dishes, orders, handleOpenOrderCard, isOpen }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const innerRef = useRef()
  const optionSets = useSelector(getOptionsets)
  const { variables } = useThemeStyles()
  const { demoVariables } = useSelector(state => state.demo)
  const [{ groupedOrders, totalPrice }, { getOrderPrice }] = usePaidOrders(orders)

  const handleOrdersAdd = () => {
    const mappedOptions = orders
      .map(order => {
        const currDish = dishes.find(dish => dish.id === order.dishId)

        if (currDish) {
          return {
            ...currDish,
            selectedOptions: order.options
          }
        }

        return null
      })
      .filter(order => order)

    dispatch(addManyOrders(mappedOptions))
  }

  const getOptions = options =>
    options.map(option => {
      const currentOptionSet = optionSets.find(set => set.id === option.id)

      if (currentOptionSet) {
        const setValue = currentOptionSet.options.find(setOption => setOption.id === option.value)
        return `, ${t(currentOptionSet.name)}${setValue ? `: ${t(setValue.name)}` : ''}`
      }

      return null
    })

  const renderIcons = () =>
    groupedOrders.map(x => {
      const dish = dishes.find(d => d.id === x.dishId)

      if (dish) {
        return (
          <div key={`${getOrderUniqueKey(x)}`} className={styles.iconWrapper}>
            <span className={styles.dishCount}>{x.count}</span>
            <CrossIcon fill={'var(--fontColor)'} style={{ marginRight: '2px' }} />
            <IconType style={{ backgroundColor: 'var(--fontColor)' }} type={dish.icon} size={24} />
          </div>
        )
      }

      return null
    })

  const renderDishes = () =>
    groupedOrders.map(order => {
      const dish = dishes.find(d => d.id === order.dishId)

      if (dish) {
        return (
          <div key={`${getOrderUniqueKey(order)}`} className={styles.orderContentDishWrapper}>
            <div className={styles.iconWrapper}>
              <span data-cy={`dishInOrderCount-${dish.id}`} className={styles.dishCount}>
                {order.count}
              </span>
              <CrossIcon fill={'var(--fontColor)'} style={{ marginRight: '2px' }} />
              <IconType style={{ backgroundColor: 'var(--fontColor)' }} type={dish.icon} size={24} />
              <span data-cy={`dishInOrder-${dish.id}`} className={styles.dishTitle}>
                {t(dish.name)}
                <span>{getOptions(order.options)}</span>
              </span>
            </div>
            <span className={`${styles.price} ${styles.priceSmall}`}>{`${money(getOrderPrice(order))} ₴`}</span>
          </div>
        )
      }

      return null
    })

  const cardVariants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    hidden: {
      y: 30,
      opacity: 0,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    }
  }
  const handleCardClick = () => {
    if (isOpen) {
      handleOpenOrderCard(null)
    } else {
      postEvent('my-orders-menu-card_open', 'engaging', {
        section: 'favorite-orders',
        dishIds: orders
          .map(order => {
            const currDish = dishes.find(dish => dish.id === order.dishId)
            return currDish?.id
          })
          .filter(order => !!order)
      })
      handleOpenOrderCard()
    }
  }

  return (
    <motion.div
      variants={cardVariants}
      onClick={handleCardClick}
      data-cy='orderCardWrapper'
      className={styles.orderCardWrapper}
      style={{
        '--viewBgLighten': demoVariables?.dishCardTitleBackground || variables?.dishCardTitleBackground || null
      }}
    >
      {!isOpen && (
        <div className={styles.orderCardHeader}>
          <div className={styles.miniIconsWrapper}>{renderIcons(orders)}</div>
          <div className={styles.price}>{`${money(totalPrice)} ₴`}</div>
        </div>
      )}
      <div
        style={{ ...(isOpen ? { height: `${innerRef?.current?.offsetHeight}px` } : {}) }}
        className={styles.orderCardContentWrapper}
      >
        <div ref={innerRef} className={styles.orderCardContentInnerWrapper}>
          <div className={`${!isOpen ? styles.dishesListClosed : ''}`}>{renderDishes(orders)}</div>
          <button
            data-cy='orderButtonReorder'
            onClick={e => {
              e.stopPropagation()
              handleOrdersAdd()
              postEvent('my-orders-card_click', 'ordering', {
                section: 'favorite-orders',
                dishIds: orders
                  .map(order => {
                    const currDish = dishes.find(dish => dish.id === order.dishId)
                    return currDish?.id
                  })
                  .filter(order => !!order)
              })
              handleOpenOrderCard(null)
            }}
          >
            {t('favorites.order')}
          </button>
        </div>
      </div>
    </motion.div>
  )
}
export default OrdersCard
