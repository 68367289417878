import React, { useEffect, useRef } from 'react'
import Particles from 'react-tsparticles'
import { ISourceOptions, Container } from 'tsparticles'
import { WithModificationVariant } from 'src/types'
import snow from 'src/assets/holidays/newYear/starburst_white.png'
import heart from 'src/assets/holidays/valentineDay/heart.svg'
import s from './styles.module.scss'

const PARTICLES_VARIANTS = {
  newYear: {
    particles: {
      number: {
        value: 150,
        density: {
          enable: true,
          value_area: 400,
          factor: 500
        },
        limit: 0
      },
      color: {
        value: '#ffffff'
      },
      shape: {
        type: 'image',
        stroke: {
          width: 3,
          color: '#fff'
        },
        polygon: {
          nb_sides: 5
        },
        image: {
          src: snow,
          width: 100,
          height: 100
        }
      },
      opacity: {
        value: 1,
        random: true,
        anim: {
          enable: true,
          speed: 0.05,
          opacity_min: 0,
          sync: true
        }
      },
      size: {
        value: 5,
        random: true,
        anim: {
          enable: false,
          speed: 20,
          size_min: 0.1,
          sync: false
        }
      },
      line_linked: {
        enable: false,
        distance: 50,
        color: '#ffffff',
        opacity: 0.6,
        width: 1
      },
      move: {
        enable: true,
        speed: 2,
        direction: 'bottom',
        random: false,
        straight: false,
        out_mode: 'out',
        bounce: false
      }
    }
  },
  valentineDay: {
    fpsLimit: 60,
    autoPlay: false,
    particles: {
      number: {
        value: 1
      },
      shape: {
        type: 'image',
        image: {
          src: heart
        }
      },
      opacity: {
        value: 0,
        random: false
      },
      size: {
        value: 60,
        random: { enable: false, minimumValue: 4 },
        animation: {
          enable: false,
          speed: 20,
          minimumValue: 4,
          sync: false
        }
      },
      move: {
        enable: true,
        gravity: {
          enable: true,
          acceleration: -2.5
        },
        speed: 5,
        direction: 'top',
        random: true,
        straight: false,
        outModes: {
          default: 'destroy',
          bottom: 'none'
        },
        attract: {
          enable: true,
          distance: 200,
          rotate: {
            x: 100,
            y: 800
          }
        }
      }
    },
    emitters: [
      {
        direction: 'top',
        life: {
          duration: 0.2,
          count: 1,
          delay: 1,
          wait: true
        },
        particles: {
          number: {
            value: 1
          },
          opacity: {
            value: 0.4
          },
          shape: {
            type: 'image',
            image: {
              src: heart
            }
          }
        },
        rate: {
          quantity: 1,
          delay: 0
        },
        size: {
          width: 100,
          height: 10
        },
        position: {
          x: 50,
          y: 110
        }
      }
    ]
  }
} as { [key in string]: ISourceOptions }

const ForegroundAnimation: React.FC<WithModificationVariant> = ({ variant }) => {
  const containerRef: React.RefObject<Container> = useRef(null)

  useEffect(() => {
    if (variant && PARTICLES_VARIANTS[variant].autoPlay === false) {
      setTimeout(() => {
        containerRef.current?.play()
      }, 1000)
    }
  }, [containerRef.current])

  return (
    <Particles
      id='foreground-animation'
      canvasClassName={s.particles}
      container={containerRef}
      params={{
        fpsLimit: 60,
        particles: {
          ...(variant ? PARTICLES_VARIANTS[variant]?.particles : {})
        },
        fullScreen: {
          enable: true,
          zIndex: 201
        },
        autoPlay: variant ? PARTICLES_VARIANTS[variant].autoPlay : false,
        emitters: variant ? PARTICLES_VARIANTS[variant].emitters : []
      }}
    />
  )
}

export default React.memo(ForegroundAnimation)
