import {
  SET_TOKEN,
  SET_FIRSTTIME,
  SET_CONFIRMED_PAYMENT,
  SET_LANG,
  SET_LANGUAGE_PREFERENCES,
  SET_TAKEOUT_READY
} from './types'

export const setToken = token => ({
  type: SET_TOKEN,
  payload: { token }
})

export const setFirstTime = firstTime => ({
  type: SET_FIRSTTIME,
  payload: { firstTime }
})

export const setLang = lang => ({
  type: SET_LANG,
  payload: { lang }
})

export const setConfirmedPayment = item => ({
  type: SET_CONFIRMED_PAYMENT,
  payload: { item }
})

export const setTakeoutReady = takeoutNum => ({
  type: SET_TAKEOUT_READY,
  takeoutNum
})

export const setLanguagePreferences = (languagesList, activeLanguage) => ({
  type: SET_LANGUAGE_PREFERENCES,
  languagesList,
  activeLanguage
})
