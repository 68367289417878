import React, { useCallback, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MenuCard } from 'src/components/RichMenu/components/MenuCard'
import { postEvent } from 'src/api'
import { useTranslation } from 'src/hooks/useTranslation'
import { addNewOrder } from 'src/state/orders'
import { useHistory } from 'react-router-dom'
import { addSelectedDish, addSelectedOptions } from 'src/state/menu'
import useCurrentDishes from 'src/hooks/useCurrentDishes'
import { ReactComponent as FavoritesEmptyView } from 'src/assets/favorites_empty_view.svg'
import useFavoritesDishes from 'src/hooks/useFavoritesDishes'
import { getFavorites } from 'src/state/user'
import OrdersCard from '../OrderCard'
import styles from './styles.module.scss'

const FavoritesTab = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [currentOpenDishId, setCurrentOpenDishId] = useState(null)
  const [currentOpenOrderId, setCurrentOpenOrderId] = useState(null)

  const userFavoritesDishes = useSelector(getFavorites)

  const { dishes } = useCurrentDishes()
  const { userFavoriteOrders, userTopOrders, allSortedFavoritesDishes } = useFavoritesDishes()
  const { t } = useTranslation()

  const handleCardClick = useCallback(
    dish => {
      history.push(`/dish/${dish.id}`)
      // postEvent('richmenu-dishcard', 'ordering', { name: t(dish.name), id: dish.id })
    },
    [history]
  )

  const handleOpenDishCard = useCallback(
    id => {
      if (!!currentOpenOrderId) {
        setCurrentOpenOrderId(null)
      }
      if (id) {
        postEvent('favorite-menu-card_open', 'engaging', {
          section: 'favorite',
          dishIds: [id]
        })
      }

      setCurrentOpenDishId(id)
    },
    [currentOpenOrderId]
  )

  const handleOpenOrderCard = key => {
    setCurrentOpenOrderId(key)
    if (!!currentOpenDishId) {
      setCurrentOpenDishId(null)
    }
  }

  const renderFavoriteOrders = useMemo(
    () => () => {
      if (userTopOrders.length) {
        return (
          <>
            <p data-cy='favoritesLabelOrders' className={styles.sectionLabel}>
              {t('favorites.orders')}
            </p>
            {userTopOrders.map((favoriteOrderItem, index) => {
              const key = `payment-${index}`
              const isOpen = key === currentOpenOrderId
              return (
                <OrdersCard
                  isOpen={isOpen}
                  handleOpenOrderCard={() => handleOpenOrderCard(isOpen ? null : key)}
                  key={key}
                  orders={favoriteOrderItem.orders}
                  dishes={dishes}
                />
              )
            })}
          </>
        )
      }
    },
    [userTopOrders, currentOpenOrderId, dishes, t, handleOpenOrderCard]
  )

  const renderFavorites = useMemo(
    () => () => {
      return (
        <>
          <p className={styles.sectionLabel}>{t('favorites.myTop')}</p>
          {allSortedFavoritesDishes.length > 0 &&
            allSortedFavoritesDishes.map(favItem => {
              const isOpen = favItem.key === currentOpenDishId
              return (
                <MenuCard
                  key={favItem.key}
                  isFavorite={Boolean(favItem.isFavorite)}
                  id={favItem.key}
                  isOpen={favItem.key === currentOpenDishId}
                  dish={favItem.dish}
                  selectedOptions={favItem.options}
                  handleCardAdd={() => {
                    postEvent('favorite-card-order_click', 'ordering', {
                      section: 'favorite',
                      dishIds: [favItem.dish.id]
                    })
                    dispatch(addNewOrder({ ...favItem.dish, selectedOptions: favItem.options }))
                    handleOpenDishCard(null)
                  }}
                  handleCardClick={e => {
                    postEvent('favorite-card-details_click', 'engaging', {
                      section: 'favorite',
                      dishIds: [favItem.dish.id]
                    })
                    dispatch(addSelectedDish(favItem.dish))
                    dispatch(addSelectedOptions(favItem.options))
                    handleCardClick(favItem.dish)
                  }}
                  handleOpenDishCard={() => handleOpenDishCard(isOpen ? null : favItem.key)}
                />
              )
            })}
        </>
      )
    },
    [allSortedFavoritesDishes, currentOpenDishId, handleCardClick, handleOpenDishCard, t, dispatch]
  )

  if (!userFavoritesDishes.length && !userFavoriteOrders.length && !userTopOrders.length) {
    return (
      <div className={styles.searchBody}>
        <FavoritesEmptyView />
        <span dangerouslySetInnerHTML={{ __html: t('richMenu.notFound') }} className={styles.searchCenter}>
          {/* Пока ничего нет.
          <br /> Но вы можете добавлять сюда понравившиеся блюда для быстрого заказа. <br />
          Также, мы сами поможем вам, добавив сюда наиболее часто повторяющиеся заказы */}
        </span>
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      {renderFavoriteOrders()}
      {renderFavorites()}
    </div>
  )
}

export default React.memo(FavoritesTab)
