import axios from 'axios'
import { config } from '../../constants'
import { setCustomPages } from './actions'

export const fetchCustomPages = () => async dispatch => {
  if (window.busytable.pages) {
    dispatch(setCustomPages(window.busytable.pages?.data || []))
  } else {
    const result = await axios(`${config.API_URL3}/${window.busytable.brandSlug}/pages`, {
      withCredentials: true
    }).catch(e => {
      console.log('failed fetchCustomPages request', e)
      return { status: 'error' }
    })
    if (result.status !== 'error') {
      dispatch(setCustomPages(result.data?.data || []))
    }
  }
}
