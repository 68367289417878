import {
  FETCH_STOPLIST_DISH,
  FETCH_STOPLIST_OPTION,
  REMOVE_STOPLIST_OPTION_SSE,
  ADD_STOPLIST_OPTION_SSE,
  ADD_STOPLIST_DISHES_SSE,
  ADD_STOPLIST_MODIFIERS
} from './types'

export const fetchStoplistDish = stoplist => ({
  type: FETCH_STOPLIST_DISH,
  payload: { stoplist }
})

export const fetchStoplistOption = stoplist => ({
  type: FETCH_STOPLIST_OPTION,
  payload: { stoplist }
})

export const removeStoplistOptionSSE = stoplist => ({
  type: REMOVE_STOPLIST_OPTION_SSE,
  payload: { stoplist }
})

export const addStoplistOptionSSE = stoplist => ({
  type: ADD_STOPLIST_OPTION_SSE,
  payload: { stoplist }
})

export const addStoplistDishesSSE = stoplist => ({
  type: ADD_STOPLIST_DISHES_SSE,
  payload: { stoplist }
})

export const addStoplistModifiers = items => ({
  type: ADD_STOPLIST_MODIFIERS,
  payload: { items }
})
