const getThemeStyle = (property = '') => {
  let themeSlug = ''
  if (window && window.busytable) {
    themeSlug = window.busytable.brandSlug
  }

  if (themeSlug.length) {
    let themeStylesObj = {}
    switch (themeSlug) {
      case 'nm': {
        themeStylesObj = {
          borderRadius: '50%',
          imageColor: 'var(--fontInvertedColor)',
          tabWidth: '64px',
          inactiveColor: '#fbebee'
        }
        break
      }
      default: {
        themeStylesObj = {
          borderRadius: '16px',
          imageColor: 'var(--accentColor)',
          tabWidth: '72px',
          inactiveColor: 'transparent'
        }
      }
    }

    if (property && property.length) {
      return themeStylesObj[property]
    }

    return themeStylesObj
  }
}

export default getThemeStyle
