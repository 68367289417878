import React from 'react'
import styles from './styles.module.scss'
import { useTranslation } from 'src/hooks/useTranslation'
import { useSelector } from 'react-redux'
import { getVenueId } from 'src/state/location'
import { getVenueById } from 'src/state/venues'
import { ReactComponent as LocationIcon } from 'src/assets/ico-location.svg'

const TipsCheckout = ({ amount }) => {
  const { t } = useTranslation()
  const venueId = useSelector(getVenueId)
  const venue = useSelector(getVenueById)(venueId)

  return (
    <div className={styles.liqpayDataWrapper}>
      {venue && (
        <div className={styles.liqpayDataVenue}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LocationIcon style={{ opacity: 0.5 }} width={24} height={24} />
            <span>{t(venue?.name)}</span>
          </div>
        </div>
      )}
      <div className={styles.liqpayDataAmount}>
        <span>{t('review.tipsCheckoutText')}</span>
        <span data-cy={`tips-${amount.slice(0, -2)}`}>{amount}</span>
      </div>
    </div>
  )
}
export default TipsCheckout
