import React, { useContext } from 'react'
import cx from 'classnames'
import { isEmpty } from 'ramda'
import { useSelector } from 'react-redux'
import { ReactComponent as ArrowIcon } from 'src/assets/ico-acc.svg'
import { ReactComponent as DeliveryIcon } from 'src/assets/ico-delivery-1.svg'
import { ReactComponent as HomeIcon } from 'src/assets/ico-home.svg'
import { ReactComponent as LocationIcon } from 'src/assets/ico-location.svg'
import { ReactComponent as TableIcon } from 'src/assets/ico-table-small.svg'
import { ReactComponent as ClockIcon16 } from 'src/assets/ico-time-16.svg'
import { ReactComponent as ClockIcon } from 'src/assets/ico-time.svg'
import { ReactComponent as TogoIcon } from 'src/assets/ico-togo-24.svg'
import { ReactComponent as UserIcon } from 'src/assets/person.svg'
import { ReactComponent as PhoneIcon } from 'src/assets/phone.svg'
import { ReactComponent as FeedbackIcon } from 'src/assets/feedbackIcon.svg'
import { ReactComponent as MoneyTipsIcon } from 'src/assets/moneyTipsIcon.svg'
import { ReactComponent as HeartShape } from 'src/assets/heartShape.svg'
import { getPaymentInHistoryById } from 'src/state/history/selectors'
import { useTranslation } from 'src/hooks/useTranslation'
import { getLang } from 'src/state/local'
import { getOrdersNew } from 'src/state/orders'
import { getUserData } from 'src/state/user'
import { getVenues } from 'src/state/venues'
import { postEvent } from 'src/api'
import { Routes } from 'src/globals/enumerations'
import useAppPreferences from 'src/hooks/useAppPreferences'
import ReadyTakeoutButton from './ReadyTakeoutButton'
import { useHistory } from 'react-router-dom'
import { IOrder, IPayment, IVenue, PAYMENT_ADDON_TYPE } from 'src/types'
import OrdersList from './OrdersList'
import { OrdersActionsContext } from '../contexts'
import s from '../styles.module.scss'
import { isTakeoutOrDeliveryOrder, isTakeoutOrder } from '../../../utils/orders'

type PaidOrdersSectionProps = {
  orders: IOrder[]
  isLastPayment: boolean
}

const PaidOrdersSection: React.FC<PaidOrdersSectionProps> = ({ orders, isLastPayment }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const lang = useSelector(getLang) || window.busytable?.fallbackLang
  const allVenues = useSelector(getVenues) as IVenue[]
  const userData = useSelector(getUserData)
  const payment = useSelector(store => getPaymentInHistoryById(store, Number(orders[0]?.paymentId))) as
    | IPayment
    | undefined
  const newOrders = useSelector(getOrdersNew)

  const { detailedOrders, toggleDetailedOrders } = useContext(OrdersActionsContext)
  const isDetailed = detailedOrders === orders?.[0]?.takeoutNum

  const { preferences } = useAppPreferences()
  const deliveryStat = orders[0].delivery
  const accordionClosed = false
  const isPreorder = orders.length > 0 ? orders[0].preorder : false
  const secondMode = isTakeoutOrDeliveryOrder(orders?.[0])

  const renderLeaveTipsButtons = (isReadyAll: boolean) => {
    if (preferences.withTips && isReadyAll && orders[0].paymentId && payment) {
      const isTipsLeft = payment.addOns.some(addOne => addOne.type === PAYMENT_ADDON_TYPE.TIPS)
      return (
        <div className={s.leaveTipsButtonsWrapper}>
          {isTipsLeft ? (
            <button
              data-cy='buttonTipsThank'
              onClick={e => {
                e.stopPropagation()
                history.push(Routes.tipsThankView)
              }}
              style={{ color: '#FF2A2A' }}
              className={`${s.leaveTipsButton}`}
            >
              <HeartShape style={{ marginRight: '8px', minWidth: '24px', minHeight: '20px' }} width={24} height={20} />{' '}
              {t('review.tipsThankText')}
            </button>
          ) : (
            <button
              data-cy='leaveTipsButton'
              onClick={e => {
                e.stopPropagation()
                postEvent('tips-button_click', 'involvement', { paymentId: orders[0].paymentId })
                history.push(`${Routes.tips}/${orders[0].paymentId}`)
              }}
              className={`${s.leaveTipsButton} ${!newOrders.length && isLastPayment ? s.leaveTipsButtonActive : ''}`}
            >
              {(!!newOrders.length || !isLastPayment) && (
                <MoneyTipsIcon
                  width={24}
                  height={24}
                  style={{ marginRight: '8px', minWidth: '24px', minHeight: '24px' }}
                />
              )}
              {t('review.leaveTipsButton')}
            </button>
          )}
          <button
            onClick={e => {
              e.stopPropagation()
              postEvent('rate-button_click', 'involvement', { paymentId: orders[0].paymentId })
              history.push(`${Routes.rate}/${orders[0].paymentId}`)
            }}
            className={`${s.leaveTipsButton}`}
            data-cy='leaveCommentButton'
          >
            <FeedbackIcon width={24} height={24} style={{ marginRight: '8px', minWidth: '24px', minHeight: '24px' }} />{' '}
            {t('review.leaveCommentButton')}
          </button>
        </div>
      )
    }
  }

  if (isEmpty(orders)) {
    return null
  }

  const isAllTakeoutReady = orders.filter(e => e.status && e.status.paid).length === orders.length
  const tColor = isAllTakeoutReady ? s.headerTakeoutReady : s.headerTakeoutNotReady

  let count = orders.filter(e => e.status && e.status.ready).length

  const isShowingImHereBtn = orders.every(order => order?.status?.ready && !order?.status?.finished) && secondMode

  if (orders.length) {
    const isAllOrdersReady = orders.every(order => order.status?.ready)
    const isAllOrdersDone = orders.some(order => isTakeoutOrDeliveryOrder(order))
      ? orders.every(order => order.status?.finished)
      : true

    return (
      <>
        <div
          onClick={() => {
            if (!isDetailed) {
              toggleDetailedOrders(orders?.[0]?.takeoutNum)
            } else {
              toggleDetailedOrders(null)
            }
          }}
          style={{
            transition: 'all 0.3s ease'
          }}
          className={cx(s.item, { [s.accordionClosed]: accordionClosed }, isDetailed ? s.detailed : '', s.paidSection)}
        >
          {
            <div className={cx(s.titleWrap, s.flex)}>
              <div style={{ paddingTop: '0px' }} className={s.title} />
            </div>
          }
          <div className={s.priceList}>
            {secondMode && !isPreorder && (
              <div className={s.sectionHeader}>
                {count === 0 ? '' : count === orders.length ? '' : `${orders.length - count} ${t('orderSection.of')} `}
                {count === 0
                  ? ''
                  : count !== orders.length
                  ? t('numDishesCooking', { count: orders.length } as any)
                  : t('numDishesReady', { count } as any)}
              </div>
            )}
            <div className={s.header}>
              {!(secondMode && !isPreorder) ? (
                <>
                  <div className={s.headerPaid}>
                    {isTakeoutOrder(orders?.[0]) && (
                      <TogoIcon
                        style={{ marginRight: orders[0].tableNum ? '8px' : '0px' }}
                        width={24}
                        height={24}
                        stroke='currentColor'
                      />
                    )}
                    {orders[0].tableNum ? (
                      <TableIcon fill={'currentColor'} />
                    ) : (
                      deliveryStat && <DeliveryIcon width='32' height='32' fill={'currentColor'} />
                    )}
                    <div>
                      {orders[0].tableNum ? (
                        <span style={{ marginLeft: '8px' }}>{orders[0].tableNum}</span>
                      ) : deliveryStat ? (
                        ''
                      ) : (
                        <div className={s.smallText}>{t('orderSection.zone')}</div>
                      )}
                    </div>
                    <ArrowIcon style={{ transition: 'all 0.3s ease', transform: !isDetailed ? '' : 'rotate(90deg)' }} />
                  </div>
                  <div className={s.headerDate}>
                    <div className={[s.headerTime, isDetailed ? s.headerNotActive : s.headerActive].join(' ')}>
                      {new Date(orders?.[0]?.paidAt || '').toLocaleTimeString(lang, {
                        hour: 'numeric',
                        minute: 'numeric'
                      })}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ marginBottom: '8px' }} className={s.headerPaid}>
                    {isTakeoutOrder(orders?.[0]) && (
                      <TogoIcon
                        style={{ marginRight: orders[0].tableNum ? '8px' : '0px' }}
                        width={24}
                        height={24}
                        stroke='currentColor'
                      />
                    )}
                    {orders[0].tableNum ? (
                      <TableIcon fill={'currentColor'} />
                    ) : (
                      deliveryStat && <DeliveryIcon width='32' height='32' fill={'currentColor'} />
                    )}

                    <div className={s.headerRight}>
                      {orders.length > 0 && orders[0].takeoutNum && (
                        <div
                          style={{ marginLeft: '8px' }}
                          className={[s.headerRightItem, isAllOrdersReady ? s.os3 : s.os31].join(' ')}
                        >
                          {orders[0].tableNum && orders[0].tableNum !== 0 ? `${orders[0].tableNum} / ` : ''}#
                          {orders[0].takeoutNum}
                        </div>
                      )}
                    </div>
                    <ArrowIcon style={{ transition: 'all 0.3s ease', transform: !isDetailed ? '' : 'rotate(90deg)' }} />
                  </div>
                </>
              )}
              {secondMode && !isPreorder ? (
                <div className={s.headerRight33}>
                  {orders.length > 0 && orders[0].timedTakeout ? (
                    <>
                      {isAllOrdersReady ? <ClockIcon16 /> : <ClockIcon />}
                      <div className={isAllOrdersReady ? s.os0 : s.os01}>
                        {new Date(orders[0].timedTakeout).toLocaleTimeString(lang, {
                          hour: 'numeric',
                          minute: 'numeric'
                        })}
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                  {isAllOrdersReady ? (
                    <div className={s.os2}>{t('ordersSection.ready')}</div>
                  ) : (
                    <div className={s.os1}>{t('ordersSection.cooking')}</div>
                  )}
                </div>
              ) : (
                <div className={s.headerRight}>
                  {orders.length > 0 && orders[0].takeoutNum && (
                    <div className={[s.headerRightItem, tColor].join(' ')}>#{orders[0].takeoutNum}</div>
                  )}
                </div>
              )}
            </div>

            {isShowingImHereBtn && <ReadyTakeoutButton takeoutNumber={orders[0].takeoutNum} />}

            {isDetailed && deliveryStat && deliveryStat.type && (
              <div className={s.commentCheck}>
                <LocationIcon width={12} height={12} />
                <div style={{ marginLeft: '16px' }}>{t(deliveryStat.type)}</div>
              </div>
            )}
            {isDetailed && orders[0].venueSlug && (
              <div className={s.commentCheck}>
                <LocationIcon width={12} height={12} />
                <div style={{ marginLeft: '16px' }}>
                  {allVenues.filter(v => v.slug === orders[0].venueSlug).length > 0 &&
                    t(allVenues.filter(v => v.slug === orders[0].venueSlug)[0].name)}
                </div>
              </div>
            )}
            {isDetailed && deliveryStat && deliveryStat.address && (
              <div className={s.commentCheck}>
                <HomeIcon fill={'#B3B3B3'} width={12} height={12} />
                <div style={{ marginLeft: '16px' }}>{deliveryStat.address}</div>
              </div>
            )}
            {isDetailed && userData && userData.phoone && (
              <div className={s.commentCheck}>
                <PhoneIcon width={12} height={12} />
                <div style={{ marginLeft: '16px' }}>{deliveryStat.phone}</div>
              </div>
            )}
            {isDetailed && userData && userData.name && (
              <div className={s.commentCheck}>
                <UserIcon width={12} height={12} />
                <div style={{ marginLeft: '16px' }}>{userData.name}</div>
              </div>
            )}
            {isDetailed && orders[0].comment && <div className={s.commentCheck}>{orders[0].comment}</div>}
            <OrdersList orders={orders} />
            {renderLeaveTipsButtons(isAllOrdersDone)}
          </div>
        </div>
      </>
    )
  }

  return null
}

export default PaidOrdersSection
