import React, { useEffect } from 'react'

/**
 * Logic and data
 */
import { useTranslation } from 'src/hooks/useTranslation'

import { Redirect, useParams } from 'react-router'

import { fetchConfirmAuth, fetchSetAuth } from 'src/api'

/**
 * Components and styling
 */
import { Link } from 'react-router-dom'

import { Popup } from '../ui/Popup'

import s from './styles.module.scss'

export function BannerAuth() {
  /**
   * Hooks
   */
  const { t } = useTranslation()

  const { nonce } = useParams()

  /**
   * Event handlers
   */
  function handlePressYes() {
    fetchConfirmAuth(nonce)
  }

  return (
    <Popup>
      <div className={s.header}>{t('bannerAuth.header')}</div>
      <div className={s.containerButtons}>
        <Link className={s.button} to='/'>
          {t('general.no')}
        </Link>
        <Link className={s.button} to='/' onClick={handlePressYes}>
          {t('general.yes')}
        </Link>
      </div>
    </Popup>
  )
}

export function BannerSelfAuth() {
  const { token } = useParams()

  useEffect(() => {
    const setToken = async () => {
      try {
        await fetchSetAuth(token)
        window.location.reload()
      } catch (e) {
        console.log(`Error while setting token: ${e.message}`)
      }
    }
    setToken()
  }, [token])

  return <Redirect to='/'></Redirect>
}
