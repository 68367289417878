import React, { ChangeEvent, useCallback } from 'react'
import clx from 'classnames'
import s from './styles.module.scss'

type RadioOptionProps = {
  name: string
  value: string
  onChecked: () => void
  disabled?: boolean
  checked: boolean
  label: string
}

const RadioOption: React.FC<RadioOptionProps> = function ({ label, name, value, checked, disabled, onChecked }) {
  const handleCheck = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        onChecked()
      }
    },
    [onChecked]
  )

  return (
    <label
      className={clx(
        'body1',
        s.radio,
        s.radioLabel,
        disabled && s.radioDisabled,
        disabled && checked && s.radioDisabledChecked,
        checked && !disabled && s.radioChecked
      )}
    >
      <input type='radio' name={name} value={value} checked={checked} onChange={handleCheck} className={s.radioInput} />
      <span>{label}</span>
    </label>
  )
}

export default RadioOption
