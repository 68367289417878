import React, { useState } from 'react'
import { useTranslation } from 'src/hooks/useTranslation'
import { postEvent } from 'src/api'
import { IOrder, NewOrderInput } from 'src/types'
import { useSelector } from 'src/state/hooks'
import { getDishById } from 'src/state/menu'
import s from '../styles.module.scss'

type OrderActionButtonProps = {
  cb: () => void
  order: IOrder | NewOrderInput
}

const OrderActionButton: React.FC<OrderActionButtonProps> = ({ cb, order, children }) => {
  const dishById = useSelector(getDishById)

  const [state, setState] = useState(false)
  const { t } = useTranslation()

  return (
    <div
      className={[s.contextButton, state ? s.anim : ''].join(' ')}
      onClick={() => {
        setState(true)
        if (cb) {
          cb()
        }
        postEvent('cart-dish_context-add_to_cart', 'ordering', {
          name: t(dishById(order.dishId).name),
          id: order.dishId
        })
      }}
      onAnimationEnd={() => setState(false)}
    >
      {children}
    </div>
  )
}

export default OrderActionButton
