import React from 'react'
import { useDispatch } from 'react-redux'
import { addSelectedDish, addSelectedOptions } from 'src/state/menu'
import MenuDishCard from 'src/components/MenuDishCard'
import { MenuCard } from './MenuCard'
import useAppPreferences from 'src/hooks/useAppPreferences'
import { isDesktop } from 'src/utils'
import useFavoritesDishes from '../../../hooks/useFavoritesDishes'

const Cards = ({ dishes, searchMode, handleCardClick }) => {
  const dispatch = useDispatch()
  const { findTopDishWithOptions, findFavoriteDishWithOptions } = useFavoritesDishes()
  const { preferences } = useAppPreferences()
  const isNewDesignMode = isDesktop ? false : preferences.designVersion === 1.1

  const handleSetCurrentOpenCard = (currentDish, existedOptions) => {
    dispatch(addSelectedDish(currentDish))
    dispatch(addSelectedOptions(existedOptions))
    handleCardClick(currentDish)
  }

  return dishes.map((dish, index) => {
    if (dish) {
      const favDishWithOptions = findFavoriteDishWithOptions(dish)
      const topDishWithOptions = findTopDishWithOptions(dish)

      if (favDishWithOptions) {
        if (isNewDesignMode) {
          return (
            <MenuDishCard
              dishId={favDishWithOptions.dish.id}
              index={index}
              favoriteOptions={favDishWithOptions.options}
              handleSetCurrentOpenCard={() => {
                handleSetCurrentOpenCard(favDishWithOptions.dish, favDishWithOptions.options)
              }}
              key={`${favDishWithOptions.dish.id}_${index}`}
            />
          )
        } else {
          return (
            <MenuCard
              dish={favDishWithOptions.dish}
              selectedOptions={favDishWithOptions.options}
              handleCardClick={e => {
                dispatch(addSelectedDish(favDishWithOptions.dish))
                dispatch(addSelectedOptions(favDishWithOptions.options))
                handleCardClick(favDishWithOptions.dish)
              }}
              searchMode={searchMode}
              key={`${favDishWithOptions.dish.id}_${index}`}
            />
          )
        }
      }

      if (topDishWithOptions) {
        if (isNewDesignMode) {
          return (
            <MenuDishCard
              dishId={topDishWithOptions.dish.id}
              index={index}
              favoriteOptions={topDishWithOptions.options}
              handleSetCurrentOpenCard={() => {
                handleSetCurrentOpenCard(topDishWithOptions.dish, topDishWithOptions.options)
              }}
              key={`${topDishWithOptions.dish.id}_${index}`}
            />
          )
        } else {
          return (
            <MenuCard
              dish={topDishWithOptions.dish}
              selectedOptions={topDishWithOptions.options}
              handleCardClick={e => {
                dispatch(addSelectedDish(topDishWithOptions.dish))
                dispatch(addSelectedOptions(topDishWithOptions.options))
                handleCardClick(topDishWithOptions.dish)
              }}
              searchMode={searchMode}
              key={`${topDishWithOptions.dish.id}_${index}`}
            />
          )
        }
      }

      if (isNewDesignMode) {
        return (
          <MenuDishCard
            dishId={dish.id}
            index={index}
            handleSetCurrentOpenCard={() => {}}
            key={`${dish.id}_${index}`}
          />
        )
      } else {
        return (
          <MenuCard
            {...{ dish }}
            handleCardClick={e => handleCardClick(dish)}
            searchMode={searchMode}
            key={`${dish.id}_${index}`}
          />
        )
      }
    }
  })
}

export default Cards
