/**
 * @important History must be sorted on the Back-End side.
 * @param {array} history
 * @param {number?} numberToReturn
 * @returns {array<any>} returns @numberToReturn most favorites orders
 */
import { IOrder, IPayment } from '../types'
import { getOrderUniqueKey } from './orders'

type SortedPaymentsMap = { [key in string]: IOrder[][] }

export const findFavoritesOrders = (history: IPayment[]): IOrder[][] => {
  const paymentsMap = history.reduce((result, current) => {
    const ordersToCompare = current.orders
      .sort((a, b) => (a?.dishId || 0) - (b?.dishId || 0))
      .map(order => getOrderUniqueKey(order))
    const ordersKey = JSON.stringify(ordersToCompare)
    if (result[ordersKey]) {
      return { ...result, [ordersKey]: [...result[ordersKey], current.orders] } as SortedPaymentsMap
    }
    return { ...result, [ordersKey]: [current.orders] } as SortedPaymentsMap
  }, {} as SortedPaymentsMap)

  /**
   * sorting our entries by orders amount
   * filtering entries by dishes.length > 1
   */
  const sortedPayments = Object.values(paymentsMap)
    .filter(payments => payments.every(orders => orders.length > 1))
    .sort((b, a) => a.length - b.length)

  return sortedPayments.map(paymentOrders => paymentOrders[0])
}

/**
 * @important History must be sorted on the Back-End side.
 * @param {array} history
 * @param {number?} numberToReturn
 * @returns {array<object>} returns @numberToReturn most favorites dishes
 */
export const findFavoritesDishes = (history: IPayment[]): IOrder[] => {
  let results = {} as { [key in string]: number }

  for (let i = 0; i < history.length; i++) {
    const currentPayment = { ...history[i] }
    const ordersToCompare = currentPayment.orders.map(order => {
      const { dishPrice, optionsPrice, takeout, ...orderData } = order
      return orderData
    })
    ordersToCompare.forEach(order => {
      const stringifiedOrdersInfo = JSON.stringify({
        ...order,
        options: order.options?.sort((a, b) => a.id - b.id)
      })
      results[`${stringifiedOrdersInfo}`] = (results[stringifiedOrdersInfo] || 0) + 1
    })
  }
  const entries = Object.entries(results)
  /**
   * sorting our entries by orders amount
   */
  let sortedEntries = entries.sort((b, a) => a[1] - b[1])
  return sortedEntries.map(([order]) => JSON.parse(order))
}
