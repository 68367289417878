import React, { useMemo, useRef } from 'react'
import { useTranslation } from 'src/hooks/useTranslation'
import useShoppingCartStatus from 'src/hooks/ShoppingCart/useShoppingCartStatus'
import { useSelector } from 'src/state/hooks'
import { getTableNum, getVenueStatus, VENUE_STATUSES } from 'src/state/location/selectors'
import useUnpaidOrders from 'src/hooks/useUnpaidOrders'
import { MIN_ORDER_AMOUNT } from 'src/constants'
import { IDish, IOrder, SHOPPING_CART_STATE, NewOrderInput, SelectedOptionType } from 'src/types'
import { ReactComponent as WarnIcon } from 'src/assets/ico-warn-24.svg'
import { ReactComponent as TableIcon } from 'src/assets/ico-table-24-18.svg'
import { ReactComponent as TableIcon2 } from 'src/assets/ico-table-clear-24.svg'
import useOrdersWarnings from 'src/hooks/ShoppingCart/useOrdersWarnings'
import { getOrderUniqueKey } from 'src/utils/orders'
import useCurrentDishes from 'src/hooks/useCurrentDishes'
import { IconType } from 'src/components/RichMenu/components/IconType'
import { useLocation } from 'react-router-dom'
import { THEME_MODE } from 'src/globals/enumerations'
import useThemeStyles from 'src/hooks/useThemeStyles'
import s from '../styles.module.scss'
import ActiveServiceTypeIcon from 'src/components/ShoppingCart/components/ActiveServiceTypeIcon'
import { getAddingAnim } from 'src/state/addingAnim/selectors'
import { getSelectedOptions } from 'src/state/menu'
import { arraysEqual } from 'src/utils'
import ShoppingCartIcon from './ShoppingCartIcon'

const StatusLabel = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const venueStatus = useSelector(getVenueStatus)
  const tableNumber = useSelector(getTableNum)
  const labelWrapperRef = useRef<null | HTMLDivElement>(null)
  const currentDishId: number | null = location.pathname.includes('/dish/')
    ? +location.pathname.split('/dish/')[1]
    : null
  const { dish } = useCurrentDishes(currentDishId) as { dish: IDish }

  const { dishes } = useCurrentDishes()
  const selectedOptions = useSelector(getSelectedOptions) as SelectedOptionType[]
  const { mode } = useThemeStyles()
  const isDarkMode = mode === THEME_MODE.DARK
  const [{ isUnderMinOrderAmount, orders, groupedOrders }] = useUnpaidOrders()
  const [{ status, cookingOrdersCount }] = useShoppingCartStatus()
  const { hasWarnings, isDisabledOptions, notFoundOptions, isDisabledDish } = useOrdersWarnings(orders)
  const venueAvailable = venueStatus === VENUE_STATUSES.OPEN || venueStatus === VENUE_STATUSES.NOT_SELECTED
  const { activeServiceType } = useSelector(store => store.shoppingCard)

  const Icons = useMemo(() => {
    const reversedGrouppedOrders = [...groupedOrders].reverse()
    return reversedGrouppedOrders.map((x, index) => {
      const currDish = dishes?.find(d => d.id === x.dishId)
      if (currDish) {
        const isActiveBadge =
          currDish.id === dish?.id &&
          selectedOptions.length === x.selectedOptions?.length &&
          x.selectedOptions?.every(option =>
            selectedOptions.some(
              selectedOption => selectedOption.id === option.id && selectedOption.value === option.value
            )
          )
        return (
          <ShoppingCartIcon
            currDish={currDish}
            currentDishId={currentDishId}
            isDarkMode={isDarkMode}
            newOrder={x}
            key={getOrderUniqueKey(x)}
          />
        )
      }

      return null
    })
  }, [groupedOrders, isDarkMode, dishes, dish])

  const statusLabel: string = useMemo(() => {
    switch (status) {
      case SHOPPING_CART_STATE.NEW: {
        return <div className={s.dishesIconsWrapper}>{Icons}</div>
      }
      case SHOPPING_CART_STATE.CAN_TAKEOUT:
        return t('shoppingCart.closedContent.readytogo')
      case SHOPPING_CART_STATE.PAID:
        return cookingOrdersCount === 0
          ? t('shoppingCart.closedContent.allpay')
          : t('numDishesCooking', { count: cookingOrdersCount })
      default:
        return t('general.myorder')
    }
  }, [Icons, status, cookingOrdersCount, t])

  const warningLabel: string = useMemo(() => {
    if (!venueAvailable) {
      return t('shoppingCart.closedContent.notwork')
    } else if (isDisabledDish || isDisabledOptions || notFoundOptions) {
      return t('shoppingCart.closedContent.stoplisted')
    } else if (isUnderMinOrderAmount) {
      return t('shoppingCart.minimalOrderWarning.title', { amount: MIN_ORDER_AMOUNT / 100 })
    }
  }, [isUnderMinOrderAmount, isDisabledDish, isDisabledOptions, venueAvailable, notFoundOptions, t])

  return (
    <div className={s.title}>
      <div className={s.tableLayout}>
        {/* {Boolean(tableNumber) ? (
          <>
            <TableIcon width={24} height={24} />
            <div className={s.tableNum}>&nbsp;{tableNumber}</div>
          </>
        ) : (
          <TableIcon2 width={24} height={24} />
        )} */}
        <ActiveServiceTypeIcon
          activeServiceType={activeServiceType}
          tableNumber={tableNumber}
          venueAvailable={venueAvailable}
        />
      </div>
      <div ref={labelWrapperRef} className={s.labelWrapper}>
        {(hasWarnings || !venueAvailable) && warningLabel}
        {!hasWarnings && venueAvailable && statusLabel}
      </div>
      {!venueAvailable && <div className={s.icoSleep} />}
      <span className={s.warnIconWrapper}>
        {hasWarnings && venueAvailable && (
          <WarnIcon fill={isDisabledDish || isDisabledOptions ? 'var(--warningColor)' : 'white'} />
        )}
      </span>
    </div>
  )
}

export default React.memo(StatusLabel)
