import React from 'react'
import { IOrder, NewOrderInput } from 'src/types'
import OrdersListItem from './OrdersListItem'
import { getOrderUniqueKey } from '../../../utils/orders'

type OrdersListProps = {
  orders: (IOrder | NewOrderInput)[]
}

const OrdersList: React.FC<OrdersListProps> = ({ orders }) => {
  if (!orders.length) {
    return null
  }
  return (
    <ul>
      {orders.map(order => {
        return <OrdersListItem key={getOrderUniqueKey(order)} order={order} />
      })}
    </ul>
  )
}

export default OrdersList
