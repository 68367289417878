import {
  SET_NOT_FIRST_TIME,
  SET_USER_LANGUAGE,
  ADD_FAVORITE,
  REMOVE_FAVORITE,
  TOGGLE_CHECK,
  ADD_USERDATA,
  SET_IP,
  SET_RATE,
  TOGGLE_SHOW_BANNER,
  START_USER_CONFIRMATION,
  FINISH_USER_CONFIRMATION,
  ADD_USER_DATA_STARTED,
  ADD_USER_DATA_ERROR,
  TOGGLE_LAST_REVIEW,
  SET_FAVORITES
} from './types'

export const setNotFirstTime = () => ({
  type: SET_NOT_FIRST_TIME
})

export const setUserLanguage = language => ({
  type: SET_USER_LANGUAGE,
  payload: { language }
})

export const setRate = rate => ({
  type: SET_RATE,
  payload: { rate }
})

export const setUserIp = ip => ({
  type: SET_IP,
  payload: { ip }
})

export const addFavorite = favorite => ({
  type: ADD_FAVORITE,
  payload: { favorite }
})

export const setFavorites = favorites => ({
  type: SET_FAVORITES,
  favorites: favorites
})

export const removeFavorite = favorite => ({
  type: REMOVE_FAVORITE,
  payload: { favorite }
})

export const toggleCheck = check => ({
  type: TOGGLE_CHECK,
  payload: { check }
})

export const addUserDataStarted = () => ({
  type: ADD_USER_DATA_STARTED
})

export const addUserDataError = () => ({
  type: ADD_USER_DATA_ERROR
})

export const addUserData = user => ({
  type: ADD_USERDATA,
  payload: { user }
})

export const toggleLastReviewAction = lastReview => {
  return {
    lastReview,
    type: TOGGLE_LAST_REVIEW
  }
}
export const startUserConfirmation = date => ({
  type: START_USER_CONFIRMATION,
  payload: { date }
})

export const finishUserConfirmation = () => ({
  type: FINISH_USER_CONFIRMATION
})

export const toggleShowBannerAction = isShow => ({
  isShow,
  type: TOGGLE_SHOW_BANNER
})
