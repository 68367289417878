import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'src/state/hooks'
import AddingAnimation from './AddingAnimation'
import { getAddingAnim, clearAddingAnim, removeAddingAnim } from '../../state/addingAnim'
import { useParams } from 'react-router'

export interface IAddingAnimationContainerProps {
  iconColor?: string
}

export const AddingAnimationContainer: React.FC<IAddingAnimationContainerProps> = ({ iconColor }) => {
  const dispatch = useDispatch()
  const addingAnim = useSelector(getAddingAnim)
  const params = useParams<{ dishId: string }>()
  /**
   * Если гость закрыл дишкард и вышел не дождавшись анимации, –
   * убивает оставшиеся анимации и добавляет недобавленные блюда
   * Depricated behavior since: 'Ticke# should be here'
   */
  useEffect(() => {
    return () => {
      if (addingAnim.length && !params.dishId) {
        dispatch(clearAddingAnim())
      }
    }
  }, [addingAnim, dispatch])

  return (
    <div style={{ position: 'absolute' }}>
      {addingAnim.map((aam: any) => (
        <AddingAnimation
          key={aam.id}
          iconColor={iconColor}
          dish={aam.dish}
          id={aam.id}
          clic={aam.clicked}
          cb={(id: any) => {
            dispatch(removeAddingAnim({ id }))
          }}
        />
      ))}
    </div>
  )
}
