import React from 'react'
import { motion } from 'framer-motion'
import clsx from 'classnames'
import s from './styles.module.scss'

type TagButtonProps = {
  label: string | null | undefined
  onClick: () => void
  isActive: boolean
  className?: string
}

const variants = {
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000, velocity: -100 }
    }
  },
  hidden: {
    x: 30,
    opacity: 0,
    transition: {
      x: { stiffness: 1000, velocity: -100 }
    }
  }
}

const TagButton: React.FC<TagButtonProps> = function({ label, onClick, isActive, className }) {
  return (
    <motion.div
      onClick={onClick}
      variants={variants}
      initial='hidden'
      animate='visible'
      className={clsx(s.root, isActive && s.active, className)}
    >
      <span className={clsx(s.icon, isActive && s.iconActive)}>
        {isActive && <span className={s.check} />}
        {!isActive && <span className={s.plus}>+</span>}
      </span>
      {label}
    </motion.div>
  )
}

export default TagButton
