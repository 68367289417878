import React, { useEffect, useRef, useState, useCallback } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.scss'
import { useTranslation } from 'src/hooks/useTranslation'
import { getSubcategories } from 'src/state/categories'
import { MenuNavbar } from 'src/components/RichMenu/components/MenuNavbar'
import { ReactComponent as CloseIcon } from 'src/assets/btn-back-24.svg'
import useCurrentCategories from 'src/hooks/useCurrentCategories'
import { setCurrentCategory } from 'src/state/location'
import { postEvent } from 'src/api'
import TabBarItem from 'src/components/TabBarItem'
import { ICategory } from 'src/types'

export interface ITabBarMenuProps {
  handleFullTabBarView: (condition: boolean) => void
  handleIsFullTabBarViewSearch: (condition: boolean) => void
  handleSearchKeyword: (text: string) => void
}

const TabBarMenu: React.FC<ITabBarMenuProps> = ({
  handleFullTabBarView,
  handleSearchKeyword,
  handleIsFullTabBarViewSearch
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isScrolled, setIsScrolled] = useState<boolean>(false)
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const { parentCategories, categories, getActiveCategory } = useCurrentCategories()
  const { currentCategoryId, currentSubcategoryId } = getActiveCategory()
  const [isNavBarClosingAnimation, setIsNavBarClosingAnimation] = useState<boolean>(false)

  useEffect(() => {
    if (isSearch) {
      setIsScrolled(false)
    }
    handleIsFullTabBarViewSearch(isSearch)
  }, [isSearch])

  const handleTabClick = useCallback(
    category => {
      postEvent('richmenu-category', 'searching', { name: t(category.name), id: category.id })
      dispatch(setCurrentCategory(category.id))
    },
    [dispatch, t]
  )

  const handleSubtabClick = useCallback(
    subCategory => {
      postEvent('richmenu-subcategory', 'searching', { name: t(subCategory.name), id: subCategory.id })
      dispatch(setCurrentCategory(subCategory.id))
    },
    [dispatch, t]
  )

  const handleCloseTabBarView = () => {
    setIsNavBarClosingAnimation(true)
    setTimeout(() => {
      handleFullTabBarView(false)
    }, 400)
  }

  return (
    <div
      className={`${styles.wrapper} ${isNavBarClosingAnimation ? styles.wrapperClosing : ''} ${
        isSearch ? styles.wrapperSearch : ''
      }`}
    >
      <div className={`${styles.header} ${isScrolled ? styles.headerScrolled : ''}`}>
        <MenuNavbar
          handleRightClick={() => {}}
          setSearchKeyword={handleSearchKeyword}
          setIsSearch={setIsSearch}
          handleClose={handleCloseTabBarView}
          quitSearch
          quitSearchHeader={
            <>
              <div onClick={handleCloseTabBarView} className={styles.quitIcon}>
                <CloseIcon />
              </div>
              <div className={styles.navHeader}>Всі категорії</div>
            </>
          }
        />
      </div>
      {!isSearch && (
        <div
          onScroll={e => {
            const scrollTopValue = (e.target as any).scrollTop
            if (scrollTopValue !== 0 && !isScrolled) {
              setIsScrolled(true)
            } else if (scrollTopValue === 0 && isScrolled) {
              setIsScrolled(false)
            }
          }}
          className={styles.categoriesWrapper}
        >
          {parentCategories
            .sort((a: ICategory, b: ICategory) => {
              if (b.id === currentCategoryId) {
                return 1
              } else {
                return -1
              }
            })
            .filter(category => categories.some(childCategory => childCategory.parentId === category.id))
            .map(category => {
              return (
                <TabBarItem
                  key={category.id}
                  activeTab={currentCategoryId}
                  activeSubTab={currentSubcategoryId}
                  handleTabClick={handleTabClick}
                  handleSubTabClick={handleSubtabClick}
                  handleFullTabBarView={handleFullTabBarView}
                  data={category}
                />
              )
            })}
        </div>
      )}
    </div>
  )
}

export default React.memo(TabBarMenu)
