import React from 'react'
import { ReactComponent as CloseIcon } from 'src/assets/ico-close-24.svg'
import { useTranslation } from 'src/hooks/useTranslation'
import { Popup } from 'src/components/ui/Popup'
import Background from 'src/components/ui/Background'
import s from './styles.module.scss'

const Work = ({ history }) => {
  const { t } = useTranslation()

  return (
    <Popup
      fullsize
      header={
        <>
          <CloseIcon onClick={() => history.push('/settings')} className={'closeIcon'}></CloseIcon>
          <div>{t('jobbrand.header')}</div>
        </>
      }
    >
      <div className={s.wrapper}>
        {/* <ReactMarkdown transformLinkUri={uriTransformer}>{t('aboutText')}</ReactMarkdown> */}
      </div>

      <Background />
    </Popup>
  )
}

export default Work
