import { useSelector } from 'react-redux'
import { getVenueId } from 'src/state/location'
import { getFavorites } from 'src/state/user'
import { getVenueById } from 'src/state/venues'
import useAppPreferences from 'src/hooks/useAppPreferences'
import useCurrentCategories from 'src/hooks/useCurrentCategories.ts'
import { FAVORITES_CATEGORY_ID } from 'src/constants'
import useCurrentDishes from 'src/hooks/useCurrentDishes.ts'

/**
 * @returns {Array<Dish>} list of dishes to show depending on state
 */
const useActiveDishes = (activeTab, activeSubtab) => {
  let result = []
  const { preferences } = useAppPreferences()
  const currentVenueId = useSelector(getVenueId)
  const currentVenue = useSelector(getVenueById)(parseInt(currentVenueId))
  const { categories } = useCurrentCategories()
  const favorites = useSelector(getFavorites)
  const popularIds = preferences?.popularDishesIds
  const { dishes } = useCurrentDishes()

  if (Array.isArray(dishes) && dishes.length) {
    const getDishesFromIds = getDishesFromIdsClosure(dishes)
    if (activeTab === 0 && !popularIds?.length) {
      /**
       * showing all dishes if there is no popular ids and index of the active tab === 0
       */

      return getDishesFromIds(getDishIdsForDefaultMenu(categories))
    }
    if (activeTab === FAVORITES_CATEGORY_ID) {
      /**
       * @todo добавить функционал получения опций блюд
       */
      const favoriteIds = favorites.map(x => x.id)
      result = getDishesFromIds(favoriteIds)
    } else if (activeTab > 0) {
      result = getDishesFromIds(getDishIdsInCategory(categories)({ activeTab, activeSubtab, popularIds }))
    } else {
      const popdishes = getDishesFromIds(popularIds)
      // console.log(popdishes)
      if (currentVenueId && currentVenue) {
        result = popdishes.filter(x =>
          categories.filter(y => y.menuId === currentVenue.menuId).some(y => y.dishIds.some(z => z === x.id))
        )
        // console.log(result)
      } else {
        result = popdishes
      }
    }
  }

  return result
}

const getDishesFromIdsClosure = dishes => ids => ids.map(id => dishes.find(dish => dish.id === id)).filter(x => x)

/**
 * returns dishIds for category with subcategories if any,
 * returns dishIds for parant if subcategory is virtualMisc
 * return popular if activeTab is wrong
 * returns all dishIds if categoryId is empty
 * @returns {Array<number>}
 * @function
 */
const getDishIdsInCategory = categories => ({ activeTab, activeSubtab, popularIds = [] }) => {
  const virtualMisc = activeSubtab === -1
  const categoryId = activeSubtab && !virtualMisc ? activeSubtab : activeTab

  if (categoryId) {
    const activeCategory = categories.find(x => x.id === categoryId)

    // if acitiveTab does not exit in categories then show popular
    if (!activeCategory) return popularIds

    let dishIdsInSubcatetories = []

    if (activeCategory.parentId === null && !virtualMisc) {
      const activeSubcategories = categories.filter(x => x.parentId === categoryId)
      dishIdsInSubcatetories = activeSubcategories.flatMap(x => x.dishIds)
    }
    return [...activeCategory.dishIds, ...dishIdsInSubcatetories]
  }

  return categories.flatMap(x => x.dishIds)
}

const getDishIdsForDefaultMenu = categories => {
  if (categories.length) {
    return categories.filter(category => category.defaultMenu).flatMap(category => category.dishIds)
  }

  return []
}

export default useActiveDishes
