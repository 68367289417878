import { isEmpty } from 'ramda'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLastConfirmedPayment, setConfirmedPayment } from '../../state/local'
import { getDishById } from '../../state/menu'
import { getOrdersPaid } from '../../state/orders'
import { ConfirmationView } from './ConfirmationView'
import { postEvent } from '../../api'
import { useHistory } from 'react-router'
import { getUserData } from 'src/state/user'

const PaymentConfirmation = () => {
  const dispatch = useDispatch()
  const lastConfirmation = useSelector(getLastConfirmedPayment)
  const paid = useSelector(getOrdersPaid)
  const history = useHistory()
  const user = useSelector(getUserData)
  const [data, setData] = useState(null)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const dishById = useSelector(getDishById)

  const lastPaidDishes = paid
    .filter(el => el.takeoutNum === paid[0].takeoutNum)
    .map(ord => {
      const dish = dishById(ord.dishId)
      if (dish) {
        return {
          id: dish.id,
          name: dish.name?.en,
          category: dish.categoryName?.en,
          price: dish.price / 100
        }
      }
    })

  useEffect(() => {
    // если есть заказы позже lastConfirmation то показать confirmation
    if (!isEmpty(paid)) {
      const paidSorted = paid.sort((b, a) => a.paidAt.localeCompare(b.paidAt))
      const hoursDiff = Math.abs(new Date().getTime() - new Date(paidSorted[0].paidAt).getTime()) / 36e5
      if (hoursDiff <= 1 && lastConfirmation !== paidSorted[0].paidAt) {
        setData(paidSorted[0])
        setShowConfirmation(true)
      }
    }
  }, [paid, lastConfirmation])

  const handleClose = useCallback(() => {
    setShowConfirmation(false)
    dispatch(setConfirmedPayment(data.paidAt))
    postEvent('modal-confirmation-btn_ok', 'payment')
  }, [dispatch, data])

  const handleProvideContacts = useCallback(() => {
    setShowConfirmation(false)
    dispatch(setConfirmedPayment(data.paidAt))
    postEvent('modal-confirmation-btn_profile', 'payment')
    history.push('/profile')
  }, [dispatch, history, data])

  return (
    <>
      {showConfirmation && data && data.takeoutNum && (
        <ConfirmationView
          {...{
            handleClose,
            onProvideContacts: handleProvideContacts,
            data,
            lastPaidDishes,
            isProfileFilled: Boolean(user.phone)
          }}
        />
      )}
      {/* {contactsRequestCheck && <ContactsRequest {...{ setContactsRequestCheck, data }} />} */}
    </>
  )
}

export default PaymentConfirmation
