// хук для переопределения scss переменных, записанных в /public/config.js

import { useSelector } from 'react-redux'
import { getTheme } from 'src/state/themes'

// export const useStyles = context => {
export const useStyles = () => {
  // if (!context) {
  //   context =
  //     window.busytable.application && window.busytable.application.CSSvariables
  //       ? window.busytable.application.CSSvariables
  //       : []
  // }
  const context = useSelector(getTheme)?.variables
  // console.log(context)
  if (!context) return
  Object.keys(context).forEach(x => {
    document.documentElement.style.setProperty(x, context[x])
  })
}
