import React from 'react'
import { useSelector } from 'react-redux'
import { getPageLoaderState } from 'src/state/ui/selectors'
import styles from './styles.module.scss'

export default function PageLoader() {
  const isPageLoading = useSelector(getPageLoaderState)

  if (!isPageLoading) {
    return null
  }

  return (
    <div className={styles.pageLoader}>
      <svg width='42' height='30' viewBox='0 0 786 585' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          className={styles.pageLoaderDashA}
          d='M 359 305.5 L 359 284.5 C 359 249.706 330.794 221.5 296 221.5 L 193.5 221.5 C 158.706 221.5 130.5 193.294 130.5 158.5 L 130.5 71.25 C 130.5 38.527 103.973 12 71.25 12 L 71.25 12 C 38.527 12 12 38.527 12 71.25 L 12 242.5 C 12 277.294 40.206 305.5 75 305.5 L 182 305.5 C 216.794 305.5 245 333.706 245 368.5 L 245 509.5 C 245 544.294 216.794 572.5 182 572.5 L 53.75 572.5 C 30.692 572.5 12 553.808 12 530.75 L 12 530.75 C 12 507.692 30.692 489 53.75 489 L 296 489 C 330.794 489 359 460.794 359 426 L 359 305.5 Z'
        />
        <path
          className={styles.pageLoaderDashB}
          d='M 447.5 305.5 L 447.5 284.5 C 447.5 249.706 475.706 221.5 510.5 221.5 L 565.439 221.5 C 602.529 221.5 638.672 209.782 668.707 188.019 L 748.965 129.865 C 765.319 118.015 775 99.044 775 78.849 L 775 75 C 775 40.206 746.794 12 712 12 L 308 12 C 273.758 12 246 39.758 246 74 L 246 74 C 246 108.242 273.758 136 308 136 L 531.5 136 C 566.294 136 594.5 164.206 594.5 199 L 594.5 509.5 C 594.5 544.294 566.294 572.5 531.5 572.5 L 510.5 572.5 C 475.706 572.5 447.5 544.294 447.5 509.5 L 447.5 305.5 Z'
        />
      </svg>
    </div>
  )
}
