import React from 'react'

export const Wrapper = React.forwardRef(({ zIndex, style, children, ...props }, ref) => {
  React.useEffect(window.scrollFix, [])

  return (
    <div
      ref={ref}
      onScroll={props.onScroll}
      className={['wrapper', props.className].join(' ')}
      style={{
        zIndex: zIndex,
        position: window.innerWidth > 1025 ? 'absolute' : 'fixed',
        ...style
      }}
    >
      {children}
    </div>
  )
})
