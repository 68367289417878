import { ADD_ORDER_CHECKOUT, CLEAR_ORDER_CHECKOUT } from './types'

export const addOrderCheckout = order => ({
  type: ADD_ORDER_CHECKOUT,
  payload: { order }
})

export const clearOrderCheckout = () => ({
  type: CLEAR_ORDER_CHECKOUT
})
