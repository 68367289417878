import React from 'react'
import { useDispatch } from 'react-redux'
import { ReactComponent as TimeIcon } from 'src/assets/ico-time.svg'
import { addComment, addNewOrder } from 'src/state/orders/actions.ts'
import { money } from 'src/utils'
import { useHistory } from 'react-router-dom'
import { cycleActivityView } from 'src/state/ui'
import { useTranslation } from 'src/hooks/useTranslation'
import { setDishAdded } from 'src/state/status/actions'
import { selectVenueBySlug } from 'src/state/venues/selectors'
import { ReactComponent as DeliveryIcon } from 'src/assets/ico-delivery-1.svg'
import { ReactComponent as CrossIcon } from 'src/assets/ico-minicros.svg'
import { ReactComponent as TableIcon } from 'src/assets/ico-table-small.svg'
import { ReactComponent as TogoIcon } from 'src/assets/ico-togo-24.svg'
import { IconType } from 'src/components/RichMenu/components/IconType'
import s from 'src/components/PaymentHistory/styles.module.scss'
import { useSelector } from '../../state/hooks'
import { isDeliveryOrder, isTakeoutOrder } from '../../utils/orders'

const OrderCard = ({ elem, fold, unFold, dishes }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isFold = fold === elem.id
  const history = useHistory()
  const venue = useSelector(selectVenueBySlug(elem.venueSlug || elem.venue?.slug))

  const isDelivery = isDeliveryOrder(elem)
  const isTakeout = isTakeoutOrder(elem)

  const handleFold = () => {
    switch (fold) {
      case elem.id:
        unFold(false)
        break
      default:
        unFold(elem.id)
        break
    }
  }

  const handleOrdering = () => {
    // we need unique tempId.
    // So we increment it for every array element starting from current timestamp
    // If we just use now, then tempIds could collide inside 1 millisecond
    const now = Date.now()
    elem.orders.map((order, index) => {
      const tempId = now + index
      dispatch(setDishAdded())
      dispatch(addComment(elem.comment))
      dispatch(
        addNewOrder({
          ...order,
          tempId,
          id: order.dishId,
          price: order.dishPrice,
          selectedOptions: order.options
        })
      )
    })

    // we close history view and open ShoppingCart so user could see his order
    history.push('/')
    dispatch(cycleActivityView({ id: 1, current: -window.innerHeight + 100 }))
  }

  // TODO: добавить выведение опций блюд
  const dishMap = arr => {
    const combined = arr.reduce((acc, v) => {
      acc[v.dishId] = acc[v.dishId]
        ? { ...acc[v.dishId], count: acc[v.dishId].count + 1 }
        : { count: 1, dishId: v.dishId }
      return acc
    }, {})

    const result = Object.values(combined).map(x => {
      const dish = dishes.find(d => d.id === x.dishId)
      if (dish) {
        return {
          short: (
            <div className={s.smolOrd}>
              <strong style={{ fontWeight: 'bold' }}>{x.count}</strong>
              <CrossIcon fill={'#262626'} style={{ marginRight: '2px' }} />
              <IconType style={{ backgroundColor: 'var(--fontColor)' }} type={dish.icon} size={24} />
            </div>
          ),
          long: (
            <>
              <strong style={{ marginRight: '8px', fontWeight: 'bold' }}>{x.count}x</strong> {t(dish.name)}
            </>
          )
        }
      } else {
        return { short: t('orderCard.loading'), long: t('orderCard.loading') }
      }
    })

    // return result.join(' • ')
    return result
  }

  // console.log(dishMap(elem.orders))
  return (
    <div className={!isFold ? s.card : `${s.card} ${s.visible}`} onClick={() => handleFold()}>
      <div className={s.row}>
        <div className={!isFold ? s.venue : `${s.venue} ${s.vis}`}>{t(venue?.name)}</div>
        <div className={!isFold ? s.tableid : `${s.tableid} ${s.vis}`}>
          {isDelivery && <DeliveryIcon fill={'currentColor'} width={16} height={16} />}
          {(isTakeout || Boolean(elem.tableNum)) && (
            <>
              &nbsp;
              {Boolean(elem.tableNum) && <TableIcon width={16} height={16} fill={'currentColor'} />}&nbsp;
              {isTakeout && <TogoIcon stroke={'currentColor'} width={16} height={16} />}
            </>
          )}
          {Boolean(elem.tableNum) && `${elem.tableNum} /`}&nbsp;{elem.takeoutNum}
        </div>
      </div>
      <div className={s.row}>
        <div className={[s.date].join(' ')}>
          {elem.timedTakeout ? (
            <>
              <TimeIcon style={{ marginRight: '8px' }} width={13} height={13} />
              {formatDate(elem.timedTakeout, t('general.shortMonth'))}
            </>
          ) : (
            formatDate(elem.paid, t('general.shortMonth'))
          )}
        </div>
        <div className={[s.price, s.flex, s.jcStart].join(' ')}>
          <strong>{money(elem.amountBeforeDiscount)}</strong>
        </div>
      </div>
      <div className={!isFold ? `${s.details} ${s.flex}` : `${s.details} ${s.wrap}`}>
        {dishMap(elem.orders).map((ord, index, arr) => (
          <div key={index}>
            {ord.id}
            {!isFold ? ord.short : ord.long}{' '}
            {/* {!isFold && index === 0 && arr.length > 1 ? t('orderCard.dishesCount', { count: arr.length - 1 }) : ''} */}
          </div>
        ))}
      </div>
      {!isFold ? '' : elem.comment && elem.comment !== '' ? <div className={s.commBox}>{elem.comment}</div> : ''}
      {!isFold ? '' : <div className={s.phr}></div>}
      {!isFold ? (
        ''
      ) : (
        <div onClick={handleOrdering} className={s.sompeText}>
          {t('orderCard.repeat')}
        </div>
      )}
    </div>
  )
}

const formatDate = (date, translation) => {
  const month = translation.split(',')

  const local = new Date(date)
  const mins = local.getMinutes()
  const hours = local.getHours()

  return `${local.getDate()} ${month[local.getMonth()]}, ${hours < 10 ? '0' + hours : hours}:${
    mins < 10 ? '0' + mins : mins
  }`
}

export default OrderCard
