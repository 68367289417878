import axios from 'axios'

import { config } from 'src/constants'

export function getActiveTheme() {
  return axios({
    url: `${config.API_URL3}/${window.busytable.brandSlug}/themes`
  })
    .then(resp => {
      if (resp?.data) {
        return resp?.data
      }
    })
    .catch(e => {
      if (e.response.data) {
        throw Error(e.response.data)
      } else {
        throw Error(e)
      }
    })
}
