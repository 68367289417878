import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { motion, useMotionValue } from 'framer-motion'
import { getActivityViewCurrent } from '../../state/ui/selectors'
import { cycleActivityView } from '../../state/ui/actions'
import { clearAddingAnim } from '../../state/addingAnim'
import { SHOPPING_CART_INIT_POSITION_Y } from 'src/constants'
import  DraggerIndicator  from 'src/components/DraggerIndicator'
import useShoppingCartStatus from 'src/hooks/ShoppingCart/useShoppingCartStatus'

const ActivityView = ({
  classNames,
  elevation = 4,
  style = {},
  initPositionY,
  openPositionY,
  closeHandler = () => {},
  shaderTapHandler = () => {},
  shader = true,
  closeState = false,
  closePositionY = window.innerHeight,
  tappable = true,
  ...props
}) => {
  const dispatch = useDispatch()
  const y = useMotionValue(window.outerHeight)
  const [dragStatus, setDragStatus] = useState(false)
  const [tapStatus, setTapStatus] = useState(false)
  const [draggerStatus, setDraggerStatus] = useState(false)
  const { current } = useSelector(getActivityViewCurrent)(props.id)
  const contentRef = useRef()
  const [{ status }] = useShoppingCartStatus()
  const contentHeight = contentRef.current && contentRef.current.getBoundingClientRect().height

  const trashold = 50

  /* useEffect(() => {
    dispatch(cycleActivityView({ id: props.id, current: openPositionY }))
  }, [])
 */
  useEffect(() => {
    console.log({ current })
  }, [current]) // eslint-disable-line

  return (
    <>
      <motion.div
        className={['activity', classNames].join(' ')}
        style={{ y, zIndex: elevation, ...style }}
        animate={{
          y: current,
          transition: { ease: 'easeOut', duration: 0.25 }
        }}
        drag='y'
        dragConstraints={{
          top: -contentHeight,
          bottom: SHOPPING_CART_INIT_POSITION_Y
        }}
        dragElastic={0.2}
        dragMomentum={false}
        // onTap={toggleTapHandler}
        onDragStart={(event, info) => {
          if (!dragStatus && !tapStatus) {
            setDragStatus(true)
          } else {
            event.preventDefault()
          }
        }}
        onDrag={(event, info) => {
          if (info.offset.y > trashold && !draggerStatus) {
            setDraggerStatus(true)
          } else if (info.offset.y < trashold && draggerStatus) {
            setDraggerStatus(false)
          }
        }}
        onDragEnd={(offset, info) => {
          setDraggerStatus(false)
          setTimeout(() => setDragStatus(false), 250)
          // user dragging up
          if (info.offset.y < 0) {
            switch (current) {
              case openPositionY:
                // console.log('case Up Open')
                if (info.offset.y > -trashold) {
                  dispatch(cycleActivityView({ id: props.id, current: openPositionY }))
                  dispatch(clearAddingAnim())
                }
                break
              case initPositionY:
                // console.log('case Up Init')
                if (info.offset.y < -trashold) {
                  dispatch(cycleActivityView({ id: props.id, current: openPositionY }))
                  dispatch(clearAddingAnim())
                } else dispatch(cycleActivityView({ id: props.id, current: initPositionY }))
                break
              default:
                dispatch(cycleActivityView({ id: props.id, current: current }))
            }
            // user dragging down
          } else if (info.offset.y > 0) {
            switch (current) {
              case openPositionY:
                if (info.offset.y < trashold) {
                  // console.log('case Down Open 2')
                  dispatch(cycleActivityView({ id: props.id, current: openPositionY }))
                  dispatch(clearAddingAnim())
                } else if (y.current > openPositionY) {
                  dispatch(cycleActivityView({ id: props.id, current: initPositionY }))
                  if (closeHandler) {
                    closeHandler()
                  }
                }
                break
              case initPositionY:
                // console.log('case Down Init')
                closeState
                  ? dispatch(cycleActivityView({ id: props.id, current: closePositionY }))
                  : dispatch(cycleActivityView({ id: props.id, current: current }))
                setTimeout(() => closeHandler(), 200)
                break
              default:
                dispatch(cycleActivityView({ id: props.id, current: current }))
            }
          } else {
            dispatch(cycleActivityView({ id: props.id, current: current }))
          }
        }}
      >
        <DraggerIndicator inverted={current === initPositionY || (status === 'empty' && current !== initPositionY)} isOnBorder={draggerStatus} style={{ width: '64px', top: '8px' }} />
        <div
          className={'tapZone'}
          onClick={e => {
            e.stopPropagation()
            if (tappable && !tapStatus && !dragStatus) {
              setTapStatus(true)
              dispatch(cycleActivityView({ id: props.id, current: openPositionY }))
              dispatch(clearAddingAnim())
              setTimeout(() => {
                setTapStatus(false)
              }, 150)
            }
          }}
        />
        <div className='just_content_ref' ref={contentRef}>
          {props.children}
        </div>
      </motion.div>
      {shader && current === openPositionY && (
        <motion.div
          data-cy='modalShader'
          className={'modalShader'}
          onClick={e => {
            e.stopPropagation()
            if (!dragStatus) {
              shaderTapHandler()
            }
          }}
          style={{ zIndex: elevation - 1 }}
        />
      )}
    </>
  )
}
export default ActivityView
