import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'src/state/hooks'
import { getOptionsets } from 'src/state/menu'
import { IOrder } from 'src/types'
import { getOrdersPaid } from '../state/orders'
import { GroupedOrders, sameOrdersReducer } from '../utils/orders'
import useCurrentDishes from './useCurrentDishes'

type GetOrderPriceFunc = (order: IOrder) => number

type PaidOrdersState = {
  totalPrice: number
  groupedOrders: IOrder[]
  orders: IOrder[]
  lastPaidOrders: IOrder[]
  isEmpty: boolean
}
type PaidOrdersApi = {
  getOrderPrice: GetOrderPriceFunc
}
type PaidOrdersReturnValue = [PaidOrdersState, PaidOrdersApi]

export default function usePaidOrders(orders?: IOrder[]): PaidOrdersReturnValue {
  const optionSets = useSelector(getOptionsets)
  const paidOrders = useSelector(getOrdersPaid)

  const [currentOrders, setCurrentOrders] = useState<IOrder[]>([])

  const { findDish } = useCurrentDishes()

  useEffect(() => {
    setCurrentOrders(orders || paidOrders || [])
  }, [orders, paidOrders])

  const getOrderPrice: GetOrderPriceFunc = useMemo(
    () => order => {
      const dishPrice = order.dishPrice || findDish?.(order.dishId)?.price
      let result = dishPrice || 0

      const orderOptions = order.selectedOptions || order.options

      orderOptions?.forEach(option => {
        const currentOptionSet = optionSets.find(set => set.id === option.id)
        if (currentOptionSet) {
          const optionPrice = currentOptionSet.options?.find(curr => curr.id === option.value)?.price || 0
          result += optionPrice
        }
      })

      return result
    },
    [optionSets, findDish]
  )

  const totalPrice = useMemo(
    () => currentOrders.reduce((sum, order) => sum + getOrderPrice(order), 0) || 0,
    [currentOrders, getOrderPrice]
  )

  const groupedOrders: IOrder[] = useMemo(
    () => Object.values(currentOrders.reduce(sameOrdersReducer, {} as GroupedOrders) || {}) as IOrder[],
    [currentOrders]
  )

  const lastPaidOrders: IOrder[] = useMemo(() => {
    const sortedByPaidAt = currentOrders.sort(
      (b, a) => new Date(a.paidAt || 0).getTime() - new Date(b.paidAt || 0).getTime()
    )
    return sortedByPaidAt.filter(order => order.takeoutNum === sortedByPaidAt[0].takeoutNum)
  }, [currentOrders])

  const state = useMemo(
    () => ({
      totalPrice,
      groupedOrders,
      orders: currentOrders,
      lastPaidOrders,
      isEmpty: currentOrders.length === 0
    }),
    [totalPrice, groupedOrders, currentOrders, lastPaidOrders]
  )

  const api = useMemo(
    () => ({
      getOrderPrice
    }),
    [getOrderPrice]
  )

  return [state, api]
}
