import React, { useEffect, useRef, useState } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useSelector } from 'react-redux'
import styles from './styles.module.scss'
import { useTranslation } from 'src/hooks/useTranslation'
import { getSubcategories } from 'src/state/categories'
import TabBarCategoryItem from './TabBarCategoryItem'
import { ICategory } from 'src/types'

export interface ITabBarItemProps {
  data: ICategory
  activeTab: number | null
  activeSubTab: number | null
  handleTabClick: (category: Partial<ICategory>) => void
  handleSubTabClick: (category: Partial<ICategory>) => void
  handleFullTabBarView: (condition: boolean) => void
}

const TabBarItem: React.FC<ITabBarItemProps> = ({
  data,
  activeTab,
  activeSubTab,
  handleTabClick,
  handleSubTabClick,
  handleFullTabBarView
}) => {
  const { t } = useTranslation()
  const subcats = useSelector(getSubcategories)({ parentId: data.id })

  const handleCategoryClick = (categoryId: number) => {
    handleTabClick({ id: data.id, name: data.name })
    handleSubTabClick({ id: categoryId, name: 'all' as any })
    handleFullTabBarView(false)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabName}>
        <span>{t(data.name)}</span>
      </div>
      <div className={styles.dishes}>
        {subcats.map((subCategory: ICategory) => {
          return (
            <TabBarCategoryItem
              isActive={activeSubTab === subCategory.id}
              handleCategoryClick={handleCategoryClick}
              key={subCategory.id}
              data={subCategory}
            />
          )
        })}
      </div>
    </div>
  )
}

export default React.memo(TabBarItem)
