import { getServicesStoplistStarted, getServicesStoplistError, getServicesStoplistSuccess } from './actions'
import { getServicesStoplist } from 'src/network/get/services-stoplist'

export const getServicesStoplistThunk = (venueSlug, fromDate, type, toDate) => async dispatch => {
  dispatch(getServicesStoplistStarted())
  try {
    const servicesStoplist = await getServicesStoplist(venueSlug, fromDate, type, toDate)
    dispatch(getServicesStoplistSuccess(servicesStoplist))
  } catch (error) {
    dispatch(getServicesStoplistError(error))
  }
}
