/**
 * Doing post user event to server.
 * @constant defaultVenue
 */
const strings = {
  hlbn: {
    defaultVenue: 'ХЛБНЙ',
    timedTakeoutText:
      'Доставка будет осуществлена на такси, по указанному вами адресу. Стоимость и расчетное время доставки будет отправлено вам на указанный телефон. Расчет за доставку с водителем по факту. Доставка не включена в стоимости заказа.',
    takeoutText:
      'В связи с заразой толпой не валить, намордник не снимать. В сообщении указать издали различимые признаки.',
    paymentSectionDiv1Strong: 'Мы отправим заказ к вам на такси по указанному адресу 🙌🏻',
    paymentSectionDiv1Text: ' Оставьте, пожалуйста, свой номер телефона — мы напишем о времени доставки и стоимости.',
    paymentSectionDiv2Text:
      'Такси нужно будет оплатить по факту прибытия водителя к вам, так как доставка не включена в стоимость заказа 🙏🏻 Водитель не поднимется на ваш этаж — так что не забывайте, пожалуйста, маску, выходя за заказом на улицу ❤️',
    paymentSectionDiv3Text: 'Вы можете забрать заказ сами на ',
    paymentSectionDiv3Strong: 'NO ADDRESS',
    paymentSectionDiv3Final: ' 😌.',
    paymentSectionDiv4Text: 'Мы очень хотим, чтобы наши встречи были максимально безопасными, поэтому',
    paymentSectionDiv4Strong: ' просим заходить внутрь только в маске',
    paymentSectionDiv4Final: '.',
    paymentSectionDiv5Strong: 'Вы можете воспользоваться кнопкой «Я на месте»',
    paymentSectionDiv5Text: ' и ваш заказ вынесут на улицу или прямо к машине.',
    aboutP1:
      'Мы подумали - никому не нравится стоять в очереди, давайте сделаем такое приложение, чтобы можно было заказать что угодно прямо за столик. И чтобы его не нужно было искать в аппсторе и скачивать, чтобы оно запускалось сразу со стола. И чтобы не требовало регистрации. И чтобы можно было сохранить карту, и платить в 1 клик. И чтобы можно было добавить любимый круассан и латте в избранное, и тратить на заказ не больше 10 секунд. И чтобы....',
    aboutP2Link: 'Пишите нам',
    aboutP2Text:
      ', мы будем рады услышать, что мы можем улучшить или добавить! Мы хотим, чтобы у нас были самые вкусные круассаны, и самое лучшее ',
    aboutP2Text2: 'приложение',
    aboutP2Final: '.',
    contactUsPhone: 'NO NUMBER',
    contactUsTelegram: 'falcn'
  },
  holy: {
    defaultVenue: 'HOLY BURGER',
    timedTakeoutText:
      'Доставка будет осуществлена на такси, по указанному вами адресу. Стоимость и расчетное время доставки будет отправлено вам на указанный телефон. Расчет за доставку с водителем по факту. Доставка не включена в стоимости заказа.',
    takeoutText:
      'В связи с заразой толпой не валить, намордник не снимать. В сообщении указать издали различимые признаки.',
    paymentSectionDiv1Strong: 'Мы отправим заказ к вам на такси по указанному адресу 🙌🏻',
    paymentSectionDiv1Text: ' Оставьте, пожалуйста, свой номер телефона — мы напишем о времени доставки и стоимости.',
    paymentSectionDiv2Text:
      'Такси нужно будет оплатить по факту прибытия водителя к вам, так как доставка не включена в стоимость заказа 🙏🏻 Водитель не поднимется на ваш этаж — так что не забывайте, пожалуйста, маску, выходя за заказом на улицу ❤️',
    paymentSectionDiv3Text: 'Вы можете забрать заказ сами на ',
    paymentSectionDiv3Strong: 'вулиця Шота Руставелі, 16, Київ, 02000    ',
    paymentSectionDiv3Final: ' 😌.',
    paymentSectionDiv4Text: 'Мы очень хотим, чтобы наши встречи были максимально безопасными, поэтому',
    paymentSectionDiv4Strong: ' просим заходить внутрь только в маске',
    paymentSectionDiv4Final: '.',
    paymentSectionDiv5Strong: 'Вы можете воспользоваться кнопкой «Я на месте»',
    paymentSectionDiv5Text: ' и ваш заказ вынесут на улицу или прямо к машине.',
    aboutP1:
      'Мы подумали - никому не нравится стоять в очереди, давайте сделаем такое приложение, чтобы можно было заказать что угодно прямо за столик. И чтобы его не нужно было искать в аппсторе и скачивать, чтобы оно запускалось сразу со стола. И чтобы не требовало регистрации. И чтобы можно было сохранить карту, и платить в 1 клик. И чтобы можно было добавить любимый круассан и латте в избранное, и тратить на заказ не больше 10 секунд. И чтобы....',
    aboutP2Link: 'Пишите нам',
    aboutP2Text:
      ', мы будем рады услышать, что мы можем улучшить или добавить! Мы хотим, чтобы у нас были самые вкусные круассаны, и самое лучшее ',
    aboutP2Text2: 'приложение',
    aboutP2Final: '.',
    contactUsPhone: 'NO NUMBER',
    contactUsTelegram: 'falcn'
  },
  holyraw: {
    defaultVenue: 'HOLYBURGER special',
    timedTakeoutText:
      'Доставка будет осуществлена на такси, по указанному вами адресу. Стоимость и расчетное время доставки будет отправлено вам на указанный телефон. Расчет за доставку с водителем по факту. Доставка не включена в стоимости заказа.',
    takeoutText:
      'В связи с заразой толпой не валить, намордник не снимать. В сообщении указать издали различимые признаки.',
    paymentSectionDiv1Strong: 'Мы доставим заказ по указанному адресу 🙌🏻 ',
    paymentSectionDiv1Text: 'Оставьте, пожалуйста, ваш номер телефона — мы перезвоним перед отправкой.',
    paymentSectionDiv2Text:
      'Водитель не поднимется к вам на этаж: поэтому не забывайте, пожалуйста, маску, выходя на улицу ❤️',
    paymentSectionDiv3Text: 'Вы можете забрать заказ на ',
    paymentSectionDiv3Strong: 'Шота Руставели 16а',
    paymentSectionDiv3Final: ' 😌',
    paymentSectionDiv4Text: 'Мы очень хотим, чтобы наши встречи были максимально безопасными, поэтому очень',
    paymentSectionDiv4Strong: ' просим заходить внутрь только в маске',
    paymentSectionDiv4Final: '.',
    paymentSectionDiv5Strong: 'Вы можете воспользоваться кнопкой «Я на месте» —',
    paymentSectionDiv5Text: ' и ваш заказ вынесут на улицу или прямо к машине.',
    aboutP1:
      'Мы подумали - никому не нравится стоять в очереди, давайте сделаем такое приложение, чтобы можно было заказать что угодно прямо за столик. И чтобы его не нужно было искать в аппсторе и скачивать, чтобы оно запускалось сразу со стола. И чтобы не требовало регистрации. И чтобы можно было сохранить карту, и платить в 1 клик. И чтобы можно было добавить любимый круассан и латте в избранное, и тратить на заказ не больше 10 секунд. И чтобы....',
    aboutP2Link: 'Пишите нам',
    aboutP2Text:
      ', мы будем рады услышать, что мы можем улучшить или добавить! Мы хотим, чтобы у нас были самые вкусные круассаны, и самое лучшее ',
    aboutP2Text2: 'приложение',
    aboutP2Final: '.',
    contactUsPhone: 'NO NUMBER',
    contactUsTelegram: 'falcn'
  },
  yt: {
    defaultVenue: 'YELLOW TRUCK',
    timedTakeoutText:
      'Доставка будет осуществлена на такси, по указанному вами адресу. Стоимость и расчетное время доставки будет отправлено вам на указанный телефон. Расчет за доставку с водителем по факту. Доставка не включена в стоимости заказа.',
    takeoutText:
      'В связи с заразой толпой не валить, намордник не снимать. В сообщении указать издали различимые признаки.',
    paymentSectionDiv1Strong: 'Мы отправим заказ к вам на такси по указанному адресу 🙌🏻',
    paymentSectionDiv1Text: ' Оставьте, пожалуйста, свой номер телефона — мы напишем о времени доставки и стоимости.',
    paymentSectionDiv2Text:
      'Такси нужно будет оплатить по факту прибытия водителя к вам, так как доставка не включена в стоимость заказа 🙏🏻 Водитель не поднимется на ваш этаж — так что не забывайте, пожалуйста, маску, выходя за заказом на улицу ❤️',
    paymentSectionDiv3Text: 'Вы можете забрать заказ сами на ',
    paymentSectionDiv3Strong: 'точке выдачи',
    paymentSectionDiv3Final: ' 😌.',
    paymentSectionDiv4Text: '',
    paymentSectionDiv4Strong: '',
    paymentSectionDiv4Final: '',
    paymentSectionDiv5Strong: '',
    paymentSectionDiv5Text: '',
    aboutP1:
      'Мы подумали - никому не нравится стоять в очереди, давайте сделаем такое приложение, чтобы можно было заказать что угодно прямо за столик. И чтобы его не нужно было искать в аппсторе и скачивать, чтобы оно запускалось сразу со стола. И чтобы не требовало регистрации. И чтобы можно было сохранить карту, и платить в 1 клик. И чтобы можно было добавить любимый круассан и латте в избранное, и тратить на заказ не больше 10 секунд. И чтобы....',
    aboutP2Link: 'Пишите нам',
    aboutP2Text:
      ', мы будем рады услышать, что мы можем улучшить или добавить! Мы хотим, чтобы у нас были самые вкусные круассаны, и самое лучшее ',
    aboutP2Text2: 'приложение',
    aboutP2Final: '.',
    contactUsPhone: 'NO NUMBER',
    contactUsTelegram: 'falcn'
  },
  'no brand': {
    defaultVenue: 'NO BRAND',
    timedTakeoutText: 'NO BRAND TIMED TAKEOUT TEXT',
    takeoutText: 'NO BRAND TAKEOUT TEXT',
    paymentSectionDiv1Strong: 'Мы отправим заказ к вам на такси по указанному адресу 🙌🏻',
    paymentSectionDiv1Text: ' Оставьте, пожалуйста, свой номер телефона — мы напишем о времени доставки и стоимости.',
    paymentSectionDiv2Text:
      'Такси нужно будет оплатить по факту прибытия водителя к вам, так как доставка не включена в стоимость заказа 🙏🏻 Водитель не поднимется на ваш этаж — так что не забывайте, пожалуйста, маску, выходя за заказом на улицу ❤️',
    paymentSectionDiv3Text: 'Вы можете забрать заказ сами на ',
    paymentSectionDiv3Strong: 'Луговая ул., 12, Київ, 02000',
    paymentSectionDiv3Final: ' 😌.',
    paymentSectionDiv4Text: 'Мы очень хотим, чтобы наши встречи были максимально безопасными, поэтому',
    paymentSectionDiv4Strong: ' просим заходить внутрь только в маске',
    paymentSectionDiv4Final: '.',
    paymentSectionDiv5Strong: 'Вы можете воспользоваться кнопкой «Я на месте»',
    paymentSectionDiv5Text: ' и ваш заказ вынесут на улицу или прямо к машине.',
    aboutP1:
      'Мы подумали - никому не нравится стоять в очереди, давайте сделаем такое приложение, чтобы можно было заказать что угодно прямо за столик. И чтобы его не нужно было искать в аппсторе и скачивать, чтобы оно запускалось сразу со стола. И чтобы не требовало регистрации. И чтобы можно было сохранить карту, и платить в 1 клик. И чтобы можно было добавить любимый круассан и латте в избранное, и тратить на заказ не больше 10 секунд. И чтобы....',
    aboutP2Link: 'Пишите нам',
    aboutP2Text:
      ', мы будем рады услышать, что мы можем улучшить или добавить! Мы хотим, чтобы у нас были самые вкусные круассаны, и самое лучшее ',
    aboutP2Text2: 'приложение',
    aboutP2Final: '.',
    contactUsPhone: 'NO NUMBER',
    contactUsTelegram: 'falcn'
  }
}

const handler = {
  get: function(target, name) {
    return target.hasOwnProperty(name) ? target[name] : target['no brand']
  }
}

const p = new Proxy(strings, handler)

export default p[window.busytable && window.busytable.brandSlug ? window.busytable.brandSlug : 'no brand']
