import React from 'react'
import { Marker } from 'src/components/ui/Marker'
import { useTranslation } from 'src/hooks/useTranslation'
import { getDistanceFromLatLonInKm } from 'src/utils'
import useFontColorReadability from 'src/hooks/useFontColorReadability'
import s from '../styles.module.scss'

const List = ({
  setSelectedVenue,
  selectedVenue,
  venues = [],
  icoFunction,
  workingHoursValidator,
  day,
  userLocation = { lat: 0, lng: 0 }
}) => {
  const { t } = useTranslation()

  const venuesNotFar = venues
    .filter(x => x && x.lat && x.lng)
    .map(v => {
      return {
        ...v,
        distance: getDistanceFromLatLonInKm(userLocation.lat, userLocation.lng, parseFloat(v.lat), parseFloat(v.lng))
      }
    })
    .filter(v => v.distance <= 2)
    .sort((a, b) => a.distance - b.distance)

  const venuesFar = venues
    .filter(x => x && x.lat && x.lng)
    .map(v => {
      return {
        ...v,
        distance: getDistanceFromLatLonInKm(userLocation.lat, userLocation.lng, parseFloat(v.lat), parseFloat(v.lng))
      }
    })
    .filter(v => v.distance > 2)
    .sort((a, b) => a.distance - b.distance)

  const isListItemReadable = useFontColorReadability('#ffffff')

  const getDistance = venue => {
    if (userLocation?.lat && userLocation?.lng) {
      const distance = (
        getDistanceFromLatLonInKm(userLocation.lat, userLocation.lng, parseFloat(venue.lat), parseFloat(venue.lng)) *
        1000
      ).toFixed(0)

      return distance > 100000 ? '-' : `${distance}м`
    }

    return '-'
  }

  const renderVenue = venue => {
    const mark = { ...icoFunction(venue, selectedVenue) }
    return (
      <div
        onClick={() => {
          setSelectedVenue(venue)
        }}
        className={[s.listCard, selectedVenue && selectedVenue.id === venue.id ? s.listCardSelected : ''].join(' ')}
        key={venue.id}
        style={{
          '--fontColor': isListItemReadable ? null : '#000000'
        }}
      >
        <div className={s.listLeft}>
          <Marker {...mark} size={'small'} />
          <div className={s.listMeterFont}>{getDistance(venue)}</div>
        </div>
        <div>
          <div className={s.listCardHeaderFont} data-cy={`venue-${venue.slug}`}>{t(venue.name)}</div>
          <div className={s.listCardBodyFont}>
            {workingHoursValidator(venue)[0] && !venue.disabled
              ? `${venue.workingHours[day][0]} – ${venue.workingHours[day][1]}`
              : t('map.disabledLabel')}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={[s.listStart, 'content'].join(' ')}>
      {userLocation ? (
        <>
          {venuesNotFar.length > 0 ? (
            <>
              <div className={s.listHeaderNotFar}>{t('map.notfar')}</div>
              <div className={s.listView}>{venuesNotFar.map(renderVenue)}</div>
            </>
          ) : null}
          {venuesFar.length > 0 ? (
            <>
              <div className={s.listHeaderNotFar}>{venuesNotFar.length > 0 && t('map.tofar')}</div>
              <div style={{ marginBottom: '100px' }} className={s.listView}>
                {venuesFar.map(renderVenue)}
              </div>
            </>
          ) : null}
        </>
      ) : (
        venues.map(renderVenue)
      )}
    </div>
  )
}

export default List
