import { getActiveTheme } from 'src/network/get/theme'
import { getActiveThemeStarted, getActiveThemeError, getActiveThemeSuccess } from './actions'

export const getActiveThemeThunk = () => async dispatch => {
  dispatch(getActiveThemeStarted())
  try {
    const theme = window.busytable.themes || (await getActiveTheme())
    dispatch(getActiveThemeSuccess(theme))
  } catch (error) {
    dispatch(getActiveThemeError(error))
  }
}
