import React from 'react'
import { ReactComponent as WarnIcon } from 'src/assets/ico-warn-24.svg'
import { ReactComponent as FavIcon } from 'src/assets/ico-stroke.svg'
import { useTranslation } from 'src/hooks/useTranslation'
import useCurrentDishes from 'src/hooks/useCurrentDishes.ts'
import useFavoritesApi from 'src/hooks/useFavoritesApi.ts'
import useOrdersWarnings from 'src/hooks/ShoppingCart/useOrdersWarnings.ts'
import OrderListItemOptions from 'src/components/ShoppingCart/components/OrderListItemOptions.tsx'
import { money } from '../../utils'
import s from './styles.module.scss'

export const CheckItem = ({ order }) => {
  const { t } = useTranslation()
  const { dish } = useCurrentDishes(order.dishId)
  const { isDishInFavorites } = useFavoritesApi(order.dishId, order.selectedOptions)
  const { isDisabledDish, isDisabledOptions } = useOrdersWarnings([order])

  const options = order.selectedOptions
  const totalPrice = parseInt(order && order.totalPrice ? order.totalPrice : 0)

  return (
    <div className={s.row}>
      <div className={[s.info, isDisabledOptions || isDisabledDish ? s.drawerWarn : ''].join(' ')}>
        <div className={s.uncontextedPrice}>
          <span className={s.combieText}>{order.count}</span>
          <span className={s.combieText}>x</span>
        </div>
        <div
          className={[
            s.product,
            order.status && order.status.paid
              ? s.defProduct
              : order.status && order.status.paid
              ? s.paidProduct
              : s.defProduct,
            order.status && order.status.paid ? s.doneProduct : s.defProduct,
            isDisabledDish || isDisabledOptions ? s.warnOrder : '',
            s.uncontextBlack
          ].join(' ')}
          style={{ position: 'relative' }}
        >
          {isDishInFavorites && <FavIcon className={s.favIcon} />}
          <div className={s.dishLabel}>{dish && dish.name ? t(dish.name) : t('listSection.dishNotFound')}</div>
          <div className={s.optionsWrapper}>
            <OrderListItemOptions
              options={options}
              recipeId={dish?.recipeId}
              renderLabel={label => <span className={s.optionLabel}>{label}&nbsp;</span>}
            />
          </div>
        </div>
      </div>
      <div className={s.price}>
        {isDisabledOptions || isDisabledDish ? <WarnIcon fill='var(--warningColor)' /> : `${money(totalPrice)} ₴`}
      </div>
    </div>
  )
}
