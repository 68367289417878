import React, { useEffect, useRef } from 'react'
import { MediaItemType } from 'src/types'
import { ANIMATED_MEDIA_REGEX } from 'src/constants'
import styles from './styles.module.scss'

type MediaItemProps = {
  data: MediaItemType
  style?: React.CSSProperties
  isActive: boolean
}

const MediaItem: React.FC<MediaItemProps> = ({ data, style, isActive }) => {
  const mediaRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (mediaRef.current) {
      const video = mediaRef.current
      if (!isActive) {
        video.pause()
      } else if (isActive) {
        video.play()
      }
    }
  }, [isActive])

  const renderMediaType = () => {
    if (ANIMATED_MEDIA_REGEX.test(data.path)) {
      return <video ref={mediaRef} src={data.path} muted loop playsInline controls={false} />
    }

    return <img src={`${data.path}`} alt='' />
  }

  return (
    <div style={style ? { ...style } : {}} className={styles.carouselItemWrapper}>
      {renderMediaType()}
    </div>
  )
}

export default MediaItem
