import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { postEvent } from 'src/api'
import { isAfterTomorrow } from 'src/utils/isAfterTomorrow'
import { Popup } from 'src/components/ui/Popup.tsx'
import { ReactComponent as DeliveryIcon } from 'src/assets/ico-delivery-24.svg'
import { ReactComponent as TableIcon } from 'src/assets/ico-table-24.svg'
import { ReactComponent as TogoIcon } from 'src/assets/ico-togo-24-new.svg'
import { ReactComponent as TimedTakeout } from 'src/assets/ico-deffered_togo-24.svg'
import { useTranslation } from 'src/hooks/useTranslation'
import { getLang } from 'src/state/local'
import { isTomorrow, timeRange } from '../../utils'
import { isDeliveryOrder, isTakeoutOrDeliveryOrder, isTakeoutOrder } from '../../utils/orders'
import s from './styles.module.scss'
import { useHistory } from 'react-router-dom'
import { Routes } from '../../globals/enumerations'

// появляется после оплаты
export const ConfirmationView = ({ handleClose, onProvideContacts, data, lastPaidDishes, isProfileFilled }) => {
  const history = useHistory()
  const { t } = useTranslation()
  console.log(`ConfirmationView`)
  // Очистить данные оплаченного ШК
  useEffect(() => {
    postEvent('confirmation-modal', 'payment', {
      type: data.serviceType,
      value: data.price,
      id: data.tempId,
      lastPaidDishes
    })
  }, [])

  const handleLeaveTips = useCallback(() => {
    handleClose()
    history.push(`${Routes.tips}/${data?.paymentId}`)
  }, [history, data, handleClose])

  return (
    <Popup>
      <div style={{ display: 'flex', marginTop: '32px', justifyContent: 'center' }}>
        {isDeliveryOrder(data) && <DeliveryIcon className={s.confirmationIcon} width='56' height='56' />}
        {isTakeoutOrder(data) &&
          (data.timedTakeout ? (
            <TimedTakeout className={s.confirmationIcon} width='56' height='56' />
          ) : (
            <TogoIcon className={s.confirmationIcon} width='56' height='56' />
          ))}
        {data.tableNum ? (
          <>
            <TableIcon
              className={[s.confirmationIcon, isTakeoutOrder(data) ? 'mleft8' : ''].join(' ')}
              width='56'
              height='56'
            />
            <div
              className='mleft8'
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
            >
              <div className={[s.tableFont1].join(' ')}>{data.tableNum}</div>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
      <div className={s.confirmationBoxHeader}>{t('confirmationView.payment')}</div>
      <div style={{ marginTop: '24px' }} className={s.tableFont2}>
        {t('confirmationView.orderNum')}
      </div>
      <div style={{ display: 'flex', alignItems: 'flex-start' }} className={s.tableFont3}>
        <span style={{ marginTop: '24px', marginRight: '4px' }} className={s.tableFont3_1}>
          #
        </span>
        {data.takeoutNum}
      </div>
      <div className={s.codeText} style={{ margin: '0px 16px 20px 16px' }}>
        {isDeliveryOrder(data) && <DeliveryView {...{ data }} />}
        {isTakeoutOrder(data) && <TakeoutView {...{ data }} />}
        {!isTakeoutOrDeliveryOrder(data) && <HereView {...{ data }} />}
        {!isProfileFilled && (
          <div>
            <div style={{ textAlign: 'left', marginTop: '16px' }} className={s.tableFont4}>
              {t('confirmationView.provideYourContacts')}
            </div>
          </div>
        )}
      </div>
      <div className={s.confirmationBottom}>
        {!isProfileFilled && (
          <>
            <div className={s.confirmationBoxButtonWrapper}>
              <div style={{ padding: '16px 0px' }} onClick={onProvideContacts} className={s.confirmationBoxButton}>
                {t('confirmationView.provideContacts')}
              </div>
            </div>
          </>
        )}
        <div className={s.confirmationBoxButtonWrapper}>
          <div style={{ padding: '16px 0px' }} onClick={handleLeaveTips} className={s.confirmationBoxButton}>
            {t('review.leaveTipsButton')}
          </div>
        </div>
        <div className={s.confirmationBoxButtonWrapper}>
          <div style={{ padding: '16px 0px' }} onClick={handleClose} className={s.confirmationBoxButton}>
            {t(isProfileFilled ? 'general.ok' : 'general.close')}
          </div>
        </div>
      </div>
    </Popup>
  )
}

const DeliveryView = ({ data }) => {
  const { t } = useTranslation()
  return (
    <div>
      <span>
        <div className={s.boldbold}>{t('confirmationView.deliveryView.header')}</div>
        <br /> {t('confirmationView.deliveryView.body')} <strong>{data.delivery.phone}</strong>.{' '}
        {t('confirmationView.deliveryView.label')}
      </span>
    </div>
  )
}

const TakeoutView = ({ data = {} }) => {
  const { t } = useTranslation()
  const lang = useSelector(getLang) || window.busytable.fallbackLang
  const timedTakeoutObj = new Date(data.timedTakeout)
  const timeString = timedTakeoutObj.toLocaleString(lang, {
    hour: '2-digit',
    minute: '2-digit'
  })
  const dateString = timedTakeoutObj.toLocaleString(lang, { day: 'numeric', month: 'long' })
  const prefix = isTomorrow(timedTakeoutObj.getTime()) ? ` ${t('general.tommorow')} ` : ''
  const cookingTime = data.info && data.info.cookingTime ? data.info.cookingTime : 0
  const [tmin, tmax] = timeRange(cookingTime)

  if (data.tableNum) {
    return (
      <div>
        {t('confirmationView.takeoutView.labelwithtable', { stol: data.tableNum })}{' '}
        <strong>
          {tmin}-{tmax} {t('waitingTimeBadge.time')}
        </strong>
      </div>
    )
  }

  const timeDateString = isAfterTomorrow(timedTakeoutObj.getTime())
    ? ` ${dateString} ${t('general.at')} ${timeString}`
    : `${prefix} ${t('general.at')} ${timeString}`

  return (
    <div>
      {t('confirmationView.takeoutView.label')}
      {data.timedTakeout ? (
        <strong>{timeDateString}</strong>
      ) : (
        <>
          {` ${t('general.after')} `}
          <strong>
            {tmin}-{tmax} {t('waitingTimeBadge.time')}
          </strong>
        </>
      )}
    </div>
  )
}

const HereView = ({ data }) => {
  const { t } = useTranslation()
  const cookingTime = data.info && data.info.cookingTime ? data.info.cookingTime : 0
  const [tmin, tmax] = timeRange(cookingTime)

  return (
    <div>
      <span>{t('confirmationView.hereView.label')} </span>
      <strong>
        {tmin}-{tmax} {t('waitingTimeBadge.time')}
      </strong>
    </div>
  )
}
