import { config } from 'src/constants'
import axios from 'axios'
/**
 *
 * @param {string} userId
 * @returns manager confirms employee registration on API
 */

export async function createAddOn(paymentId, createAddOnInput, venueSlug) {
  return axios({
    url: `${config.API_URL3}/${window.busytable.brandSlug}/${venueSlug ? `${venueSlug}/` : ''}payments/${
      paymentId ? `${paymentId}/` : ''
    }add-ons`,
    method: 'POST',
    data: createAddOnInput,
    withCredentials: true,
    headers: { verified: 'yes' }
  })
    .then(resp => {
      if (resp?.data) {
        return resp?.data
      }
    })
    .catch(e => {
      if (e.response.data) {
        throw Error(e.response.data)
      } else {
        throw Error(e)
      }
    })
}
