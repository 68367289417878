import axios from 'axios'
import { config } from 'src/constants'

export const createReview = async payload => {
  return axios
    .post(`${config.API_URL3}/${window.busytable.brandSlug}/reviews`, payload, {
      withCredentials: true,
      headers: { verified: 'yes' }
    })
    .then(resp => {
      if (resp?.data) {
        return resp?.data?.result
      }
    })
    .catch(e => {
      if (e.response?.data) {
        throw Error(e.response.data)
      } else {
        throw Error(e)
      }
    })
}

export const updateReviewById = async (id, payload) => {
  return axios
    .patch(`${config.API_URL3}/${window.busytable.brandSlug}/reviews/${id}`, payload, {
      withCredentials: true,
      headers: { verified: 'yes' }
    })
    .then(resp => {
      if (resp?.data) {
        return resp?.data?.result
      }
    })
    .catch(e => {
      if (e.response.data) {
        throw Error(e.response.data)
      } else {
        throw Error(e)
      }
    })
}
