import React from 'react'
import styles from './styles.module.scss'
import { Tag } from 'src/types'

interface IDishTagComponent {
  tag: Partial<Tag>
  label: string
  disableTagLarge?: boolean
}

const DishTag: React.FC<IDishTagComponent> = ({ tag, label, disableTagLarge }) => {
  return (
    <div
      style={{ color: tag.fontColor, background: tag.color }}
      className={`${styles.menucardBadge} ${disableTagLarge ? styles.largeTag : ''}`}
    >
      {label}
    </div>
  )
}

export default DishTag
