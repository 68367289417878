import {
  SET_NOT_FIRST_TIME,
  SET_USER_LANGUAGE,
  ADD_FAVORITE,
  REMOVE_FAVORITE,
  TOGGLE_CHECK,
  ADD_USERDATA,
  SET_IP,
  SET_RATE,
  TOGGLE_SHOW_BANNER,
  START_USER_CONFIRMATION,
  FINISH_USER_CONFIRMATION,
  ADD_USER_DATA_STARTED,
  ADD_USER_DATA_ERROR,
  TOGGLE_LAST_REVIEW,
  SET_FAVORITES
} from './types'
import { areArrayEquals } from 'src/utils'

const initialState = {
  id: 1,
  lang: '',
  check: false,
  userData: false,
  ip: '',
  favorites: [],
  firstTime: false, // false for dev
  showBanner: false,
  lastReview: false,
  isUserLoading: false
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NOT_FIRST_TIME: {
      return {
        ...state,
        firstTime: false
      }
    }

    case SET_USER_LANGUAGE: {
      const { language } = action.payload
      return {
        ...state,
        lang: language
      }
    }
    case TOGGLE_CHECK: {
      const { check } = action.payload
      return { ...state, check }
    }

    case SET_IP: {
      const { ip } = action.payload
      return { ...state, ip }
    }
    case SET_FAVORITES: {
      const newFavorites = action.favorites?.map(favoriteDish => ({
        id: Number(favoriteDish.dishId),
        favoriteId: Number(favoriteDish.id),
        options: favoriteDish.options.map(option => ({ id: option.optionsetId, value: option.optionId }))
      }))
      const areArraysSame = areArrayEquals(newFavorites, state.favorites)
      console.log('User cache and api resonse are the same  ', areArraysSame)
      if (areArraysSame) {
        return state
      }
      return {
        ...state,
        favorites: newFavorites || []
      }
    }
    case ADD_FAVORITE: {
      const { favorite } = action.payload
      return {
        ...state,
        favorites: [...state.favorites.filter(e => e.favoriteId !== favorite.favoriteId), favorite]
      }
    }

    case REMOVE_FAVORITE: {
      const { favorite } = action.payload
      return {
        ...state,
        favorites: [...state.favorites.filter(e => e.favoriteId !== favorite.id)]
      }
    }

    case ADD_USER_DATA_STARTED: {
      return {
        ...state,
        isUserLoading: true
      }
    }

    case ADD_USERDATA: {
      const { user } = action.payload
      // if (user.lang !== state.lang) {
      //   patchUserLangAsync(state.lang)
      // }
      return {
        ...state,
        isUserLoading: false,
        userData: {
          ...state.userData,
          ...user
        }
      }
    }

    case ADD_USER_DATA_ERROR: {
      return {
        ...state,
        isUserLoading: false
      }
    }

    case SET_RATE: {
      const { rate } = action.payload
      return {
        ...state,
        userData: { ...state.userData, rate }
      }
    }

    case TOGGLE_SHOW_BANNER: {
      return {
        ...state,
        showBanner: action.isShow
      }
    }

    case TOGGLE_LAST_REVIEW: {
      return {
        ...state,
        lastReview: action.lastReview
      }
    }

    case START_USER_CONFIRMATION: {
      const { date } = action.payload
      return {
        ...state,
        confirmationExpireDate: date
      }
    }

    case FINISH_USER_CONFIRMATION: {
      return {
        ...state,
        confirmationExpireDate: null
      }
    }

    default:
      return state
  }
}
