import React from 'react'
import { Button } from 'src/components/ui/Button'

type CommonButtonProps = {
  label: string
  handleClick: () => void
}

const CommonButton: React.FC<CommonButtonProps> = function ({ label, handleClick }) {
  return (
    <Button style={{ margin: '24px auto' }} onClick={handleClick}>
      <span>{label}</span>
    </Button>
  )
}

export default CommonButton
