import {
  ANIMATE_ORDERS,
  RESET_ANIMATE_ORDERS,
  ACTIVITY_VIEW_CURRENT,
  ACTIVITY_VIEW_ANIMATE,
  STORE_MENUCARD_COORDS,
  CLEAR_MENUCARD_COORDS,
  TOGGLE_PAGE_LOADER,
  SET_PROMO_POPUP_TO_DISPLAY,
  REMOVE_PROMO_POPUP_TO_DISPLAY
} from './types'

const initialState = {
  promoPopupToDisplay: null,
  animateOrders: [],
  activityViews: [
    { id: 0, current: null },
    { id: 1, current: null },
    { id: 2, current: null },
    { id: 3, current: null }
  ],
  activityViewAnimate: false,
  menuCardCoords: null,
  pageLoading: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ANIMATE_ORDERS: {
      const { msg } = action.payload

      return {
        ...state,
        animateOrders: [...state.animateOrders, msg.id]
      }
    }
    case REMOVE_PROMO_POPUP_TO_DISPLAY: {
      return {
        ...state,
        promoPopupToDisplay: null
      }
    }

    case SET_PROMO_POPUP_TO_DISPLAY: {
      return {
        ...state,
        promoPopupToDisplay: action.payload
      }
    }

    case RESET_ANIMATE_ORDERS: {
      return {
        ...state,
        animateOrders: []
      }
    }

    case ACTIVITY_VIEW_CURRENT: {
      return {
        ...state,
        activityViews: [
          ...state.activityViews.slice(0, action.payload.id),
          action.payload,
          ...state.activityViews.slice(action.payload.id + 1)
        ]
      }
    }

    case ACTIVITY_VIEW_ANIMATE: {
      return {
        ...state,
        activityViewAnimate: !state.activityViewAnimate
      }
    }

    case STORE_MENUCARD_COORDS: {
      return {
        ...state,
        menuCardCoords: action.payload
      }
    }

    case CLEAR_MENUCARD_COORDS: {
      return {
        ...state,
        menuCardCoords: initialState.menuCardCoords
      }
    }

    case TOGGLE_PAGE_LOADER: {
      return {
        ...state,
        pageLoading: action.payload
      }
    }

    default:
      return state
  }
}
