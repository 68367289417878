import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'src/hooks/useTranslation'
import useFeedbackApi from 'src/hooks/useFeedbackApi'
import thumbUp from 'src/assets/thumbs-up.png'
import thumbDown from 'src/assets/thumbs-down.png'
import { ReactComponent as CheckBox } from 'src/assets/Checkbox.svg'
import { ReactComponent as SendIcon } from 'src/assets/ico-send.svg'
import { ReactComponent as AttachIcon } from 'src/assets/ico-attach.svg'
import { ReactComponent as UnCheckBox } from 'src/assets/Uncheckbox.svg'
import { getUserData, toggleLastReviewAction } from 'src/state/user'
import { getVenueId } from 'src/state/location'
import { getVenueById } from 'src/state/venues'
import Spinner from '../Spinner'
import { ThankView } from '../CodeScan/ConfirmationView'
import { MenuBar } from './MenuBar'
import { Popup } from './Popup.tsx'
import s from './styles.module.scss'
import useFileUploadApi from 'src/hooks/useFileUploadApi'
import FileItem from './FileItem'

export default function FeedbackView({
  paymentId,
  anonymousLabel,
  children,
  renderThankModal,
  thankModalFeedbackState,
  contentStyles
}) {
  const [text, setText] = useState('')
  const [isThankModalOpen, setIsThankModalOpen] = useState(false)
  const [inputFocused, setInputFocused] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const venueSlug = useSelector(getVenueId)
  const userData = useSelector(getUserData)
  const venue = useSelector(getVenueById)(venueSlug)
  const inputRef = useRef(null)
  const [{ filesList }, { multipleUpload, remove }] = useFileUploadApi('reviews')
  // const isDisabled = Boolean(filesList)

  // console.log(filesList)

  const handleUpload = useCallback(
    acceptedFiles => {
      multipleUpload(acceptedFiles, filesList.length)
    },
    [filesList]
  )

  const { t } = useTranslation()

  const [{ data, loading, rate, anonymous }, { create, update, setRate, setAnonymous }] = useFeedbackApi({
    rate: 0,
    ...(paymentId ? { paymentId } : {}),
    ...(venue ? { venue: venue.slug } : {})
  })

  const handleClose = () => {
    history.push('/')
    dispatch(toggleLastReviewAction(false))
  }

  const handleAttachClick = useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.click()
    }
  }, [inputRef])

  useEffect(() => {
    if (data?.message && !isThankModalOpen) {
      setIsThankModalOpen(true)
      dispatch(toggleLastReviewAction(new Date().getTime()))
    }
  }, [data, dispatch, isThankModalOpen])

  // useEffect(() => {
  //   if (uploadingFiles) {
  //     multipleUpload(uploadingFiles, handleUploadFiles)
  //   }
  // }, [uploadingFiles])

  const onRateButtonClick = value => {
    setRate(value)
    if (data?.id) {
      update({ rate: value })
    } else {
      create({ rate: value })
    }
  }

  const onCommentButtonClick = () => {
    if (data?.id) {
      update({
        message: text,
        ...(filesList.length
          ? {
              media: filesList
                .filter(filesListItem => filesListItem.file.id)
                .map(filesListItem => filesListItem.file.id)
            }
          : {})
      })
    } else {
      create({
        message: text,
        ...(filesList.length
          ? {
              media: filesList
                .filter(filesListItem => filesListItem.file.id)
                .map(filesListItem => filesListItem.file.id)
            }
          : {})
      })
    }
    setText('')
  }

  const toggleInputFocus = () => {
    setInputFocused(prevState => !prevState)
  }

  const renderRate = () => {
    if (!data?.message) {
      return (
        <>
          <div className={s.askText}>{t('contactUs.rateus')}</div>

          <div className={s.ratesWrapper}>
            <div
              onClick={() => onRateButtonClick(5)}
              data-cy='rateButtonGood'
              className={`${s.rateWrapper} ${rate === 5 ? s.activeTip : ''}`}
            >
              <img src={thumbUp} alt='tip good' />
            </div>
            <div
              onClick={() => onRateButtonClick(1)}
              data-cy='rateButtonBad'
              className={`${s.rateWrapper} ${rate === 1 ? s.activeTip : ''}`}
            >
              <img src={thumbDown} alt='tip bad' />
            </div>
          </div>
        </>
      )
    }
  }

  const renderRightHeader = () => {
    if (rate) {
      return (
        <div className={s.headerRight}>
          <button onClick={handleAttachClick} data-cy='rateAttachButton' className={s.submitButton}>
            <input
              ref={inputRef}
              className={s.attachInput}
              onChange={data => handleUpload(Object.values(data.target.files))}
              type='file'
              multiple
              accept='image/*, video/*'
            />
            {<AttachIcon />}
          </button>

          {!data?.message && (
            <button
              data-cy='rateSubmitButton'
              onClick={onCommentButtonClick}
              disabled={text.length < 3 || filesList.some(filesListItem => filesListItem.progress !== 100)}
              className={s.submitButton}
            >
              {loading ? <Spinner type='sm' /> : <SendIcon />}
            </button>
          )}
        </div>
      )
    }
  }

  const renderThankView = () => {
    if (renderThankModal) {
      if (thankModalFeedbackState && Object.keys(thankModalFeedbackState).length) {
        const renderExtraThank = Object.keys(thankModalFeedbackState).every(
          key => thankModalFeedbackState[key] === data[key]
        )

        if (renderExtraThank) {
          return renderThankModal()
        }
      }
    }

    return (
      <ThankView
        text1={userData.phone || anonymous ? t('review.commentThankText') : t('contactUs.thank')}
        text2={
          userData.phone || anonymous ? t('contactUs.weread') : { t1: t('contactUs.weread'), t2: t('contactUs.leave') }
        }
        singleButton={Boolean(userData.phone || anonymous)}
        buttonText={
          userData.phone || anonymous ? t('general.ok') : { t1: t('contactUs.later'), t2: t('contactUs.now') }
        }
        callback={
          userData.phone || anonymous
            ? handleClose
            : {
                cb1: handleClose,
                cb2: () => {
                  history.push('/profile')
                }
              }
        }
      />
    )
  }

  return (
    <Popup
      fullsize
      withoutHeaderWrapper
      additional={isThankModalOpen && renderThankView()}
      header={
        <MenuBar
          className='headerBrandBgAbout'
          title={t('review.leaveRate')}
          icon={!inputFocused ? 'cross' : 'close'}
          eventCb={handleClose}
          right={<div>{renderRightHeader()}</div>}
        />
      }
    >
      <div style={{ ...(!rate ? { justifyContent: 'center' } : {}), ...(contentStyles || {}) }} className={s.wrapper}>
        {renderRate()}
        {!rate || (
          <textarea
            data-cy='feedbackTextArea'
            onFocus={toggleInputFocus}
            onBlur={toggleInputFocus}
            placeholder={rate === 5 ? t('review.areaPlaceholderGood') : t('review.areaPlaceholderBad')}
            onChange={e => setText(e.target.value)}
            value={text}
            className={[s.tableFont2, s.textarea, s.fullArea].join(' ')}
          />
        )}
        {anonymousLabel && Boolean(rate) && (
          <div onClick={() => setAnonymous(!anonymous)} className={s.checkControl}>
            {anonymous === true ? <CheckBox /> : <UnCheckBox />}
            <div className={s.checkLabel}>{t('contactUs.anon')}</div>
          </div>
        )}

        {Boolean(rate) && (
          <div className={s.filesListWrapper}>
            {filesList.map(filesListItem => {
              const handleRemove = () => {
                remove(filesListItem.file, filesListItem.order)
              }
              return (
                <FileItem
                  handleRemove={handleRemove}
                  key={filesListItem.order}
                  progress={filesListItem.progress}
                  data={filesListItem.file}
                />
              )
            })}
          </div>
        )}

        {children}
      </div>
    </Popup>
  )
}

FeedbackView.defaultProps = {
  contentStyles: {},
  anonymousLabel: false
}
