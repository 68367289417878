if (!window.busytable) {
  window.busytable = {} as IBusytable
}

type ApiModeSettings = {
  [key in string]: string
}

type GeneralApiSettings = {
  [key in string]: ApiModeSettings
}

export const settings: GeneralApiSettings = {
  production: {
    API_URL: `https://api.${window.busytable.domain}/v3`,
    API_URL3: `https://api.${window.busytable.domain}/v3`,
    API_URL4: `https://api.${window.busytable.domain}/v4`,
    CHANNEL_URL: `https://api.${window.busytable.domain}/v3`,
    ORIGIN_URL: `https://${window.busytable.domain}`
  },
  staging: {
    API_URL: `https://api.${window.busytable.domain}/v3`,
    API_URL3: `https://api.${window.busytable.domain}/v3`,
    API_URL4: `https://api.${window.busytable.domain}/v4`,
    CHANNEL_URL: `https://api.${window.busytable.domain}/v3`,
    ORIGIN_URL: `https://${window.busytable.domain}`
  },
  develop: {
    API_URL: `https://devapi.${window.busytable.domain}/v3`,
    API_URL3: `https://devapi.${window.busytable.domain}/v3`,
    API_URL4: `https://devapi.${window.busytable.domain}/v4`,
    CHANNEL_URL: `https://devapi.${window.busytable.domain}/v3`,
    ORIGIN_URL: `https://dev.${window.busytable.domain}`
  },
  localdev: {
    API_URL: 'http://localhost:5000/v3',
    API_URL3: 'http://localhost:5000/v3',
    API_URL4: 'http://localhost:5000/v4',
    CHANNEL_URL: 'http://localhost:5001/v3',
    ORIGIN_URL: 'http://localhost:3000'
  },
  remotedev: {
    API_URL: 'http://192.168.0.103:3002/v3',
    API_URL3: 'http://192.168.0.103:3002/v3',
    API_URL4: 'http://192.168.0.103:3002/v4',
    CHANNEL_URL: 'http://192.168.0.103:3002/v3',
    ORIGIN_URL: 'http://192.168.0.103:3002'
  }
}

export const authHeader = {
  withCredentials: true,
  headers: { 'x-url': window.location.href, verified: 'yes', 'Content-Type': 'application/json' }
}

export const MIN_ORDER_AMOUNT = 900

export const CONFIRMATION_CODE_LENGTH = 6
export const POPULAR_CATEGORY_ID = 0
export const FAVORITES_CATEGORY_ID = -1
export const PAYMENTS_TO_FIND_FAVORITES = 100
export const DISHES_FAVORITES_COUNT = 15
export const PAYMENTS_FAVORITES_COUNT = 3

export const POPULAR_CATEGORY_DATA = { id: 0, name: { en: 'popular', ru: 'популярное' } }
export const FAVORITES_CATEGORY_DATA = { id: -1, name: { en: 'favorites', ru: 'избранное' } }

export const SHOPPING_CART_INIT_POSITION_Y = -64
export const NEW_SHOPPING_CART_INIT_POSITION_Y = 0
// window.mode
// export const config = process.env.REACT_APP_DEV ? dev : prod

export const SHOPPING_CART_SUGGESTED_MAP = {
  715: [588, 586, 66, 126, 75, 59],
  718: [588, 586, 66, 126, 75, 59]
}

export type SuggestedCategoryKey = keyof typeof SHOPPING_CART_SUGGESTED_MAP

/**
 * REGULAR EXPRESSIONS
 * */
export const ANIMATED_MEDIA_REGEX = /\/video|.mp4|.gif/gim

export const config = settings[window.mode]
export const getConfig = () => settings[window.mode]
