import { useMemo } from 'react'
import { NewOrderInput, OptionType } from 'src/types'
import { isDishInStoplist } from '../../utils'
import useCurrentDishes from '../useCurrentDishes'
import { useSelector } from 'src/state/hooks'
import { getStoplist } from 'src/state/stoplist'
import useOptionSetApi from '../useOptionSetApi'

type UseOrdersWarningsValue = {
  isDisabledOptions: boolean
  notFoundOptions: boolean
  isDisabledDish: boolean
  hasWarnings: boolean
}

type UseOrdersWarningsHook = (orders: NewOrderInput[]) => UseOrdersWarningsValue

const useOrdersWarnings: UseOrdersWarningsHook = function(orders) {
  const stopList = useSelector(getStoplist)
  const { isOptionInStopList, findFullOption } = useOptionSetApi()
  const { isDishExist, findDish } = useCurrentDishes()

  const ordersOptions = orders.reduce((result, order) => {
    const currentDish = findDish?.(order.dishId)
    const mappedOptions =
      order.selectedOptions?.map(orderOption => findFullOption(orderOption, currentDish?.recipeId)) || []
    return [...result, ...mappedOptions] as OptionType[]
  }, [] as OptionType[])

  const isDisabledDish = useMemo(
    () => orders.some(order => isDishInStoplist(stopList, order.dishId) || !isDishExist?.(order.dishId)),
    [orders, stopList, isDishExist]
  )

  const isDisabledOptions = useMemo(
    () => ordersOptions.some(option => option && isOptionInStopList(option.modifierId)),
    [ordersOptions, isOptionInStopList]
  )

  const notFoundOptions = useMemo(() => ordersOptions.some(option => !option), [ordersOptions])

  return useMemo(
    () => ({
      isDisabledOptions: isDisabledOptions,
      isDisabledDish,
      notFoundOptions,
      hasWarnings: isDisabledOptions || isDisabledDish || notFoundOptions || false
    }),
    [isDisabledDish, isDisabledOptions, notFoundOptions]
  )
}

export default useOrdersWarnings
