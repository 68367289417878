import React, { useState, useEffect } from 'react'
import RadioGroup from './RadioGroup'
import { ITranslationsInput, OptionSetType, OptionType } from '../../types'
import { useSelector } from 'src/state/hooks'
import { getSelectedOptions } from '../../state/menu'
import { compareOptionsPosition } from './utils'

export type RadioGroupData = {
  title: ITranslationsInput
  options: OptionType[]
}

type RadioSetProps = {
  set: OptionSetType
  handleFormSubmit: (value: number) => void
  disabled?: boolean
}

export const RadioSet: React.FC<RadioSetProps> = ({ set, handleFormSubmit, disabled }) => {
  const selectedOptions = useSelector(getSelectedOptions) || []
  const selectedCurrentSetOption = selectedOptions?.filter(selectedOption => selectedOption.id === set.id)[0]
  const defaultOption = set.options?.filter(e => e.default)[0]
  const [radio, setRadio] = useState<number>(0)

  useEffect(() => {
    setRadio(selectedCurrentSetOption?.value || defaultOption?.id || 0)
  }, [defaultOption, selectedCurrentSetOption])

  const handleOptionChange = (value: number) => {
    handleFormSubmit(value)
    setRadio(value)
  }

  return (
    <RadioGroup
      name={`${set.id}`}
      handleChange={handleOptionChange}
      radio={radio}
      text={{ title: set.name, options: set.options?.sort(compareOptionsPosition) } as RadioGroupData}
      disabled={disabled}
    />
  )
}
