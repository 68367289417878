import { useMemo } from 'react'
import { OptionSetType, OptionType, SelectedOptionType } from 'src/types'
import { useSelector } from 'src/state/hooks'
import { getOptionsets, getOptionsetsByRecipeId } from 'src/state/menu'
import { getStoplist } from 'src/state/stoplist'

type FindFullOptionFunc = (option: SelectedOptionType, recipeId?: number) => OptionType | undefined
type FindOptionSetFunc = (id: number, recipeId?: number) => OptionSetType | undefined
type IsOptionInStopListFunc = (id: number | undefined) => boolean
type IsOptionExistFunc = (option: SelectedOptionType, recipeId?: number) => boolean
type IsOptionSetExistFunc = (id: number, recipeId?: number) => boolean

type UseOptionSetValue = {
  findFullOption: FindFullOptionFunc
  findOptionSet: FindOptionSetFunc
  isOptionInStopList: IsOptionInStopListFunc
  isOptionExist: IsOptionExistFunc
  isOptionSetExist: IsOptionSetExistFunc
}

type UseOptionSetHook = (recipeId?: number) => UseOptionSetValue

const useOptionSetApi: UseOptionSetHook = function(recipeId) {
  const stopList = useSelector(getStoplist)
  const optionSets =
    useSelector(recipeId ? getOptionsetsByRecipeId(recipeId) : getOptionsets) || ([] as OptionSetType[])
  const options = optionSets.reduce((result, set) => [...result, ...(set.options || [])], [] as OptionType[]) || []

  const findOptionSetsByRecipeId = useMemo(
    () => (recipeId?: number) => (recipeId ? optionSets.filter(set => set.recipeId === recipeId) : optionSets),
    [optionSets]
  )

  const findOptionSet: FindOptionSetFunc = useMemo(
    () => (id, recipeId) => {
      const currentOptionSets = recipeId ? findOptionSetsByRecipeId(recipeId) : optionSets
      return currentOptionSets.find(set => set.id === id)
    },
    [findOptionSetsByRecipeId, optionSets]
  )

  const findFullOption: FindFullOptionFunc = useMemo(
    () => (option, recipeId) => {
      const currentOptionSets = findOptionSetsByRecipeId(recipeId)
      return currentOptionSets?.find(set => set.id === option.id)?.options?.find(opt => opt.id === option.value)
    },
    [findOptionSetsByRecipeId]
  )

  const isOptionInStopList: IsOptionInStopListFunc = useMemo(
    () => id => {
      const { options } = stopList
      return options.indexOf(id) >= 0
    },
    [stopList]
  )

  const isOptionExist: IsOptionExistFunc = useMemo(() => (option, recipeId) => !!findFullOption(option, recipeId), [
    findFullOption
  ])
  const isOptionSetExist: IsOptionSetExistFunc = useMemo(() => (id, recipeId) => !!findOptionSet(id, recipeId), [
    findOptionSet
  ])

  const api = useMemo(
    () => ({
      findFullOption,
      findOptionSet,
      isOptionInStopList,
      isOptionExist,
      isOptionSetExist
    }),
    [findFullOption, findOptionSet, isOptionExist, isOptionSetExist, isOptionInStopList]
  )

  return api
}

export default useOptionSetApi
