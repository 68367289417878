export const ANIMATE_ORDERS = 'ANIMATE_ORDERS'
export const RESET_ANIMATE_ORDERS = 'RESET_ANIMATE_ORDERS'
export const SHOW_COMMENT = 'SHOW_COMMENT'
export const ACTIVITY_VIEW_CURRENT = 'ACTIVITY_VIEW_CURRENT'
export const ACTIVITY_VIEW_ANIMATE = 'ACTIVITY_VIEW_ANIMATE'
export const STORE_MENUCARD_COORDS = 'STORE_MENUCARD_COORDS'
export const CLEAR_MENUCARD_COORDS = 'CLEAR_MENUCARD_COORDS'
export const TOGGLE_PAGE_LOADER = 'TOGGLE_PAGE_LOADER'
export const SET_PROMO_POPUP_TO_DISPLAY = 'SET_PROMO_POPUP_TO_DISPLAY'
export const REMOVE_PROMO_POPUP_TO_DISPLAY = 'REMOVE_PROMO_POPUP_TO_DISPLAY'
