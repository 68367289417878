import React from 'react'
import { ReactComponent as PoiGen2 } from 'src/assets/poi-gen2.svg'
import { MARKER_TYPES, SIZE_TYPES } from 'src/utils'
import useAppPreferences from '../../hooks/useAppPreferences'
import useFontColorReadability from '../../hooks/useFontColorReadability'

export const Marker = ({ type, size, venueMarkerColor }) => {
  const { customizationSettings } = useAppPreferences()
  const brandSignReadable = useFontColorReadability('#ffffff')

  return (
    <div
      className={[
        'markerWrapper',
        MARKER_TYPES[type] || MARKER_TYPES['DEFAULT'],
        SIZE_TYPES[size] || SIZE_TYPES['normal']
      ].join(' ')}
    >
      <PoiGen2 className='markerBase' />
      <div
        style={{
          maskImage: `url(${customizationSettings?.brandSign})`,
          '-webkit-mask-image': `url(${customizationSettings?.brandSign})`,
          ...(venueMarkerColor && { backgroundColor: venueMarkerColor }),
          '--fontColor': !brandSignReadable ? '#000000' : null
        }}
        className='bgSign'
      />
    </div>
  )
}
