import { isEmpty } from 'ramda'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as SearchIcon } from 'src/assets/btn-search-24.svg'
import { ReactComponent as CloseIcon } from 'src/assets/ico-close-24.svg'
import { useTranslation } from 'src/hooks/useTranslation'
import { getHistory } from 'src/state/history/selectors'
import { getDishes, getOptionsets } from 'src/state/menu/selectors.ts'
import { MenuBar } from 'src/components/ui/MenuBar'
import OrderCard from 'src/components/PaymentHistory/OrderCard'
import s from './styles.module.scss'
import { postEvent } from '../../api'

const PaymentHistory = () => {
  const { t } = useTranslation()
  const [fold, unFold] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [searchVal, setSearchVal] = useState('')
  const payments = useSelector(getHistory)
  const dishes = useSelector(getDishes)
  const optionsets = useSelector(getOptionsets)

  // zero code build
  const filteredPayments =
    searchVal !== ''
      ? payments.filter(
          x =>
            (x.tableNum && x.tableNum.toString().indexOf(searchVal) >= 0) ||
            (x.takeoutNum && x.takeoutNum.toString().indexOf(searchVal) >= 0) ||
            (x.amountBeforeDiscount && x.amountBeforeDiscount.toString().indexOf(searchVal) >= 0)
        )
      : payments

  return (
    <div className={'confirmation'}>
      <MenuBar
        className={['registerHead'].join(' ')}
        icon={'cross'}
        title={t('orderHistory')}
        url={'/settings'}
        right={
          !isSearch ? (
            <div
              onClick={() => {
                postEvent('history-search', 'involvement')
                setIsSearch(true)
              }}
              className={s.searchBarIcon}
            >
              <SearchIcon />
            </div>
          ) : (
            <nav className={[s.searchBarInput, s.menuSearch].join(' ')}>
              <input
                autoFocus
                className={s.menuNavbarInput}
                value={searchVal}
                onChange={_ => {
                  setSearchVal(_.target.value)
                }}
              />
              <CloseIcon
                onClick={() => {
                  setIsSearch(false)
                  setSearchVal('')
                }}
                className={[s.right, s.iconClass].join(' ')}
              />
            </nav>
          )
        }
      />
      <div className={'content'}>
        <div className={'contentWrapper'}>
          <div className={s.listWrapper}>
            {isEmpty(filteredPayments) || !filteredPayments || !Array.isArray(filteredPayments) ? (
              <p>{t('paymentHistory.nothing')}</p>
            ) : (
              <div className={s.list}>
                {filteredPayments.map(elem => (
                  <OrderCard {...{ elem, fold, unFold, dishes, optionsets }} key={elem.id} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentHistory
