import { SHOW_TABLE, HIDE_TABLE } from './types'

/* State Shape
{

}
*/

export const initialState = false

export default function(state = initialState, action) {
  switch (action.type) {
    case SHOW_TABLE: {
      const { num } = action.payload
      return num
    }

    case HIDE_TABLE: {
      return false
    }

    default:
      return state
  }
}
