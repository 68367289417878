import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getVisibleVenues } from 'src/state/venues'
import Map from './components/Map'
import List from './components/List'
import ActivityView from 'src/components/ui/ActivityView'
import { getActivityViewCurrent } from 'src/state/ui/selectors'
import BtnMain from 'src/components/ui/BtnMain'
import VenueButton from './components/VenueButton'
import { setTableNum, setVenueId } from 'src/state/location'
import { setScanDate } from 'src/state/location/actions'
import { setTakeout } from 'src/state/orders'
import { ReactComponent as CloseIcon } from 'src/assets/icons/ico-close-24.svg'
import { ReactComponent as LocationIcon } from 'src/assets/icons/location.svg'
import { postEvent } from 'src/api'
import { useHistory } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useTranslation } from 'src/hooks/useTranslation'
import { fitBounds } from 'google-map-react'
import { cycleActivityView } from 'src/state/ui'
import s from './styles.module.scss'
import { day, MARKER_TYPES, SIZE_TYPES, workingHoursValidator } from 'src/utils'
import useFontColorReadability from '../../hooks/useFontColorReadability'

const VenueSelect = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const venues = useSelector(getVisibleVenues)
  const [selectedVenue, setSelectedVenue] = useState(null)
  const [centerLocation, setCenterLocation] = useState()
  const [zoom, setZoom] = useState(14)
  const [userLocation, setUserLocation] = useState(false)

  const size = {
    width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
    height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
  }

  const { current } = useSelector(getActivityViewCurrent)(0)
  const initPositionY = -60
  const openPositionY = -292

  useEffect(() => {
    if (selectedVenue) {
      toggleCartOpen()
    }
  }, [selectedVenue])

  const toggleCartOpen = () => {
    dispatch(cycleActivityView({ id: 0, current: openPositionY }))
  }

  const handleVenueSelect = () => {
    if (!(selectedVenue.here && selectedVenue.takeout)) {
      if (selectedVenue.here) {
        dispatch(setTakeout(false))
      } else {
        dispatch(setTakeout(true))
      }
    }
    dispatch(setVenueId(selectedVenue.slug))
    dispatch(setTableNum(0))
    dispatch(setScanDate())
    onClose()
  }

  const mapsSelector = () => {
    if (
      navigator.platform.indexOf('iPhone') !== -1 ||
      navigator.platform.indexOf('iPod') !== -1 ||
      navigator.platform.indexOf('iPad') !== -1
    )
      window.open(`http://maps.apple.com/?address=${selectedVenue.lat},${selectedVenue.lng}&t=m`)
    else window.open(`https://maps.google.com/maps?daddr=${selectedVenue.lat},${selectedVenue.lng}&amp;ll=`)
  }

  // Fits cluster of venues into vieport
  const calculateZoomAndCenter = cluster => {
    if (cluster.length) {
      const northernmost = Math.max(...cluster.map(poi => poi.lat && Number.parseFloat(poi.lat)))
      const southernmost = Math.min(...cluster.map(poi => poi.lat && Number.parseFloat(poi.lat)))
      const westernmost = Math.min(...cluster.map(poi => poi.lng && Number.parseFloat(poi.lng)))
      const easternmost = Math.max(...cluster.map(poi => poi.lng && Number.parseFloat(poi.lng)))
      const bounds = {
        nw: {
          lat: northernmost,
          lng: westernmost
        },
        se: {
          lat: southernmost,
          lng: easternmost
        }
      }
      return fitBounds(bounds, size)
    }
  }

  /**
   * Returns the distance between two co-ordinates by radius.
   */
  const distance = (lat1, lon1, lat2, lon2) => {
    const deg2rad = deg => {
      return deg * (Math.PI / 180)
    }
    const R = 6371 // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1)
    const dLon = deg2rad(lon2 - lon1)
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const d = R * c // Distance in km
    return d
  }

  // find the venue with the smallest radius
  const nearestVenue = user => {
    let min = venues[0]
    venues.map((v, i) => {
      if (distance(v.lat, v.lng, user.lat, user.lng) < distance(venues[0].lat, venues[0].lng, user.lat, user.lng)) {
        min = venues[i]
      }
    })
    return min
  }
  /**
   *
   * @param {Venue} venue
   * @param {Venue} selectedVenue
   */
  function icoFunction(venue, selectedVenue) {
    let size = 'normal'
    let type = 'DEFAULT'
    if (selectedVenue && selectedVenue.id === venue.id) {
      size = 'big'
      type = venue.disabled ? 'DISABLED' : 'ACTIVE'
      // return '/images/poi_selected.svg'
    } else if (workingHoursValidator(venue)[1] && !venue.disabled) {
      size = 'normal'
      type = 'DEFAULT'
      // return '/images/poi.svg'
    } else {
      size = 'normal'
      type = 'DISABLED'
      // return '/images/poi_disabled.1.svg'
    }
    return { size, type }
  }

  const getGeoLocation = () => {
    if (navigator.geolocation) {
      dispatch(cycleActivityView({ id: 0, current: window.innerWidth }))
      setSelectedVenue(null)
      navigator.geolocation.getCurrentPosition(
        position => {
          const user = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
          setUserLocation(user)
          const { center, zoom } = calculateZoomAndCenter([nearestVenue(user), user])
          setCenterLocation(center)
          console.log({ zoom })
          setZoom(zoom - 1)
          // setZoom(zoom)
        },
        error => {
          if (venues.length > 1) {
            const { center, zoom } = calculateZoomAndCenter(venues)
            setCenterLocation(center)
            setZoom(zoom)
          } else if (venues.length === 1) {
            setCenterLocation({ lat: Number.parseFloat(venues[0].lat), lng: Number.parseFloat(venues[0].lng) })
            setZoom(15)
          }
        }
      )
    } else {
      if (venues.length > 1) {
        const { center, zoom } = calculateZoomAndCenter(venues)
        setCenterLocation(center)
        setZoom(zoom)
      } else if (venues.length === 1) {
        setCenterLocation({ lat: Number.parseFloat(venues[0].lat), lng: Number.parseFloat(venues[0].lng) })
        setZoom(15)
      }
    }
  }

  useEffect(() => {
    if (venues.length) {
      getGeoLocation()
    }
  }, [venues.length])

  const onClose = () => {
    postEvent('VenueSelect', 'cancel')
    history.push('/')
    if (props?.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    postEvent('VenueSelect', 'coords ' + JSON.stringify(userLocation))
  }, [userLocation])

  const isNavbarReadable = useFontColorReadability('#ffffff')

  return (
    <>
      {current && selectedVenue && (
        <ActivityView
          id={0}
          elevation={202}
          shader={false}
          closeState={true}
          closeHandler={() => {
            setSelectedVenue(null)
          }}
          initPositionY={initPositionY}
          openPositionY={openPositionY}
          classNames={s.brandMap}
        >
          <div className={s.brandingAM}></div>
          <div className={s.mapWrapper}>
            <div className={s.superHeader}>
              <div className={s.header}>
                <h4 className={['H4map', s.title].join(' ')}>{t(selectedVenue.name)}</h4>
              </div>
              <div className={s.venueSubname}>
                {t(selectedVenue.address)} || {selectedVenue.phone}
              </div>
              <div>
                <p
                  className={
                    workingHoursValidator(selectedVenue)[1] && !selectedVenue.disabled ? s.hours : `${s.hours} ${s.out}`
                  }
                >
                  {workingHoursValidator(selectedVenue)[0] && !selectedVenue.disabled
                    ? `${selectedVenue.workingHours[day][0]} – ${selectedVenue.workingHours[day][1]}`
                    : t('map.disabledLabel')}
                </p>
              </div>
            </div>
            <div className={s.mapRow} style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '280px' }}>
                {workingHoursValidator(selectedVenue)[1] && !selectedVenue.disabled ? (
                  <BtnMain clickHandler={handleVenueSelect}>{t('map.chooseBtn')}</BtnMain>
                ) : (
                  <BtnMain clickHandler={handleVenueSelect}>{t('map.openBtn')}</BtnMain>
                )}
              </div>
            </div>
            <div className={s.mapRow}>
              <div className={s.actionBlock}>
                <VenueButton iconType={'phone'} url={selectedVenue.phone} />
                <VenueButton iconType={'map'} url={selectedVenue} />
                {/* <VenueButton iconType={'map'} url={selectedVenue} iconComponent={IcoMap} /> */}
              </div>
            </div>
          </div>
        </ActivityView>
      )}
      <div className={s.wrapper}>
        <Tabs className={s.reactTabs}>
          <nav
            className={s.navbar}
            style={{
              '--fontColor': isNavbarReadable ? null : '#000000'
            }}
          >
            <div
              className={s.icon}
              onClick={
                props.onClose
                  ? () => {
                      setSelectedVenue(null)
                      postEvent('VenueSelect', 'select')
                      props.onClose()
                    }
                  : () => {
                      setSelectedVenue(null)
                      history.push('/')
                      postEvent('VenueSelect', 'cancel')
                    }
              }
            >
              <CloseIcon fill='black' />
            </div>
            <TabList className={s['react-tabs__list']}>
              <Tab
                onClick={() => dispatch(cycleActivityView({ id: 0, current: null }))}
                className={[s['react-tabs__title']].join(' ')}
              >
                {t('map.karta')}
              </Tab>
              <Tab
                onClick={() => dispatch(cycleActivityView({ id: 0, current: null }))}
                className={[s['react-tabs__title']].join(' ')}
              >
                {t('map.list')}
              </Tab>
            </TabList>
            <div
              className={s.icon}
              onClick={() => {
                setCenterLocation(userLocation)
              }}
            >
              <LocationIcon className={s.locationIcon} fill='black' />
            </div>
          </nav>
          <TabPanel>
            <div className={s.map}>
              <Map
                venues={venues}
                selectedVenue={selectedVenue}
                setSelectedVenue={setSelectedVenue}
                zoom={zoom}
                centerLocation={centerLocation}
                setCenterLocation={setCenterLocation}
                icoFunction={icoFunction}
                day={day}
                userLocation={userLocation}
                history={history}
                onClose={
                  props.onClose
                    ? () => {
                        postEvent('VenueSelect', 'select')
                        props.onClose()
                      }
                    : () => {
                        history.push('/')
                        postEvent('VenueSelect', 'cancel')
                      }
                }
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div className={s.list}>
              <List
                selectedVenue={selectedVenue}
                setSelectedVenue={setSelectedVenue}
                icoFunction={icoFunction}
                workingHoursValidator={workingHoursValidator}
                day={day}
                onClose={
                  props.onClose
                    ? () => {
                        postEvent('VenueSelect', 'select')
                        props.onClose()
                      }
                    : () => {
                        history.push('/')
                        postEvent('VenueSelect', 'cancel')
                      }
                }
                userLocation={userLocation}
                venues={venues}
              />
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </>
  )
}

export default VenueSelect
