import { ADD_TRANSLATIONS, CHANGE_LANG } from './types'

/* State Shape
{

}
*/

export const initialState = {}

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_TRANSLATIONS: {
      const { translations } = action.payload
      return translations
    }

    case CHANGE_LANG: {
      state = {
        ...state,
        menu: {
          ...state.menu,
          cats: [1, 2]
        }
      }
      return state
    }

    default:
      return state
  }
}
