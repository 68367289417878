import React, { useEffect, useMemo, useState } from 'react'
import tinycolor from 'tinycolor2'
import useThemeStyles from './useThemeStyles'
import { useSelector } from 'react-redux'

const ICONS_SET = {
  hlbn: {
    general: {
      size: 18,
      color: null,
      icons: [
        { user: { icon: require('src/assets/person.svg') } },
        { venue: { icon: require('src/assets/icons/venueIcon.svg') } },
        { table: { icon: require('src/assets/iconka.svg') } },
        { order: { icon: require('src/assets/icons/orderIcon.svg') } },
        { contact: { icon: require('src/assets/icons/contactIcon.svg') } },
        { about: { icon: require('src/assets/icons/aboutIcon.svg') } },
        { phone: { icon: require('src/assets/icons/ico-phone-settings.svg') } },
        { telegram: { icon: require('src/assets/icons/ico-telegram.svg') } },
        { tipsIcon: { icon: require('src/assets/tipsIcon.svg') } }
      ]
    }
  },
  default: {
    general: {
      size: 24,
      color: '#000000',
      icons: [
        { user: { icon: require('src/assets/ico-user-24.svg') } },
        { venue: { icon: require('src/assets/ico-poi-24.svg') } },
        { table: { icon: require('src/assets/iconka.svg') } },
        { order: { icon: require('src/assets/ico-history-24.svg') } },
        { contact: { icon: require('src/assets/ico-rating-24.svg') } },
        { about: { icon: require('src/assets/nm/ico-about-24.svg') } },
        { phone: { icon: require('src/assets/bt/ico-phone-24.svg') } },
        { telegram: { icon: require('src/assets/ico-telegram-24.svg') } },
        { tipsIcon: { icon: require('src/assets/tipsIcon.svg') } }
      ]
    }
  }
}

const useCustomIconSet = (iconSlug, group) => {
  const [icons, setIcons] = useState({ general: {} })
  const [iconsSet, setIconsSet] = useState({})
  const [iconStyles, setIconStyles] = useState({})

  const { variables } = useThemeStyles()
  const { demoVariables } = useSelector(state => state.demo)

  const slug = window.busytable.brandSlug

  const set = ICONS_SET[slug] || ICONS_SET.default

  const styles = useMemo(
    () => ({
      '--generalIconColor': tinycolor(demoVariables?.fontColor || variables?.fontColor || '#000000')
        .setAlpha(0.5)
        .toString(),
      '--generalIconSize': `${set.general.size || 24}px`
    }),
    [demoVariables, variables]
  )

  useEffect(() => {
    setIconsSet(set)
    setIcons({
      general: set.general.icons.reduce((res, curr) => ({ ...res, ...curr }), {})
    })
    setIconStyles(styles)
  }, [styles])

  if (iconSlug) {
    const iconBySlug = Object.values(icons).find(row => Object.keys(row).find(key => key === iconSlug))
    return iconBySlug ? iconBySlug[iconSlug] : null
  }

  if (group && iconsSet && iconsSet[group]) {
    return {
      ...iconsSet[group],
      [group]: iconsSet[group].icons.reduce((res, curr) => ({ ...res, ...curr }), {})
    }
  }

  return [icons, iconStyles]
}

export default useCustomIconSet
