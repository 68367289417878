import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'ramda'
import axios from 'axios'

import { config } from 'src/constants'
import { Wrapper } from 'src/components/ui/Wrapper'
import { calcOptionsCost, getDistanceFromLatLonInKm, formatterDelivery, transformTimestampToString } from 'src/utils'
import { getComment, getTakeout } from 'src/state/orders/selectors.ts'
import { getDishes, getOptionsets } from 'src/state/menu/selectors.ts'
import { getTableNum, getVenueId, setVenueId } from 'src/state/location'
import { getLang } from 'src/state/local'
import { getRoles } from 'src/state/roles'

import { ChangeLocationView } from 'src/components/CodeScan/ConfirmationView'
import { ReactComponent as WarnIcon } from 'src/assets/ico-warn-18.svg'
import { ReactComponent as TableIcon } from 'src/assets/ico-table-small.svg'
import { ReactComponent as HomeIcon } from 'src/assets/ico-home.svg'
import { ReactComponent as UserIcon } from 'src/assets/person.svg'
import { ReactComponent as PhoneIcon } from 'src/assets/phone.svg'
import { ReactComponent as DateIcon } from 'src/assets/ico-date.svg'
import { ReactComponent as LocationIcon } from 'src/assets/ico-location.svg'
import { ReactComponent as DeliveryIcon } from 'src/assets/ico-delivery-1.svg'
import { ReactComponent as PersonIcon } from 'src/assets/icons/icon-person.svg'
import { ReactComponent as DiesIcon } from 'src/assets/icons/ico-dies-16.svg'
import { ReactComponent as PoiIcon } from 'src/assets/icons/ico-poi-24.svg'
import { ReactComponent as TogoIcon } from 'src/assets/ico-togo-24.svg'
import { ReactComponent as TimeIcon } from 'src/assets/ico-time.svg'
import { toggleCheck } from 'src/state/user/actions'
import { getUserData, getUserIp } from 'src/state/user/selectors'
import { fetchPayFree, postEvent, paidFromWidgetUpdate } from 'src/api'
import { getVenues, getVenueById } from 'src/state/venues'
import { getAllVenuesStatuses, VENUE_STATUSES, getScanDate, getVenueStatus } from 'src/state/location/selectors'
import { addComment, removeUnpaid } from 'src/state/orders/actions.ts'
import { MenuBar } from 'src/components/ui/MenuBar'
import { getTimedTakeout, setTimedTakeout } from 'src/state/timedTakeout'
import strings from 'src/strings'
import { getDelivery, setDelivery } from 'src/state/delivery'
import { cycleActivityView } from 'src/state/ui/actions'
import { getDatedTakeout, setDatedTakeout } from 'src/state/datedTakeout'
import { useTranslation } from 'src/hooks/useTranslation'
import VenueSelect from 'src/components/VenueSelect'
import { decoratePhone } from 'src/utils/phone'
import useAppPreferences from 'src/hooks/useAppPreferences'
import { transformTakeAwayDateTime } from 'src/utils/strings'
import { getServicesStoplistOwnDeliveryItems } from 'src/state/services-stoplist/selectors'
import { fetchHistoryThunk } from 'src/state/history/thunkActions'
import LiqPayWidget from 'src/components/LiqPayWidget'
import useUnpaidOrders from 'src/hooks/useUnpaidOrders.ts'
import useOrdersWarnings from 'src/hooks/ShoppingCart/useOrdersWarnings.ts'
import { SERVICES_TYPE } from 'src/types/index.ts'
import { CheckItem } from './CheckItem'
import s from './styles.module.scss'

const Checkout = () => {
  const { t } = useTranslation()
  const month = t('general.longMonth').split(',')
  const ownDeliveryStoplist = useSelector(getServicesStoplistOwnDeliveryItems)
  const [liqpayData, setLiqpayData] = useState({})
  const [userLocation, setUserLocation] = useState({})
  const status = useSelector(state => state.status)
  const [isSuccessPaid, setIsSuccessPaid] = useState(false)
  const [isVenue, setVenue] = useState(false)
  const dispatch = useDispatch()
  const delivery = useSelector(getDelivery)
  const timedTakeout = useSelector(getTimedTakeout)
  const unpaiders = useSelector(getRoles)
  const userData = useSelector(getUserData)
  const datedTakeout = useSelector(getDatedTakeout)
  const userIp = useSelector(getUserIp)
  const tableNum = useSelector(getTableNum)
  const takeout = useSelector(getTakeout)
  const venues = useSelector(getVenues)
  const [askUserLocationData, setAskUserLocationData] = useState(null)
  const [isUserAskedLocation, setIsUserAskedLocation] = useState(false)
  const reliableVenues = useSelector(getAllVenuesStatuses).filter(v => v.status === VENUE_STATUSES.OPEN)
  const venueId = useSelector(getVenueId)
  const venue = useSelector(getVenueById)(venueId)
  const venueStatus = useSelector(getVenueStatus)
  const dishes = useSelector(getDishes)
  const comment = useSelector(getComment)
  const optionsets = useSelector(getOptionsets)
  const lastChange = useSelector(getScanDate)
  const currentLangague = useSelector(getLang)
  const { preferences } = useAppPreferences()
  const [
    { groupedOrders, orders, totalPrice, cookingTime, isUnderMinOrderAmount, preorderInfo, includesCutleryDishes }
  ] = useUnpaidOrders()
  const { isDisabledDish, isDisabledOptions } = useOrdersWarnings(orders)
  const { activeServiceType } = useSelector(store => store.shoppingCard)
  const isActive = !tableNum && takeout && !delivery ? status.drinkToggler : false
  const venueAvailable = venueStatus === VENUE_STATUSES.OPEN || venueStatus === VENUE_STATUSES.NOT_SELECTED

  const venue1 = venues.find(e => parseInt(e.slug) === parseInt(venueId))
  const len1 = getDistanceFromLatLonInKm(
    userLocation ? userLocation.lat : parseFloat(venue1.lat),
    userLocation ? userLocation.lng : parseFloat(venue1.lng),
    parseFloat(venue1.lat),
    parseFloat(venue1.lng)
  )
  const len2 = getDistanceFromLatLonInKm(
    userLocation ? userLocation.lat : parseFloat(venue1.lat),
    userLocation ? userLocation.lng : parseFloat(venue1.lng),
    parseFloat(askUserLocationData?.lat),
    parseFloat(askUserLocationData?.lng)
  )

  const getGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        console.log('[position]', position)
        setUserLocation({
          lat: parseFloat(position.coords.latitude),
          lng: parseFloat(position.coords.longitude)
        })
      })
    } else {
      console.log('Immediately return grandpa phone, son')
    }
  }

  const getCurrentServiceType = () => {
    if (activeServiceType === SERVICES_TYPE.DELIVERY_TAXI) {
      if (!isUnderMinOrderAmount) {
        if (ownDeliveryStoplist?.length && timedTakeout) {
          const isInStoplist = ownDeliveryStoplist.some(ownDeliveryStoplistItem => {
            return (
              timedTakeout >= new Date(ownDeliveryStoplistItem.startingAt).getTime() &&
              timedTakeout <= new Date(ownDeliveryStoplistItem.endingAt).getTime()
            )
          })
          if (isInStoplist) {
            return SERVICES_TYPE.DELIVERY_TAXI
          }
        }
        return SERVICES_TYPE.DELIVERY_OWN
      }
    }
    return activeServiceType
  }

  useEffect(() => {
    if (!askUserLocationData && !isUserAskedLocation && userIp.length) {
      const isIp = reliableVenues.filter(v => v.ip === userIp)

      if (isIp.length > 0) {
        if (parseInt(isIp[0].slug) !== parseInt(venueId)) {
          setAskUserLocationData(isIp[0])
        } else if (Date.now() - new Date(lastChange) > 2 * 3600 * 1000) {
          getGeoLocation()
        }
      }
    }
  }, [askUserLocationData, isUserAskedLocation, userIp])

  useEffect(() => {
    if (isSuccessPaid) {
      paidFromWidgetUpdate(liqpayData.takeoutNum)
      setTimeout(() => {
        dispatch(fetchHistoryThunk())
      }, 500)
      dispatch(
        removeUnpaid({
          venueSlug: venueId,
          paymentId: liqpayData.paymentId,
          takeoutNum: liqpayData.takeoutNum,
          timedTakeout: liqpayData.timedTakeout,
          info: liqpayData.info
        })
      )
      dispatch(addComment(''))
      dispatch(setTimedTakeout(false))
      dispatch(toggleCheck(false))
      dispatch(setDelivery(null))
      setIsSuccessPaid(false)
    }
  }, [isSuccessPaid])

  useEffect(() => {
    // console.log(userLocation)
    if (userLocation.lat && userLocation.lng && !askUserLocationData && !isUserAskedLocation) {
      const dist = reliableVenues
        .map(v => {
          return {
            ...v,
            distance: getDistanceFromLatLonInKm(
              userLocation.lat,
              userLocation.lng,
              parseFloat(v.lat),
              parseFloat(v.lng)
            )
          }
        })
        .filter(v => v.distance < 1)
        .sort((a, b) => a.distance - b.distance)
      // console.log(dist)
      if (dist.length > 0) {
        if (parseInt(dist[0].slug) !== parseInt(venueId)) {
          setAskUserLocationData(dist[0])
        }
      }
    }
  }, [userLocation, askUserLocationData, isUserAskedLocation])

  const handleCloseCheckout = () => {
    postEvent('checkout-btn_close')
    dispatch(toggleCheck(false))
  }

  /**
   *
   * @returns {string}
   */

  const getUpdatedUserComment = () => {
    let text = ''

    if (comment) {
      text = comment
    }

    if (isActive) {
      text = `${text} ${comment ? '. ' : ''}${t('paymentSection.gotovitNapitkiCashier')}`
    }

    if (
      (delivery || takeout) &&
      includesCutleryDishes &&
      (delivery || takeout) &&
      window.busytable.brandSlug !== 'nm'
    ) {
      text = `${text} ${comment || isActive ? '. ' : ''}${
        status.isApplianceActive ? t('paymentSection.addAppliance') : t('paymentSection.dontAddAppliance')
      }`
    }

    return text
  }

  useEffect(() => {
    const isTimedTakeoutPresent = timedTakeout && timedTakeout !== 'false'
    const timedDate = isTimedTakeoutPresent ? transformTimestampToString(timedTakeout) : null

    const load = orders.map(x => ({
      tempId: x.tempId,
      dishId: x.dishId,
      takeout,
      dishPrice: x.price,
      optionsPrice: calcOptionsCost(x.selectedOptions, optionsets),
      options: x.selectedOptions,
      timedTakeout: timedDate
    }))
    // TODO: временный код до тех пор пока venueSlug не будет получаться из localStorage

    const getLiqpayData = async () => {
      try {
        const response = await axios.post(
          `${config.API_URL3}/${window.busytable.brandSlug}/payments`,
          {
            status: {
              dishAdded: Date.now() - new Date(status.dishAdded),
              started: Date.now() - new Date(status.started)
            },
            venueSlug: venueId,
            delivery: delivery
              ? delivery.type === SERVICES_TYPE.DELIVERY_TAXI
                ? {
                    address: delivery.isRecipientSomeone ? delivery.address : userData.address,
                    type: 'taxi',
                    phone: delivery.isRecipientSomeone ? delivery.phone : userData.phone,
                    name: delivery.isRecipientSomeone ? delivery.name : userData.name
                  }
                : delivery
              : undefined,
            amountBeforeDiscount: totalPrice,
            amountPaid: totalPrice,
            serviceType: getCurrentServiceType(),
            tableNum: venueAvailable ? tableNum : null,
            load,
            comment: getUpdatedUserComment(),
            timedTakeout: timedDate,
            preorder: !!preorderInfo,
            info: { cookingTime }
          },
          { withCredentials: true }
        )

        if (response.data) {
          if (response.data.success === true) {
            setLiqpayData(response.data.result)
          }
        }
      } catch (e) {
        if (e.response?.status === 401) {
          window.location.reload()
        }
      }
    }
    getLiqpayData()
  }, []) // eslint-disable-line

  /**
   * selecting unpaider role
   */
  const myUnpaiderRole = useMemo(() => {
    return userData && unpaiders?.find(e => userData.userRole === e.key)
  }, [unpaiders, userData])

  // проверить факт загрузки виджета

  const renderTakeoutTime = () => {
    if (delivery) {
      return formatterDelivery(
        t,
        timedTakeout,
        currentLangague,
        preferences?.preorderOnFutureDaysNumber,
        preferences?.timePickerStep
      )
    }
    return transformTakeAwayDateTime(t, timedTakeout, currentLangague, preferences?.preorderOnFutureDaysNumber)
  }

  const onLiqpayCallBack = data => {
    dispatch(cycleActivityView({ id: 1, current: -window.innerHeight + 101 }))
    dispatch(cycleActivityView({ id: 1, current: -window.innerHeight + 100 }))

    if (data.status === 'success' || data.status === 'wait_accept') {
      setIsSuccessPaid(true)
    }
  }

  // const onLiqPayReady = sendLogs => {
  //   if (sendLogs) {
  //     axios.post(
  //       `${config.API_URL}/${window.busytable.brandSlug}/log`,
  //       JSON.stringify({
  //         type: '[app] minor log',
  //         error: 'Liqpay ready'
  //       }),
  //       {
  //         headers: {
  //           withCredentials: true,
  //           'Content-Type': 'application/json',
  //           'x-url': window.location.href,
  //           credentials: 'include'
  //         },
  //         withCredentials: true
  //       }
  //     )
  //   }
  // }

  const renderLiqpay = () => {
    if (!preorderInfo && (isDisabledDish || isDisabledOptions)) {
      return <></>
    }

    if (isEmpty(liqpayData)) {
      return (
        <h4 className={s.loadTitle}>
          {t('checkout.paymethod')}
          <br />
          <div className={s.load}>
            <div />
            <div />
            <div />
            <div />
          </div>
        </h4>
      )
    }

    if (myUnpaiderRole) {
      return (
        <div className={s.payWrapper}>
          <div
            className={[s.pay, s.unpaidersColor].join(' ')}
            onClick={() => {
              //todo
              postEvent('checkout-btn_pay', 'payment')
              fetchPayFree(liqpayData, dispatch, venueId)
            }}
          >
            {t('general.pay')}
            <span style={{ fontSize: '12px' }}>({myUnpaiderRole.value})</span>
          </div>
        </div>
      )
    }

    return (
      <LiqPayWidget
        data={liqpayData.data}
        signature={liqpayData.signature}
        callback={onLiqpayCallBack}
        // onReady={onLiqPayReady}
      />
    )
  }

  return (
    <>
      <Wrapper
        zIndex='345'
        style={{
          background: 'var(--checkout-bg,#f2f2f2)',
          WebkitOverflowScrolling: 'touch',
          position: window.innerWidth > 1025 ? 'absolute' : 'fixed'
        }}
      >
        {!isUserAskedLocation && askUserLocationData && askUserLocationData.name ? (
          <ChangeLocationView
            venue1={venue1.name}
            N={isNaN(len1) ? 0 : Math.round((len1 * 1000) / 50) * 50}
            M={isNaN(len2) ? 0 : Math.round((len2 * 1000) / 50) * 50}
            venue2={askUserLocationData.name}
            choice1={() => {
              postEvent('modal-venue_selector-btn_current', 'payment', {
                closest: askUserLocationData.name,
                current: venue1.name,
                distToClosest: len2,
                distToCurrent: len1
              })
              setIsUserAskedLocation(true)
            }}
            choice2={() => {
              postEvent('modal-venue_selector-btn_closest', 'payment', {
                closest: askUserLocationData.name,
                current: venue1.name,
                distToClosest: len2,
                distToCurrent: len1
              })
              dispatch(setVenueId(askUserLocationData.slug))
              dispatch(toggleCheck(false))
              setIsUserAskedLocation(true)
            }}
            rightClick={() => {
              setIsUserAskedLocation(true)
              setVenue(true)
            }}
          />
        ) : null}
        {isVenue && (
          <div style={{ zIndex: '294', position: 'absolute' }}>
            <VenueSelect
              onClose={() => {
                setVenue(false)
                setAskUserLocationData(null)
              }}
            />
          </div>
        )}
        <MenuBar
          title={
            <div className={s.center}>
              <div className={s.newTitle}>{!venue ? strings.defaultVenue : venue.brand && t(venue.brand.name)}</div>
              <div className={s.newSubtitle}>{!venue ? t('tableNumber.notSelectedVenue') : t(venue.name)}</div>
            </div>
          }
          icon='close'
          eventCb={handleCloseCheckout}
        />

        <div className={s.containerWrap}>
          <div style={{ margin: '12px 16px' }}>
            {!preorderInfo && (isDisabledDish || isDisabledOptions) ? (
              <div style={{ borderRadius: '0px' }} className={[s.contextedWrapper, s.payWarn, s.warnText].join(' ')}>
                <WarnIcon style={{ marginRight: '8px' }} /> {t('checkout.stoplist')}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className={s.container}>
            {!isEmpty(groupedOrders) ? (
              <>
                <div style={{ display: 'flex', position: 'relative' }} className={s.header}>
                  {delivery ? (
                    <div className={s.checkRow}>
                      <DeliveryIcon width={32} height={32} fill={'#000000'} />{' '}
                      <div style={{ marginLeft: '8px' }} className={s.anotherFont}>
                        {liqpayData.takeoutNum}
                      </div>
                    </div>
                  ) : !takeout ? (
                    <div className={s.checkRow}>
                      <TableIcon style={{ opacity: '.5' }} width={24} height={24} fill={'#000000'} />{' '}
                      <div style={{ marginLeft: '8px' }} className={s.anotherFont}>
                        {tableNum}
                      </div>
                    </div>
                  ) : (
                    <div className={s.checkRow}>
                      <TogoIcon style={{ opacity: '.5' }} width='24' height='24' stroke={'#000000'} />
                      <div
                        style={{
                          marginLeft: '8px',
                          display: 'flex',
                          alignItems: tableNum ? 'center' : null,
                          flexDirection: tableNum ? 'row' : 'column'
                        }}
                      >
                        {venueAvailable && tableNum ? (
                          <>
                            <TableIcon style={{ opacity: '.5' }} width={20} height={20} fill={'#000000'} />
                            &nbsp;{tableNum}
                          </>
                        ) : (
                          t('orderSection.zone')
                        )}
                      </div>
                    </div>
                  )}
                  {timedTakeout || datedTakeout ? (
                    <div className={s.checkTimedTakeout}>
                      <TimeIcon width={24} height={24} fill={'#555555'} />{' '}
                      <div style={{ marginLeft: '8px' }}>
                        {/* {timedTakeout ? timedTakeout : ''}
                        {datedTakeout
                          ? ` ${new Date(datedTakeout).getDate()} ${month[new Date(datedTakeout).getMonth()]}`
                          : timedTakeout && timedTakeout.indexOf('Завтра') >= 0
                          ? ''
                          : ` ${t('general.today')}`} */}
                        {renderTakeoutTime()}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className={s.checkHR} />
                {delivery ? (
                  <div style={{ marginTop: '12px' }} className={s.commentCheck}>
                    <LocationIcon width={12} height={12} />
                    <div style={{ marginLeft: '16px' }}>
                      {delivery.type === SERVICES_TYPE.DELIVERY_TAXI ? t('deliveryTaxi') : t('deliveryNovaposhta')}
                    </div>
                  </div>
                ) : null}
                <div style={{ marginTop: '12px' }} className={s.commentCheck}>
                  <LocationIcon width={12} height={12} />
                  <div style={{ marginLeft: '16px' }}>{t(venue.name)}</div>
                </div>
                {delivery && !delivery.isRecipientSomeone && delivery.type !== SERVICES_TYPE.DELIVERY_NOVAPOSHTA ? (
                  <div style={{ marginTop: '12px' }} className={s.commentCheck}>
                    <HomeIcon fill={'#B3B3B3'} width={12} height={12} />
                    <div style={{ marginLeft: '16px' }}>{userData.address}</div>
                  </div>
                ) : null}

                {userData && userData.phone && !(delivery && delivery.type === SERVICES_TYPE.DELIVERY_NOVAPOSHTA) ? (
                  <div style={{ marginTop: '12px' }} className={s.commentCheck}>
                    <PhoneIcon width={12} height={12} />
                    <div style={{ marginLeft: '16px' }}>{decoratePhone(userData.phone)}</div>
                  </div>
                ) : null}

                {datedTakeout ? (
                  <div style={{ marginTop: '12px' }} className={s.commentCheck}>
                    <DateIcon width={12} height={12} />
                    <div style={{ marginLeft: '16px' }}>
                      {datedTakeout
                        ? `${new Date(datedTakeout).getDate()} ${month[new Date(datedTakeout).getMonth()]}`
                        : 'Сегодня'}
                    </div>
                  </div>
                ) : null}

                {userData && userData.name && !(delivery && delivery.type === SERVICES_TYPE.DELIVERY_NOVAPOSHTA) ? (
                  <div style={{ marginTop: '12px' }} className={s.commentCheck}>
                    <UserIcon width={12} height={12} />
                    <div style={{ marginLeft: '16px' }}>{userData.name}</div>
                  </div>
                ) : null}

                {delivery &&
                  delivery.name &&
                  (delivery.isRecipientSomeone || delivery.type === SERVICES_TYPE.DELIVERY_NOVAPOSHTA) && (
                    <div style={{ marginTop: '12px' }} className={s.commentCheck}>
                      <PersonIcon width={12} height={12} />
                      <div style={{ marginLeft: '16px' }}>{delivery.name}</div>
                    </div>
                  )}
                {delivery &&
                  delivery.address &&
                  (delivery.isRecipientSomeone || delivery.type === SERVICES_TYPE.DELIVERY_NOVAPOSHTA) && (
                    <div style={{ marginTop: '12px' }} className={s.commentCheck}>
                      <PoiIcon width={12} height={12} />
                      <div style={{ marginLeft: '16px' }}>{delivery.address}</div>
                    </div>
                  )}
                {delivery && delivery.type === SERVICES_TYPE.DELIVERY_NOVAPOSHTA && (
                  <div style={{ marginTop: '12px' }} className={s.commentCheck}>
                    <DiesIcon width={12} height={12} />
                    <div style={{ marginLeft: '16px' }}>{delivery.department}</div>
                  </div>
                )}
                {delivery &&
                  delivery.phone &&
                  (delivery.isRecipientSomeone || delivery.type === SERVICES_TYPE.DELIVERY_NOVAPOSHTA) && (
                    <div style={{ marginTop: '12px' }} className={s.commentCheck}>
                      <PhoneIcon width={12} height={12} />
                      <div style={{ marginLeft: '16px' }}>{decoratePhone(delivery.phone)}</div>
                    </div>
                  )}

                <div style={{ marginTop: '12px' }} className={s.commentCheck}>
                  {getUpdatedUserComment()}
                </div>
                <div className={s.checkHR} />
                <div className={s.priceList}>
                  {groupedOrders.map((order, index) => (
                    <CheckItem key={index} order={order} dishes={dishes} />
                  ))}
                </div>
              </>
            ) : null}
          </div>
        </div>

        {renderLiqpay()}
      </Wrapper>
    </>
  )
}

export default Checkout
