import { useSelector } from 'react-redux'
import { getVenueId } from '../../state/location'
import { getVenueById } from '../../state/venues'

export const useVenueOpenAt = (timestamp = new Date().getTime()) => {
  const venueId = useSelector(getVenueId)
  const venue = useSelector(getVenueById)(venueId)

  if (!venue) return true
  const workingHours = venue.workingHours

  const daysOfWeek = {
    0: 'sun',
    1: 'mon',
    2: 'tue',
    3: 'wed',
    4: 'thu',
    5: 'fri',
    6: 'sat'
  } as { [key in number]: string }

  const d = new Date(timestamp)
  const open = new Date(timestamp)
  const close = new Date(timestamp)

  const targetDayOfWeek = daysOfWeek[d.getDay()]
  const [openTime, closeTime] = workingHours[targetDayOfWeek]
  const openHours = openTime.split(':') as number[]
  const closeHours = closeTime.split(':') as number[]

  open.setHours(openHours[0], openHours[1], 0, 0)
  close.setHours(closeHours[0], closeHours[1], 0, 0)

  return open <= d && d <= close
}
