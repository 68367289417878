import { SET_DEMO_VARIABLES, SET_DEMO_ICONS, SET_DEMO_THEME, SET_DEMO_THEME_SETTING } from './types'
import { ITheme, ThemeIcons, ThemeVariables } from '../../types'
import { DemoThemeActionsType } from './actions'

export type DemoThemeState = Readonly<{
  demoTheme: ITheme,
  demoVariables: ThemeVariables | null,
  demoIcons: ThemeIcons | null
}>

const initialState: DemoThemeState = {
  demoTheme: {},
  demoVariables: null,
  demoIcons: null
}

export default function demoThemeReducer(state: DemoThemeState = initialState, action: DemoThemeActionsType): DemoThemeState {
  switch (action.type) {
    case SET_DEMO_THEME: {
      return {
        ...state,
        demoTheme: action.theme
      }
    }

    case SET_DEMO_VARIABLES: {
      return {
        ...state,
        demoVariables: {
          ...state.demoVariables,
          ...action.variables
        }
      }
    }

    case SET_DEMO_ICONS: {
      return {
        ...state,
        demoIcons: action.icons
      }
    }

    case SET_DEMO_THEME_SETTING: {
      return {
        ...state,
        demoTheme: {
          ...state.demoTheme,
          ...action.setting
        }
      }
    }

    default:
      return state
  }
}
