import { motion } from 'framer-motion'
import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from '../../../hooks/useTranslation'
import { getSubcategories } from '../../../state/categories'
import s from '../styles.module.scss'
// import s from './RichMenu.module.scss'

const list = {
  visible: {
    transition: {
      staggerChildren: 0.04
      // delayChildren: 0.1
    }
  },
  hidden: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1
    }
  }
}

const variants = {
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000, velocity: -100 }
    }
  },
  hidden: {
    x: 30,
    opacity: 0,
    transition: {
      x: { stiffness: 1000, velocity: -100 }
    }
  }
}

export const SubcatTabs = ({ handleSubtabClick, activeSubtab, activeTab }) => {
  const subcats = useSelector(getSubcategories)({ parentId: activeTab })
  const showActiveSubtab = subcatId => (subcatId === activeSubtab ? s.activeSubtab : '')

  const { t } = useTranslation()

  if (subcats.length < 2) return null

  return (
    <motion.div key={activeTab} initial='hidden' animate='visible' variants={list} className={s.subcatTabs}>
      <motion.div
        variants={variants}
        onClick={() => handleSubtabClick({ id: activeTab, name: 'all' })}
        className={activeSubtab === 0 ? s.activeSubtab : ''}
        key='0'
      >
        {t('subcat.all')}
      </motion.div>
      {subcats.map(subcat => (
        <motion.div
          variants={variants}
          onClick={() => handleSubtabClick(subcat)}
          className={showActiveSubtab(subcat.id)}
          key={subcat.id}
        >
          {t(subcat.name)}
        </motion.div>
      ))}
    </motion.div>
  )
}
