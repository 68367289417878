import { GET_SERVICES_STOPLIST_STARTED, GET_SERVICES_STOPLIST_ERROR, GET_SERVICES_STOPLIST_SUCCESS } from './types'

const initialState = {
  isLoading: false,
  error: undefined,
  servicesStoplist: []
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SERVICES_STOPLIST_STARTED: {
      return {
        isLoading: true,
        error: undefined
      }
    }
    case GET_SERVICES_STOPLIST_ERROR: {
      return {
        isLoading: false,
        error: action.error
      }
    }
    case GET_SERVICES_STOPLIST_SUCCESS: {
      return {
        isLoading: false,
        servicesStoplist: action.servicesStoplist,
        error: undefined
      }
    }
    default: {
      return state
    }
  }
}
