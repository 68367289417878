import React from 'react'
import { motion } from 'framer-motion'
import { ANIMATED_MEDIA_REGEX } from 'src/constants'
import { MediaItemType } from 'src/types'
import MediaCarousel from '../MediaCarousel'
import styles from './styles.module.scss'

type DishMediaType = {
  media: MediaItemType[]
  img: string
  activeMediaIndex: number
  handleActiveMediaIndex: (activeIndex: number) => void
  isExpanded: boolean
}

export const MenuDishMedia = React.forwardRef<HTMLDivElement, DishMediaType>(
  ({ media, img, activeMediaIndex, handleActiveMediaIndex, isExpanded }) => {
    const singleMedia = media[0]

    return (
      <motion.div className={styles.carouseImageWrapper}>
        {isExpanded && media?.length > 1 && (
          <MediaCarousel
            activeIndex={activeMediaIndex}
            handleActiveMediaIndex={handleActiveMediaIndex}
            indicatorDirection='horizontal'
            isHorizontal={true}
            isMenu={false}
            medias={media}
            isExpanded={isExpanded}
          />
        )}
        {(media?.length < 2 && singleMedia && ANIMATED_MEDIA_REGEX.test(singleMedia.mediaType) && (
          <motion.div className={styles.carouselDishImage}>
            <video style={{ width: '100%', height: '100%' }} src={singleMedia.path} autoPlay controls={false} muted />
          </motion.div>
        )) ||
          ((!isExpanded || media?.length < 2) && singleMedia && (
            <motion.div
              style={{ backgroundImage: `url('${singleMedia?.path}')` }}
              className={styles.carouselDishImage}
            />
          ))}
        {!singleMedia && img && (
          <motion.div style={{ backgroundImage: `url('/${img}')` }} className={styles.carouselDishImage} />
        )}
      </motion.div>
    )
  }
)
