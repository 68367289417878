import React, { useState, useRef, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { motion, MotionStyle } from 'framer-motion'
import useAppPreferences from 'src/hooks/useAppPreferences'
import useThemeStyles from 'src/hooks/useThemeStyles'
import { useTranslation } from 'src/hooks/useTranslation'
import smoothscroll from 'smoothscroll-polyfill'
import { getMenuCardCoords, getActivityViewCurrent } from 'src/state/ui/selectors'
import { AppState } from 'src/state/store'
import { getDishes, getOptionsetsByRecipeId, getSelectedOptions } from 'src/state/menu/selectors'
import { getStoplist } from 'src/state/stoplist'
import { getOrdersNew } from 'src/state/orders/selectors'
import { arraysEqual, calcOptionsCost, isDishInStoplist } from 'src/utils'
import { addNewOrder, removeOneOrder, updateOrder } from 'src/state/orders/actions'
import { addAddingAnim } from 'src/state/addingAnim/actions'
import { postEvent } from 'src/api'
import { IDish, IOrder, OptionSetType, SelectedOptionType, Tag } from 'src/types'
import useCurrentDishes from '../../hooks/useCurrentDishes'
import s from './styles.module.scss'
import { ReactComponent as CloseIcon } from '../../assets/btn-back-24.svg'
import { LikeWrapper } from '../RichMenu/components/LikeWrapper'
import { DishMedia } from './DishMedia'
import { DishTabContainer } from '../ui/DishTabContainer'
import { SizeOptions } from './SizeOptions'
import { ReactComponent as MinusIcon } from '../../assets/ico-remove.svg'
import { ReactComponent as PlusIcon } from '../../assets/plus.svg'
import Background from '../ui/Background'
import { setDishAdded } from '../../state/status/actions'
import { getOpenPositionValue } from './utils'
import { resetSelectedOptions, addSelectedOptions } from '../../state/menu'
import DishesTags from '../DishesTags'

smoothscroll.polyfill()

type DishCardProps = {
  dishId: number
  hideDishCard: () => void
  style?: React.CSSProperties
}

const DishCard: React.FC<DishCardProps> = ({ dishId, hideDishCard }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { dish } = useCurrentDishes(dishId) as { dish: IDish }
  const optionSets = useSelector<AppState, OptionSetType[]>(getOptionsetsByRecipeId(dish?.recipeId || 0)) || []
  const selectedOptions = useSelector(getSelectedOptions)

  const localSelectedOptions = useRef<SelectedOptionType[]>(selectedOptions)

  const { demoVariables } = useSelector((state: AppState) => state.demo)
  const coords = useSelector(getMenuCardCoords)
  const stoplist = useSelector(getStoplist)
  const ordersNew = useSelector(getOrdersNew) as IOrder[]
  const { current } = useSelector(getActivityViewCurrent)(1)
  const dishes = useSelector(getDishes) as IDish[]

  const { preferences } = useAppPreferences()
  const { variables, dishCardBranding } = useThemeStyles()
  const { t } = useTranslation()

  const [adding, setAdd] = useState<boolean>(false)
  const [isCardOpen, setIsCardOpen] = useState<boolean>(true)
  const [isOnScrollTopLimit, setIsOnScrollTopLimit] = useState<boolean>(false)

  const scrollRef = useRef<HTMLDivElement>(null)
  const dishImageRef = useRef<HTMLDivElement>(null)

  const isSizeChanged = location.pathname.includes('size-changed')
  const dishWithSizes = !!dish?.parentSizeId || dishes.some(dishItem => dishItem.parentSizeId === dishId)

  useEffect(() => {
    setTimeout(() => {
      if (adding) {
        handleAddChange(false)
      }
    }, 2000)
  }, [adding])

  const handleAddChange = (adding: boolean) => {
    setAdd(adding)
  }

  const handleTabChange = () => {
    scrollRef?.current?.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const replaceOptions = useMemo(
    () => (): void => {
      const ordersWithCurrentDishId = ordersNew.filter(order => order.dishId === dishId)
      /**
       * checking if there is only 1 dish in shopping cart with the same id
       */
      if (ordersWithCurrentDishId?.length === 1) {
        const currentRecordedOrder = ordersWithCurrentDishId[0]

        if (
          currentRecordedOrder &&
          !arraysEqual(selectedOptions, localSelectedOptions.current) &&
          !arraysEqual(selectedOptions, currentRecordedOrder.selectedOptions)
        ) {
          postEvent('dish-options-replaced')

          dispatch(
            updateOrder({
              ...currentRecordedOrder,
              selectedOptions
            })
          )
        }
      }
    },
    [selectedOptions, localSelectedOptions.current, dishId, ordersNew, dispatch]
  )

  useEffect(() => {
    if (dishImageRef && dishImageRef.current) {
      const navHeight = 48
      const imageHeight = window.innerWidth
      const threshold = navHeight / imageHeight
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].intersectionRatio <= threshold && !isOnScrollTopLimit) {
            setIsOnScrollTopLimit(true)
          } else if (entries[0].intersectionRatio >= threshold && isOnScrollTopLimit) {
            setIsOnScrollTopLimit(false)
          }
        },
        {
          threshold: [threshold]
        }
      )

      observer.observe(dishImageRef.current)

      return () => {
        observer.disconnect()
      }
    }
  }, [dishImageRef?.current?.offsetHeight, isOnScrollTopLimit])

  useEffect(() => {
    if (current === getOpenPositionValue()) {
      replaceOptions()
    }
  }, [current, replaceOptions])

  useEffect(
    () => () => {
      dispatch(resetSelectedOptions())
    },
    [dispatch]
  )

  useEffect(() => {
    if (optionSets?.length) {
      let updatedOptions = [...selectedOptions]
      const optionSetsWithShowDefault = optionSets.filter(
        optionSet =>
          optionSet.showDefault && !selectedOptions.some(selectedOption => selectedOption.id === optionSet.id)
      )
      optionSetsWithShowDefault.forEach(optionSet => {
        const defaultOption = optionSet?.options?.find(option => option.default)
        if (defaultOption) {
          updatedOptions = [...updatedOptions, { id: optionSet.id, value: defaultOption?.id }]
        }
      })
      dispatch(addSelectedOptions(updatedOptions))
      localSelectedOptions.current = updatedOptions
    }
  }, [optionSets.length])

  // пересчитывать общую стоимость заказа при изменении опций
  const totalPrice = useMemo(() => parseInt(dish?.price + calcOptionsCost(selectedOptions, optionSets)), [
    selectedOptions,
    optionSets,
    dish
  ])

  const isOrderInCart = useMemo(() => {
    if (dish) {
      return ordersNew.filter(order => {
        return (
          Number(dish.id) === Number(order.dishId) &&
          JSON.stringify(order.selectedOptions?.sort((a, b) => a.id - b.id)) ===
            JSON.stringify(selectedOptions.sort((a, b) => a.id - b.id))
        )
      })
    }

    return []
  }, [dish, selectedOptions, ordersNew]) as IOrder[]

  const isDisabledDish = isDishInStoplist(stoplist, dish?.id)

  const transition = {
    ease: 'easeOut',
    duration: isSizeChanged ? 0 : 0.4
  }

  const cardVariants = {
    init: {
      y: coords ? coords.y : null,
      x: coords ? coords.x : null,
      width: coords ? coords.width : null,
      height: coords ? coords.height : null,
      transition
    },
    final: {
      y: 0,
      x: 0,
      width: window.innerWidth > 1025 ? 400 : window.innerWidth,
      height: '100%',
      transition
    },
    exit: {
      opacity: 0,
      y: window.innerHeight / 2,
      x: 16,
      width: window.innerWidth - 32,
      height: 176,
      transition: {
        ease: 'easeOut',
        duration: 0.4,
        opacity: {
          ease: 'circIn'
        }
      }
    }
  }

  const onCloseHandler = () => {
    setIsCardOpen(false)
    postEvent('dishcard-btn_back')

    replaceOptions()

    setTimeout(() => {
      hideDishCard()
    }, 400)
  }

  const onAddDish = () => {
    dispatch(setDishAdded())
    dispatch(addAddingAnim({ dish, selectedOptions, clicked: false }))
    dispatch(addNewOrder({ ...dish, selectedOptions }))

    postEvent('dishcard-btn_add_to_cart', 'ordering', {
      dish,
      options: selectedOptions,
      value: totalPrice
    })
  }

  if (!dish) return <div>{t('dishCard.error')}</div>

  return (
    <motion.div
      initial='init'
      animate={isCardOpen ? 'final' : 'exit'}
      variants={cardVariants}
      ref={scrollRef}
      // style={
      //   {
      //     '--viewBg': demoVariables?.dishCardBg || variables?.dishCardBg || null
      //   } as MotionStyle
      // }
      className={s.wrap}
    >
      <div style={isOnScrollTopLimit ? {} : { backgroundColor: 'transparent' }} className={s.dishNav}>
        <div onClick={onCloseHandler} data-cy='exitFromDishCard' className={`navbarIconWrapper32 left`}>
          <CloseIcon />
        </div>
        {isOnScrollTopLimit && (
          <DishesTags wrapperClassName={s.rightSectionScrolled} isDisabledDish={isDisabledDish} dish={dish} />
        )}

        {preferences?.withFavorites && !isOnScrollTopLimit ? (
          <LikeWrapper
            clickable={true}
            className={`navbarIconWrapper32 right`}
            dishId={dish.id}
            recipeId={dish.recipeId}
            options={selectedOptions}
          />
        ) : null}
      </div>

      <DishMedia ref={dishImageRef} img={dish?.img} media={dish?.media}>
        {!isOnScrollTopLimit && <DishesTags isDisabledDish={isDisabledDish} dish={dish} />}
      </DishMedia>

      <DishTabContainer
        currentOptions={selectedOptions}
        dishWithSizes={dishWithSizes}
        dish={dish}
        sizes={dishWithSizes ? <SizeOptions disabled={isDisabledDish} {...{ dish }} /> : null}
        isDisabledDish={isDisabledDish}
        handleTabChange={handleTabChange}
        isScrolled={isOnScrollTopLimit}
        additionalComponent={
          !preferences.disableShoppingCart && (
            <div className={s.add_block}>
              {isOrderInCart.length > 0 && (
                <button
                  onClick={() => {
                    dispatch(removeOneOrder(isOrderInCart[0].dishId, isOrderInCart[0].selectedOptions || []))
                    postEvent('dishcard-btn_remove_from_cart', 'ordering', {
                      dish,
                      options: selectedOptions,
                      value: totalPrice
                    })
                  }}
                  data-cy='removeButton'
                  className={s.rem_button}
                >
                  <MinusIcon />
                </button>
              )}
              {isOrderInCart.length > 0 && <div data-cy='dishCardNumberInOrder' className={s.add_text}>{isOrderInCart.length}</div>}
              <button onClick={onAddDish} data-cy='addButton' className={[s.add_button].join(' ')}>
                <PlusIcon />
              </button>
            </div>
          )
        }
      />
      {dishCardBranding && <Background image={dishCardBranding} />}
    </motion.div>
  )
}

export default DishCard
