import { SET_DELIVERY } from './types'

const initialState = {
  data: null
}

export default function deliveryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DELIVERY: {
      const { data } = action.payload
      return {
        ...state,
        data
      }
    }

    default:
      return state
  }
}
