import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import useCurrentDishes from 'src/hooks/useCurrentDishes'
import { useTranslation } from 'src/hooks/useTranslation'
import { IDish } from 'src/types'
import { money } from 'src/utils'
import { IDishTab } from '.'
import { compareSizesPosition } from '../DishCard/utils'
import SegmentSwitch from '../ui/formControls/SegmentSwitch'
import styles from './styles.module.scss'

export interface IDishSizeToggle {
  tabs: IDishTab[]
  currentActiveTab: IDishTab | null
  currentActiveDish: IDish
  handleSize: (value: number) => void
}

const DishSizeToggle: React.FC<IDishSizeToggle> = ({ currentActiveDish, handleSize, tabs, currentActiveTab }) => {
  const { dishes } = useCurrentDishes()
  const { t } = useTranslation()

  const filteredDishes = dishes
    ?.filter(
      dish =>
        dish.id === currentActiveDish.id ||
        dish.parentSizeId === currentActiveDish.id ||
        currentActiveDish.parentSizeId === dish.id
    )
    .sort(compareSizesPosition)

  const renderCondition: boolean = useMemo(() => {
    if (!currentActiveTab) {
      return true
    }

    if (currentActiveTab?.type === 'options') {
      return true
    }

    if (currentActiveTab?.type === 'description' && !tabs.some(tab => tab.type === 'options')) {
      return true
    }

    if (
      currentActiveTab?.type === 'calories' &&
      !tabs.some(tab => tab.type === 'options' || tab.type === 'description')
    ) {
      return true
    }

    return false
  }, [tabs, currentActiveTab])

  if (filteredDishes?.length && filteredDishes.length > 1 && renderCondition) {
    return (
      <motion.div className={styles.dishSizeWrapper}>
        <div className={styles.dishSizeHeader}>
          <span className={styles.dishSizeTitle}>{t('sizeOptions.size')}</span>

          <div className={styles.dishSizeVolumeWrapper}>
            {(currentActiveDish?.volume || 0) > 0 && (
              <span>
                {currentActiveDish.volume} {t(currentActiveDish.unit)}
              </span>
            )}
            {currentActiveDish.id !== filteredDishes[0].id ? (
              <span className={styles.dishSizePriceDiff}>
                {`${currentActiveDish.price - filteredDishes[0].price > 0 ? '+' : '-'} ${money(
                  Math.abs(currentActiveDish.price - filteredDishes[0].price)
                )} ₴`}
              </span>
            ) : null}
          </div>
        </div>

        <SegmentSwitch
          options={filteredDishes.map(dish => {
            return {
              value: dish.id,
              label: t(dish.sizeName)
            }
          })}
          value={currentActiveDish.id}
          onChange={value => handleSize(value)}
        />
      </motion.div>
    )
  }

  return <></>
}
export default DishSizeToggle
