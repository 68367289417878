import React, { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'src/hooks/useTranslation'
import useSuggestedDishes from 'src/hooks/ShoppingCart/useSuggestedDishes'
import SuggestedDishList from './list'
import s from './styles.module.scss'
import { postEvent } from '../../api'
import { IDish } from '../../types'

type ShoppingCartSuggestedProps = {
  categoriesIds: (number | undefined)[]
}

const ShoppingCartSuggested: React.FC<ShoppingCartSuggestedProps> = function({ categoriesIds }) {
  const { t } = useTranslation()
  const { ref, inView } = useInView({
    threshold: 1,
    triggerOnce: true
  })

  const { suggestedDishes } = useSuggestedDishes(categoriesIds.filter(catId => Boolean(catId)) as number[])
  const localSuggestedDishes = useRef<IDish[]>(suggestedDishes)

  useEffect(() => {
    if (inView) {
      postEvent('suggested-dishes-show', 'ordering', {
        dishes: suggestedDishes.map(dish => dish.id)
      })
    }
  }, [inView])

  if (!localSuggestedDishes.current.length) {
    return null
  }

  return (
    <div className={s.shoppingCart} ref={ref}>
      <p className={s.shoppingCartTitle}>{t('shoppingCart.suggestedDishes')}</p>
      <SuggestedDishList dishes={localSuggestedDishes.current} />
    </div>
  )
}

export default ShoppingCartSuggested
