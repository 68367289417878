import { FETCH_VENUES, DISABLE_VENUE, GET_VENUE_SLUG } from './types'

export const fetchVenues = venues => ({
  type: FETCH_VENUES,
  venues
})

export const getDefaultVenueSlug = slug => ({
  type: GET_VENUE_SLUG,
  slug
})

export const disableVenue = (status, venueSlug) => ({
  type: DISABLE_VENUE,
  payload: {
    status,
    venueSlug
  }
})
