import { ADD_TRANSLATIONS, CHANGE_LANG } from './types'

export const addTranslations = translations => ({
  type: ADD_TRANSLATIONS,
  payload: { translations }
})

export const changeLang = () => ({
  type: CHANGE_LANG,
  payload: {}
})
