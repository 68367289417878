import React, { ReactNode, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { isInstagramIOS, money, calcOptionsCost } from 'src/utils'
import { WarnBadge } from 'src/components/DishCard/WarnBadge'
import { useTranslation } from 'src/hooks/useTranslation'
import s from './styles.module.scss'
import useThemeStyles from '../../hooks/useThemeStyles'
import { IDish, OptionSetType, SelectedOptionType } from '../../types'
import { AppState } from '../../state/store'
import { getVenueId, getVenueStatus, VENUE_STATUSES } from '../../state/location/selectors'
import DishMainTab from '../DishCard/DishMainTab'
import DishCalories from '../DishCard/DishCalories'
import { getVenueById } from '../../state/venues'
import { IconType } from '../RichMenu/components/IconType'
import { AddingAnimationContainer } from '../DishCard/AddingAnimationContainer'
import { getOptionsetsByRecipeId } from '../../state/menu'

type HandleTabChangeFunc = () => void

type DishTabContainerProps = {
  additionalComponent?: ReactNode
  dish: IDish
  isDisabledDish: boolean
  handleTabChange?: HandleTabChangeFunc
  isScrolled: boolean
  sizes?: ReactNode
  currentOptions: SelectedOptionType[]
  dishWithSizes: boolean
}

export const DishTabContainer: React.FC<DishTabContainerProps> = ({
  additionalComponent,
  dish,
  isDisabledDish,
  isScrolled,
  sizes,
  handleTabChange,
  currentOptions,
  dishWithSizes
}) => {
  const { t } = useTranslation()

  const currentVenueId = useSelector(getVenueId)
  const currentVenue = useSelector(getVenueById)(parseInt(currentVenueId))
  const optionSets = useSelector<AppState, OptionSetType[]>(getOptionsetsByRecipeId(dish.recipeId || 0)) || []
  const venueStatus = useSelector(getVenueStatus)
  const { variables } = useThemeStyles()
  const { demoVariables } = useSelector((state: AppState) => state.demo)

  const name = t(dish.name)
  const renderDisabledDishWarn = () => {
    if (isDisabledDish) {
      return (
        <div style={{ marginTop: '8px' }}>
          <WarnBadge>{t('waitingTimeBadge.outOfStock')}</WarnBadge>
        </div>
      )
    }
  }

  const DishVolume = () => {
    if (!dishWithSizes && dish?.volume && dish?.unit) {
      return (
        <div className={s.volumeWrapper}>
          <span>{dish.volume}</span>
          <span>{t(dish.unit)}</span>
        </div>
      )
    } else {
      return <></>
    }
  }

  const totalPrice = useMemo(
    (): number => parseInt(dish.price + calcOptionsCost(currentOptions, optionSets)),
    [dish, currentOptions, optionSets]
  )

  const formatPrice = isDisabledDish ? (
    <span className={s.endStock}>{money(totalPrice)}₴</span>
  ) : (
    `${money(totalPrice)}₴`
  )

  const formatName = (
    <>
      <div className={['iconType', isDisabledDish ? s.endStock : ''].join(' ')}>
        <IconType style={{ backgroundColor: 'var(--fontColor)' }} type={dish.icon} size={24} />
      </div>
      <span
        data-cy={`dishCardName-${dish.id}`}
        style={{ ...(isScrolled ? { display: '-webkit-box' } : {}) }}
        className={`${isDisabledDish ? s.endStock : ''} ${s.dishTitle}`}
      >
        {name}
      </span>
      <AddingAnimationContainer />
    </>
  )

  const hasCalories =
    (dish?.calories?.fat || dish?.calories?.kcal || dish?.calories?.carbs || dish?.calories?.protein) &&
    currentVenue?.settings?.calories

  return (
    <Tabs
      selectedTabClassName={'react-tabs__tab--selected'}
      style={{
        paddingBottom: isInstagramIOS() ? '120px' : '',
        position: 'relative',
        minHeight: !dish.img || dish.img.length === 0 ? 'calc(100vh - 400px)' : 'calc(100vh - 240px)'
      }}
      className={'react-tabs'}
    >
      <div
        style={
          {
            ...(hasCalories || isScrolled ? { boxShadow: ' 0px 1px 5px rgba(0, 0, 0, 0.1)' } : {}),
            '--viewBg': demoVariables?.dishCardTitleBackground || variables?.dishCardTitleBackground || null
          } as React.CSSProperties
        }
        className={[
          'sticky',
          venueStatus === VENUE_STATUSES.OPEN || venueStatus === VENUE_STATUSES.NOT_SELECTED ? '' : 'menuClosed'
        ].join(' ')}
      >
        <div className={s.title__wrap1}>
          <div className={s.dishTitleInnerWrapper}>
            <div>
              <div style={{ display: 'flex' }} className={'titleH2_1'}>
                {formatName}
              </div>
              {renderDisabledDishWarn()}
            </div>
            {!totalPrice || (
              <div className={s.priceWrapper}>
                <div className={'price'}>{formatPrice}</div>
                <DishVolume />
              </div>
            )}
          </div>
          {sizes}
          {additionalComponent}
        </div>
        <div className={s.tabListWrapper}>
          {hasCalories && (
            <TabList className={'react-tabs__list'}>
              <Tab
                onClick={() => {
                  if (handleTabChange) {
                    handleTabChange()
                  }
                }}
                className={'react-tabs__title'}
              >
                <div className='tab-title'>{t('dishCard.description')}</div>
              </Tab>
              <Tab
                onClick={() => {
                  if (handleTabChange) {
                    handleTabChange()
                  }
                }}
                className={'react-tabs__title'}
              >
                <div className='tab-title'>{t('dishCard.calories')}</div>
              </Tab>
            </TabList>
          )}
        </div>
      </div>

      {!hasCalories && (
        <div>
          <DishMainTab dish={dish} />
        </div>
      )}
      {hasCalories && (
        <>
          <TabPanel>
            <DishMainTab dish={dish} />
          </TabPanel>
          <TabPanel>
            <DishCalories calories={dish.calories} />
          </TabPanel>
        </>
      )}
    </Tabs>
  )
}
