import { SET_THEME, GET_ACTIVE_THEME_STARTED, GET_ACTIVE_THEME_ERROR, GET_ACTIVE_THEME_SUCCESS } from './types'

export const setTheme = data => ({
  type: SET_THEME,
  payload: { data }
})

export const getActiveThemeStarted = () => ({
  type: GET_ACTIVE_THEME_STARTED
})

/**
 *
 * @param {object} error
 */
export const getActiveThemeError = error => ({
  type: GET_ACTIVE_THEME_ERROR,
  error
})

/**
 *
 * @param {Theme} theme
 */
export const getActiveThemeSuccess = theme => ({
  type: GET_ACTIVE_THEME_SUCCESS,
  theme
})
