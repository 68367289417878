export const getCategories = store => store.categories
export const getCategoriesByMenuId = menuId => store =>
  store.categories.filter(category => (menuId ? category.menuId === menuId : category.defaultMenu))

/**
 * returns virtual Misc subcategory if parent has dishes and real subcategories exist
 */
export const getSubcategories =
  store =>
  ({ parentId }) =>
    getCategories(store).filter(x => x.parentId == parentId)
