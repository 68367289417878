import React from 'react'
import clsx from 'classnames'
import { ReactComponent as PlusIcon } from 'src/assets/plus.svg'
import styles from './styles.module.scss'

type PlusButtonProps = {
  onClick: (event: any) => void
  className?: string
  size?: number
}

const PlusButton: React.FC<PlusButtonProps> = function({ onClick, className, size }) {
  return (
    <button onClick={onClick} className={clsx(styles.plusButton, className)}>
      <PlusIcon width={size || 19} height={size || 19} />
    </button>
  )
}

export default PlusButton
