import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'src/state/hooks'
import { postEvent } from 'src/api'
import { getTakeout, setTakeout } from 'src/state/orders'
import { getDelivery, setDelivery } from 'src/state/delivery'
import { setTimedTakeout } from 'src/state/timedTakeout'
import { setDatedTakeout } from 'src/state/datedTakeout'
import { useTranslation } from 'src/hooks/useTranslation'
import useAppPreferences from 'src/hooks/useAppPreferences'
import useUnpaidOrders from 'src/hooks/useUnpaidOrders'
import { setServiceTypeAction } from 'src/state/shoppingCard/ShoppingCart.actions'
import { getVenueId } from 'src/state/location'
import { getVenueById } from 'src/state/venues'
import { IVenue, SERVICES_TYPE, VENUE_SERVICES_TYPE, VenueServicesType } from 'src/types'
import { useVenueOpenAt } from './useVenueOpenAt'
import SegmentSwitch from '../ui/formControls/SegmentSwitch'

export const SegmentButton: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const venueId = useSelector(getVenueId)
  const venue = useSelector(getVenueById)(venueId) as IVenue
  const delivery = useSelector(getDelivery)
  const takeout = useSelector(getTakeout)
  const { activeServiceType } = useSelector(store => store.shoppingCard)

  const { preferences } = useAppPreferences()
  const isVenueOpen = useVenueOpenAt()
  const [{ preorderInfo }] = useUnpaidOrders()

  const active = useMemo(() => {
    // @ts-ignore
    if ([SERVICES_TYPE.DELIVERY_TAXI, SERVICES_TYPE.DELIVERY_NOVAPOSHTA].includes(activeServiceType))
      return VENUE_SERVICES_TYPE.DELIVERY
    if (takeout) return VENUE_SERVICES_TYPE.TAKEOUT
    return Boolean(preorderInfo) ? VENUE_SERVICES_TYPE.TAKEOUT : VENUE_SERVICES_TYPE.HERE
  }, [delivery, takeout, preorderInfo, activeServiceType])

  const handleHere = useCallback(() => {
    dispatch(setDatedTakeout(false))
    dispatch(setTakeout(false))
    dispatch(setTimedTakeout(false))
    dispatch(setDelivery(null))
    dispatch(setServiceTypeAction(SERVICES_TYPE.HERE))
    postEvent('cart-service_type', 'ordering', { type: VENUE_SERVICES_TYPE.HERE })
  }, [dispatch])

  const handleTakeout = useCallback(() => {
    dispatch(setDelivery(null))
    dispatch(setTakeout(true))
    dispatch(setServiceTypeAction(SERVICES_TYPE.TAKEAWAY))
    postEvent('cart-service_type', 'ordering', { type: VENUE_SERVICES_TYPE.TAKEOUT })
  }, [dispatch])

  const handleDelivery = useCallback(
    (serviceType: SERVICES_TYPE) => {
      dispatch(setTakeout(true))
      dispatch(setServiceTypeAction(serviceType))
      if (!delivery) {
        dispatch(
          setDelivery({
            type: serviceType,
            isRecipientSomeone: false,
            name: '',
            address: '',
            phone: '',
            department: ''
          })
        )
      } else {
        dispatch(
          setDelivery({
            ...delivery,
            type: serviceType
          })
        )
      }
      postEvent('cart-service_type', 'ordering', { type: VENUE_SERVICES_TYPE.DELIVERY })
    },
    [dispatch, delivery]
  )

  const handleChangeServiceType = useCallback(
    (value: VenueServicesType) => {
      switch (value) {
        case VENUE_SERVICES_TYPE.HERE:
          handleHere()
          break
        case VENUE_SERVICES_TYPE.TAKEOUT:
          handleTakeout()
          break
        case VENUE_SERVICES_TYPE.DELIVERY:
          handleDelivery(SERVICES_TYPE.DELIVERY_TAXI)
          break
        default:
          break
      }
    },
    [handleHere, handleDelivery, handleTakeout]
  )

  useEffect(() => {
    if (!isVenueOpen && active === VENUE_SERVICES_TYPE.HERE && venue?.takeout) {
      handleTakeout()
    }
  }, [isVenueOpen, venue, handleTakeout, active])

  useEffect(() => {
    if (isVenueOpen && !activeServiceType && active === VENUE_SERVICES_TYPE.HERE) {
      dispatch(setServiceTypeAction(SERVICES_TYPE.HERE))
    } else if (!activeServiceType || (Boolean(preorderInfo) && activeServiceType === SERVICES_TYPE.HERE)) {
      handleTakeout()
    }
  }, [isVenueOpen, activeServiceType, active, preorderInfo, handleTakeout, dispatch])

  useEffect(() => {
    if (venue && !venue?.takeout && (preferences?.disableInVenueOrders || !venue?.here || !isVenueOpen) && !delivery) {
      handleDelivery(SERVICES_TYPE.DELIVERY_TAXI)
    }
  }, [preferences, isVenueOpen, venue, handleDelivery, delivery])

  useEffect(() => {
    // @ts-ignore
    if ([SERVICES_TYPE.DELIVERY_TAXI, SERVICES_TYPE.DELIVERY_NOVAPOSHTA].includes(activeServiceType) && !delivery) {
      // @ts-ignore
      handleDelivery(activeServiceType)
    }
    if (activeServiceType === SERVICES_TYPE.DELIVERY_NOVAPOSHTA && !preorderInfo) {
      handleDelivery(SERVICES_TYPE.DELIVERY_TAXI)
    }
  }, [activeServiceType, preorderInfo])

  const defaultServiceTypeOptions = [
    {
      value: VENUE_SERVICES_TYPE.TAKEOUT,
      label: t('segmentButton.togo'),
      disabled: venue && !venue?.takeout
    },
    {
      value: VENUE_SERVICES_TYPE.DELIVERY,
      label: t('segmentButton.delivery')
    }
  ]
  const extendedOptions = [
    {
      value: VENUE_SERVICES_TYPE.HERE,
      label: t('segmentButton.here'),
      disabled: (venue && (!venue?.here || !isVenueOpen)) || Boolean(preorderInfo)
    },
    ...defaultServiceTypeOptions
  ]

  return (
    <SegmentSwitch
      value={active}
      options={!preferences?.disableInVenueOrders ? extendedOptions : defaultServiceTypeOptions}
      onChange={handleChangeServiceType}
    />
  )
}
