import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setDemoThemeSetting, setDemoVariables } from '../state/demo/actions'
import { setDemoThemeThunk } from '../state/demo/thunkActions'

export default function useWindowMessageHandler() {
  const dispatch = useDispatch()

  useEffect(() => {
    const messageHandler = e => {
      const { type, data } = e.data
      if (type === 'set-demo-variables') {
        console.log('RECEIVING VARIABLES')
        dispatch(setDemoVariables(data))
      }
      if (type === 'set-demo-theme') {
        console.log('RECEIVING ACTIVE THEME')
        dispatch(setDemoThemeThunk(data))
      }
      if (type === 'set-demo-theme-setting') {
        dispatch(setDemoThemeSetting(data))
      }
    }
    window.addEventListener('message', messageHandler)

    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, [dispatch])
}
