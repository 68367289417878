import { IDish } from '../../types'
import useCurrentDishes from '../useCurrentDishes'
import { useMemo } from 'react'
import { SHOPPING_CART_SUGGESTED_MAP, SuggestedCategoryKey } from '../../constants'
import { getUniqueItemsByProperties } from '../../utils/general'

type UseSuggestedDishesValue = {
  suggestedDishes: IDish[]
}
type UseSuggestedDishesHook = (categoryIds: number[]) => UseSuggestedDishesValue

const useSuggestedDishes: UseSuggestedDishesHook = function(categoryIds) {
  const { dishes } = useCurrentDishes()

  const suggestedDishesByCategoryId = useMemo(
    () => (categoryId: number) => {
      const dishIds = SHOPPING_CART_SUGGESTED_MAP[categoryId as SuggestedCategoryKey]
      if (dishes && dishIds) {
        return dishes.filter(dish => dishIds.includes(dish.id))
      }
      return []
    },
    [dishes]
  )

  const allSuggestedDishes = useMemo(() => {
    const dishes = categoryIds.flatMap(catId => suggestedDishesByCategoryId(catId)) as object[]
    return getUniqueItemsByProperties(dishes, 'id') as IDish[]
  }, [categoryIds, suggestedDishesByCategoryId])

  return {
    suggestedDishes: allSuggestedDishes
  }
}

export default useSuggestedDishes
