import React, { useRef, useEffect, useState, useMemo, useCallback, useLayoutEffect } from 'react'
import { motion, useAnimation, useMotionValue, PanInfo } from 'framer-motion'
import styles from './styles.module.scss'
import { ICategory, IDish, OptionSetType, SelectedOptionType } from 'src/types'
import { useTranslation } from 'src/hooks/useTranslation'
import { calcOptionsCost, isDishInStoplist, money, arraysEqual } from 'src/utils'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { getStoplist } from 'src/state/stoplist'
import useAppPreferences from 'src/hooks/useAppPreferences'
import { LikeWrapper } from '../RichMenu/components/LikeWrapper'
import useCurrentCategories from 'src/hooks/useCurrentCategories'
import { useDispatch, useSelector } from 'react-redux'
import { getOrdersNew, updateOrder } from 'src/state/orders'
import { IconType } from '../RichMenu/components/IconType'
import useThemeStyles from 'src/hooks/useThemeStyles'
import DishSizeToggle from './DishSizeToggle'
import { DishOptions } from '../DishCard/DishOptions'
import DishTabs from './DishTabs'
import DishCalories from '../DishCard/DishCalories'
import ReactMarkdown from 'react-markdown'
import { addSelectedOptions, getOptionsetsByRecipeId, getSelectedOptions, resetSelectedOptions } from 'src/state/menu'
import { AppState } from 'src/state/store'
import useCurrentDishes from 'src/hooks/useCurrentDishes'
import { MenuDishMedia } from './MenuDishMedia'
import { getActivityViewCurrent, getMenuCardCoords, storeMenuCardCoords } from 'src/state/ui'
import DishesTags from '../DishesTags'
import { THEME_MODE } from 'src/globals/enumerations'
import { AddingAnimationContainer } from '../DishCard/AddingAnimationContainer'
import { postEvent } from 'src/api'
import { getOpenPositionValue } from '../DishCard/utils'
import DraggerIndicator from '../DraggerIndicator'
export interface IMenuDishCardProps {
  dishId?: number
  index?: number
  favoriteOptions?: SelectedOptionType[]
  handleSetCurrentOpenCard?: () => void
}

export type DishTabType = 'options' | 'description' | 'calories'
export interface IDishTab {
  type: DishTabType
  value: string
}

const MenuDishCard: React.FC<IMenuDishCardProps> = ({ dishId, index, favoriteOptions, handleSetCurrentOpenCard }) => {
  const cardRef = useRef<any | null>(null)
  const draggerRef = useRef<any | null>(null)
  const dishCardRef = useRef<any | null>(null)
  const dishContentHeaderRef = useRef<any | null>(null)
  const params = useParams<{ dishId?: string }>()
  const paramsDishId = +(params?.dishId || '')
  const shouldExpand: boolean = !!paramsDishId && !dishId
  const dispatch = useDispatch()
  const [optionsCost, setOptionsCost] = useState<number>(0)
  const { current } = useSelector(getActivityViewCurrent)(1)
  const [dishContentTabs, setDishContentTabs] = useState<IDishTab[]>([])
  const [isSticky, setIsSticky] = useState<boolean>(false)
  const [currentOpenTab, setCurrentOpenTab] = useState<IDishTab | null>(null)
  const [dishCardMaxHeight, setDishCardMaxHeight] = useState<number>(0)
  const selectedOptions = useSelector(getSelectedOptions) as SelectedOptionType[]
  const localSelectedOptions = useRef<SelectedOptionType[]>(selectedOptions)
  const [activeMediaIndex, setActiveMediaIndex] = useState<number>(0)
  const [shouldImageExpand, setShouldImageExpand] = useState<boolean>(false)
  const [isOnBorder, setIsOnBorder] = useState<boolean>(false)
  const handleActiveMediaIndex = (activeIndex: number) => setActiveMediaIndex(activeIndex)
  const { dish } = useCurrentDishes(dishId || paramsDishId) as { dish: IDish }
  const timing: number = 0.3
  const optionSets = useSelector<AppState, OptionSetType[]>(getOptionsetsByRecipeId(dish?.recipeId || 0)) || []
  const { t } = useTranslation()
  const { preferences } = useAppPreferences()
  const y = useMotionValue<number>(0)
  const wrapperY = useMotionValue<number>(0)
  const { demoVariables } = useSelector((state: AppState) => state.demo)
  const { variables, mode } = useThemeStyles()
  const history = useHistory()
  const location = useLocation()
  const ordersNew = useSelector(getOrdersNew)
  const topCoords = useSelector(getMenuCardCoords)
  const { categories, getActiveCategory } = useCurrentCategories()
  const { currentCategoryId: activeTab } = getActiveCategory()
  const stoplist = useSelector(getStoplist)
  const isDisabledDish = isDishInStoplist(stoplist, dish?.id)
  const areOtherColors = demoVariables?.dishCardBg || variables?.dishCardBg

  const dishInitiallyOpen: boolean = (location as any).state?.dishInitiallyOpen
  const isDishImageExist: boolean = !!dish?.media?.length || !!dish?.imgWide || !!dish?.img
  const cardContentOpenHeight: number = useMemo(
    () => (isDishImageExist ? window.innerWidth - window.innerWidth / 5 - 106 : -42),
    [isDishImageExist]
  )
  const cardContentOffsetTop: number = useMemo(
    () => (isDishImageExist ? window.innerWidth - window.innerWidth / 5 : -42),
    [isDishImageExist]
  )
  const isDarkMode: boolean = mode === THEME_MODE.DARK

  const defaultTransition = {
    easings: 'linear',
    duration: timing
  }

  const dishCardControls = useAnimation()
  const contentCardControls = useAnimation()
  const dragWrapperControls = useAnimation()
  const shadowControls = useAnimation()
  const headerControls = useAnimation()
  const contentHeaderControls = useAnimation()
  const contentControls = useAnimation()
  const dishImage = useAnimation()
  const gallery = useAnimation()

  const selectedCategory = useMemo(
    () => categories.find((category: ICategory) => category?.dishIds?.includes(dish?.id || 0)),
    [categories, dish?.id]
  )

  const isSameCategory = useMemo(() => selectedCategory?.id === activeTab || selectedCategory?.parentId === activeTab, [
    selectedCategory?.id,
    activeTab
  ])

  const replaceOptions = useMemo(
    () => (): void => {
      const ordersWithCurrentDishId = ordersNew.filter(order => order.dishId === dish.id)
      /**
       * checking if there is only 1 dish in shopping cart with the same id
       */
      if (ordersWithCurrentDishId?.length === 1) {
        const currentRecordedOrder = ordersWithCurrentDishId[0]

        if (
          currentRecordedOrder &&
          !arraysEqual(selectedOptions, localSelectedOptions.current) &&
          !arraysEqual(selectedOptions, currentRecordedOrder.selectedOptions)
        ) {
          postEvent('dish-options-replaced')

          dispatch(
            updateOrder({
              ...currentRecordedOrder,
              selectedOptions
            })
          )
        }
      }
    },
    [selectedOptions, localSelectedOptions.current, dish, ordersNew, dispatch]
  )

  useEffect(() => {
    if (paramsDishId) {
      return () => {
        dispatch(resetSelectedOptions())
      }
    }
  }, [dispatch, paramsDishId])

  useEffect(() => {
    if (current === getOpenPositionValue() && paramsDishId) {
      replaceOptions()
    }
  }, [current, replaceOptions, paramsDishId])

  useEffect(() => {
    if (optionSets?.length && shouldExpand) {
      let updatedOptions = [...selectedOptions]
      const optionSetsWithShowDefault = optionSets.filter(
        optionSet =>
          optionSet.showDefault && !selectedOptions.some(selectedOption => selectedOption.id === optionSet.id)
      )
      optionSetsWithShowDefault.forEach(optionSet => {
        const defaultOption = optionSet?.options?.find(option => option.default)
        if (defaultOption) {
          updatedOptions = [...updatedOptions, { id: optionSet.id, value: defaultOption?.id }]
        }
      })
      dispatch(addSelectedOptions(updatedOptions))
      localSelectedOptions.current = updatedOptions
    }
  }, [optionSets.length, shouldExpand])

  const selectedDishOrders = ordersNew.filter(order => {
    if (dish?.id === order.dishId) {
      if (shouldExpand && selectedOptions?.length) {
        if (order?.selectedOptions?.length) {
          return order.selectedOptions.every(option =>
            selectedOptions.some(
              selectedOption => selectedOption.id === option.id && selectedOption.value === option.value
            )
          )
        }

        return false
      } else if (favoriteOptions?.length) {
        if (order?.selectedOptions?.length) {
          return order.selectedOptions.every(option =>
            favoriteOptions.some(
              selectedOption => selectedOption.id === option.id && selectedOption.value === option.value
            )
          )
        }

        return false
      }

      return !order?.selectedOptions?.length
    }
    return false
  })

  useEffect(() => {
    if (favoriteOptions?.length) {
      let acc = 0

      favoriteOptions.forEach(option => {
        const currentOptionSet = optionSets.find(set => set.id === option.id)

        if (currentOptionSet) {
          const setValue = currentOptionSet.options.find(setOption => setOption.id === option.value)
          acc += setValue?.price || 0
        }
      })

      setOptionsCost(acc)
    }
  }, [favoriteOptions, optionSets])

  useEffect(() => {
    const dishTabs: IDishTab[] = []
    if (optionSets.length) {
      dishTabs.push({
        type: 'options',
        value: t('dishCard.options')
      })
    }
    if (t(dish?.desc)) {
      dishTabs.push({
        type: 'description',
        value: t('dishCard.description')
      })
    }
    if (dish?.calories && Object.values(dish.calories).some(caloriesValue => Boolean(caloriesValue))) {
      dishTabs.push({
        type: 'calories',
        value: t('dishCard.calories')
      })
    }

    setDishContentTabs(dishTabs)
    setCurrentOpenTab(dishTabs[0])
  }, [dish])

  useEffect(() => {
    if (shouldExpand && dishContentHeaderRef && dishContentHeaderRef.current) {
      const getThresholds = () => {
        const result = []
        for (let i = 0; i <= 1; i += 0.01) {
          result.push(i)
        }

        return result
      }

      const observer = new IntersectionObserver(
        () => {
          const currentDragPosition = y.get()

          if (currentDragPosition + cardContentOffsetTop < 0 && !isSticky && window.innerHeight < dishCardMaxHeight) {
            setIsSticky(true)
            dragWrapperControls.start({
              width: window.innerWidth,
              translateX: -8,
              transition: defaultTransition
            })
            contentCardControls.start({
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 8,
              borderTopLeftRadius: '0px',
              borderTopRightRadius: '0px',
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
              background: [
                isDarkMode && !areOtherColors ? 'rgba(0, 0, 0, 0.19)' : 'rgba(255, 255, 255, 0.4)',
                isDarkMode && !areOtherColors ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.8)'
              ],
              transition: defaultTransition
            })
          } else if (
            currentDragPosition + cardContentOffsetTop > 0 &&
            isSticky &&
            window.innerHeight < dishCardMaxHeight
          ) {
            removeStickyStyles(false)
          }
        },

        {
          root: null,
          rootMargin: '-12px 0px',
          threshold: getThresholds()
        }
      )

      observer.observe(dishContentHeaderRef.current)

      return () => {
        observer.unobserve(dishContentHeaderRef.current)
      }
    }
  }, [dishContentHeaderRef, dishContentHeaderRef.current, shouldExpand, dishCardMaxHeight, isSticky])

  useEffect(() => {
    if (shouldExpand) {
      if (dishCardRef?.current) {
        const currMaxHeight = Math.max(window.innerHeight - 64, (dishCardRef?.current?.clientHeight || 0) + 104)
        setDishCardMaxHeight(currMaxHeight)
      }
    }
  }, [dishCardRef?.current, currentOpenTab, shouldExpand])

  useLayoutEffect(() => {
    if (shouldExpand && dishCardMaxHeight > 0) {
      const animateDishCardOpen = (withoutTransition?: boolean) => {
        dishCardControls
          .start({
            position: 'fixed',
            pointerEvents: ['none', 'auto'],
            width: window.innerWidth,
            translateY: [0, topCoords ? -topCoords + 8 : 0],
            left: [8, 0],
            transition: withoutTransition ? { duration: 0 } : defaultTransition
          })
          .then(() => {
            shadowControls
              .start({
                opacity: 1,
                visibility: 'visible',
                translateY: 0,
                transition: withoutTransition ? { duration: 0 } : defaultTransition
              })
              .then(() => {
                history.replace({
                  state: {
                    dishInitiallyOpen: true
                  }
                })
              })

            if (!withoutTransition) {
              setTimeout(() => {
                setShouldImageExpand(true)
              }, timing * 1000)
            } else {
              setShouldImageExpand(true)
            }
          })

        dishImage.start({
          height: window.innerWidth,
          transition: withoutTransition ? { duration: 0 } : defaultTransition
        })

        gallery.start({
          borderRadius: 0,
          transition: withoutTransition ? { duration: 0 } : defaultTransition
        })

        contentCardControls.start({
          height: dishCardMaxHeight,
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
          transition: withoutTransition ? { duration: 0 } : defaultTransition
        })

        dragWrapperControls.start({
          translateY: cardContentOpenHeight,
          translateX: isSticky ? 0 : 8,
          width: window.innerWidth - 32,
          transition: withoutTransition ? { duration: 0 } : defaultTransition
        })

        headerControls.start({
          opacity: 0,
          translateY: -40,
          transition: withoutTransition ? { duration: 0 } : defaultTransition
        })

        contentControls.start({
          opacity: 1,
          translateY: -48,
          transition: withoutTransition ? { duration: 0 } : defaultTransition
        })

        contentHeaderControls.start({
          opacity: 0,
          translateY: -48,
          transition: withoutTransition ? { duration: 0 } : defaultTransition
        })
      }
      animateDishCardOpen(dishInitiallyOpen)
    }
  }, [shouldExpand, dishCardMaxHeight])

  const removeStickyStyles = (withoutTransition: boolean) => {
    setIsSticky(false)
    dragWrapperControls.start({
      width: window.innerWidth - 32,
      translateX: 8,
      transition: withoutTransition ? { duration: 0 } : defaultTransition
    })

    contentCardControls.start({
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 8,
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
      background: [
        isDarkMode && !areOtherColors ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)',
        isDarkMode && !areOtherColors ? 'rgba(0, 0, 0, 0.19)' : 'rgba(255, 255, 255, 0.4)'
      ],
      transition: withoutTransition ? { duration: 0 } : defaultTransition
    })
  }

  const cardTapHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!shouldExpand) {
      const cardData = cardRef.current.getBoundingClientRect()
      dispatch(storeMenuCardCoords(cardData.top + 8))
      if (handleSetCurrentOpenCard) {
        handleSetCurrentOpenCard()
      }
      history.push({
        pathname: `/dish/${dish?.id}`
      })
    }
  }

  const handleSize = useCallback(
    (value: number) => {
      history.push({
        pathname: `/dish/${value}`,
        state: {
          dishInitiallyOpen: true
        }
      })

      if (isSticky) {
        removeStickyStyles(true)
      }

      if (isDishImageExist) {
        if (cardContentOffsetTop - 64 + y.get() < 0) {
          y.set(0)
        }
      }
    },
    [isDarkMode, isSticky]
  )

  const handleTab = useCallback(
    (tab: IDishTab) => {
      setCurrentOpenTab(tab)

      if (isSticky) {
        removeStickyStyles(true)
      }

      if (isDishImageExist) {
        if (cardContentOffsetTop - 64 + y.get() < 0) {
          y.set((cardContentOffsetTop - 64) * -1)
        }
      }
    },
    [isDarkMode, isSticky]
  )

  const DishCountBadge = useMemo(() => {
    if (selectedDishOrders.length) {
      return (
        <motion.div
          style={{ ...(dishInitiallyOpen && shouldExpand ? { top: '8px', left: '8px' } : {}) }}
          className={`${styles.dishCountBadge} ${
            shouldExpand && !dishInitiallyOpen ? styles.dishCountBadgeExpanded : ''
          }`}
        >
          {selectedDishOrders.length}
        </motion.div>
      )
    } else {
      return <></>
    }
  }, [selectedDishOrders, shouldExpand])

  const DishVolume = () => {
    if (dish?.unit && dish?.volume) {
      return (
        <>
          <span>{dish.volume}</span>
          <span>{t(dish.unit)}</span>
        </>
      )
    } else {
      return <></>
    }
  }

  const totalPrice = useMemo(
    () => parseInt((dish?.price || 0) + (shouldExpand ? calcOptionsCost(selectedOptions, optionSets) : 0)),
    [selectedOptions, optionSets, dish]
  )
  const getBackgroundColor = () => {
    if (areOtherColors) {
      return variables?.fontColor
    }
    if (isDarkMode) {
      return '#000'
    }
    return '#fff'
  }

  const DishContentHeaderSticky = () => (
    <div className={`${styles.dishContentHeaderOuterWrapper} ${styles.dishContentHeaderOuterWrapperSticky}`}>
      <div className={styles.dishContentHeader}>
        <div className={styles.categoryWrapper}>
          <div className={styles.categoryNameWrapper}>
            <IconType
              style={{
                backgroundColor: getBackgroundColor()
              }}
              type={dish?.icon}
              size={24}
            />
            <AddingAnimationContainer iconColor={getBackgroundColor()} />
            <span>{t(dish?.categoryName)}</span>
          </div>
          <div className={styles.dishUnitsWrapper}>
            <DishVolume />
          </div>
        </div>
        <div className={styles.dishTitleWrapper}>
          <div className={styles.dishTitle}>{t(dish?.name)}</div>
          <div className={styles.likeWrapper}>
            {preferences?.withFavorites ? (
              <LikeWrapper
                recipeId={dish.recipeId}
                options={shouldExpand ? selectedOptions : favoriteOptions || []}
                className={styles.navBarLike}
                dishId={dish?.id || 0}
                clickable={true}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <DishBadges />
    </div>
  )

  const DishBadges = () => (
    <div
      style={{
        ...(isDarkMode && !areOtherColors
          ? { borderColor: 'rgba(255, 255, 255, 0.21)' }
          : { borderColor: 'rgba(0, 0, 0, 0.1)' })
      }}
      className={styles.dishBadgesWrapper}
    >
      <div className={`${styles.badges} ${isDisabledDish ? styles.badgesDisabled : ''}`}>
        <DishesTags
          withoutCategoryTag
          wrapperClassName={`${styles.tagsWrapperOverride} ${styles.tagsWrapperLarge} ${!isDisabledDish &&
            (isDarkMode ? styles.darkTags : styles.lightTags)}`}
          disableTagLarge
          isDisabledDish={isDisabledDish}
          dish={dish}
        />
      </div>

      {!isDisabledDish ? <div className={styles.dishPrice}>{`${money(totalPrice + optionsCost)}₴`}</div> : null}
    </div>
  )

  const DishContentHeader = useMemo(
    () => (
      <div
        style={{ opacity: isSticky ? 0 : 1 }}
        className={styles.dishContentHeaderOuterWrapper}
        ref={dishContentHeaderRef}
      >
        <div className={styles.dishContentHeader}>
          <div className={styles.categoryWrapper}>
            <div className={styles.categoryNameWrapper}>
              <IconType
                style={{
                  backgroundColor: getBackgroundColor()
                }}
                type={dish?.icon}
                size={24}
              />
              <AddingAnimationContainer iconColor={getBackgroundColor()} />
              <span>{t(dish?.categoryName)}</span>
            </div>
            <div className={styles.dishUnitsWrapper}>
              <DishVolume />
            </div>
          </div>
          <div className={styles.dishTitleWrapper}>
            <div className={styles.dishTitle}>{t(dish?.name)}</div>
          </div>
        </div>
        <DishBadges />
      </div>
    ),
    [dish, variables, isSticky, isDarkMode, totalPrice]
  )

  const DishTabContent = useMemo(() => {
    const desc = t(dish?.desc)

    if (currentOpenTab?.type === 'calories') {
      return <DishCalories calories={dish?.calories || null} />
    }

    if (currentOpenTab?.type === 'description') {
      return <ReactMarkdown source={desc} />
    }

    return <DishOptions recipeId={dish?.recipeId || 0} dishId={dish?.id || 0} />
  }, [currentOpenTab, t])

  if (!dish) {
    return <></>
  }

  const onCardContentClose = () => {
    replaceOptions()
    history.push('/')
  }

  const setInitialCoordinates = () => {
    dishCardControls.start({
      translateY: topCoords ? -topCoords + 8 : 0,
      transition: defaultTransition,
      scale: 1
    })

    gallery.start({
      borderRadius: 0,
      transition: defaultTransition
    })
  }

  const onDragEnd = (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo): void => {
    const cardOffset: number = y.get() - 20

    if (cardOffset > 40 || (info.velocity.y > 100 && cardOffset > 0)) {
      onCardContentClose()
    } else {
      setInitialCoordinates()
    }
  }

  return (
    <div
      ref={cardRef}
      style={{
        animationDelay: `${index ? (index > 0 ? index / 8 : 0) : 0}s`,
        ...(shouldExpand ? { position: 'fixed', zIndex: 2, top: 0, left: 0 } : {})
      }}
      className={`${styles.dishCardOuterWrapper} ${shouldExpand ? '' : styles.dishCardInitialAnimation}`}
    >
      <motion.div
        drag={shouldExpand ? 'y' : false}
        dragElastic={{
          top: 0,
          bottom: 0.2
        }}
        dragConstraints={{
          bottom: 0,
          top: 0
        }}
        onDragEnd={(event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo): void => {
          const cardOffset: number = wrapperY.get() - 20

          if (cardOffset > 40 || (info.velocity.y > 100 && cardOffset > 0)) {
            onCardContentClose()
          } else {
            setInitialCoordinates()
          }
        }}
        onDrag={(event, info) => {
          const currentDragPosition = wrapperY.get()
          y.set(currentDragPosition)

          const absoluteDragValue = Math.abs(currentDragPosition)

          if (dishCardMaxHeight < window.innerHeight) {
            if (draggerRef.current) {
              clearTimeout(draggerRef.current)
            }
            if (absoluteDragValue >= cardContentOffsetTop - 72 && !isOnBorder) {
              setIsOnBorder(true)
            } else if (absoluteDragValue < cardContentOffsetTop - 72 && isOnBorder) {
              setIsOnBorder(false)
            }
            draggerRef.current = setTimeout(() => {
              const currentDragPosition = Math.abs(wrapperY.get())

              if (currentDragPosition >= cardContentOffsetTop - 72 && !isOnBorder) {
                setIsOnBorder(true)
              } else if (currentDragPosition < cardContentOffsetTop - 72 && isOnBorder) {
                setIsOnBorder(false)
              }
            }, 500)
          }

          const cardOffset: number = currentDragPosition - 20

          if (cardOffset > 0) {
            gallery.start({
              borderRadius: 8,
              transition: {
                duration: 0,
                easings: 'linear'
              }
            })
            dishCardControls.start({
              translateY: topCoords ? -topCoords + 8 + cardOffset / 1.5 : cardOffset / 1.5,
              scale: 1 - cardOffset / 1000,
              transition: {
                duration: 0,
                easings: 'linear'
              }
            })
          } else {
            dishCardControls.start({
              translateY: topCoords ? -topCoords + 8 : 0,
              scale: 1,
              transition: {
                duration: 0,
                easings: 'linear'
              }
            })
            gallery.start({
              borderRadius: 0,
              transition: {
                duration: 0,
                easings: 'linear'
              }
            })
          }
        }}
        className={`${styles.dishCard} ${shouldExpand ? styles.dishCardOpen : ''} ${
          isDarkMode ? styles.dishCardDark : styles.dishCardLight
        }`}
        style={{
          animationDelay: `${index ? (index > 0 ? index / 5 : 0) : 0}s`,
          ...(shouldExpand && topCoords ? { top: `${topCoords - 8}px` } : {}),
          overflow: 'visible',
          y: wrapperY,
          ...(areOtherColors ? { color: demoVariables?.fontColor || variables?.fontColor } : {})
        }}
        exit={{
          height: [window.innerHeight, 170],
          scale: 1,
          translateX: [0, 8],
          width: window.innerWidth - 16,
          pointerEvents: 'none',
          translateY: 0,
          transition: defaultTransition,
          opacity: 0
        }}
        animate={dishCardControls}
        onClick={cardTapHandler}
      >
        {isSticky && <DishContentHeaderSticky />}
        <motion.div
          exit={{
            opacity: 1,
            translateY: 0,
            transition: defaultTransition
          }}
          animate={headerControls}
          className={styles.header}
        >
          <div className={styles.tagsWrapper}>
            <DishesTags
              withoutCategoryTag={isSameCategory || shouldExpand}
              wrapperClassName={`${styles.tagsWrapperOverride} ${!isDisabledDish &&
                (isDarkMode ? styles.darkTags : styles.lightTags)}`}
              isDisabledDish={isDisabledDish}
              dish={dish}
            />
          </div>

          <div className={styles.likeWrapper}>
            {preferences?.withFavorites ? (
              <LikeWrapper
                recipeId={dish.recipeId}
                options={shouldExpand ? selectedOptions : favoriteOptions || []}
                className={styles.newLike}
                dishId={dish?.id || 0}
                clickable={true}
              />
            ) : (
              <></>
            )}
          </div>
        </motion.div>

        <motion.div
          exit={{
            translateY: 0,
            transition: defaultTransition
          }}
          animate={dragWrapperControls}
          className={`${styles.contentDrag}`}
        >
          <motion.div
            style={{
              y,
              ...(isDarkMode && !areOtherColors
                ? { background: 'rgba(0, 0, 0, 0.19)' }
                : { background: `rgba(255, 255, 255, ${areOtherColors ? '0.59' : '0.4'})` })
            }}
            className={`${styles.contentContainer} ${isSticky ? styles.contentContainerSticky : ''}`}
            animate={contentCardControls}
            exit={{
              height: 56,
              transition: defaultTransition
            }}
            drag={shouldExpand ? 'y' : false}
            dragTransition={{ timeConstant: 240, power: 0.39 }}
            dragElastic={{
              top: 0,
              bottom: 0.5
            }}
            onDrag={(event, info) => {
              const currentDragPosition = y.get()

              const absoluteDragValue = Math.abs(currentDragPosition)

              if (dishCardMaxHeight < window.innerHeight) {
                if (draggerRef.current) {
                  clearTimeout(draggerRef.current)
                }
                if (absoluteDragValue >= cardContentOffsetTop - 72 && !isOnBorder) {
                  setIsOnBorder(true)
                } else if (absoluteDragValue < cardContentOffsetTop - 72 && isOnBorder) {
                  setIsOnBorder(false)
                }
                draggerRef.current = setTimeout(() => {
                  const currentDragPosition = Math.abs(y.get())

                  if (currentDragPosition >= cardContentOffsetTop - 72 && !isOnBorder) {
                    setIsOnBorder(true)
                  } else if (currentDragPosition < cardContentOffsetTop - 72 && isOnBorder) {
                    setIsOnBorder(false)
                  }
                }, 500)
              }

              const cardOffset: number = currentDragPosition - 20

              if (cardOffset > 0) {
                gallery.start({
                  borderRadius: 8,
                  transition: {
                    duration: 0,
                    easings: 'linear'
                  }
                })

                dishCardControls.start({
                  translateY: topCoords ? -topCoords + 8 + cardOffset / 1.5 : cardOffset / 1.5,
                  scale: 1 - cardOffset / 1000,
                  transition: {
                    duration: 0,
                    easings: 'linear'
                  }
                })
              } else {
                dishCardControls.start({
                  translateY: topCoords ? -topCoords + 8 : 0,
                  scale: 1,
                  transition: {
                    duration: 0,
                    easings: 'linear'
                  }
                })
                gallery.start({
                  borderRadius: 0,
                  transition: {
                    duration: 0,
                    easings: 'linear'
                  }
                })
              }
            }}
            onDragEnd={onDragEnd}
            dragConstraints={{
              top: Math.min(window.innerHeight - cardContentOffsetTop - dishCardMaxHeight, 0),
              bottom: 0
            }}
          >
            {DishCountBadge}
            <div className={styles.contentHeaderOuterWrapper}>
              <motion.div
                exit={{
                  opacity: 1,
                  translateY: 0,
                  transition: defaultTransition
                }}
                animate={contentHeaderControls}
                className={`${styles.contentHeader} ${isDisabledDish ? styles.contentHeaderDisabled : ''}`}
              >
                <div className={styles.dishIcon}>
                  <IconType
                    style={{
                      backgroundColor: getBackgroundColor()
                    }}
                    type={dish?.icon}
                    size={24}
                  />
                </div>
                <div className={styles.title}>{t(dish?.name)}</div>
                <div className={styles.priceWrapper}>
                  <div className={styles.price}>{`${money(totalPrice + optionsCost)}₴`}</div>
                  <div className={styles.unitsWrapper}>
                    <DishVolume />
                  </div>
                </div>
              </motion.div>
            </div>

            {shouldExpand && (
              <motion.div
                exit={{
                  opacity: 0,
                  translateY: 0,
                  transition: defaultTransition
                }}
                ref={dishCardRef}
                animate={contentControls}
                className={`${styles.dishCardContent}`}
              >
                {!isSticky && (
                  <DraggerIndicator
                    style={{
                      top: '8px'
                    }}
                    isOnBorder={isOnBorder}
                  />
                )}
                {preferences?.withFavorites ? (
                  <LikeWrapper
                    recipeId={dish.recipeId}
                    options={selectedOptions}
                    className={styles.newLikeExpanded}
                    dishId={dish?.id || 0}
                    clickable
                  />
                ) : (
                  <></>
                )}
                {DishContentHeader}
                {dishContentTabs.length > 1 ? (
                  <DishTabs currentActiveTab={currentOpenTab} handleTab={handleTab} tabs={dishContentTabs} />
                ) : null}
                <div
                  style={{
                    ...(dishContentTabs.length <= 1 ? { paddingTop: '8px' } : {})
                  }}
                  className={styles.dishTabContentWrapper}
                >
                  <DishSizeToggle
                    tabs={dishContentTabs}
                    currentActiveTab={currentOpenTab}
                    handleSize={handleSize}
                    currentActiveDish={dish}
                  />
                  {DishTabContent}
                </div>
              </motion.div>
            )}
          </motion.div>
        </motion.div>

        <motion.div animate={gallery} className={styles.gallery}>
          <motion.div
            exit={{
              opacity: 0,
              translateY: `-100%`,
              transition: defaultTransition
            }}
            animate={shadowControls}
            className={`${styles.dishShadow}`}
          />
          <motion.div
            exit={{
              height: 170,
              transition: defaultTransition
            }}
            className={styles.img}
            style={{ pointerEvents: shouldExpand ? 'all' : 'none' }}
            animate={dishImage}
          >
            <MenuDishMedia
              activeMediaIndex={activeMediaIndex}
              handleActiveMediaIndex={handleActiveMediaIndex}
              img={dish?.imgWide || dish?.img}
              media={dish?.media}
              isExpanded={shouldImageExpand}
            />

            {shouldExpand && (
              <div
                className={`${styles.imagePlaceholderWrapper} ${
                  !isDishImageExist ? styles.imagePlaceholderWrapperBg : ''
                }`}
              >
                {isDishImageExist && (
                  <img src={dish?.media[activeMediaIndex]?.path || `/${dish?.imgWide || dish?.img}`} alt='' />
                )}
              </div>
            )}
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  )
}

export default React.memo(MenuDishCard)
