import { CLEAR_ADDING_ANIM, SET_ADDING_ANIM, ADD_ADDING_ANIM, REMOVE_ADDING_ANIM } from './types'

export const setAddingAnim = anim => ({
  type: SET_ADDING_ANIM,
  payload: { anim }
})

export const addAddingAnim = anim => ({
  type: ADD_ADDING_ANIM,
  payload: { anim }
})

export const removeAddingAnim = anim => ({
  type: REMOVE_ADDING_ANIM,
  payload: { anim }
})

export const clearAddingAnim = () => ({
  type: CLEAR_ADDING_ANIM
})
