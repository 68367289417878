import React from 'react'
import { getOptionsetsByRecipeId, getSelectedOptions } from 'src/state/menu/selectors'
import { useDispatch, useSelector } from 'src/state/hooks'
import { RadioSet } from './RadioSet'
import { postEvent } from '../../api'
import { isDishInStoplist } from '../../utils'
import { getStoplist } from '../../state/stoplist'
import { addSelectedOptions } from '../../state/menu'
import s from '../DishCard/styles.module.scss'
import useAppPreferences from 'src/hooks/useAppPreferences'
import { isDesktop } from 'src/utils'

type DishOptionsProps = {
  recipeId: number
  dishId: number
}

export const DishOptions: React.FC<DishOptionsProps> = ({ recipeId, dishId }) => {
  const dispatch = useDispatch()

  const selectedOptions = useSelector(getSelectedOptions)
  const optionSets = useSelector(getOptionsetsByRecipeId(recipeId || 0)) || []
  const stoplist = useSelector(getStoplist)
  const { preferences } = useAppPreferences()
  const isNewDesignMode = isDesktop ? false : preferences.designVersion === 1.1

  const isDisabledDish = isDishInStoplist(stoplist, dishId)

  const handleSetOptions = (optionId: number, optionSetId: number) => {
    const optionSet = optionSets.find(set => set.id === optionSetId)
    const defaultOption = optionSet?.options.find(e => e.default)

    if (optionId) {
      let updatedOptions = selectedOptions.filter(option => option.id !== optionSetId)
      if ((optionSet?.showDefault && defaultOption?.id === optionId) || defaultOption?.id !== optionId) {
        updatedOptions = [...updatedOptions, { id: optionSetId, value: optionId }]
      }
      dispatch(addSelectedOptions(updatedOptions))
    } else {
      const newOptions = selectedOptions.filter(e => e.id !== optionSetId)

      dispatch(addSelectedOptions(newOptions))
    }
    postEvent('dishcard-options', 'ordering', { options: selectedOptions })
  }

  return (
    <div style={{ ...(isNewDesignMode ? {} : { padding: '0 16px' }) }} className={s['tab-content']}>
      <form>
        {optionSets.map(set => (
          <RadioSet
            key={set.id}
            handleFormSubmit={(e: number) => {
              handleSetOptions(e, set.id)
            }}
            set={set}
            disabled={isDisabledDish}
          />
        ))}
      </form>
    </div>
  )
}
