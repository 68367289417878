import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import { MenuBar } from 'src/components/ui/MenuBar'
import { useTranslation } from 'src/hooks/useTranslation'
import { Popup } from 'src/components/ui/Popup.tsx'
import { getPaymentInHistoryById, getHistory } from 'src/state/history/selectors'
import { setHistory } from 'src/state/history/actions'
import { createAddOn } from 'src/network/sendingData/payments'
import { PAYMENT_ADDON_TYPE } from 'src/globals/enumerations'
import LiqPayWidgetComponent from 'src/components/LiqPayWidget'
import { toggleLastReviewAction } from 'src/state/user'
import { config } from 'src/constants'
import Tips from './Tips'
import TipsCheckout from './TipsCheckout'
import TipsThanksView from './TipsThanksView'
import s from './styles.module.scss'

const TipsView = () => {
  const { paymentId } = useParams()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const payment = useSelector(store => getPaymentInHistoryById(store, parseInt(paymentId)))
  const paymentHistory = useSelector(getHistory)

  const [liqPayData, setLiqPayData] = useState(null)
  const [selectedAmountOfPay, setSelectedAmountOfPay] = useState(null)
  const [isLiqpayLoading, setIsLiqpayLoading] = useState(false)
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false)
  const currency = '₴'

  useEffect(() => {
    if (isPaymentSuccess) {
      const changedHistory = paymentHistory.map(order => {
        if (order.id === payment.id) {
          return {
            ...order,
            addOns: [
              ...order.addOns,
              {
                amountPaid: selectedAmountOfPay,
                type: 'tips'
              }
            ]
          }
        }

        return order
      })

      dispatch(setHistory(changedHistory))
    }
  }, [isPaymentSuccess, selectedAmountOfPay])

  useEffect(
    () => () => {
      dispatch(toggleLastReviewAction(false))
    },
    [dispatch]
  )

  const handleClose = () => {
    history.push('/')
    dispatch(toggleLastReviewAction(false))
  }

  const handleTipClick = async amount => {
    try {
      setSelectedAmountOfPay(amount)
      setIsLiqpayLoading(true)

      const resp = await createAddOn(paymentId, {
        type: PAYMENT_ADDON_TYPE.TIPS,
        amountPaid: amount,
        currency: 'UAH'
      })
      setLiqPayData(resp)
    } catch (e) {
      console.error(e)
      console.log(`Error while creating add-on to payment with id ${paymentId}`)
    } finally {
      setIsLiqpayLoading(false)
    }
  }

  const onLiqpayCallback = (data, sendLogs) => {
    if (sendLogs) {
      axios.post(
        `${config.API_URL}/${window.busytable.brandSlug}/log`,
        JSON.stringify({
          type: '[app] minor log',
          error: 'Liqpay callback'
        }),
        {
          headers: {
            withCredentials: true,
            'Content-Type': 'application/json',
            'x-url': window.location.href,
            credentials: 'include'
          },
          withCredentials: true
        }
      )
    }
    if (data.status === 'success' || data.status === 'wait_accept') {
      setIsPaymentSuccess(true)
    }
  }

  const onLiqpayReady = sendLogs => {
    if (sendLogs) {
      axios.post(
        `${config.API_URL}/${window.busytable.brandSlug}/log`,
        JSON.stringify({
          type: '[app] minor log',
          error: 'Liqpay ready'
        }),
        {
          headers: {
            withCredentials: true,
            'Content-Type': 'application/json',
            'x-url': window.location.href,
            credentials: 'include'
          },
          withCredentials: true
        }
      )
    }
  }

  if (liqPayData) {
    return (
      <Popup
        fullsize
        withoutHeaderWrapper
        additional={isPaymentSuccess && <TipsThanksView history={history} />}
        header={
          <MenuBar className='headerBrandBgAbout' title={t('review.leaveTips')} icon='close' eventCb={handleClose} />
        }
      >
        <TipsCheckout amount={`${selectedAmountOfPay / 100} ${currency}`} takeoutNum={liqPayData.takeoutNum} />
        <LiqPayWidgetComponent
          data={liqPayData.liqPayDataBuffer}
          signature={liqPayData.signature}
          callback={onLiqpayCallback}
          onReady={onLiqpayReady}
        />
      </Popup>
    )
  }

  return (
    <Popup
      fullsize
      withoutHeaderWrapper
      header={
        <MenuBar className='headerBrandBgAbout' title={t('review.leaveTips')} icon='cross' eventCb={handleClose} />
      }
    >
      <div className={s.wrapper}>
        <Tips
          isLiqpayLoading={isLiqpayLoading}
          handleTipClick={handleTipClick}
          currency={currency}
          selectedAmountOfPay={selectedAmountOfPay}
          paymentTotal={payment?.amountBeforeDiscount}
        />
      </div>
    </Popup>
  )
}

export default TipsView
