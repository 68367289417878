import { useEffect, useState } from 'react'

const useCheckNative = () => {
  const [isNative, setIsNative] = useState(false)

  useEffect(() => {
    if (/busytable/gim.test(window.navigator.userAgent) && !isNative) {
      setIsNative(true)
    }
  }, [window.navigator.userAgent, isNative])

  return isNative
}

export default useCheckNative
