import { SET_TAGS } from './types'
import { Tag } from 'src/types'

export const setTags = (tags: Tag[]): ITagsActionsTypes => ({
  type: SET_TAGS,
  tags
})

interface ISetTagsAction {
  type: typeof SET_TAGS
  tags: Tag[]
}

export type ITagsActionsTypes = ISetTagsAction
