import React, { useCallback } from 'react'
import { OptionType } from '../../types'
import { useSelector } from 'src/state/hooks'
import { getStoplist } from 'src/state/stoplist/selectors'
import s from '../DishCard/styles.module.scss'
import { isOptionInStoplist, money } from '../../utils'
import { ReactComponent as WarnIcon } from '../../assets/ico-warn-24.svg'
import { useTranslation } from '../../hooks/useTranslation'
import { AppState } from 'src/state/store'
import useThemeStyles from 'src/hooks/useThemeStyles'

type RadioOptionProps = {
  option: OptionType
  disabled?: boolean
  onChecked: (value: number) => void
  value: number
  name: string
}

const RadioOption: React.FC<RadioOptionProps> = ({ name, option, onChecked, disabled, value }) => {
  const stopList = useSelector(getStoplist)
  const isStopListed = isOptionInStoplist(stopList, option.modifierId)
  const { t } = useTranslation()

  const { demoVariables } = useSelector((state: AppState) => state.demo)
  const { variables } = useThemeStyles()
  const areOtherColors = demoVariables?.dishCardBg || variables?.dishCardBg

  const onRadioChecked = useCallback(
    e => {
      if (!isStopListed && !disabled) {
        onChecked(Number(e.target.value))
      }
    },
    [isStopListed, disabled, onChecked]
  )

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        ...(areOtherColors ? { color: demoVariables?.fontColor || variables?.fontColor } : {})
      }}
      className={s['form-check']}
    >
      <label
        data-cy={`optionLabel-${option.id}`}
        className={[
          'body1',
          s.optionLabel,
          disabled
            ? option.id === value
              ? s['form-check-label-disabled-check']
              : s['form-check-label-disabled']
            : isStopListed && option.id === value
            ? s['form-check-label-checked-dis']
            : option.id === value
            ? s['form-check-label-checked']
            : isStopListed
            ? s['form-check-label-disabled']
            : s['form-check-label']
        ].join(' ')}
      >
        <input
          type='radio'
          name={name}
          data-cy={`optionCheckBox-${option.id}`}
          value={option.id}
          checked={option.id === value}
          onChange={onRadioChecked}
          className={s['form-check-input']}
        />
        <div className={s.optionNameWrapper}>
          <span
            data-cy={`optionName-${option.id}`}
            style={{
              ...(isStopListed
                ? { color: '#C4C4C4' }
                : areOtherColors
                ? { color: demoVariables?.fontColor || variables?.fontColor }
                : {})
            }}
          >
            {t(option.name)}
          </span>

          <div className={s.optionModifierData}>
            {option?.volume > 0 && option.unit && (
              <span
                style={{ ...(areOtherColors ? { color: demoVariables?.fontColor || variables?.fontColor } : {}) }}
                className={s.optionVolumeWrapper}
              >
                {option?.volume > 0 ? option.volume : ''} {t(option.unit)}
              </span>
            )}
            {Number.isInteger(option.price) && !isStopListed && option.price > 0 && (
              <span
                style={{ ...(areOtherColors ? { color: demoVariables?.fontColor || variables?.fontColor } : {}) }}
                className={`${s.dishPrice} ${option?.volume > 0 && option.unit ? s.optionPrice : ''}`}
              >
                {`+ ${money(option.price)} ₴`}
              </span>
            )}
          </div>
        </div>
      </label>
      {isStopListed && option.id === value && (
        <div className={s.leftItem}>{isStopListed ? <WarnIcon fill='var(--warningColor)' /> : ''}</div>
      )}
    </div>
  )
}

export default RadioOption
