import { SET_DATED_TAKEOUT } from './types'

/* State Shape
bool || Date
*/

const initialState = false

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_DATED_TAKEOUT: {
      const { data } = action.payload
      return data
    }

    default:
      return state
  }
}
