import React from 'react'
import { SERVICES_TYPE } from 'src/types'
import s from '../styles.module.scss'
import { ReactComponent as TableIcon } from 'src/assets/ico-table-24.svg'
import { ReactComponent as EmptyTableIcon } from 'src/assets/ico-table-clear-24.svg'
import { ReactComponent as DeliveryIcon } from 'src/assets/ico-delivery-1.svg'
import { ReactComponent as TakeawayIcon } from 'src/assets/ico-togo-24.svg'

interface IActiveServiceTypeIcon {
  activeServiceType: SERVICES_TYPE | null
  tableNumber: number | null
  venueAvailable?: boolean
  color?: string
}

const renderTableIcon = (tableNumber: number | null) => (
  <>
    <TableIcon width={24} height={24} />
    <div className={s.tableNum}>&nbsp;{tableNumber}</div>
  </>
)

const ActiveServiceTypeIcon: React.FC<IActiveServiceTypeIcon> = ({
  tableNumber,
  activeServiceType,
  venueAvailable
}) => {
  if (activeServiceType === SERVICES_TYPE.TAKEAWAY) {
    return (
      <>
        <TakeawayIcon fill='none' stroke='currentColor' width={24} height={24} />
        {Boolean(tableNumber) && venueAvailable && renderTableIcon(tableNumber)}
      </>
    )
  }
  if (activeServiceType?.includes('delivery')) {
    return <DeliveryIcon width={24} height={24} fill='currentColor' />
  }

  if (Boolean(tableNumber)) {
    return renderTableIcon(tableNumber)
  }

  return <EmptyTableIcon fill='currentColor' width={24} height={24} />
}
export default ActiveServiceTypeIcon
