import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postEvent } from 'src/api'
import { ReactComponent as RrowIcon } from 'src/assets/ico-rrow.svg'
import { ReactComponent as TimeIcon } from 'src/assets/ico-time.svg'
import { useTranslation } from 'src/hooks/useTranslation'
import { getWorkingHoursFull, setTableNum } from 'src/state/location'
import { getTimedTakeout, setTimedTakeout } from 'src/state/timedTakeout'
import {
  formatterDelivery,
  datesRange,
  getDatesRangeForTakeAwayTimePicker,
  calculateOrdersAmount,
  calculateIsCookingTimeBeforeClose,
  isCookingTimeInStopList
} from 'src/utils'
import useAppPreferences from 'src/hooks/useAppPreferences'
import { transformTakeAwayDateTime } from 'src/utils/strings'
import { getLang } from 'src/state/local'
import { PurchaseType } from 'src/globals/enumerations'
import { getOrdersNew } from 'src/state/orders/selectors.ts'
import { getServicesStoplistOwnDeliveryItems, getServicesStopList } from 'src/state/services-stoplist/selectors'
import s from './styles.module.scss'

const ShoppingCartTimePicker = ({ cookingTime = 0, type, preorderInfo }) => {
  const timeFormatter = type === PurchaseType.DELIVERY ? formatterDelivery : transformTakeAwayDateTime
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const timedTakeout = useSelector(getTimedTakeout)
  const workingHours = useSelector(getWorkingHoursFull)
  const { preferences } = useAppPreferences()
  const currentLangague = useSelector(getLang)
  const orders = useSelector(getOrdersNew)

  const preorderEndDaysLeft = preorderInfo
    ? Math.ceil((new Date(preorderInfo.end).getTime() - new Date().getTime()) / (1000 * 3600 * 24))
    : 2
  const days =
    'preorderOnFutureDaysNumber' in preferences ? preferences?.preorderOnFutureDaysNumber : preorderEndDaysLeft

  const ordersAmount = calculateOrdersAmount(orders)
  const ownDeliveryStoplist = useSelector(getServicesStoplistOwnDeliveryItems)
  const servicesStopList = useSelector(getServicesStopList)
  const isDeliveryOwn = preferences?.minOrderAmount && ordersAmount >= preferences?.minOrderAmount

  const onTimeSelect = e => {
    const newTimedTakeout = e.target.value
    if (newTimedTakeout && newTimedTakeout !== 'false') {
      dispatch(setTableNum(0))
      postEvent('cart-form-time', 'ordering', { value: newTimedTakeout })
      dispatch(setTimedTakeout(+newTimedTakeout))
    } else {
      dispatch(setTimedTakeout(false))
    }
  }
  const getSelectorOptions = (arr, t, preorderOnFutureDaysNumber, currentLangague) => {
    return arr.map(currentDay => ({
      value: currentDay,
      time: timeFormatter(t, currentDay, currentLangague, preorderOnFutureDaysNumber, preferences?.timePickerStep),
      disabled: isDeliveryOwn
        ? ownDeliveryStoplist?.some(ownDeliveryStoplistItem => {
            return (
              currentDay >= new Date(ownDeliveryStoplistItem.startingAt).getTime() &&
              currentDay <= new Date(ownDeliveryStoplistItem.endingAt).getTime()
            )
          })
        : false
    }))
  }
  const fromDate =
    preorderInfo?.start && new Date(preorderInfo?.start) > new Date() ? new Date(preorderInfo?.start) : new Date()
  const toDate =
    preorderInfo?.end && new Date(preorderInfo?.end) > new Date()
      ? new Date(preorderInfo?.end)
      : days
      ? new Date().getTime() + days * 24 * 3600 * 1000
      : new Date().setHours(23)

  const timesBySteps =
    type === PurchaseType.TAKEAWAY
      ? getDatesRangeForTakeAwayTimePicker({ cookingTime, workingHours, fromDate, toDate })
      : datesRange(workingHours, fromDate, preferences?.timePickerStep, toDate, cookingTime)
  const selectorData = getSelectorOptions(timesBySteps, t, days, currentLangague)

  const preorderOnFutureDaysNumber = preferences?.preorderOnFutureDaysNumber || days

  const getSelectedDateString = () => {
    if (timedTakeout) {
      return timeFormatter(t, timedTakeout, currentLangague, preorderOnFutureDaysNumber, preferences?.timePickerStep)
    }
    if (preorderInfo) {
      return <span className={s.invalidLabel}>{t('timeSelector.selectPlaceholder')}</span>
    }

    if (type === PurchaseType.DELIVERY) {
      if (
        calculateIsCookingTimeBeforeClose(cookingTime, workingHours) &&
        !isCookingTimeInStopList(servicesStopList, cookingTime)
      ) {
        return t('timeSelector.selectDefault')
      } else {
        return <span className={s.invalidLabel}>{t('timeSelector.selectPlaceholder')}</span>
      }
    } else {
      if (calculateIsCookingTimeBeforeClose(cookingTime, workingHours)) {
        return t('timeSelector.selectDefault')
      } else {
        return <span className={s.invalidLabel}>{t('timeSelector.selectPlaceholder')}</span>
      }
    }
  }

  const renderDefaultOption = () => {
    if (type === PurchaseType.DELIVERY) {
      if (
        calculateIsCookingTimeBeforeClose(cookingTime, workingHours) &&
        !isCookingTimeInStopList(servicesStopList, cookingTime)
      ) {
        return <option value={false}>{t('timeSelector.selectDefault')}</option>
      } else {
        return (
          <option disabled defaultChecked value={false} selected={!timedTakeout}>
            {t('timeSelector.selectPlaceholder')}
          </option>
        )
      }
    } else {
      if (calculateIsCookingTimeBeforeClose(cookingTime, workingHours)) {
        return <option value={false}>{t('timeSelector.selectDefault')}</option>
      } else {
        return (
          <option disabled defaultChecked value={false} selected={!timedTakeout}>
            {t('timeSelector.selectPlaceholder')}
          </option>
        )
      }
    }
  }

  return (
    <div className={s.paymentBlock}>
      <select className={s.uxissue} value={timedTakeout} onChange={onTimeSelect}>
        {renderDefaultOption()}
        {selectorData.map(({ value, time, disabled }) => (
          <option disabled={disabled} key={value} value={value}>
            {time}
          </option>
        ))}
      </select>

      <div className={s.pbElem}>
        <div className={s.pbIcon}>
          <TimeIcon width={16} height={16} />
        </div>
        <div className={s.pbLabel}>{t('timeSelector.when')}</div>
      </div>
      <div className={s.pbVr} />
      <div className={[s.pbElem, s.pbElem2].join(' ')}>
        <div className={s.pbText}>
          <div className={s.timedInput}>{getSelectedDateString()}</div>
        </div>
        <div>
          <RrowIcon className={s.rowIcon} />
        </div>
      </div>
    </div>
  )
}

export default ShoppingCartTimePicker
