import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postEvent } from 'src/api'
import { getTableNum, setTableNum, setVenueId } from 'src/state/location'
import { setTakeout } from 'src/state/orders/actions.ts'
import { useLocation } from 'react-router-dom'
import useCheckNative from 'src/hooks/useCheckNative'
import { setServiceTypeAction } from 'src/state/shoppingCard/ShoppingCart.actions.ts'
import { SERVICES_TYPE } from 'src/types/index.ts'
import { ConfirmationBenchView, ConfirmationViewNew } from './ConfirmationView'
import CodeScan from '.'
import s from './styles.module.scss'
import { setDelivery } from '../../state/delivery'

export const UrlParse = props => {
  // const url = window.location.pathname.replace(/\//g, '')
  const url = props.match.params.qrCodeVal

  return url ? <UrlSwitch url={url} {...props} /> : null
}

const UrlSwitch = ({ url, history, ...props }) => {
  const dispatch = useDispatch()
  const [display, setDisplay] = useState(true)
  const [confirmation, setConfirmation] = useState(false)
  const [urlParsed, setUrlParsed] = useState(false)
  const location = useLocation()
  const isNative = useCheckNative()

  const onClose = () => {
    history.push('/')
    postEvent('scan-modal-confirmation-btn_ok', 'involvement')
    setDisplay(false)
  }

  const handleUrlParseEvent = () => {
    const search = location.search.slice(1)

    postEvent('app-path_parsing', 'service', {
      protocol: window.location.protocol,
      host: window.location.host,
      path: window.location.pathname,
      ...(search ? { props: search } : {}),
      isnative: isNative,
      userAgent: navigator.userAgent
    })

    location.search = ''
    history.replace(location)
    setUrlParsed(true)
  }

  useEffect(() => {
    if (!urlParsed) {
      const [venueId, tableNum] = decodeUrl(url)

      dispatch(setVenueId(`${venueId}`))
      dispatch(setTakeout(false))

      // console.log('takeoutfalse 1')
      if (venueId && tableNum) {
        dispatch(setTableNum(tableNum))
        dispatch(setServiceTypeAction(SERVICES_TYPE.HERE))
        dispatch(setDelivery(null))
        setConfirmation(true)
      } else if (venueId) {
        dispatch(setServiceTypeAction(SERVICES_TYPE.TAKEAWAY))
        dispatch(setTakeout(true))
        dispatch(setTableNum(0))
        dispatch(setDelivery(null))
        setConfirmation(false)
        // history.push('/')
      } else {
        console.log('error: wrong venue or table number')
        alert('error: "' + url + '" - wrong venue or table number')
      }

      if (location && venueId) {
        handleUrlParseEvent()
      }
    }
  }, [dispatch, location, isNative, urlParsed]) // eslint-disable-line

  return display && confirmation ? <Confirmation {...{ onClose }} /> : null
}

export const Confirmation = ({ onClose, tableShow, history }) => {
  const dispatch = useDispatch()
  const tableNum = useSelector(getTableNum)
  // const isFirstTime = useSelector(getFirstTime)
  // const actualNum = tableShow ? tableShow : tableNum
  const [scan, showScan] = useState(false)
  // if (isFirstTime) {
  //   return ''
  // }
  return (
    <div className={s.wrapper}>
      {scan ? (
        <CodeScan
          onClose={() => showScan(false)}
          onSuccess={() => {
            showScan(false)
          }}
        />
      ) : tableNum > 2000 ? (
        <ConfirmationBenchView
          tableNum={tableNum}
          topClick={() => {
            dispatch(setTableNum(0))
          }}
          newClick={() => {
            postEvent('scan-modal-confirmation-btn_change', 'involvement')
            showScan(true)
          }}
          leftClick={() => showScan(true)}
          rightClick={onClose}
        />
      ) : (
        <ConfirmationViewNew
          tableNum={tableNum}
          topClick={() => {
            dispatch(setTableNum(0))
          }}
          newClick={() => {
            postEvent('scan-modal-confirmation-btn_change', 'involvement')
            showScan(true)
          }}
          leftClick={() => showScan(true)}
          rightClick={onClose}
        />
      )}
    </div>
  )
}

export const encodeUrl = (venueId, tableNum) => {
  const venueArr = venueId.toString().split('')
  venueArr.forEach(function (e, i) {
    venueArr[i] = e === '0' ? 'j' : String.fromCharCode(e.charCodeAt() + 48)
  })
  const venueStr = venueArr.join('')
  return venueStr + tableNum.toString()
}

export const decodeUrl = url => {
  let venueId
  let tableNum
  if (url.match(/[a-j](\d+)?/)) {
    // we have a venue number, let's decode it
    // a=1, b=2, c=3 ... j=0
    const venueStr = url.match(/([a-j]+)/)[0]
    const venueArr = venueStr.split('')
    venueArr.forEach(function (e, i) {
      venueArr[i] = e === 'j' ? '0' : String.fromCharCode(e.charCodeAt() - 48)
    })
    venueId = parseInt(venueArr.join('')) || 0
  } else {
    return [0, 0]
  }
  if (url.match(/[a-j]\d/)) {
    // do we have a table?
    tableNum = parseInt(url.match(/(\d+)/)[0])
  }

  return [venueId, venueId && tableNum ? tableNum : 0]
}
