import { useSelector } from 'react-redux'
import { getLang } from '../state/local'
import { selectTexts } from '../state/texts'

/**
 * Функция t() принимает param и options
 * param - ключ к переводу или объект с языками в формате {en: 'Text'}
 * если ключ отсутсвует в файле переводв то выводится сам ключ как fallback
 * options - объект с переменными которые заменяются в тексте
 * при отсутсвии в options переменных заданных в тексте выпадет ошибка
 * В тексте переменные вносятся в формате {{name}} без пробелов
 * Множественные числа вносятся в формате {{num|стол|стола|столов||hide}}
 * num - число, по умолчанию выводится через пробел
 * дальше идет необходимое число вариантов слова для данного языка (3 для рус)
 * hide - опциональный параметр, прячет цифру, выводит только слово
 */
export const useTranslation = () => {
  const textObj = useSelector(selectTexts)

  const userLang = useSelector(getLang)
  const fallbackLang = window.busytable.fallbackLang || 'en'

  const lang = userLang || fallbackLang

  const t = (param, options) => {
    if (!param) {
      /**
       * @todo отправить сообщение об ошибке
       */
      return ''
    }

    const stringsFallback = param => textObj[param] || { [lang]: param }
    const langObj = typeof param === 'string' ? stringsFallback(param) : param

    const result = lang in langObj ? langObj[lang] : langObj[fallbackLang]

    if (isEmpty(result, `Language '${lang}' and fallback language '${fallbackLang}' is absent in translations.`))
      return null

    return options ? interpolate(result, options, lang) : result
  }

  return { t }
}

const interpolate = (text, options = {}, lang = '') => {
  if (typeof text != 'string') return text

  const arr = text.split(/({{.+?}})/g)

  const result = arr.map(x => {
    if (!x.startsWith('{{')) return x

    const [params, type] = x.replace(/[{}]/g, '').split('||')
    const [key, ...words] = params.split('|')
    const val = options[key]
    if (isEmpty(val, `Option '${key}' is absent.`)) return null

    return words.length ? formatPlural(words, val, lang, type) : val
  })

  return result.join('')
}

const formatPlural = (words, num, lang, type = null) => {
  const word = pluralRules(words, num, lang)
  let result = `${num} ${word}`

  if (type === 'hide') result = word

  return result
}

const isEmpty = (value, msg) => {
  if (value == null) {
    // console.log(new Error().stack)
    // alert(`Error: ${msg}`)
    return true
  }
  return false
}

const pluralRules = (words, num, lang) => {
  const int = parseInt(num, 10)
  if (isNaN(int)) {
    isEmpty(null, `'${num}' should be a number.`)
    return null
  }

  if (['ru', 'uk'].includes(lang)) {
    const [one, two, many] = words
    const mod100 = int % 100
    const mod10 = int % 10

    if (mod100 >= 11 && mod100 <= 19) return many
    if (mod10 === 1) return one
    if (mod10 >= 2 && mod10 <= 4) return two

    return many
  }

  if (['en'].includes(lang)) {
    if (int === 1) return words[0]

    return words[1]
  }

  return words[0]
}
