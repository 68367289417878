import React, { useMemo } from 'react'
import { useTranslation } from 'src/hooks/useTranslation'
import useShoppingCartStatus from 'src/hooks/ShoppingCart/useShoppingCartStatus'
import { useSelector } from 'src/state/hooks'
import { getTableNum, getVenueStatus, VENUE_STATUSES } from 'src/state/location/selectors'
import useAppPreferences from 'src/hooks/useAppPreferences'
import useUnpaidOrders from 'src/hooks/useUnpaidOrders'
import { MIN_ORDER_AMOUNT } from 'src/constants'
import { SERVICES_TYPE, SHOPPING_CART_STATE } from 'src/types'
import { ReactComponent as WarnIcon } from 'src/assets/ico-warn-24.svg'
import { ReactComponent as TableIcon } from 'src/assets/ico-table-24-18.svg'
import useOrdersWarnings from 'src/hooks/ShoppingCart/useOrdersWarnings'
import s from '../styles.module.scss'

const StatusLabel = () => {
  const { t } = useTranslation()

  const venueStatus = useSelector(getVenueStatus)
  const tableNumber = useSelector(getTableNum)
  const { activeServiceType } = useSelector(store => store.shoppingCard)

  const { preferences } = useAppPreferences()

  const [{ isUnderMinOrderAmount, orders }] = useUnpaidOrders()
  const [{ status, cookingOrdersCount }] = useShoppingCartStatus()
  const { hasWarnings, isDisabledOptions, notFoundOptions, isDisabledDish } = useOrdersWarnings(orders)

  const venueAvailable = venueStatus === VENUE_STATUSES.OPEN || venueStatus === VENUE_STATUSES.NOT_SELECTED

  const statusLabel: string = useMemo(() => {
    switch (status) {
      case SHOPPING_CART_STATE.NEW: {
        if (preferences.withQrMenuMode) {
          return t('numDishesWaitingQr', { count: orders.length } as any)
        } else {
          return t('numDishesWaiting', { count: orders.length } as any)
        }
      }
      case SHOPPING_CART_STATE.CAN_TAKEOUT:
        return t('shoppingCart.closedContent.readytogo')
      case SHOPPING_CART_STATE.PAID:
        return cookingOrdersCount === 0
          ? t('shoppingCart.closedContent.allpay')
          : t('numDishesCooking', { count: cookingOrdersCount } as any)
      default:
        return preferences.withQrMenuMode ? t('general.myorderQrMenu') : t('general.myorder')
    }
  }, [status, preferences, cookingOrdersCount, orders, t])

  const warningLabel: string = useMemo(() => {
    if (!venueAvailable) {
      return t('shoppingCart.closedContent.notwork')
    } else if (isDisabledDish || isDisabledOptions || notFoundOptions) {
      return t('shoppingCart.closedContent.stoplisted')
    } else if (isUnderMinOrderAmount) {
      return t('shoppingCart.minimalOrderWarning.title', { amount: MIN_ORDER_AMOUNT / 100 } as any)
    }
  }, [isUnderMinOrderAmount, isDisabledDish, isDisabledOptions, venueAvailable, notFoundOptions, t])

  return (
    <div className={s.title}>
      <div data-cy="shoppingCardCloseText">
        {(hasWarnings || !venueAvailable) && warningLabel}
        {!hasWarnings && venueAvailable && statusLabel}
      </div>
      {!venueAvailable && <div className={s.icoSleep} />}
      {hasWarnings && venueAvailable && (
        <WarnIcon fill={isDisabledDish || isDisabledOptions ? 'var(--warningColor)' : 'white'} />
      )}
      {activeServiceType === SERVICES_TYPE.HERE && !hasWarnings && venueAvailable && (
        <div className={s.tableLayout}>
          <TableIcon />
          {Boolean(tableNumber) && <div className={s.tableNum}>&nbsp;{tableNumber}</div>}
        </div>
      )}
    </div>
  )
}

export default React.memo(StatusLabel)
