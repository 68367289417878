import {
  GET_APP_PREFERENCES_STARTED,
  GET_APP_PREFERENCES_ERROR,
  GET_APP_PREFERENCES_SUCCESS,
  GET_PUBLIC_BRAND_PREFERENCES,
  SET_VENUES_APP_PREFERENCES
} from './types'

const initialState = {
  isLoading: false,
  error: undefined,
  appPreferences: {
    holidayModifications: null
  },
  venueAppPreferences: {},
  telegram: '',
  phone: '',
  name: '',
  customizationSettings: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_APP_PREFERENCES_STARTED: {
      return {
        ...state,
        isLoading: true,
        error: undefined
      }
    }
    case GET_APP_PREFERENCES_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    }
    case GET_APP_PREFERENCES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        appPreferences: action.appPreferences
      }
    }
    case GET_PUBLIC_BRAND_PREFERENCES: {
      return {
        ...state,
        phone: action.publicSettings.phone || '',
        telegram: action.publicSettings.telegram || '',
        name: action.publicSettings.name || '',
        customizationSettings: action.publicSettings.customizationSettings || null
      }
    }
    case SET_VENUES_APP_PREFERENCES: {
      return {
        ...state,
        venueAppPreferences: action.venueAppPreferences
      }
    }
    default: {
      return state
    }
  }
}
