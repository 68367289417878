import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { usePageVisibility } from 'react-page-visibility'
import { addDishes, addOptionsets } from 'src/state/menu/actions.ts'
import { config } from './constants'
import { addTranslations } from './state/translation'
import { setPaidOrders, processWebsocketOrder, addComment, removeUnpaid } from './state/orders/actions.ts'
import { fetchVenues } from './state/venues'
import { getVenueId, setVenueId } from './state/location'
import { setToken } from './state/local'
import {
  addFavorite,
  addUserData,
  addUserDataStarted,
  addUserDataError,
  setUserIp,
  finishUserConfirmation,
  setFavorites,
  toggleCheck
} from './state/user/actions'
import { addRoles } from './state/roles'

import { addStoplistDishesSSE, fetchStoplistDish, addStoplistModifiers } from './state/stoplist'
import { appEnable, appDisable } from './state/hebel'

import { disableVenue } from './state/venues/actions'
import { addCategories } from './state/categories'
import { getUserIp, getUserData } from './state/user/selectors'
import { cycleActivityView, setPromoPopupToDisplay, togglePageLoader } from './state/ui/actions'
import { getDynamicTexts, fetchDynamicTexts } from './state/texts'
import { setTheme } from './state/themes'
import { getServicesStoplistSuccess } from './state/services-stoplist/actions'
import { getActiveThemeThunk } from './state/themes/thunkActions'
import { fetchHistoryThunk } from './state/history/thunkActions'
import { setDelivery } from './state/delivery'
import { setTimedTakeout } from './state/timedTakeout'
import { registerUserThunk } from 'src/network/get/user'

function gtag() {
  if (window.dataLayer) window.dataLayer.push(arguments)
}

export const fetchImHere = async payload =>
  axios.post(
    `${config.API_URL3}/${window.busytable.brandSlug}/orders/imhere`,
    {
      takeout: payload.takeout,
      msg: payload.text
    },
    {
      withCredentials: true
    }
  )

export const fetchFavoriteAdd = async (dispatch, token, payload) => {
  const result = await axios.post(
    `${config.API_URL3}/${window.busytable.brandSlug}/favorites`,
    {
      dishId: payload.dishId,
      options: payload.options,
      nonce: Math.round(Math.random() * 1230456)
      // options: [
      //   {
      //     optionsetId: 34,
      //     optionId: 45
      //   }
      // ]
    },
    {
      withCredentials: true
    }
  )
  dispatch(
    addFavorite({
      id: parseInt(result.data.dishId),
      favoriteId: parseInt(result.data.id),
      options: result.data.options.map(elem => ({ id: elem.optionsetId, value: elem.optionId }))
    })
  )
}

export const fetchFavoriteRemove = async (dispatch, token, payload) => {
  await axios.delete(`${config.API_URL3}/${window.busytable.brandSlug}/favorites/${payload.id}/`, {
    withCredentials: true
  })
  // dispatch(setToken(result.headers['authorization']))
}

export const useFetchFavorites = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchFavoritesGet())
  }, [dispatch])
}

export const fetchFavoritesGet = () => async dispatch => {
  try {
    const result = await axios.get(`${config.API_URL3}/${window.busytable.brandSlug}/favorites`, {
      withCredentials: true
    })
    dispatch(setFavorites(result.data))
  } catch (e) {
    if (e?.response?.status === 401) {
      console.log('Error while getting favorites dishes: ' + e)
      dispatch(registerUserThunk())
    }
  }
}

const gaMapper = (action, category, data) => {
  switch (action) {
    case 'confirmation-modal':
      gtag('event', 'purchase', {
        transaction_id: data.id,
        value: data.value / 100,
        currency: 'UAH',
        shipping: data.type,
        items: data.lastPaidDishes
      })
      break

    case 'dishcard-btn_add_to_cart':
      gtag('event', 'add_to_cart', {
        value: data.value / 100,
        currency: 'UAH',
        items: [
          {
            id: data.dish.id,
            name: data.dish.name.en,
            category: data.dish.categoryName.en,
            price: data.dish.price / 100
          }
        ]
      })
      break

    case 'dishcard-btn_remove_from_cart':
      gtag('event', 'add_to_cart', {
        value: data.value / 100,
        currency: 'UAH',
        items: [
          {
            id: data.dish.id,
            name: data.dish.name.en,
            category: data.dish.categoryName.en,
            price: data.dish.price / 100
          }
        ]
      })
      break

    default:
      gtag('event', action, {
        event_category: category,
        ...data
      })
      break
  }
}

/**
 * Doing post user event to server.
 * @param {string} action - What did the user do? (~BEM signature)
 * @param {('engaging'|'searching'|'ordering'|'payment'|'involvement'|'service'|'default')} category='default' - What kind of intentions/experience user had? (~BEM signature?).
 * @param {Object} data - The payload of the event, depends on particular action. (context)
 */
export const postEvent = (action, category = 'default', data = {}) => {
  console.log(`[event] ${action} : ${category}`, data)

  /* if (window.mode === 'production') {
    gtag('event', action, {
      event_category: category,
      ...data
    })
  } */

  gaMapper(action, category, data)

  // fixme: временная реализация
  // Будет ждать настоящей переделки евентов
  if (window.mode === 'production') {
    try {
      if (action.includes('add_to_cart')) {
        console.log('track', 'add_to_cart')
        window.fbq('track', 'add_to_cart', {
          value: data.name,
          currency: 'UAH',
          content_ids: data.id,
          content_type: 'product'
        })
      } else if (action === 'confirmation-modal' && category === 'ordering') {
        const mode = { here: 'Here', delivery: 'Delivery', takeout: 'Takeaway' }
        window.fbq('track', mode[data.type], {
          value: data.value,
          currency: 'UAH'
        })
      }
    } catch (error) {
      console.log('tracking error', error)
    }
  }

  axios.post(
    `${config.API_URL3}/${window.busytable.brandSlug}/event`,
    { action, category, data },
    {
      withCredentials: true,
      headers: { withCredentials: true, 'Content-Type': 'application/json', 'x-url': window.location.href }
    }
  )
}

// fetch Venues from API
export const useFetchVenues = () => {
  const dispatch = useDispatch()
  const venueId = useSelector(getVenueId)

  useEffect(() => {
    fetchVenuesAsync(dispatch, venueId)
  }, [dispatch])
}

export const fetchVenuesAsync = async (dispatch, venueId = null, fromChannel) => {
  let venuesList = []
  let slug = null

  if (!fromChannel && window.busytable && window.busytable.venues) {
    venuesList = window.busytable.venues.venues
    slug = window.busytable.venues.defaultVenueSlug
  } else {
    const resp = await axios(`${config.API_URL3}/${window.busytable.brandSlug}/venues`)
    if (resp.data) {
      slug = resp.data.defaultVenueSlug
      venuesList = resp.data.venues
    }
  }
  /**
   * setting default value if there is only one venue of current brand
   */
  if (venuesList?.length === 1 && !slug) {
    slug = venuesList[0].slug
  }
  dispatch(fetchVenues(venuesList))
  localStorage.setItem('lastchange_venues', Date.now())
  localStorage.setItem('lastchange_categoriesDishes', Date.now())
  if (!venueId && slug && !window.location.pathname.includes('/q/')) {
    dispatch(setVenueId(slug))
  }
}

export const paidFromWidgetUpdate = async takeoutNum => {
  try {
    axios.post(
      `${config.API_URL3}/${window.busytable.brandSlug}/widget-callback/${takeoutNum}`,
      {},
      {
        withCredentials: true,
        headers: {
          verified: 'yes'
        }
      }
    )
  } catch (err) {
    console.log(err)
  }
}

export const useFetchTheme = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    fetchThemeAsync(dispatch)
  }, [dispatch])
}

export const fetchThemeAsync = async (dispatch, venueId = null) => {
  const result =
    (window.busytable && window.busytable.themes) ||
    (await axios(`${config.API_URL3}/${window.busytable.brandSlug}/themes`))
  if (result.data && result.data.result && result.data.result.length > 0) {
    console.log(result.data.result[0])
    dispatch(setTheme(result.data.result[0]))
    localStorage.setItem('lastchange_theme', Date.now())
  }
}

export const fetchThemeAsyncWindowReload = async (dispatch, venueId = null, fromChannel) => {
  const result =
    (!fromChannel && window.busytable && window.busytable.themes) ||
    (await axios(`${config.API_URL3}/${window.busytable.brandSlug}/themes`))
  if (result.data && result.data.result && result.data.result.length > 0) {
    // console.log(result.data.result[0])
    dispatch(setTheme(result.data.result[0]))
    localStorage.setItem('lastchange_theme', Date.now())
    window.location.reload()
  }
}

export const useFetchRoles = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    fetchRoles(dispatch)
  }, [dispatch])
}

/* DEPRECATED */
// REMOVE HERE
export const fetchRoles = async dispatch => {
  const result = await axios(`${config.API_URL}/${window.busytable.brandSlug}/roles`)
  dispatch(addRoles(result.data))
}
// REMOVE HERE

export const patchUserRateAsync = num => {
  axios
    .patch(
      `${config.API_URL3}/${window.busytable.brandSlug}/user`,
      {
        rate: num
      },
      {
        withCredentials: true
      }
    )
    .catch(e => {
      if (e.response && e.response.status) {
        throw new Error(e.response.status)
      } else {
        return null
        // throw new Error('fetch fail')
      }
    })
}

export const patchUserLangAsync = lang => {
  axios
    .patch(
      `${config.API_URL3}/${window.busytable.brandSlug}/user`,
      {
        lang
      },
      {
        withCredentials: true
      }
    )
    .catch(e => {
      if (e.response && e.response.status) {
        throw new Error(e.response.status)
      } else {
        return null
        // throw new Error('fetch fail')
      }
    })
}

export const fetchConfirmAuth = nonce => {
  axios.post(
    `${config.API_URL3}/${window.busytable.brandSlug}/auth/confirm`,
    { nonce },
    {
      withCredentials: true,
      headers: { verified: 'yes' }
    }
  )
}

export const fetchSetAuth = token =>
  axios.post(
    `${config.API_URL3}/${window.busytable.brandSlug}/auth/token/set`,
    { token },
    {
      withCredentials: true,
      headers: {
        token,
        verified: 'yes',
        'x-url': window.location.href
      }
    }
  )

export const fetchGetToken = async () =>
  await axios.get(`${config.API_URL3}/${window.busytable.brandSlug}/auth/token`, {
    withCredentials: true,
    headers: { verified: 'yes' }
  })

export const fetchUserAsync = () => async dispatch => {
  dispatch(addUserDataStarted())
  const timestamp = new Date().getTime()
  const result = await axios(`${config.API_URL3}/${window.busytable.brandSlug}/user?${timestamp}`, {
    withCredentials: true
  }).catch(e => {
    console.log('failed fetchUserAsync request', e)
    return { status: 'error' }
  })

  if (result.status !== 'error') {
    dispatch(addUserData(result.data))
    if (result.data && localStorage.getItem('__userlang') && result.data.lang !== localStorage.getItem('__userlang'))
      patchUserLangAsync(localStorage.getItem('__userlang'))
    window.busytable.user = result.data // используется для LogRocket
  } else {
    dispatch(addUserDataError())
  }
}

export const fetchPayFree = async (liqpayData, dispatch, venueId) => {
  await axios.post(`${config.API_URL}/${window.busytable.brandSlug}/payments/free`, liqpayData, {
    withCredentials: true
  })
  // dispatch(cycleActivityView({ id: 1, current: -window.innerHeight + 101 }))
  // dispatch(cycleActivityView({ id: 1, current: -window.innerHeight + 100 }))
  setTimeout(() => {
    dispatch(fetchHistoryThunk())
  }, 500)
  dispatch(
    removeUnpaid({
      venueSlug: venueId,
      paymentId: liqpayData.paymentId,
      takeoutNum: liqpayData.takeoutNum,
      timedTakeout: liqpayData.timedTakeout,
      info: liqpayData.info
    })
  )
  dispatch(setTimedTakeout(false))
  dispatch(toggleCheck(false))
  dispatch(setDelivery(null))
  dispatch(addComment(''))
}

// fetch меню при изменении venueId
// для скачивания меню нужно ждать выбор заведения. Обдумать необходимость наличия заведения по умолчанию
export const useFetchMenu = () => {
  const dispatch = useDispatch()
  const venueId = useSelector(getVenueId)
  useEffect(() => {
    fetchCats(dispatch)
    fetchDishes(dispatch)
    fetchOptionsets(dispatch)
    fetchTranslations(dispatch)
    // fetchDynamicTexts(dispatch)
  }, [dispatch])

  // fetch stoplists when venue selected and changes
  useEffect(() => {
    if (venueId > 0) {
      fetchModifiersStoplist(dispatch, venueId.toString())
      fetchDishesStoplist(dispatch, venueId.toString())
    }
  }, [venueId, dispatch])
}

export const fetchCats = async (dispatch, fromChannel) => {
  const resultCats =
    (!fromChannel && window.busytable && window.busytable.categories) ||
    (await axios(`${config.API_URL3}/${window.busytable.brandSlug}/categories`))
  dispatch(addCategories(resultCats.data))
  localStorage.setItem('lastchange_cats', Date.now())
}

export const fetchDishes = async (dispatch, fromChannel) => {
  const resultDishes =
    (!fromChannel && window.busytable && window.busytable.dishes) ||
    (await axios(`${config.API_URL}/${window.busytable.brandSlug}/dishes`))
  if (resultDishes && resultDishes.data) {
    dispatch(addDishes(resultDishes.data))
    localStorage.setItem('lastchange_dishes', Date.now())
    localStorage.setItem('lastchange_recipes', Date.now())
  }
}

export const fetchOptionsets = async (dispatch, fromChannel) => {
  const resultOptionsets =
    (!fromChannel && window.busytable.optionsets) ||
    (await axios(`${config.API_URL3}/${window.busytable.brandSlug}/optionsets`))
  if (resultOptionsets && typeof resultOptionsets.data === 'string') return
  dispatch(addOptionsets(resultOptionsets.data))
  localStorage.setItem('lastchange_optionSets', Date.now())
  localStorage.setItem('lastchange_options', Date.now())
}

export const fetchTranslations = async dispatch => {
  let resultTranslations
  if (window.busytable?.translations) {
    resultTranslations = window.busytable.translations
  } else {
    const resp = await axios(`${config.API_URL3}/${window.busytable.brandSlug}/translations`)
    if (resp) {
      resultTranslations = resp.data
    }
  }

  dispatch(addTranslations(resultTranslations))
  getDynamicTexts(dispatch, window.busytable?.translations?.data || resultTranslations?.data)
}

export const fetchModifiersStoplist = async (dispatch, venueSlug) => {
  const result = await axios(`${config.API_URL3}/${window.busytable.brandSlug}/${venueSlug}/modifiers-stoplist`)
  dispatch(addStoplistModifiers(result.data))
}

export const fetchDishesStoplist = async (dispatch, venueSlug) => {
  const result = await axios(`${config.API_URL3}/${window.busytable.brandSlug}/${venueSlug}/dishes-stoplist`)
  dispatch(fetchStoplistDish(result.data))
}

// fetch Orders paid from API
export const useFetchPaidOrders = () => {
  const dispatch = useDispatch()
  const isVisible = usePageVisibility()

  useEffect(() => {
    if (isVisible) {
      postEvent('app-focus', 'service')
      dispatch(fetchPaidOrdersAsync())
      dispatch(fetchUserAsync())
      try {
        navigator.permissions.query({ name: 'geolocation' }).then(e => {
          postEvent('app-navigator', 'service', { access: e.state })
        })
      } catch (e) {
        console.log(e)
      }
    }
  }, [isVisible, dispatch])
}

export const fetchPaidOrdersAsync = () => async dispatch => {
  const result = await axios(`${config.API_URL3}/${window.busytable.brandSlug}/orders`, {
    withCredentials: true
  }).catch(e => {
    console.log('failed fetchPaidOrdersAsync request', e)
    return { status: 'error' }
  })
  if (result.status !== 'error') {
    dispatch(setPaidOrders(result.data))
  }
}

// Subscribe to orders websocket
export const useOrdersWebsocket = () => {
  const dispatch = useDispatch()
  // const token = useSelector(getToken)
  const venue = useSelector(getVenueId)
  const userIp = useSelector(getUserIp)
  const userData = useSelector(getUserData)

  useEffect(() => {
    const eventDisableVenue = event => {
      const msg = JSON.parse(event.data)
      dispatch(disableVenue(msg.disabled, msg.venueSlug))
    }

    const eventPayment = event => {
      // console.log('New payment: ', event.data)
      dispatch(processWebsocketOrder(event.data))
    }

    const eventModifierStoplist = event => {
      const payload = JSON.parse(event.data)
      dispatch(addStoplistModifiers(payload))
    }

    const eventDishesStoplist = event => {
      const payload = JSON.parse(event.data)
      // console.log('stoplistSSE:', payload, 'venue:', venue)
      if (venue) dispatch(addStoplistDishesSSE(payload))
    }

    const eventUserUpdate = event => {
      // console.log('New stoplist dish: ', event.data)
      // const payload = JSON.parse(event.data)
      // console.log(payload, venue)
      dispatch(addUserData(JSON.parse(event.data)))
      // fetchUserAsync(dispatch)
    }

    const eventAppEnable = event => {
      dispatch(appEnable())
    }

    const eventMyIp = event => {
      // console.log('New ip: ', event.data)
      const payload = JSON.parse(event.data)
      // const payload = event.data
      // console.log(payload)
      dispatch(setUserIp(payload))
      // dispatch(setUserIp('176.115.100.20'))
      // 176.115.100.20
    }

    const eventAppDisable = event => {
      dispatch(appDisable())
    }

    const eventLastChange = event => {
      const eventMap = {
        dishes: () => fetchDishes(dispatch, true),
        recipes: () => fetchDishes(dispatch, true),
        cats: () => fetchCats(dispatch, true),
        categories: () => fetchCats(dispatch, true),
        categoriesDishes: () => {
          fetchCats(dispatch, true)
          fetchVenuesAsync(dispatch, null, true)
        },
        options: () => fetchOptionsets(dispatch, true),
        optionSets: () => fetchOptionsets(dispatch, true),
        brand: () => fetchDynamicTexts(dispatch, true),
        venues: () => fetchVenuesAsync(dispatch, null, true),
        theme: () => fetchThemeAsyncWindowReload(dispatch, true)
      }
      const changesList = JSON.parse(event.data)
      Object.keys(changesList).forEach(endpoint => {
        const current = Number(localStorage.getItem(`lastchange_${endpoint}`))
        if (current && current < changesList[endpoint] && eventMap[endpoint]) {
          console.log('Refreshing outdated data:', endpoint)
          console.log(eventMap[endpoint], 'ENDPOINT')
          eventMap[endpoint](dispatch)
        }
      })
    }

    const eventVersion = event => {
      try {
        const myVersion = Number(localStorage.getItem('version'))
        const newVersion = Number(event.data)
        if (newVersion > 1500000000000 && newVersion < 2690000000000 && newVersion < Date.now() + 1000000) {
          if (myVersion === 0) {
            localStorage.setItem('version', newVersion)
          } else if (newVersion > myVersion) {
            localStorage.setItem('version', newVersion)
            if (Number(localStorage.getItem('version')) === newVersion) {
              document.location.reload() // self-update
            } else console.log("can't store version")
          } else console.log('Current version')
        } else console.log(`Incorrect version: ${newVersion}`)
      } catch (e) {
        postEvent('App', `[error] Version check failed ${e}`)
      }
    }

    const onServicesStoplistUpdate = event => {
      const updatedData = JSON.parse(event.data)
      dispatch(getServicesStoplistSuccess(updatedData))
    }

    const onActiveThemeUpdate = event => {
      dispatch(getActiveThemeThunk())
    }

    const onNewPromoPopup = event => {
      const payload = JSON.parse(event.data)
      dispatch(setPromoPopupToDisplay(payload))
    }

    const params = { withCredentials: true }
    if (!venue) return
    if (!userData) return
    let eventSource
    const url = `${config.CHANNEL_URL}/${window.busytable.brandSlug}/${venue}/channel`
    if (venue) {
      const setupChannel = () => {
        eventSource.onopen = () => {
          listeners.map(listener => eventSource.addEventListener(listener.name, listener.cb))
        }
        eventSource.closeEvents = () => {
          listeners.map(listener => eventSource.removeEventListener(listener.name, listener.cb))
          eventSource.close()
        }
        eventSource.onerror = function(event) {
          if (event.target.readyState === EventSource.CONNECTING) {
            console.log('Channel: Reconnecting...')
          } else if (event.target.readyState === EventSource.CLOSED) {
            console.log('Channel: Connection failed permanently. Reconnecting...')
            eventSource.close()
            setTimeout(() => {
              if (eventSource.__url) {
                eventSource = new EventSource(eventSource.__url, params)
                setupChannel()
              }
            }, 2000)
          }
        }
      }
      const listeners = [
        { name: 'myip', cb: eventMyIp },
        { name: 'app_enable', cb: eventAppEnable },
        { name: 'app_disable', cb: eventAppDisable },
        { name: 'payment', cb: eventPayment },
        { name: 'venueStateChange', cb: eventDisableVenue },
        { name: 'modifiers_stoplist', cb: eventModifierStoplist },
        { name: 'dishes_stoplist', cb: eventDishesStoplist },
        { name: 'user', cb: eventUserUpdate },
        { name: 'version', cb: eventVersion },
        { name: 'lastchange', cb: eventLastChange },
        { name: 'services_stoplist', cb: onServicesStoplistUpdate },
        { name: 'lastchange_theme', cb: onActiveThemeUpdate },
        { name: 'promoPopup', cb: onNewPromoPopup }
      ]
      eventSource = new EventSource(url, params)
      eventSource.__url = url
      setupChannel()
    }
    return () => {
      if (eventSource && eventSource.closeEvents) eventSource.closeEvents()
    }
  }, [venue, dispatch, userData])
}

export const fetchConfirmationCode = () =>
  axios.post(
    `${config.API_URL3}/${window.busytable.brandSlug}/profile-merge/requestChanges`,
    {},
    {
      withCredentials: true
    }
  )

export const submitConfirmationCode = code =>
  axios.post(
    `${config.API_URL3}/${window.busytable.brandSlug}/profile-merge/confirm/${code}`,
    {},
    {
      withCredentials: true
    }
  )

export const finishUserConfirmationThunk = () => async dispatch => {
  await dispatch(finishUserConfirmation())
  await dispatch(fetchUserAsync())
  await dispatch(fetchHistoryThunk())
  await dispatch(fetchPaidOrdersAsync())
  await dispatch(fetchFavoritesGet())
  await dispatch(togglePageLoader(false))
}
