import React from 'react'
import cx from 'classnames'
import { useSelector } from 'react-redux'
import { ReactComponent as WarnIcon } from 'src/assets/ico-warn-18.svg'
import { MIN_ORDER_AMOUNT } from 'src/constants'
import { useTranslation } from 'src/hooks/useTranslation'
import { getTableNum } from 'src/state/location'
import useAppPreferences from 'src/hooks/useAppPreferences'
import useUnpaidOrders from 'src/hooks/useUnpaidOrders'
import useShoppingCartStatus from 'src/hooks/ShoppingCart/useShoppingCartStatus'
import { SegmentButton } from '../SegmentButton'
import OrdersList from './OrdersList'
import s from '../styles.module.scss'
import ActiveServiceTypeIcon from './ActiveServiceTypeIcon'
import { AppState } from 'src/state/store'
import { getVenueStatus, VENUE_STATUSES } from '../../../state/location/selectors'

export interface IUnpaidOrdersSectionProps {
  children: any
  newDesign?: boolean
}

export const UnpaidOrdersSection: React.FC<IUnpaidOrdersSectionProps> = ({ children, newDesign }) => {
  const { t } = useTranslation()
  const { preferences } = useAppPreferences()
  const venueStatus = useSelector(getVenueStatus)
  const tableNum = useSelector(getTableNum)
  const venueAvailable = venueStatus === VENUE_STATUSES.OPEN || venueStatus === VENUE_STATUSES.NOT_SELECTED
  const accordionClosed = false

  const [{ isUnderMinOrderAmount, orders, groupedOrders }] = useUnpaidOrders()
  const [{ hasWarning }] = useShoppingCartStatus()
  const { activeServiceType } = useSelector((store: AppState) => store.shoppingCard)
  const renderWarningCard = () => {
    if (hasWarning) {
      return (
        <div
          style={{ borderRadius: '0px', display: 'flex', alignItems: 'center' }}
          className={[s.contextedWrapper, s.payWarn, s.warnText].join(' ')}
        >
          <WarnIcon style={{ marginRight: '8px', marginLeft: '16px' }} />{' '}
          <span>
            {isUnderMinOrderAmount
              ? t('shoppingCart.minimalOrderWarning.title', { amount: MIN_ORDER_AMOUNT / 100 } as any)
              : t('orderSection.dishStoplist')}
          </span>
        </div>
      )
    }
  }

  const getShoppingCardHeader = () => {
    if (preferences.withQrMenuMode) {
      return t('numDishesWaitingQr', { count: orders.length } as any)
    } else {
      return t('numDishesWaiting', { count: orders.length } as any)
    }
  }

  return (
    <>
      <div
        style={{ transition: 'all 0.3s ease' }}
        className={cx(s.item, { [s.accordionClosed]: accordionClosed }, s.unpaidSection)}
      >
        {
          <div
            style={{ ...(newDesign ? { flexDirection: 'row-reverse' } : {}) }}
            className={cx(s.titleWrap, s.flex, s.scFlex)}
          >
            <div style={{ paddingTop: '0px' }} className={s.title}>
              {getShoppingCardHeader()}
            </div>
            <div className={s.headerPaid}>
              <ActiveServiceTypeIcon
                activeServiceType={activeServiceType}
                tableNumber={tableNum}
                venueAvailable={venueAvailable}
              />
            </div>
          </div>
        }
        <div className={s.priceList}>
          <div className={s.headerUnpaid}>{!preferences.withQrMenuMode && <SegmentButton />}</div>
          {renderWarningCard()}
          <OrdersList orders={groupedOrders} />
          {children}
        </div>
      </div>
    </>
  )
}
