import { useMemo } from 'react'
import { IDish, Tag } from '../types'
import { useSelector } from '../state/hooks'
import { getFilterableTags } from '../state/tags/selectors'
import { onlyUnique } from '../utils'

type UseDishesTagsResult = {
  tags: Tag[]
}

type UseDishesTagsHook = (dishes: IDish[]) => UseDishesTagsResult

const useDishesTags: UseDishesTagsHook = function(dishes) {
  const filterableTags = useSelector(getFilterableTags)

  const uniqueDishesTags: Tag[] = useMemo(() => {
    if (dishes) {
      return dishes
        .flatMap(dish => dish.tags)
        .filter(onlyUnique)
        .map(tagId => filterableTags.find(tag => tag.id === tagId))
        .filter(tag => tag) as Tag[]
    }
    return []
  }, [filterableTags, dishes])

  return {
    tags: uniqueDishesTags
  }
}

export default useDishesTags
