import React, { useState } from 'react'
import { ReactComponent as CloseIcon } from 'src/assets/ico-close-24.svg'
import { useTranslation } from 'src/hooks/useTranslation'
import { Popup } from 'src/components/ui/Popup'
import s from './styles.module.scss'

type CommentProps = {
  closeHandler: () => void
  handler: (value: string) => void
  text?: string
  superText?: string
  placeholder?: string
}
const Comment: React.FC<CommentProps> = ({ closeHandler, handler, text, superText, placeholder }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(text ? text : '')

  const valueHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value)
  }

  return (
    <Popup
      fullsize
      header={
        <>
          <CloseIcon onClick={closeHandler} className={'leftAbsolute'} />
          <div
            onClick={_ => {
              if (handler) {
                handler(value)
              }
            }}
            data-cy='commentSubmitButton'
            className={['rightAbsolute', s.commentButon].join(' ')}
          >
            <div className={s.textButton}>{superText ? superText : t('comment.button')}</div>
          </div>
        </>
      }
    >
      <div className={s.textArea}>
        <textarea
          className={[s.textAreaElem, 'body3'].join(' ')}
          placeholder={placeholder ? placeholder : t('comment.placeholder')}
          onChange={valueHandler}
          value={value}
          autoFocus
          data-cy='comment'
        />
      </div>
    </Popup>
  )
}

export default Comment
