import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getDishes } from 'src/state/menu'
import useCurrentCategories from './useCurrentCategories'
import { IDish } from '../types'
import { getStoplist } from '../state/stoplist'

type DishExistFunc = (id: number | undefined | null) => boolean
type FindDishFunc = (id: number | undefined | null) => IDish | null | undefined
type DishInStopListFunc = (id: number) => boolean

type CurrentDishesReturnValue = {
  dish: IDish | null
  dishes: IDish[]
  isDishExist: DishExistFunc
  isDishInStopList: DishInStopListFunc
  findDish: FindDishFunc
}

export default function useCurrentDishes(dishId?: number | undefined | null): Partial<CurrentDishesReturnValue> {
  const { categories } = useCurrentCategories()
  const allDishes = useSelector(getDishes)
  const stopList = useSelector(getStoplist)

  const dishesIds = categories.flatMap(cat => cat.dishIds) as number[]

  const dishes = useMemo(
    () => allDishes.filter(dish => dishesIds.some(id => id === dish.id || id === dish.parentSizeId)),
    [dishesIds, allDishes]
  )

  const findDish = useMemo(
    () => (id: number | undefined | null) => id ? allDishes.find(d => d.id === id) || null : null,
    [allDishes]
  )

  const isDishExist: DishExistFunc = useMemo(
    () => id => {
      if (dishesIds.length && allDishes.length) {
        const dish = findDish(id)
        return !!dish && [dish.id, dish.parentSizeId].some(x => x && dishesIds.includes(x))
      } else {
        return true
      }
    },
    [dishesIds, findDish, allDishes]
  )

  const isDishInStopList: DishInStopListFunc = useMemo(() => id => {
    const { dishes } = stopList
    return dishes.indexOf(id) >= 0
  }, [stopList])

  if (dishId) {
    return {
      dish: findDish(dishId)
    }
  }

  return {
    dishes,
    isDishExist,
    isDishInStopList,
    findDish
  }
}
