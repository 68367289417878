import axios from 'axios'
import { useCallback, useMemo, useState } from 'react'
import { config, authHeader } from 'src/constants'

const FILES_UPLOAD_URL = `${config.API_URL3}/${window.busytable.brandSlug}/files`

const reorderFilesEntries = obj =>
  Object.values(obj).reduce((res, curr, index) => ({ ...res, [index]: { ...curr, order: index } }), {})

export default function useFileUploadApi(type) {
  const [progress, setProgress] = useState(0)
  const [uploadedFile, setUploadedFile] = useState(null)
  const [filesList, setFilesList] = useState({})

  const uploadRequest = useCallback(
    async (data, onSuccess, onProgress) => {
      try {
        const result = await axios.post(`${FILES_UPLOAD_URL}?type=${type}`, data, {
          withCredentials: true,
          headers: {
            'x-url': window.location.href,
            'Content-Type': 'multipart/form-data',
            verified: 'yes'
          },
          onUploadProgress: e => {
            onProgress(Math.round((100 * e.loaded) / e.total))
          }
        })

        onSuccess(result)
        return result
      } catch (e) {
        console.log(e)
      }
    },
    [type]
  )

  const upload = useCallback(
    async (file, bodyRequest) => {
      let formData = new FormData()

      formData.append('file', file)
      if (bodyRequest) {
        const formKeys = Object.keys(bodyRequest)
        if (formKeys && formKeys.length) {
          formKeys.forEach(key => {
            const value = bodyRequest[key]
            formData.append(key, JSON.stringify(value))
          })
        }
      }
      setUploadedFile({
        originalName: file.name
      })

      await uploadRequest(
        formData,
        response => {
          const result = response.data

          if (result) {
            const createdFile = {
              ...result
            }
            setUploadedFile(createdFile)
          }
        },
        progress => setProgress(progress)
      )
    },
    [setUploadedFile, uploadRequest, setProgress]
  )

  const multipleUpload = useCallback(
    (files, prevListLength) => {
      let currentLength = prevListLength

      setFilesList(currentList => {
        const initialFilesList = files
          .map((file, index) => ({
            progress: 0,
            order: index + currentLength,
            file: {
              originalName: file.name
            }
          }))
          .reduce((obj, item) => {
            return {
              ...obj,
              [item.order]: item
            }
          }, {})

        return {
          ...currentList,
          ...initialFilesList
        }
      })

      const uploadStack = files.map((file, index) => {
        let formData = new FormData()
        const currentIndex = index + currentLength

        formData.append('file', file)

        return uploadRequest(
          formData,
          response => {
            const result = response.data

            if (result) {
              const createdFile = {
                ...result
              }
              setFilesList(prevState => {
                return {
                  ...prevState,
                  [currentIndex]: {
                    ...prevState[currentIndex],
                    file: createdFile
                  }
                }
              })
            }
          },
          progress => {
            setFilesList(prevState => {
              return {
                ...prevState,
                [currentIndex]: {
                  ...prevState[currentIndex],
                  progress
                }
              }
            })
          }
        )
      })

      Promise.all(uploadStack).then(result => {
        console.log(result)
      })
    },
    [setFilesList, uploadRequest, filesList]
  )

  const remove = useCallback(
    async (file, index) => {
      setFilesList(prevState => {
        const { [index]: toDelete, ...rest } = prevState
        return reorderFilesEntries(rest)
      })

      return true
    },
    [setUploadedFile, setProgress, setFilesList]
  )

  const setMultipleFiles = useCallback(
    files => {
      const list = files
        .map((file, index) => ({
          order: index,
          file
        }))
        .reduce((obj, item) => {
          return {
            ...obj,
            [item.order]: item
          }
        }, {})

      setFilesList(list)
    },
    [setFilesList]
  )

  const state = useMemo(
    () => ({
      progress,
      file: uploadedFile,
      filesList: Object.values(filesList)
    }),
    [progress, uploadedFile, filesList]
  )

  const api = useMemo(
    () => ({
      upload,
      multipleUpload,
      remove,
      setFile: setUploadedFile,
      setFilesList: setMultipleFiles
    }),
    [upload, remove, multipleUpload, setUploadedFile, setMultipleFiles]
  )

  return [state, api]
}
