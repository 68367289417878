import { SET_THEME, GET_ACTIVE_THEME_STARTED, GET_ACTIVE_THEME_ERROR, GET_ACTIVE_THEME_SUCCESS } from './types'

const initialState = {
  isLoading: false,
  error: undefined,
  theme: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_THEME: {
      const { data } = action.payload
      return data
    }

    case GET_ACTIVE_THEME_STARTED: {
      return {
        ...state,
        isLoading: true,
        error: undefined
      }
    }
    case GET_ACTIVE_THEME_SUCCESS: {
      return {
        ...state,
        theme: action.theme,
        isLoading: false,
        error: undefined
      }
    }

    case GET_ACTIVE_THEME_ERROR: {
      return {
        ...state,
        theme: null,
        isLoading: false,
        error: action.error
      }
    }

    default:
      return state
  }
}
