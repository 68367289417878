export const UserRoles = {
  MANAGER: 'manager',
  CASHIER: 'cashier',
  WAITER: 'waiter',
  BAR: 'bar',
  KITHEN: 'kitchen',
  MANAGEMENT: 'management',
  OFFICE: 'office',
  FRIEND: 'friend',
  OWNER: 'owner',
  ADMIN: 'admin',
  SUPERADMIN: 'superadmin'
}

export const PurchaseType = {
  TAKEAWAY: 'takeaway',
  DELIVERY: 'delivery',
  HERE: 'here'
}

export const THEME_MODE = {
  LIGHT: 'light',
  DARK: 'dark'
}
export const SERVICES_TYPE = {
  HERE: 'here',
  TAKEAWAY: 'takeaway',
  DELIVERY_TAXI: 'delivery_taxi',
  DELIVERY_NOVAPOSHTA: 'delivery_novaposhta',
  DELIVERY_OWN: 'delivery_own',
  DELIVERY_UKLON: 'delivery_uklon'
}

export const Routes = {
  mainPage: '/',
  rate: '/rate',
  tips: '/tips',
  tipsThankView: '/tipsThankView'
}

export const PAYMENT_ADDON_TYPE = {
  TIPS: 'tips',
  DELIVERY: 'delivery'
}
