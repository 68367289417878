import React from 'react'
import { ReactComponent as WarnIcon } from '../../assets/warn.svg'

export const SnackBanner = props => {
  const classNames = props.className ? props.className : []
  // console.log(classNames)
  return (
    <div className={['snackWrapper', props.visible ? 'snackWrapperShow' : '', [...classNames]].join(' ')}>
      <div
        style={{
          backgroundColor: props.background ? props.background : '',
          color: props.textColor ? props.textColor : ''
        }}
        className={'snackBody'}
      >
        {props.icon ? <WarnIcon className={'snackIcon'} /> : ''}
        <div className={'snackText'}>{props.text}</div>
        <div onClick={props.onClose ? props.onClose : () => {}} className={'snackClose'}></div>
      </div>
    </div>
  )
}
