import React from 'react'
import { ReactComponent as SettingsIcon } from 'src/assets/btn-menu-new.svg'
import { ReactComponent as SearchIcon } from 'src/assets/btn-search-24.svg'
import strings from 'src/strings'
import { MenuCard } from '../../RichMenu/components/MenuCard'
import s from '../../RichMenu/styles.module.scss'

export const Loading = () => {
  return (
    <></>
    // <div
    //   className={['wrapper', s.richMenuWrapper].join(' ')}
    //   style={{
    //     zIndex: 0,
    //     position: window.innerWidth > 1025 ? 'absolute' : 'fixed'
    //   }}
    // >
    //   <nav className={[s.menuNavbar].join(' ')}>
    //     <div className={s.left}>
    //       {/* <SettingsIcon /> */}
    //       <SettingsIcon />
    //     </div>
    //     <div className={s.center}>
    //       <div className={s.title}>{strings.defaultVenue}</div>
    //     </div>
    //     <div className={s.rightMarg}>
    //       {/*s.right*/}
    //       <div style={{ position: 'absolute', right: '16px' }}>
    //         <SearchIcon />
    //       </div>
    //     </div>
    //   </nav>

    //   <div className={[s.catTabs, s.catLoading].join(' ')}>
    //     {[0, 1, 2, 4, 5, 6].map(cat => (
    //       <div key={cat} className={s.loadTab}></div>
    //     ))}
    //   </div>
    //   <div style={{ width: '100%', height: '24px' }}></div>
    //   {['bakery', 'pancake', 'fork', 'coffee'].map(dish => (
    //     <MenuCard
    //       activeTab={1}
    //       {...{ dish }}
    //       dish={{ price: 1800, name: '', img: 'not null', icon: dish }}
    //       handleCardClick={() => {}}
    //       keyword={''}
    //       key={dish + ' ' + dish}
    //     />
    //   ))}
    // </div>
  )
}
