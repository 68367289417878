import React, { useCallback } from 'react'
import { IDish } from 'src/types'
import { useTranslation } from 'src/hooks/useTranslation'
import useFavoritesApi from 'src/hooks/useFavoritesApi'
import { setDishAdded } from 'src/state/status/actions'
import { addNewOrder } from 'src/state/orders'
import useUnpaidOrders from 'src/hooks/useUnpaidOrders'
import { postEvent } from 'src/api'
import { useDispatch } from 'src/state/hooks'
import PlusButton from '../ui/PlusButton'
import { money } from '../../utils'
import { IconType } from '../RichMenu/components/IconType'
import { ReactComponent as FavIcon } from 'src/assets/ico-stroke.svg'
import s from './styles.module.scss'

type SuggestedDishProps = {
  dish: IDish
}

const SuggestedDish: React.FC<SuggestedDishProps> = function({ dish }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isDishInFavorites } = useFavoritesApi(dish.id, [])
  const [, { searchOrdersInCart }] = useUnpaidOrders()
  const previewImage = dish?.media?.[0]?.path || dish?.img
  const ordersInCartCount = Number(searchOrdersInCart(dish.id, [])?.length)

  const onAddDish = useCallback(
    event => {
      event.stopPropagation()
      if (dish) {
        dispatch(setDishAdded())
        dispatch(addNewOrder({ ...dish, selectedOptions: [] }))

        postEvent('suggested-btn_add_to_cart', 'ordering', {
          dish,
          options: [],
          value: dish.price
        })
      }
    },
    [dish, dispatch]
  )

  return (
    <div className={s.item} style={{ backgroundImage: `url(${previewImage})` }}>
      {ordersInCartCount > 0 && <span className={s.itemCounter}>{ordersInCartCount}</span>}
      {isDishInFavorites && <FavIcon width={10} height={10} className={s.itemFavIcon} />}
      <div className={s.itemDescription}>
        <PlusButton onClick={onAddDish} className={s.itemAddButton} size={9} />
        <div className={s.itemTitle}>
          <IconType
            style={{
              display: 'inline-block',
              backgroundColor: 'var(--fontInvertedColor)',
              marginBottom: '-4px'
            }}
            type={dish.icon}
            size={16}
          />
          &nbsp;
          {t(dish.name)}
        </div>
        <span className={s.itemPrice}>{`${money(dish.price)}₴`}</span>
      </div>
    </div>
  )
}

export default SuggestedDish
