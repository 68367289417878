import React from 'react'
import s from '../styles.module.scss'

export const IconType = ({ type = 'default', size = 24, style }) => {
  const getStyle = () => {
    let iconStyles = {
      ...style
    }

    if (/\.svg$/.test(type)) {
      iconStyles = {
        ...iconStyles,
        WebkitMaskImage: `url(${type})`,
        backgroundImage: 'none',
        maskImage: `url(${type})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        maskSize: 'cover',
        maskRepeat: 'no-repeat',
        maskPosition: 'center',
        WebkitMaskSize: 'cover',
        WebkitMaskRepeat: 'no-repeat',
        WebkitMaskPosition: 'center'
      }
    } else {
      iconStyles = {
        ...iconStyles,
        backgroundColor: 'none',
        backgroundImage: `url(${type})`,
        backgroundSize: 'contain',
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat'
      }
    }

    return iconStyles
  }

  return <div style={getStyle()} className={`${s['img_' + size]}`} />
}
