export const isPropValuesEqual = (subject: {}, target: object, propName: string): boolean => {
  // @ts-ignore
  return subject[propName] === target[propName]
}

export const getUniqueItemsByProperties = (items: object[], propName: string): object[] =>
  items.filter(
    (item, index, array) =>
      index === array.findIndex((foundItem: object) => isPropValuesEqual(foundItem, item, propName))
  )
