import axios from 'axios'

import { config } from 'src/constants'
import { Tag } from 'src/types'

export const getActiveTags = (): PromiseLike<{ data: Tag }> => {
  return axios({
    url: `${config.API_URL3}/${window.busytable.brandSlug}/tags`
  })
    .then(resp => resp.data)
    .catch()
}
