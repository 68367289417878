import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { getVenueById } from 'src/state/venues'
import { setTableNum, setVenueId } from 'src/state/location'
import { getWorkingHours, getVenueStatus, VENUE_STATUSES } from 'src/state/location/selectors'
import { MenuBar } from 'src/components/ui/MenuBar'
import { ReactComponent as TableIcon } from 'src/assets/ico-table-big.svg'
import { ReactComponent as DublClose } from 'src/assets/ico-dubl-close.svg'
import { postEvent } from 'src/api'
import strings from 'src/strings'
import { useTranslation } from 'src/hooks/useTranslation'
import { Popup } from 'src/components/ui/Popup.tsx'
import { Button } from 'src/components/ui/Button'
import s from './styles.module.scss'

const TableNumber = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { slug } = useParams()
  const { t } = useTranslation()
  const venue = useSelector(getVenueById)(slug)
  const workingHours = useSelector(getWorkingHours)
  const isClosed = useSelector(getVenueStatus)
  const [tableNum, setTable] = useState('')
  useEffect(() => {
    dispatch(setVenueId(parseInt(slug)))
  }, [dispatch, slug])
  // if (!venue) return null
  // console.log('[history]', history)
  const icon = 'close'
  const url = '/'
  const eventCb = () => {
    postEvent('input_table-btn_close')
  }
  return (
    <Popup
      fullsize
      withoutHeaderWrapper
      header={
        <MenuBar
          icon={icon}
          url={url}
          eventCb={eventCb}
          title={
            <div className={s.center}>
              <div className={s.title}>{!venue ? strings.defaultVenue : venue.brand && t(venue.brand.name)}</div>
              <div className={s.subtitle}>
                {!venue ? t('tableNumber.notSelectedVenue') : t(venue.name)}
                {isClosed !== VENUE_STATUSES.OPEN && isClosed !== VENUE_STATUSES.NOT_SELECTED ? (
                  <span style={{ color: 'var(--warningColor)', fontWeight: 'bold', marginLeft: '4px' }}>
                    {isClosed === VENUE_STATUSES.CLOSED
                      ? workingHours.join('-')
                      : isClosed === VENUE_STATUSES.DISABLED
                      ? t('tableNumber.disabledApp')
                      : isClosed === VENUE_STATUSES.ERROR
                      ? t('tableNumber.networkError')
                      : ''}
                  </span>
                ) : (
                  ''
                )}
              </div>
            </div>
          }
        />
      }
    >
      <div className={s.randomText}>{t('tableNumber.selectTableNumber')}</div>
      <div className={s.manual}>
        <form className={s.form}>
          <TableIcon width='40px' height='24px' fill='#CDCDCD' />
          <input
            className={s.formInput}
            placeholder=''
            value={tableNum}
            type='tel'
            maxLength='3'
            pattern='[0-9]*'
            onChange={e => setTable(e.target.validity.valid ? e.target.value : tableNum)}
            onFocus={() => {
              postEvent('table_number-form-number', 'involvement', { slug: slug })
            }}
          />
          <DublClose
            onClick={_ => {
              postEvent('table_number-form-number-btn_clear', 'involvement', { value: tableNum })
              setTable('')
            }}
          />
        </form>
        <Button
          disabled={!(tableNum > 0)}
          style={{ marginTop: '32px' }}
          onClick={_ => {
            if (tableNum > 0) {
              dispatch(setTableNum(parseInt(tableNum)))
              postEvent('table_number-btn_set', 'involvement', { value: tableNum })
              history.push('/')
            }
          }}
        >
          {tableNum.length > 0 ? t('general.ok') : t('tableNumber.selectTableNumber')}
        </Button>
      </div>
    </Popup>
  )
}

export default TableNumber
