import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Routes } from 'src/globals/enumerations'
import useCheckNative from 'src/hooks/useCheckNative'
import useCurrentCategories from 'src/hooks/useCurrentCategories.ts'
import { setCurrentCategory } from '../../state/location'

export const HashProcess = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const { categories } = useCurrentCategories()

  const [params, setParams] = useState({})
  const [currentLocation, setCurrentLocation] = useState(null)
  const isNative = useCheckNative()

  /**
   * получение параметров из #
   * получение источника из ?
   * внесение их в локальный стейт
   * внесение переменных из location в локальный стейт
   *   @todo переписать когда изменится систему /q ссылок
   */
  useEffect(() => {
    if (location.hash && !location.pathname.includes(Routes.rate)) {
      const raw = location.hash.slice(1).split(';')
      const result = raw.reduce((acc, val) => {
        const type = val.slice(0, 1)
        acc[type] = val.slice(1)

        return acc
      }, {})

      setParams(prevState => ({ ...prevState, ...result }))

      location.hash = ''
      history.replace(location)
    }

    if (location.search && !location.pathname.includes(Routes.rate)) {
      const search = location.search.slice(1)
      setParams(prevState => ({ ...prevState, s: search }))

      setCurrentLocation({
        protocol: window.location.protocol,
        host: window.location.host,
        path: window.location.pathname,
        isnative: isNative,
        userAgent: navigator.userAgent
      })
    }
  }, [location, history])

  useEffect(() => {
    if (Object.keys(params).length && !location.pathname.includes(Routes.rate)) {
      /**
       * установка категории из ссылки
       * если в параметре стринг то искать slug
       * в цифры то искать по id
       */
      if (params.c && categories && categories.length) {
        let categoryId = Number(params.c)

        if (!categoryId) {
          const category = categories.find(x => x.slug === params.c)
          categoryId = category ? category.id : null
        }

        if (categoryId) {
          dispatch(setCurrentCategory(categoryId))
        }

        setParams(prevState => {
          const { c: _, ...rest } = prevState
          return rest
        })
      }
    }
  }, [params, categories, currentLocation, dispatch])

  return null
}
