import React, { useState, useEffect } from 'react'
import s from './styles.module.scss'
import { useDispatch } from 'react-redux'
import { setVenueId, setTableNum } from '../../state/location'
import { ScanView } from './ScanView'
import { ReactComponent as CloseIcon } from '../../assets/icons/ico-close-24.svg'
import { getConfig } from '../../constants'
import { SnackBanner } from '../ui/SnackBanner'
import { encodeUrl, decodeUrl } from './UrlParse'
import { postEvent } from '../../api'
import { useTranslation } from '../../hooks/useTranslation'
import { Popup } from '../ui/Popup'
import { Redirect } from 'react-router'
import { useHistory } from 'react-router-dom'

const CodeScan = ({ onSuccess, onClose }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [manual, setManual] = useState('')
  const [error, showError] = useState(false)
  const [redirect, setRedirect] = useState('')
  useEffect(() => {
    let interv = setTimeout(() => {
      showError(false)
    }, 5000)
    return () => {
      clearTimeout(interv)
    }
  }, [error])

  const onCloseHandler = onClose
    ? () => {
        postEvent('scan-btn_close')
        onClose()
      }
    : () => {
        postEvent('scan-btn_close')
        history.push('/')
      }

  useEffect(() => {
    if (manual.length > 0) {
      dispatch(setTableNum(parseInt(manual)))
      onCloseHandler()
      // history.push('/')
    }
  }, [manual, dispatch]) // eslint-disable-line

  const handleScan = data => {
    console.log(data)
    if (data) {
      // const res = urlParser(data)
      // const { status, venueId, tableNum } = res
      const { status, venueId, tableNum } = urlParser(data)
      if (status === 'redirect') {
        setRedirect(venueId)
        return ''
      }
      console.log(status, venueId, tableNum)
      if (status === 'success') {
        const str = '/q/' + encodeUrl(venueId, tableNum)
        console.log('[str]', str)
        if (onSuccess) {
          dispatch(setVenueId(venueId))
          dispatch(setTableNum(tableNum))
          postEvent('scan-success', 'involvement', { tableNum, venueId })
          onSuccess()
        } else {
          postEvent('scan-success', 'involvement', { tableNum, venueId })
          history.push(str)
        }
      } else {
        postEvent('scan-error', 'involvement')
        showError(true)
      }
    }
  }

  const handleError = err => {
    console.error(err)
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Popup
      fullsize
      additional={<SnackBanner icon visible={error} text={t('codeScan.notable')} />}
      header={
        <>
          <CloseIcon onClick={onCloseHandler} className={s.closeIcon} />
          <div>{t('codeScan.scanQR')}</div>
          <div className={s.right}>&nbsp;</div>
        </>
      }
    >
      <ScanView onError={handleError} onScan={handleScan} {...{ setManual }} />
    </Popup>
  )
}

// <div className={'confirmation'}>
//       <SnackBanner icon visible={error} text={t('codeScan.notable')} />
//       <div className={'header'}>
//         <CloseIcon onClick={onClose} className={s.closeIcon}></CloseIcon>
//         <div>{t('codeScan.scanQR')}</div>
//         <div className={s.right}>&nbsp;</div>
//       </div>
//       <div className={'contentWrapper'}>
//         <div className={'content'}>
//           <ScanView onError={handleError} onScan={handleScan} {...{ setManual }} />
//         </div>
//       </div>
//     </div>

export const urlParser = url => {
  let urlParam = url.split(getConfig().ORIGIN_URL)[1]
  // https://dev.khlebniy.com.ua/auth/remote/4afb1f002c0eccd95ac08a9a3cb1ff29
  if (urlParam && urlParam.startsWith('/auth/remote/')) {
    return { status: 'redirect', venueId: urlParam }
  } else if (!urlParam || !urlParam.startsWith('/q/') || urlParam.length < 4) {
    return { status: 'error' }
  }
  let payload = urlParam.slice(3)

  const [venueId, tableNum] = decodeUrl(payload)
  // Закомментировано, чтобы принимать коды без номера стола
  // if (isNaN(tableNum) || !tableNum) {
  //   return {
  //     status: 'error'
  //   }
  // }
  return {
    status: 'success',
    venueId: venueId,
    tableNum: tableNum
  }
  // history.push('/q/' + urlParam)
}

export const parseManual = url => {
  return {
    venueId: parseInt(url.slice(0, 1)),
    tableNum: parseInt(url.slice(1, 3))
  }
}

export default CodeScan
