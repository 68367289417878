import React from 'react'
import s from 'src/components/Settings/styles.module.scss'
import useCustomIconSet from '../../../hooks/useCustomIconSet'

const SettingsIcon = ({ iconSlug, icon }) => {
  const settingsIcon = useCustomIconSet(iconSlug)
  const iconsGroup = useCustomIconSet(null, 'general')

  const colorIconStyles = {
    WebkitMaskImage: `url(${settingsIcon?.icon?.default || icon})`,
    maskImage: `url(${settingsIcon?.icon?.default || icon})`
  }

  return <div className={[s.colorIcon, s[`colorIcon_${iconsGroup?.size || 24}`]].join(' ')} style={colorIconStyles} />
}

export default SettingsIcon
