import { motion, useAnimation } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IconType } from 'src/components/RichMenu/components/IconType'
import useCurrentDishes from 'src/hooks/useCurrentDishes'
import useThemeStyles from 'src/hooks/useThemeStyles'
import { getAddingAnim } from 'src/state/addingAnim'
import { IDish, NewOrderInput } from 'src/types'
import { getOrderUniqueKey } from 'src/utils/orders'
import styles from '../styles.module.scss'

interface IShoppingCartIcon {
  isDarkMode: boolean
  currDish?: IDish
  newOrder: NewOrderInput
  currentDishId: number | null
}
const ShoppingCartIcon: React.FC<IShoppingCartIcon> = ({ isDarkMode, currDish, newOrder, currentDishId }) => {
  const addingAnim = useSelector(getAddingAnim)
  const { dish } = useCurrentDishes(currentDishId) as { dish: IDish }
  const isActive = currDish?.id === dish?.id
  const [isAnimationActive, setIsAnimationActive] = useState<boolean>(false)
  const controls = useAnimation()
  const { variables } = useThemeStyles()

  useEffect(() => {
    if (isActive && addingAnim?.length && !isAnimationActive) {
      setTimeout(() => {
        controls.start({ y: 0, scale: 1 })
      }, 500)
    }
  }, [isActive, addingAnim, isAnimationActive])

  const getBackgroundColor = () => {
    if (isDarkMode) {
      return '#000'
    }
    return '#fff'
  }

  return (
    <motion.div
      animate={controls}
      transition={{
        type: 'spring',
        stiffness: 100
      }}
      onAnimationStart={() => {
        controls.set({ y: -10, scale: 1.2 })
        setIsAnimationActive(true)
      }}
      onAnimationComplete={() => {
        setIsAnimationActive(false)
      }}
      key={getOrderUniqueKey(newOrder)}
      className={styles.dishIconWrapper}
    >
      <span className={`${styles.dishCount} ${isActive ? styles.dishCountActive : ''}`}>{newOrder.count}</span>
      <IconType style={{ backgroundColor: getBackgroundColor() }} type={currDish?.icon} size={24} />
    </motion.div>
  )
}

export default ShoppingCartIcon
