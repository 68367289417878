import cx from 'classnames'
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'src/state/hooks'
import tinycolor from 'tinycolor2'
import { getVenueStatus, VENUE_STATUSES } from 'src/state/location/selectors'
import { getComment, getOrdersNew } from 'src/state/orders'
import { addComment, removeComment } from 'src/state/orders/actions'
import { activityViewAnimate, cycleActivityView } from 'src/state/ui/actions'
import { getActivityViewAnimate, getActivityViewCurrent } from 'src/state/ui/selectors'
import CodeScan from 'src/components/CodeScan'
import Comment from 'src/components/Comment'
import { DeliveryPopup } from 'src/components/Settings/components/DeliveryPopup'
import ActivityView from 'src/components/ui/ActivityView'
import ContentClosed from './components/ContentClosed'
import { ContentOpen } from './components/ContentOpen'
import { calculateInit, isSafari } from 'src/utils.js'
import { SHOPPING_CART_INIT_POSITION_Y } from 'src/constants'
import useThemeStyles from 'src/hooks/useThemeStyles'
import { HolidayModifications, SHOPPING_CART_STATE } from 'src/types'
import useShoppingCartStatus from 'src/hooks/ShoppingCart/useShoppingCartStatus'
import useUnpaidOrders from 'src/hooks/useUnpaidOrders'
import { ActivityViewsContext } from './contexts'
import { DeliveryFormType, TakeoutNumber } from './types'
import useAppPreferences from 'src/hooks/useAppPreferences'
import { ShoppingCartTopRightDecor } from './theme-decorating'
import { postEvent } from 'src/api'
import { useTranslation } from 'src/hooks/useTranslation'
import useTakeoutNotify from 'src/hooks/ShoppingCart/useTakeoutNotify'
import s from './styles.module.scss'
import useCurrentDishes from 'src/hooks/useCurrentDishes'
import { getOptionsets } from 'src/state/menu'

export const ShoppingCart: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const commentValue = useSelector(getComment)
  const ordersNew = useSelector(getOrdersNew)

  const [commenting, setComment] = useState(false)
  const { dishes } = useCurrentDishes()
  const [deliveryPopup, setDeliveryPopup] = useState<DeliveryFormType>(null)
  const [takeoutNotifier, setTakeoutNotifier] = useState<TakeoutNumber | null>(null)
  const [scan, setScan] = useState(false)
  const [openPositionY, setOpenPositionY] = useState(0)
  const { current } = useSelector(getActivityViewCurrent)(1)
  const animate = useSelector(getActivityViewAnimate)
  const venueStatus = useSelector(getVenueStatus)
  const optionSets = useSelector(getOptionsets)
  const { variables } = useThemeStyles()
  const { demoVariables } = useSelector(state => state.demo)

  const { alreadyNotified, notify } = useTakeoutNotify(takeoutNotifier)
  const holidayModifications: HolidayModifications | undefined = useAppPreferences().holidayModifications
  const [{ status }] = useShoppingCartStatus()
  const [{ isUnderMinOrderAmount, isEmpty: isEmptyNewOrders }] = useUnpaidOrders()

  const venueAvailable = venueStatus === VENUE_STATUSES.OPEN || venueStatus === VENUE_STATUSES.NOT_SELECTED

  const brandSlug = window.busytable.brandSlug
  const windowPrevValue = useRef(0)
  const requestRef = useRef(0)

  const getOpenPositionValue = () => {
    if (window.innerWidth > 1025) {
      return -window.innerHeight * 0.86 + 100
    }

    return -window.innerHeight + 100
  }

  const checkHeight = useCallback(() => {
    cancelAnimationFrame(requestRef.current)
    if (windowPrevValue?.current !== window.innerHeight && current === SHOPPING_CART_INIT_POSITION_Y) {
      windowPrevValue.current = window.innerHeight
      setOpenPositionY(status === SHOPPING_CART_STATE.EMPTY ? -300 : getOpenPositionValue())
    }

    requestRef.current = requestAnimationFrame(checkHeight)
  }, [status, current])

  useLayoutEffect(() => {
    requestRef.current = requestAnimationFrame(checkHeight)

    return () => {
      cancelAnimationFrame(requestRef.current)
    }
  }, [checkHeight])

  // useEffect(() => {
  //   if (ordersNew.length) {
  //     const filterNewOrders = () => {
  //       return ordersNew.filter(order => {
  //         const currentDish = dishes?.find(dish => dish.id === order.dishId)
  //         if (currentDish) {
  //           if (
  //             order.selectedOptions?.every(orderOption => {
  //               optionSets.some(
  //                 optionSet =>
  //                   optionSet.id === orderOption.id &&
  //                   currentDish.recipeId === optionSet.recipeId &&
  //                   optionSet.options?.some(optionItem => optionItem.id === orderOption.value)
  //               )
  //             })
  //           ) {
  //             return true
  //           }

  //           return false
  //         }

  //         return false
  //       })
  //     }

  //     const filteredNewOrders = filterNewOrders()

  //   }
  // }, [ordersNew])

  useEffect(() => {
    if (isEmptyNewOrders && commentValue) {
      dispatch(removeComment())
    }
  }, [isEmptyNewOrders, commentValue, dispatch])

  useEffect(() => {
    setOpenPositionY(status === SHOPPING_CART_STATE.EMPTY ? -300 : getOpenPositionValue())
    if (status === SHOPPING_CART_STATE.EMPTY || status === SHOPPING_CART_STATE.PAID) {
      dispatch(cycleActivityView({ id: 1, current: SHOPPING_CART_INIT_POSITION_Y }))
    }
  }, [status, dispatch])

  useEffect(() => {
    if (animate) {
      dispatch(cycleActivityView({ id: 1, current: SHOPPING_CART_INIT_POSITION_Y - 32 }))
      setTimeout(() => {
        if (current !== openPositionY) {
          dispatch(cycleActivityView({ id: 1, current: SHOPPING_CART_INIT_POSITION_Y }))
          dispatch(activityViewAnimate())
        }
      }, 200)
    }
  }, [animate, dispatch]) // eslint-disable-line

  useEffect(() => {
    const toggleCartOpen = () => {
      dispatch(cycleActivityView({ id: 1, current: SHOPPING_CART_INIT_POSITION_Y }))
    }
    toggleCartOpen()

    function rotateChange() {
      setTimeout(() => {
        if (isSafari() && window.innerWidth < window.innerHeight) {
          window.location.reload() // iOS13 Safari bug workaround
        }
      }, 500)
    }
    const initCoords = calculateInit(false)
    dispatch(cycleActivityView({ id: 1, current: initCoords }))

    window.addEventListener('orientationchange', rotateChange, true)

    return () => {
      window.removeEventListener('orientationchange', rotateChange)
      if (!isSafari()) {
        try {
        } catch (err) {
          console.log('[err]', err)
        }
      } else {
        try {
        } catch (err) {
          console.log('[err]', err)
        }
      }
    }
  }, [dispatch])

  const fontCaptionColor = tinycolor(demoVariables?.shopingCartFontColor || variables?.shopingCartFontColor)

  return (
    <ActivityViewsContext.Provider
      value={{
        commenting,
        codeScan: scan,
        deliveryForm: deliveryPopup,
        alreadyNotified,
        toggleCommenting: setComment,
        toggleCodeScan: setScan,
        toggleDeliveryForm: setDeliveryPopup,
        toggleTakeoutNotifier: setTakeoutNotifier
      }}
    >
      {scan && (
        <div className={s.clearfixHack}>
          <CodeScan
            onClose={() => setScan(false)}
            onSuccess={() => {
              setScan(false)
            }}
          />
        </div>
      )}
      {takeoutNotifier && (
        <div className={s.clearfixHack}>
          <Comment
            handler={(value: string) => {
              notify(value)
              setTakeoutNotifier(null)
            }}
            superText={t('shoppingCart.commentButton')}
            closeHandler={() => {
              setTakeoutNotifier(null)
              postEvent('cart-imready-btn_close', 'default')
            }}
            placeholder={t('shoppingCart.notifyText')}
          />
        </div>
      )}
      {commenting && (
        <div className={s.clearfixHack}>
          <Comment
            handler={(value: string) => {
              dispatch(addComment(value))
              setComment(false)
            }}
            closeHandler={() => setComment(false)}
            text={commentValue}
          />
        </div>
      )}
      {deliveryPopup && (
        <div className={s.clearfixHack}>
          <DeliveryPopup
            mode={deliveryPopup}
            handler={() => {
              setDeliveryPopup(null)
            }}
            closeHandler={() => setDeliveryPopup(null)}
          />
        </div>
      )}
      <ActivityView
        classNames={''}
        id={1}
        initPositionY={SHOPPING_CART_INIT_POSITION_Y}
        openPositionY={openPositionY}
        shader={true}
        shaderTapHandler={() => dispatch(cycleActivityView({ id: 1, current: SHOPPING_CART_INIT_POSITION_Y }))}
      >
        <div
          className={cx(
            s.drawer,
            s[`drawer_${brandSlug}`],
            current !== openPositionY && s.closed,
            current === openPositionY ? s.desktopDrawer : '',
            (!venueAvailable || isUnderMinOrderAmount) && current !== openPositionY ? s.notOpen : '',
            current === openPositionY && status === SHOPPING_CART_STATE.EMPTY && s.open__empty,
            current === openPositionY && status !== SHOPPING_CART_STATE.EMPTY && s.open__full,
            current !== openPositionY && status === SHOPPING_CART_STATE.EMPTY && s.closed__empty,
            current !== openPositionY && ordersNew?.length && s.closed__full
          )}
          style={
            {
              '--viewBg': demoVariables?.shopingCartBg || variables?.shopingCartBg || null,
              '--shoppingCartEmptyColor':
                demoVariables?.shopingCartEmptyColor || variables?.shopingCartEmptyColor || null,
              '--shoppingCartEmptyBgColor':
                demoVariables?.shopingCartEmptyBgColor || variables?.shopingCartEmptyBgColor || 'var(--fontColor)',
              '--fontColor': demoVariables?.shopingCartFontColor || variables?.shopingCartFontColor || null,
              '--fontCaptionColor': fontCaptionColor.isValid() ? fontCaptionColor.setAlpha(0.5).toString() : null
            } as React.CSSProperties
          }
        >
          {current === openPositionY ? <ContentOpen /> : <ContentClosed />}
          {holidayModifications?.currentVariant &&
            holidayModifications?.variants?.[holidayModifications.currentVariant]?.shoppingCartDecoration && (
              <ShoppingCartTopRightDecor variant={holidayModifications.currentVariant} />
            )}
        </div>
      </ActivityView>
    </ActivityViewsContext.Provider>
  )
}
