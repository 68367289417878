import React from 'react'
// import { ReactComponent as IcoPhone } from 'src/assets/emoji/telephone-receiver_1f4de.png'
// import { ReactComponent as IcoMap } from 'src/assets/ico-map-40.svg'
import s from '../styles.module.scss'

const VenueButton = ({ iconType, url, iconComponent }) => {
  var onClickFunc
  var IconComponent

  // url is a geolocation-object for maptype and string for phone or other type

  if (iconType === 'map') {
    onClickFunc = () => {
      if (
        navigator.platform.indexOf('iPhone') !== -1 ||
        navigator.platform.indexOf('iPod') !== -1 ||
        navigator.platform.indexOf('iPad') !== -1
      )
        window.open(`http://maps.apple.com/?address=${url.lat},${url.lng}&t=m`)
      else window.open(`https://maps.google.com/maps?daddr=${url.lat},${url.lng}&amp;ll=`)
    }
    // IconComponent = IcoMap
    IconComponent = () => <div className={[s.icoMap, s.emoji].join(' ')}></div>
  } else if (iconType === 'phone') {
    onClickFunc = () => {
      window.open('tel:' + url.replace(/\s+/g, ''))
    }
    // IconComponent = () => <img src={IcoPhone} width='32' height='32' alt='fireSpot' />
    IconComponent = () => <div className={[s.icoPhone, s.emoji].join(' ')}></div>
  } else {
    onClickFunc = () => {
      window.open(url)
    }
    IconComponent = iconComponent
  }
  return (
    <div onClick={onClickFunc} className={s.venueBlock}>
      <IconComponent />
    </div>
  )
}

export default VenueButton
