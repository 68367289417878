import { LOAD_COMPONENT, UNLOAD_COMPONENT } from './types'

/* State Shape
[
  string
]
*/

const initialState = []

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_COMPONENT: {
      const { name } = action.payload
      return [...new Set([...state, name])]
    }

    case UNLOAD_COMPONENT: {
      const { name } = action.payload
      return state.filter(x => x !== name)
    }

    default:
      return state
  }
}
