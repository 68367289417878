import { useCallback, useMemo } from 'react'
import { FavoriteDish, SelectedOptionType } from '../types'
import { useDispatch, useSelector } from '../state/hooks'
import { getFavorites } from '../state/user'
import { fetchFavoriteAdd, fetchFavoriteRemove, postEvent } from '../api'
import { removeFavorite } from '../state/user/actions'

type UseFavoritesValue = {
  isDishInFavorites: boolean
  addToFavorites: () => void
  removeFromFavorites: () => void
  toggleFavorite: () => void
}

type UseFavoritesHook = (dishId: number | undefined, options: SelectedOptionType[]) => UseFavoritesValue

const useFavoritesApi: UseFavoritesHook = function (dishId, options) {
  const favoriteDishes = useSelector(getFavorites) as FavoriteDish[]
  const dispatch = useDispatch()

  const favoriteDish = useMemo(() => {
    return favoriteDishes.find(favDish => {
      let flag = favDish.id === dishId
      flag = flag && favDish.options.length === options.length
      flag =
        flag &&
        options.every(option =>
          favDish.options.some(favOption => favOption.id === option.id && favOption.value === option.value)
        )
      return flag
    })
  }, [favoriteDishes, dishId, options])

  const addToFavorites = useCallback(() => {
    fetchFavoriteAdd(dispatch, false, {
      dishId,
      options: options.map(option => ({ optionsetId: option.id, optionId: option.value }))
    })
    postEvent('cart-dish_context-fav', 'involvement', { value: true })
  }, [options, dishId, dispatch])

  const removeFromFavorites = useCallback(() => {
    fetchFavoriteRemove(dispatch, false, { id: favoriteDish?.favoriteId })
    dispatch(removeFavorite({ id: favoriteDish?.favoriteId }))
    postEvent('cart-dish_context-fav', 'involvement', { value: false })
  }, [favoriteDish, dispatch])

  const toggleFavorite = useCallback(() => {
    if (favoriteDish) {
      removeFromFavorites()
    } else {
      addToFavorites()
    }
  }, [favoriteDish, removeFromFavorites, addToFavorites])

  return {
    isDishInFavorites: !!favoriteDish,
    addToFavorites,
    removeFromFavorites,
    toggleFavorite
  }
}

export default useFavoritesApi
