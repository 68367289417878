import useAppPreferences from 'src/hooks/useAppPreferences'
import { useEffect } from 'react'
import LogRocket from 'logrocket'

const useLogRocketInit = () => {
  const { preferences } = useAppPreferences()

  useEffect(() => {
    if (
      preferences &&
      window.busytable &&
      window.mode === 'production' &&
      !window.busytable.logRocketInit &&
      preferences.withLogRocket &&
      window.busytable.user
    ) {
      window.busytable.logRocketInit = true
      LogRocket.init('busytable/app')
      LogRocket.identify(window.busytable.user.userId, {
        name: window.busytable.user.name,
        email: window.busytable.user.email,
        // Add your own custom user variables here, ie:
        subscriptionType: window.busytable.user.userRole
      })
    }
  }, [preferences, window.busytable])
}

export default useLogRocketInit
