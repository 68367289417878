import cx from 'classnames'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'src/state/hooks'
import { useTranslation } from '../../hooks/useTranslation'
import { addSelectedOptions, getDishes } from 'src/state/menu'
import { money } from '../../utils'
import s from '../DishCard/styles.module.scss'
import { IDish } from '../../types'
import { useHistory } from 'react-router-dom'
import { compareSizesPosition } from './utils'

type SizeOptionsProps = {
  dish: IDish
  disabled: boolean
}

export const SizeOptions: React.FC<SizeOptionsProps> = ({ dish, disabled }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const dishes = useSelector(getDishes)

  const sizes = useMemo(() => {
    let result = [] as IDish[]
    const parentSizeId = +(dish.parentSizeId ? dish.parentSizeId : dish.id)
    // если блюдо является не размером по умолчанию то найти родительский размер
    // если нет то добавить это блюдо в массив размеров
    if (dish.parentSizeId) {
      const parentSize = dishes.find(({ id }) => id === Number(dish.parentSizeId))
      result = parentSize ? [...result, parentSize] : result
    } else {
      result = [...result, dish]
    }
    dishes
      .filter(x => Number(x.parentSizeId) === +parentSizeId)
      .forEach(x => {
        result = [...result, x].sort(compareSizesPosition)
      })

    return result
  }, [dishes, dish])

  const handleSizeClick = (id: number) => {
    dispatch(addSelectedOptions([]))
    history.push(`/dish/${id}?size-changed`)
  }

  if (sizes.length > 1) {
    
    return (
      <div className={s.sizesWrapper}>
        {/* <div>
          <div className={cx(s.option__title)}>{t('sizeOptions.size')}</div>
        </div> */}

        {sizes.map(size => {
          return (
            <div key={size.id} className={s.sizeWrapper}>
              <label
                data-cy={`optionSize-${size.id}`}
                className={cx(
                  'body1',
                  s.optionLabel,
                  s.sizeOptionLabel,
                  disabled
                    ? size.id === dish.id
                      ? s['form-check-label-disabled-check']
                      : s['form-check-label-disabled']
                    : size.id === dish.id
                    ? s['form-check-label-checked']
                    : s['form-check-label']
                )}
              >
                <input
                  type='radio'
                  onClick={() => {
                    if (!disabled) {
                      handleSizeClick(size.id)
                    }
                  }}
                  className={cx(s.formCheckInput)}
                />
                {t(size.sizeName)}
                <div style={{ display: 'flex' }}>
                  {Number.isInteger(size.volume) ? (
                    <span className={`${s.dishPrice} ${s.dishUnits}`}>
                      {size.volume} {t(size.unit)}
                    </span>
                  ) : null}
                  {Number.isInteger(size.price) &&
                  (size.price + dish.price) / 8 < size.price && // Если разница в цене огромная, то не показываем её
                  size.price - dish.price !== 0 ? (
                    <span className={`${s.dishPrice} ${s.dishUnits}`}>
                      {`${size.price - dish.price > 0 ? '+' : '-'} ${money(Math.abs(size.price - dish.price))} ₴`}
                    </span>
                  ) : null}
                </div>
              </label>
            </div>
          )
        })}
      </div>
    )
  }

  return <></>
}
