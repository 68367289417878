import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import addingAnim from './addingAnim'
import categories from './categories'
import datedTakeout from './datedTakeout'
import delivery from './delivery'
import hebel from './hebel'
import history from './history/reducers'
import loader from './loader'
import local from './local'
import location from './location'
import menu from './menu/reducers'
import orderCheckout from './orderCheckout'
import orders from './orders'
import roles from './roles'
import sebel from './sebel'
import status from './status/reducer'
import stoplist from './stoplist'
import tableShow from './tableShow'
import { texts } from './texts'
import timedTakeout from './timedTakeout'
import translation from './translation'
import themes from './themes'
import pages from './pages'
import demo from './demo'
import ui from './ui'
import user from './user'
import venues from './venues'
import appPreferencesReducer from './app-preferences/reducer'
import servicesStoplistReducer from './services-stoplist/reducer'
import shoppingCartReducer from './shoppingCard/ShoppingCart.reducer'
import tagsReducer from './tags/reducer'

const localPersistConfig = {
  key: 'local',
  storage
}

export const combinedReducer = combineReducers({
  texts,
  categories,
  history: persistReducer(
    {
      key: 'history',
      storage
    },
    history
  ),
  loader,
  location: persistReducer(
    {
      key: 'location',
      storage,
      blacklist: ['currentCategory']
    },
    location
  ),
  orderCheckout,
  addingAnim,
  hebel,
  delivery: persistReducer(
    {
      key: 'delivery',
      storage
    },
    delivery
  ),
  sebel,
  tableShow,
  roles,
  menu,
  venues,
  user: persistReducer(
    {
      key: 'user',
      storage,
      whitelist: ['confirmationExpireDate', 'favorites']
    },
    user
  ),
  orders: persistReducer(
    {
      key: 'takeout',
      storage
    },
    orders
  ),
  translation,
  themes,
  pages,
  demo,
  ui,
  tags: tagsReducer,
  stoplist,
  status: persistReducer(
    {
      key: 'status',
      storage
    },
    status
  ),
  timedTakeout,
  datedTakeout,
  local: persistReducer(localPersistConfig, local),
  appPreferences: appPreferencesReducer,
  servicesStoplist: servicesStoplistReducer,
  shoppingCard: persistReducer(
    {
      key: 'activeServiceType',
      storage
    },
    shoppingCartReducer
  )
})
