import React, { useState } from 'react'
import clsx from 'classnames'
import s from './styles.module.scss'

type SegmentSwitchProps = {
  value: any
  options: {
    label: string
    value: any
    disabled?: boolean
  }[]
  onChange: (value: any) => void
}
const SegmentSwitch: React.FC<SegmentSwitchProps> = ({ value, options, onChange }) => {
  const [activeIndex, setActiveIndex] = useState<number>(options.map(option => option.value).indexOf(value))

  return (
    <div className={s.segmentSwitch}>
      <div
        style={{
          width: `calc(((100% - 8px) / ${options.length}))`,
          transform: `translateX(${100 * activeIndex}%)`
        }}
        className={s.segmentSwitchMarker}
      />
      {options.map((option, index) => (
        <div
          key={`${option.value}`}
          data-cy={`segmentSwitchOption-${option.value}`}
          className={clsx(
            s.segmentSwitchOption,
            value === option.value && s.segmentSwitchOptionActive,
            option.disabled && s.segmentSwitchOptionDisabled
          )}
          onClick={() => {
            if (!option.disabled) {
              setActiveIndex(index)
              onChange(option.value)
            }
          }}
        >
          <span  className={s.segmentSwitchOptionLabel}>{option.label}</span>
        </div>
      ))}
    </div>
  )
}

export default SegmentSwitch
