import { SET_CUSTOM_PAGES } from './types'

const initialState = {
  pages: []
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOM_PAGES: {
      return {
        ...state,
        pages: action.pages
      }
    }

    default:
      return state
  }
}
