import React, { useState } from 'react'
import styles from './styles.module.scss'
import { useTranslation } from 'src/hooks/useTranslation'
import { motion } from 'framer-motion'
import Spinner from 'src/components/Spinner'

const tipsPercentage = [5, 15, 10]

const Tips = ({ paymentTotal, currency, handleTipClick, isLiqpayLoading }) => {
  const [isOtherAmountOpen, setIsOtherAmountOpen] = useState(false)
  const [currentOpenHeartIndex, setCurrentOpenHeartIndex] = useState(null)
  const [currentSelectedAmount, setCurrentSelectedAmount] = useState(null)
  const [otherAmount, setOtherAmount] = useState(null)
  const { t } = useTranslation()

  const heartPathStyle = {
    position: 'absolute',
    top: '-8px',
    left: '0',
    width: '100vw',
    fill: 'none',
    stroke: 'red',
    strokeWidth: '12',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeDasharray: '994',
    strokeDashoffset: '994'
  }

  const buttonStyle = {
    width: 'calc(50% - 8px)',
    height: 'calc(50% - 8px)',
    position: 'absolute',
    display: 'flex'
  }
  const buttonsStyle = {
    1: {
      top: 'calc(50% - (50% - 8px) / 2)',
      left: 0,
      transform: 'rotate(-90deg)'
    },
    2: {
      top: 'calc(50% - (50% - 8px) / 2)',
      right: 0,
      transform: 'rotate(90deg)'
    },
    3: {
      top: 0,
      right: 'calc(50% - (50% - 8px) / 2)',
      transform: 'rotate(0deg)'
    },
    4: {
      bottom: 0,
      right: 'calc(50% - (50% - 8px) / 2)',
      transform: 'rotate(180deg)'
    }
  }

  const getActiveHeartStyle = () => {
    switch (currentOpenHeartIndex) {
      case 0: {
        return 'rotate(-90deg) translateY(-12px) scale(1.2)'
      }
      case 1: {
        return 'rotate(90deg) scale(1.2) translateY(-12px)'
      }
      case 2: {
        return 'rotate(0deg) translateY(-12px) scale(1.2)'
      }
      case 3: {
        return 'rotate(180deg) translateY(-12px) scale(1.2)'
      }
    }
  }

  const getButtonTextActiveStyle = index => {
    switch (currentOpenHeartIndex) {
      case 0: {
        switch (index) {
          case 0: {
            return 'translate(-50%, -50%) rotate(0deg)'
          }
          case 1: {
            return 'translate(-50%, -50%) rotate(180deg)'
          }
          case 2: {
            return 'translate(-50%, -50%) rotate(-90deg)'
          }
          case 3: {
            return 'translate(-50%, -50%) rotate(90deg)'
          }
        }
      }
      case 1: {
        switch (index) {
          case 0: {
            return 'translate(-50%, -50%) rotate(180deg)'
          }
          case 1: {
            return `translate(-50%, -50%) rotate(360deg)`
          }
          case 2: {
            return 'translate(-50%, -50%) rotate(90deg)'
          }
          case 3: {
            return 'translate(-50%, -50%) rotate(270deg)'
          }
        }
      }
      case 2: {
        switch (index) {
          case 0: {
            return 'translate(-50%, -50%) rotate(90deg)'
          }
          case 1: {
            return `translate(-50%, -50%) rotate(270deg)`
          }
          case 2: {
            return 'translate(-50%, -50%) rotate(0deg)'
          }
          case 3: {
            return 'translate(-50%, -50%) rotate(180deg)'
          }
        }
      }
      case 3: {
        switch (index) {
          case 0: {
            return 'translate(-50%, -50%) rotate(-90deg)'
          }
          case 1: {
            return 'translate(-50%, -50%) rotate(90deg)'
          }
          case 2: {
            return 'translate(-50%, -50%) rotate(-180deg)'
          }
          case 3: {
            return 'translate(-50%, -50%) rotate(0deg)'
          }
        }
      }

      default: {
        switch (index) {
          case 0: {
            return 'translate(-50%, -50%) rotate(90deg)'
          }
          case 1: {
            return 'translate(-50%, -50%) rotate(-90deg)'
          }
          case 2: {
            return 'translate(-50%, -50%) rotate(0deg)'
          }
          case 3: {
            return 'translate(-50%, -50%) rotate(180deg)'
          }
        }
      }
    }
  }

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 1,
        staggerChildren: 0.3
      }
    }
  }

  const buttonsVariants = {
    1: {
      hidden: { opacity: 0 },
      show: {
        opacity: 1,
        transition: {
          delay: 1.9,
          duration: 1
        }
      }
    },
    2: {
      hidden: { opacity: 0 },
      show: {
        opacity: 1,
        transition: {
          delay: 1.3,
          duration: 1
        }
      }
    },
    3: {
      hidden: { opacity: 0 },
      show: {
        opacity: 1,
        transition: {
          delay: 1,
          duration: 1
        }
      }
    },
    4: {
      hidden: { opacity: 0 },
      show: {
        opacity: 1,
        transition: {
          delay: 1.6,
          duration: 1
        }
      }
    }
  }

  const getButtonWrapperStyle = () => {
    switch (currentOpenHeartIndex) {
      case 0: {
        return 'rotate(90deg)'
      }
      case 1: {
        return 'rotate(-90deg)'
      }
      case 2: {
        return 'rotate(0deg)'
      }
      case 3: {
        return 'rotate(180deg)'
      }

      default: {
        return 'rotate(0deg)'
      }
    }
  }

  const handleOtherAmountOpen = () => setIsOtherAmountOpen(!isOtherAmountOpen)

  const handleOtherAmountChange = e => {
    setOtherAmount(e.target.validity.valid ? +e.target.value : otherAmount)
    setCurrentSelectedAmount(e.target.validity.valid ? +e.target.value : otherAmount)
  }

  const getAmount = (index, percentage) => {
    if (paymentTotal < 20000 || !paymentTotal) {
      switch (index) {
        case 0: {
          return 15
        }
        case 1: {
          return 40
        }
        case 2: {
          return 30
        }
      }
    } else {
      return Math.ceil((paymentTotal * percentage) / 100 / 100)
    }
  }

  const renderOtherAmountInput = () => {
    if (isOtherAmountOpen || !!otherAmount) {
      return (
        <input
          value={otherAmount ? otherAmount : ''}
          className={`${styles.otherAmountInput} ${styles.heartText} ${
            currentOpenHeartIndex === 3 ? styles.otherAmountInputActive : ''
          }`}
          onBlur={handleOtherAmountOpen}
          autoFocus
          pattern='[0-9]*'
          maxLength='6'
          onChange={handleOtherAmountChange}
          type='tel'
          placeholder={`0 ${currency}`}
        />
      )
    }

    return (
      <div
        style={{ transform: getButtonTextActiveStyle(3) }}
        className={`${styles.heartText} ${styles.otherAmountText}`}
      >
        {t('review.otherAmount')}
      </div>
    )
  }

  const renderOtherAmount = () => {
    return (
      <motion.div
        data-cy='tipsHeartOtherAmount'
        style={{
          ...buttonStyle,
          ...buttonsStyle[4],
          ...(currentOpenHeartIndex === 3 ? { transform: getActiveHeartStyle(), zIndex: -1 } : {})
        }}
        className={styles.heartWrapper}
        variants={buttonsVariants[4]}
      >
        {renderOtherAmountInput()}
        <svg xmlns='http://www.w3.org/2000/svg' fill='none' width={158} height={142} viewBox='0 0 149 131'>
          <path
            onClick={e => {
              handleOtherAmountOpen()
              setCurrentOpenHeartIndex(3)
            }}
            fill='#FF2B2B'
            fillRule='evenodd'
            d='M0 39C0 17 18 0 41 0c13 0 25 6 33 16C82 6 95 0 108 0c23 0 40 17 40 39 0 15-10 27-22 40l-52 52-52-52C10 66 0 54 0 39Z'
            clipRule='evenodd'
          />
        </svg>
      </motion.div>
    )
  }

  const renderPercentageBlock = () => {
    return tipsPercentage.map((percentage, index) => {
      const amountToPay = getAmount(index, percentage)

      return (
        <BtnTip
          onClick={e => {
            setCurrentOpenHeartIndex(index)
            setCurrentSelectedAmount(amountToPay)
          }}
          key={index}
          delay={index + 1}
          textStyle={getButtonTextActiveStyle(index)}
          currency={currency}
          amountToPay={amountToPay}
          style={{
            ...buttonStyle,
            ...buttonsStyle[index + 1],
            ...(index === currentOpenHeartIndex ? { transform: getActiveHeartStyle(), zIndex: -1 } : {})
          }}
          variants={buttonsVariants[index + 1]}
        />
      )
    })
  }

  const renderSubmitButton = () => (
    <button
    data-cy='tipsSubmitButton'
      onClick={() => handleTipClick(currentSelectedAmount * 100)}
      disabled={!currentSelectedAmount}
      className={styles.tipsSubmitButton}
    >
      {isLiqpayLoading ? <Spinner style={{ borderTopColor: '#fff' }} /> : t('review.tipsButton')}
    </button>
  )

  return (
    <div className={styles.tipsWrapper}>
      <svg style={heartPathStyle} xmlns='http://www.w3.org/2000/svg' viewBox='0 -6 375 172'>
        <motion.path
          animate={{
            pathLength: [0, 1],
            pathOffset: [0, 1],
            opacity: [1, 0]
          }}
          transition={{ duration: 2, ease: 'easeInOut' }}
          d='M0 136.2c112.5-39 111 89.5 224-38.2 15-17 38-35.5 38-56 0-64-111.5-38.9-75 14 37-52.8-75-78.5-75-14 0 20.5 21.6 36.8 38.5 56 111.8 127.2 111.3-.7 225 38.2'
        />
      </svg>
      <motion.div
        style={{ transform: getButtonWrapperStyle() }}
        className={`${styles.buttonsWrapper}`}
        variants={container}
        initial='hidden'
        animate='show'
      >
        {renderPercentageBlock()}
        {renderOtherAmount()}
      </motion.div>
      {renderSubmitButton()}
    </div>
  )
}

const BtnTip = ({ style, variants, onClick, amountToPay, currency, textStyle }) => {
  return (
    <motion.div style={style} data-cy={`tipsPartHeart-${amountToPay}`} className={styles.heartWrapper} variants={variants}>
      <div style={{ transform: textStyle }} className={styles.heartText}>
        {amountToPay} <span>{currency}</span>
      </div>
      <svg xmlns='http://www.w3.org/2000/svg' fill='none' width={158} height={142} viewBox='0 0 149 131'>
        <path
          onClick={onClick}
          fill='#FF2B2B'
          fillRule='evenodd'
          d='M0 39C0 17 18 0 41 0c13 0 25 6 33 16C82 6 95 0 108 0c23 0 40 17 40 39 0 15-10 27-22 40l-52 52-52-52C10 66 0 54 0 39Z'
          clipRule='evenodd'
        />
      </svg>
    </motion.div>
  )
}

export default Tips
