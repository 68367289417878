import React, { useState, useEffect } from 'react'
import { useTranslation } from 'src/hooks/useTranslation'
import { Marker } from '../ui/Marker'

const MarkerContainer = ({ selectedVenue, icoFunction, children, venue, isUserMarker, markerOnclick }) => {
  const [state, setState] = useState('active')
  const { t } = useTranslation()

  useEffect(() => {
    if (selectedVenue && selectedVenue.id === venue.id) {
      setState('open')
    } else setState('active')
  }, [selectedVenue])
  const scale = () => {
    switch (state) {
      case 'active':
        return 1
      case 'open':
        return 1.5
      default:
        return 1
    }
  }

  const clickHandler = () => {
    setState('open')
    markerOnclick(venue)
  }

  const mark = icoFunction(venue, selectedVenue)
  const venueMarkerColor = venue?.settings?.venueMarkerColor
  return (
    <div
      className='marker'
      style={{
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
      onClick={() => clickHandler()}
    >
      <Marker venueMarkerColor={venueMarkerColor} {...mark} />

      <span
        style={{
          backgroundColor: 'var(--viewBg)',
          padding: '4px',
          borderRadius: '4px',
          textAlign: 'center',
          fontSize: '11px',
          whiteSpace: 'nowrap'
        }}
      >
        {t(venue.name)}
      </span>
    </div>
  )
}
export default MarkerContainer
