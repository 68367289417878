import { getVenueById, getVenues } from '../venues'
import { getHebel } from '../hebel'
import { getSebel } from '../sebel'
import { getUserData } from '../user'
import { getRoles } from '../roles'
import { isVenueOpen } from 'src/utils'

export const getVenueId = store => store.location.venueId
export const getTableNum = store => store.location.tableNum
export const getVenueOpen = store => store.location.open
export const getlastChange = store => store.location.lastChangeDate
export const getScanDate = store => store.location.lastScanDate

export const getActiveMenuId = store => {
  const activeVenue = getVenueById(store)(getVenueId(store))
  return activeVenue ? activeVenue.menuId : null
}

export const VENUE_STATUSES = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  NOT_SELECTED: 'NOT_SELECTED',
  DISABLED: 'DISABLED',
  ERROR: 'NET_ERROR'
}

const numToDate = {
  0: 'sun',
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thu',
  5: 'fri',
  6: 'sat'
}

export const getCustomVenueStatus = store => id => {
  const tempId = getVenueId(store)
  const venueId = id ? id : tempId
  const hebel = getHebel(store)
  const error = getSebel(store)
  const venue = getVenueById(store)(venueId)
  const userData = getUserData(store)
  const unpaiders = getRoles(store)
  const isUnpaider = userData && unpaiders.filter(e => userData.userRole === e.key).length > 0

  // kick client if received by sse that venue is closed
  if (!hebel) {
    if (isUnpaider) {
      // but not kick client if he is unpaider
    } else {
      return VENUE_STATUSES.DISABLED
    }
  }
  // kick client if received by network_http that venue is closed
  if (venue !== undefined && venue.disabled) {
    if (isUnpaider) {
      // but not kick client if he is unpaider
    } else {
      return VENUE_STATUSES.DISABLED
    }
  }
  if (error) {
    return VENUE_STATUSES.ERROR
  }
  if (venue !== undefined) {
    const currentDate = new Date()
    const currentWork = venue.workingHours[numToDate[currentDate.getDay()]]
    if (
      parseInt(currentWork[0].split(':')[0]) * 60 + parseInt(currentWork[0].split(':')[1]) >
        currentDate.getHours() * 60 + currentDate.getMinutes() ||
      parseInt(currentWork[1].split(':')[0]) * 60 + parseInt(currentWork[1].split(':')[1]) <
        currentDate.getHours() * 60 + currentDate.getMinutes()
    ) {
      return VENUE_STATUSES.CLOSED
    } else {
      return VENUE_STATUSES.OPEN
      // return VENUE_STATUSES.CLOSED
    }
  }
  return VENUE_STATUSES.NOT_SELECTED
}

export const getAllVenuesStatuses = store => {
  const error = getSebel(store)
  const venues = getVenues(store)
  const userData = getUserData(store)
  const unpaiders = getRoles(store)
  const isUnpaider = userData && unpaiders.filter(e => userData.userRole === e.key).length > 0

  return venues.map(venue => {
    if (venue !== undefined && venue.disabled) {
      if (isUnpaider) {
        // but not kick client if he is unpaider
      } else {
        return { ...venue, status: VENUE_STATUSES.DISABLED }
      }
    }
    if (error) {
      return { ...venue, status: VENUE_STATUSES.ERROR }
    }
    if (venue !== undefined) {
      if (!venue || !venue.workingHours) {
        return { ...venue, status: VENUE_STATUSES.ERROR }
      }
      const currentDate = new Date()
      const currentWork = venue.workingHours[numToDate[currentDate.getDay()]]
      if (currentWork && currentWork[0] && currentWork[1]) {
        if (!isVenueOpen(venue)) {
          return { ...venue, status: VENUE_STATUSES.CLOSED }
        } else {
          return { ...venue, status: VENUE_STATUSES.OPEN }
          // return {...venue, status:VENUE_STATUSES.CLOSED}
        }
      } else {
        return { ...venue, status: VENUE_STATUSES.ERROR }
      }
    }
    return { ...venue, status: VENUE_STATUSES.NOT_SELECTED }
  })
}

export const getVenueStatus = store => {
  const hebel = getHebel(store)
  const error = getSebel(store)
  const venue = getVenueById(store)(getVenueId(store))
  const userData = getUserData(store)
  const unpaiders = getRoles(store)
  const isUnpaider = userData && unpaiders.filter(e => userData.userRole === e.key).length > 0

  // kick client if received by sse that venue is closed
  if (!hebel) {
    if (isUnpaider) {
      // but not kick client if he is unpaider
    } else {
      return VENUE_STATUSES.DISABLED
    }
  }
  // kick client if received by network_http that venue is closed
  if (venue !== undefined && venue.disabled) {
    if (isUnpaider) {
      // but not kick client if he is unpaider
    } else {
      return VENUE_STATUSES.DISABLED
    }
  }
  if (error) {
    return VENUE_STATUSES.ERROR
  }
  if (venue !== undefined) {
    // const currentDate = new Date()
    // const currentWork = venue.workingHours[numToDate[currentDate.getDay()]] || ['13:00', '14:00']
    // const firstDate = currentWork[0].split(':') // [hours, minutes]
    // const seconDate = currentWork[1].split(':') // [hours, minutes]
    // const hours = currentDate.getHours() * 60
    // const minutes = currentDate.getMinutes()
    // const isBefore = parseInt(firstDate[0]) * 60 + (parseInt(firstDate[1]) - 15) > hours + minutes
    // const isAfter = parseInt(seconDate[0]) * 60 + parseInt(seconDate[1]) < hours + (minutes + 0)
    if (!isVenueOpen(venue)) {
      return VENUE_STATUSES.CLOSED
    } else {
      return VENUE_STATUSES.OPEN
      // return VENUE_STATUSES.CLOSED
    }
  }
  return VENUE_STATUSES.NOT_SELECTED
}

export const getWorkingHours = store => {
  // console.log(getVenueId(store))
  const venue = getVenueById(store)(getVenueId(store))
  // console.log(venue)
  if (venue !== undefined) {
    // console.log(venue)
    const currentDate = new Date()
    const currentWork = venue.workingHours[numToDate[currentDate.getDay()]]
    // console.log(currentWork)
    return currentWork
  }
  return ''
}

export const getWorkingHoursFull = store => {
  const venue = getVenueById(store)(getVenueId(store))

  if (venue && venue.workingHours) {
    return venue.workingHours
  }

  return null
}

export const selectCurrentCategory = store => store.location.currentCategory
