import { fetchHistoryStarted, setHistory, fetchHistoryError } from './actions'
import { fetchHistory } from 'src/network/get/history'

export const fetchHistoryThunk = () => async dispatch => {
  dispatch(fetchHistoryStarted())
  try {
    const historyResponse = await fetchHistory()
    dispatch(setHistory(historyResponse.data))
  } catch (error) {
    dispatch(fetchHistoryError(error))
  }
}
