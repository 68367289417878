import { FETCH_VENUES, DISABLE_VENUE, GET_VENUE_SLUG } from './types'

// TODO: maybe convert to object with id's {(:id): {}}
/* State Shape
[

]
*/

const initialState = {
  venues: [],
  defaultVenueSlug: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_VENUES: {
      return {
        ...state,
        venues: action.venues
      }
    }

    case DISABLE_VENUE: {
      const { status, venueSlug } = action.payload
      return {
        venues: state.venues.map(currentVenue => {
          if (currentVenue.slug === venueSlug) {
            return { ...currentVenue, disabled: status }
          } else {
            return currentVenue
          }
        })
      }
    }

    case GET_VENUE_SLUG: {
      return {
        ...state,
        defaultVenueSlug: action.slug
      }
    }

    default:
      return state
  }
}
