import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../styles.module.scss'
import { ReactComponent as PlusIcon } from 'src/assets/plus.svg'
import { useLocation } from 'react-router-dom'
import { postEvent } from 'src/api'
import useCurrentDishes from 'src/hooks/useCurrentDishes'
import { IDish, OptionSetType } from 'src/types'
import { setDishAdded } from 'src/state/status/actions'
import { addAddingAnim } from 'src/state/addingAnim/actions'
import { addNewOrder } from 'src/state/orders/actions'
import { getSelectedOptions, getOptionsetsByRecipeId } from 'src/state/menu'
import { AppState } from 'src/state/store'
import { calcOptionsCost, isDishInStoplist } from 'src/utils'
import { getStoplist } from 'src/state/stoplist'

export interface IPlusButtonProps {}

const PlusButton: React.FC<IPlusButtonProps> = ({}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [dishId] = location.pathname.split('/').reverse()
  const currentDishId: number | null = +dishId
  const { dish } = useCurrentDishes(currentDishId) as { dish: IDish }
  const selectedOptions = useSelector(getSelectedOptions)
  const optionSets = useSelector<AppState, OptionSetType[]>(getOptionsetsByRecipeId(dish?.recipeId || 0)) || []
  const stoplist = useSelector(getStoplist)
  const isDisabledDish = isDishInStoplist(stoplist, dish?.id)

  const totalPrice = useMemo(() => parseInt(dish?.price + calcOptionsCost(selectedOptions, optionSets)), [
    selectedOptions,
    optionSets,
    dish
  ])

  const onAddDish = (event: any) => {
    event.stopPropagation()
    if (dish) {
      dispatch(setDishAdded())
      dispatch(addAddingAnim({ dish, selectedOptions, clicked: false }))
      dispatch(addNewOrder({ ...dish, selectedOptions }))

      postEvent('dishcard-btn_add_to_cart', 'ordering', {
        dish,
        options: selectedOptions,
        value: totalPrice
      })
    }
  }

  return (
    <button
      onClick={onAddDish}
      className={`${styles.plusButton} ${currentDishId && dish && !isDisabledDish ? styles.plusButtonVisible : ''}`}
    >
      <PlusIcon width={19} height={19} />
    </button>
  )
}
export default PlusButton
