import React from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as CloseIcon } from '../../assets/ico-close-24.svg'
import { ReactComponent as ManWhite } from '../../assets/man-white.svg'
import { ReactComponent as QRCode } from '../../assets/qrcode.svg'
import { useTranslation } from '../../hooks/useTranslation'
import { getVenueId } from '../../state/location'
import { getVenueById } from '../../state/venues'
import strings from '../../strings'
import s from './styles.module.scss'

const Loyalty = ({ history, ...props }) => {
  const { t } = useTranslation()
  const venueId = useSelector(getVenueId)
  const venue = useSelector(getVenueById)(venueId)
  return (
    <div className={'confirmation'}>
      <div className={'header'}>
        <CloseIcon onClick={() => history.push('/')} className={'leftAbsolute'}></CloseIcon>
        <div className={[s.title, 'body1'].join(' ')}>Loyalty</div>
      </div>
      <div className={'content'}>
        <div className={'contentWrapper'}>
          <div className={s.openEmpty}>
            <div style={{ color: 'white' }} className={'titleH2'}>
              {!venue ? strings.defaultVenue : venue.brand && t(venue.brand.name)}
            </div>
            <div className={s.subtitle}>{!venue ? 'заведение не выбрано' : t(venue.name)}</div>
            <ManWhite className={s.man} style={{ marginTop: '16px' }} />
            <div></div>
            <div className={s.title}>Получите скиду в 10% по персональному коду SK1DK4 </div>
            <QRCode style={{ marginTop: '16px' }} />
          </div>
          {/* <div className={ui.settingsImgPlaceholder}></div>
          <div className={ui.settingsWrapper}>
            <div className={ui.settingsImg}></div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Loyalty
