import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import styles from './styles.module.scss'
import { useTranslation } from 'src/hooks/useTranslation'
import { getSubcategories } from 'src/state/categories'
import { IconType } from 'src/components/RichMenu/components/IconType'
import { ICategory } from 'src/types'
import useThemeStyles from 'src/hooks/useThemeStyles'
import getThemeStyle from 'src/utils/getThemeStyle'

export interface ITabbarCategoryItemProps {
  data: ICategory
  handleCategoryClick: (categoryId: number) => void
  isActive: boolean
}

const TabbarCategoryItem: React.FC<ITabbarCategoryItemProps> = ({ data, handleCategoryClick, isActive }) => {
  const { t } = useTranslation()
  const { navbarVariables } = useThemeStyles() as any
  const customStyles = getThemeStyle()
  const { demoTheme } = useSelector(state => (state as any).demo)

  const markerBorderRadius =
    demoTheme?.navbarVariables?.iconMarkerRadius || navbarVariables?.iconMarkerRadius || customStyles.borderRadius

  return (
    <div onClick={() => handleCategoryClick(data.id)} className={styles.categoryItem}>
      {data.icon && (
        <div className={styles.categoryIcon}>
          {isActive && <div style={{ borderRadius: markerBorderRadius }} className={styles.marker} />}
          <IconType style={{}} type={data.icon} size={40} />
        </div>
      )}
      <div className={`${styles.categoryTitle} ${!data.icon ? styles.categoryTitleBig : ''}`}>
        {isActive && !data.icon && <div className={styles.labelMarker} />}
        {t(data.name)}
      </div>
    </div>
  )
}

export default React.memo(TabbarCategoryItem)
