import React, { useCallback, useContext } from 'react'
import { motion } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import clsx from 'classnames'
import { useDispatch } from 'src/state/hooks'
import { useTranslation } from 'src/hooks/useTranslation'
import useCurrentDishes from 'src/hooks/useCurrentDishes'
import { money } from 'src/utils'
import useFontColorReadability from 'src/hooks/useFontColorReadability'
import { postEvent } from 'src/api'
import { removeNewDish, removeOneOrder } from 'src/state/orders/actions'
import { IOrder, NewOrderInput } from 'src/types'
import { setDishAdded } from 'src/state/status/actions'
import { addNewOrder } from 'src/state/orders'
import useFavoritesApi from 'src/hooks/useFavoritesApi'
import useOrdersWarnings from 'src/hooks/ShoppingCart/useOrdersWarnings'
import useUnpaidOrders from 'src/hooks/useUnpaidOrders'
import useAppPreferences from 'src/hooks/useAppPreferences'
import { OrdersActionsValue } from '../types'
import { OrdersActionsContext } from '../contexts'
import OrderActionButton from './OrderActionButton'
import OrderListItemOptions from './OrderListItemOptions'
import { ReactComponent as XIcon } from '../../../assets/ico-x.svg'
import { ReactComponent as FavIcon } from '../../../assets/ico-stroke.svg'
import { ReactComponent as AddIco } from '../../../assets/ico-add.svg'
import { ReactComponent as NotReadyIco } from '../../../assets/ico-waiting.svg'
import { ReactComponent as DoneIco } from '../../../assets/ico-done-16-11.svg'
import { ReactComponent as WarnIcon } from '../../../assets/ico-warn-18.svg'
import { ReactComponent as RemoveIco } from '../../../assets/ico-remove.svg'
import { cycleActivityView } from '../../../state/ui'
import { NEW_SHOPPING_CART_INIT_POSITION_Y, SHOPPING_CART_INIT_POSITION_Y } from '../../../constants'
import { isDesktop } from '../../../utils'
import s from '../styles.module.scss'
import { getOrderUniqueKey } from '../../../utils/orders'

type OrdersListItemProps = {
  order: IOrder | NewOrderInput
}

const OrdersListItem: React.FC<OrdersListItemProps> = ({ order }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { dish } = useCurrentDishes(order.dishId)
  const { preferences } = useAppPreferences()
  const isNewDesignMode = isDesktop ? false : preferences.designVersion === 1.1

  const paid = Boolean(order.paidAt)

  const { toggleExpandedOrder, expandedOrder } = useContext<OrdersActionsValue>(OrdersActionsContext)
  const { isDishInFavorites, toggleFavorite } = useFavoritesApi(
    order.dishId,
    order.options || order.selectedOptions || []
  )

  const [, { getOrderPrice }] = useUnpaidOrders()
  const { isDisabledDish, isDisabledOptions, notFoundOptions } = useOrdersWarnings([order])

  const totalPrice = getOrderPrice(order)
  const isExpanded = expandedOrder === getOrderUniqueKey(order)
  const secondMode = order.takeout || order.delivery

  const dishDeleteHandle = (order: IOrder | NewOrderInput) => {
    toggleExpandedOrder(null)

    setTimeout(() => {
      setTimeout(() => {
        dispatch(removeNewDish(order.dishId, order.selectedOptions || []))
        postEvent('cart-dish_context-delete', 'ordering')
      }, 150)
    }, 150)
  }

  const handleExpandingOrder = useCallback(
    e => {
      e.stopPropagation()
      if (isExpanded) {
        toggleExpandedOrder(null)
        postEvent('cart-dish_context-close', 'ordering', {
          paid: paid,
          name: dish ? t(dish.name) : t('listSection.dishNotFound'),
          id: order.dishId
        })
      } else {
        toggleExpandedOrder(getOrderUniqueKey(order))
        postEvent('cart-dish_context-open', 'ordering', {
          paid: paid,
          name: dish ? t(dish.name) : t('listSection.dishNotFound'),
          id: order.dishId
        })
      }
    },
    [isExpanded, order, dish, paid, toggleExpandedOrder, t]
  )
  const isContentReadable = useFontColorReadability('#ffffff')

  const renderOrderStatusIcon = () => {
    if (paid) {
      if (secondMode && order.status && !order.status.ready) {
        return <NotReadyIco />
      } else {
        return <DoneIco />
      }
    } else {
      if (isDisabledOptions || isDisabledDish || notFoundOptions) {
        return <WarnIcon fill={'var(--warningColor)'} />
      } else {
        return <span>{money(totalPrice)}&nbsp;₴</span>
      }
    }
  }

  return (
    <motion.div
      className={clsx(s.contextedWrapper, !paid && s.wrapperMargin)}
      animate={isExpanded ? { zIndex: 4, transitionDelay: '0.3s' } : { transitionEnd: { zIndex: 2 } }}
      style={{ '--fontColor': isContentReadable ? null : '#000000' } as React.CSSProperties}
    >
      <motion.div
        className={clsx(
          s.orderWrapper,
          isExpanded && s.contexted,
          isExpanded && paid && s.contextPaid,
          (isDisabledOptions || isDisabledDish || notFoundOptions) && s.orderWrapper_Warn
        )}
      >
        <motion.div
          onClick={handleExpandingOrder}
          data-cy={`orderItemButton-${order?.dishId}-${paid ? 'paid' : 'unpaid'}`}
          className={clsx(
            s.flex,
            s.orderItem,
            paid && s.orderItem_Paid,
            isExpanded && s.orderItem_Contexted,
            (isDisabledDish || isDisabledOptions || notFoundOptions) && s.warnOrder2
          )}
          initial={
            isExpanded
              ? {
                  backgroundColor: paid ? 'rgba(244,244,244,1)' : 'rgba(255,255,255,1)'
                }
              : {}
          }
          animate={
            isExpanded
              ? {
                  backgroundColor: paid ? 'rgba(255,255,255,1)' : 'rgba(244,244,244,1)'
                }
              : {}
          }
          exit={
            isExpanded
              ? {
                  backgroundColor: paid ? 'rgba(244,244,244,1)' : 'rgba(255,255,255,1)'
                }
              : {}
          }
        >
          <div className={isExpanded ? s.contextedPrice : s.uncontextedPrice}>
            <span
              data-cy={`countDish-${order?.dishId}`}
              className={s.combieText}
              style={{
                marginRight: '4px',
                fontWeight: 'normal'
              }}
            >
              {order.count}
            </span>
            <XIcon style={{ position: 'absolute', right: '2px' }} />
          </div>
          <div className={s.uncontextBlack}>
            <div
              className={[
                s.product,
                s.paidProduct,
                isDisabledDish || isDisabledOptions || notFoundOptions ? s.warnOrder : ''
              ].join(' ')}
              style={{ position: 'relative' }}
            >
              {isDishInFavorites && <FavIcon className={s.favIcon} />}
              <div
                style={{
                  color: isDisabledDish && !isExpanded ? 'var(--warningColor)' : '',
                  fontWeight: 'normal'
                }}
                data-cy={`dishLabel-${dish?.id}`}
                className={(order.selectedOptions?.length || 0) > 0 ? s.dishLabelSeparate : s.dishLabel}
              >
                {dish ? t(dish.name) : t('listSection.dishNotFound')}
              </div>
              <OrderListItemOptions options={order.selectedOptions} recipeId={dish?.recipeId} />
            </div>
            <div className={s.orderStatusIconWrapper}>{renderOrderStatusIcon()}</div>
          </div>
        </motion.div>
        {paid && (
          <motion.div
            key='paid-bottom'
            initial={{ bottom: 0, scale: 0.99, boxShadow: '0 0 0 rgba(0, 0, 0, 0), 0px 0 0 rgba(0, 0, 0, 0)' }}
            animate={{
              bottom: isExpanded ? '-40px' : 0,
              scale: isExpanded ? 1 : 0.99,
              boxShadow: isExpanded ? '0px 2px 4px rgba(0, 0, 0, 0.1), 0px -2px 4px rgba(0, 0, 0, 0.1)' : ''
            }}
            exit={{ bottom: 0, boxShadow: '0 0 0 rgba(0, 0, 0, 0), 0px 0 0 rgba(0, 0, 0, 0)', scale: 0.99 }}
            className={clsx(s.hiddenSection, s.hiddenSection_Bottom)}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <div
              onClick={() => {
                history.push('/dish/' + order.dishId)
                postEvent('cart-dish_context-dishcard', 'ordering', {
                  paid: paid,
                  name: t(dish?.name),
                  id: order.dishId
                })
                dispatch(
                  cycleActivityView({
                    id: 1,
                    current: isNewDesignMode ? NEW_SHOPPING_CART_INIT_POSITION_Y : SHOPPING_CART_INIT_POSITION_Y
                  })
                )
              }}
              className={s.contextButton}
            >
              {t('listSection.open')}
            </div>
            <OrderActionButton
              order={order}
              cb={() => {
                if (paid) {
                  for (var i = 0; i < Number(order.count); i++) {
                    dispatch(
                      addNewOrder({
                        ...dish,
                        selectedOptions: order.selectedOptions
                      })
                    )
                  }
                }
              }}
            >
              {t('listSection.povtorit')}
            </OrderActionButton>
            <div onClick={toggleFavorite} className={s.contextButton}>
              {t('general.favoriteLabel')}
            </div>
          </motion.div>
        )}
        {!paid && (
          <>
            <motion.div
              key='top'
              initial={{ top: 0, scale: 0.99, boxShadow: '0 0 0 rgba(0, 0, 0, 0), 0px 0 0 rgba(0, 0, 0, 0)' }}
              animate={{
                top: isExpanded ? '-40px' : 0,
                scale: isExpanded ? 1 : 0.99,
                boxShadow: isExpanded ? '0px 2px 4px rgba(0, 0, 0, 0.1), 0px -2px 4px rgba(0, 0, 0, 0.1)' : ''
              }}
              exit={{ top: 0, boxShadow: '0 0 0 rgba(0, 0, 0, 0), 0px 0 0 rgba(0, 0, 0, 0)', scale: 0.99 }}
              className={clsx(s.hiddenSection, s.hiddenSection_Top)}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <div
                onClick={() => {
                  if (!(isDisabledDish || isDisabledOptions || notFoundOptions)) {
                    dispatch(setDishAdded())
                    dispatch(
                      addNewOrder({
                        ...dish,
                        selectedOptions: order.selectedOptions
                      })
                    )
                  }
                  postEvent('cart-dish_context-increase', 'ordering', {
                    paid: paid,
                    value: Number(order.count) + 1
                  })
                }}
                className={s.iconWrap}
                data-cy={`sCardPlusButton-${order?.dishId}`}
              >
                <AddIco className={!(isDisabledDish || isDisabledOptions || notFoundOptions) ? s.enIcon : s.disIcon} />
              </div>
              <div
                onClick={!preferences.withQrMenuMode ? toggleFavorite : undefined}
                className={clsx(s.contextButton, preferences.withQrMenuMode && s.contextButtonDisabled)}
              >
                {!isDishInFavorites ? t('general.addToFavorite') : t('listSection.deleteFavorite')}
              </div>
            </motion.div>
            <motion.div
              key='bottom'
              initial={{ bottom: 0, scale: 0.99, boxShadow: '0 0 0 rgba(0, 0, 0, 0), 0px 0 0 rgba(0, 0, 0, 0)' }}
              animate={{
                bottom: isExpanded ? '-40px' : 0,
                scale: isExpanded ? 1 : 0.99,
                boxShadow: isExpanded ? '0px 2px 4px rgba(0, 0, 0, 0.1), 0px -2px 4px rgba(0, 0, 0, 0.1)' : ''
              }}
              exit={{ bottom: 0, boxShadow: '0 0 0 rgba(0, 0, 0, 0), 0px 0 0 rgba(0, 0, 0, 0)', scale: 0.99 }}
              className={clsx(s.hiddenSection, s.hiddenSection_Bottom)}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <div
                onClick={() => {
                  if (Number(order.count) > 1) {
                    if (!(isDisabledDish || isDisabledOptions || notFoundOptions)) {
                      dispatch(removeOneOrder(order.dishId, order.selectedOptions || []))
                      postEvent('cart-dish_context-decrease', 'ordering', {
                        paid: paid,
                        value: Number(order.count) - 1
                      })
                    }
                  }
                }}
                className={s.iconWrap}
                data-cy={`sCardMinusButton-${order?.dishId}`}
              >
                <RemoveIco
                  className={
                    !(isDisabledDish || isDisabledOptions || notFoundOptions)
                      ? order.count === 1
                        ? s.enIcon2
                        : s.enIcon
                      : s.disIcon
                  }
                />
              </div>
              <div
                data-cy={`sCardDelete-${order?.dishId}`}
                onClick={() => dishDeleteHandle(order)}
                className={s.contextButton}
              >
                {t('general.delete')}
              </div>
            </motion.div>
          </>
        )}
      </motion.div>
    </motion.div>
  )
}

export default OrdersListItem
