import { APP_ENABLE, APP_DISABLE } from './types'

/* State Shape
{

}
*/

export const initialState = true

export default function(state = initialState, action) {
  switch (action.type) {
    case APP_ENABLE: {
      return true
    }

    case APP_DISABLE: {
      return false
    }

    default:
      return state
  }
}
