import { SET_HISTORY, FETCH_HISTORY_ERROR, FETCH_HISTORY_STARTED } from './types'
import { areArrayEquals } from 'src/utils'

const initialState = {
  isLoading: false,
  error: null,
  orders: []
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_HISTORY: {
      const areEquals = areArrayEquals(state.orders, action.orders)
      console.log('User has the same history ', areEquals)
      if (areEquals) {
        return {
          ...state,
          isLoading: false
        }
      }
      return {
        ...state,
        isLoading: false,
        orders: action.orders
      }
    }
    case FETCH_HISTORY_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    }
    case FETCH_HISTORY_STARTED: {
      return {
        ...state,
        isLoading: true,
        error: null
      }
    }

    default:
      return state
  }
}
