import { isTomorrow } from 'src/utils'

export const findRole = currentRole => {
  if (currentRole === 'user') {
    return ''
  }
  if (currentRole.includes('candidate_')) {
    return currentRole.split('_')[1]
  }
  return currentRole
}

export const isToday = date => {
  const today = new Date()
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

/**
 *
 * @param {string} currentLanguage
 */
export const getLanguageCode = currentLanguage => {
  const languagesCodes = {
    en: 'en-US',
    ru: 'ru-RU',
    uk: 'uk-UA'
  }
  return languagesCodes[currentLanguage] || 'en-US'
}

export const transformTakeAwayDateTime = (t, date, currentLangague, preorderOnFutureDaysNumber) => {
  const currentDay = new Date(date)
  const isTomorrowDay = isTomorrow(date)
  const isTodayDay = isToday(currentDay)
  let prefix = ''
  if (isTomorrowDay && (preorderOnFutureDaysNumber <= 2 || !preorderOnFutureDaysNumber)) {
    prefix = `${t('general.tommorow')} `
  } else if (preorderOnFutureDaysNumber && !isTodayDay) {
    const languageCode = getLanguageCode(currentLangague)
    prefix = `${currentDay.toLocaleDateString(languageCode, { weekday: 'long' })}, ${currentDay.toLocaleDateString(
      languageCode,
      {
        day: 'numeric',
        month: 'long'
      }
    )}, `
  }
  const hoursStr = currentDay
    .getHours()
    .toString()
    .padStart(2, 0)

  const minutesStr = currentDay
    .getMinutes()
    .toString()
    .padStart(2, 0)
  return [`${prefix}${hoursStr}:${minutesStr}`]
}

export function generateRandomString(length) {
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}
