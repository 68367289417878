import React from 'react'
import cx from 'classnames'
import { useTranslation } from 'src/hooks/useTranslation'
import s from '../styles.module.scss'

type WaitingTimeBadgeProps = {
  time?: string
  label?: string
  disabled?: boolean
  needAttention?: boolean
  className?: string
}

export const WaitingTimeBadge: React.FC<WaitingTimeBadgeProps> = ({
  time,
  label,
  disabled,
  needAttention,
  className
}) => {
  const { t } = useTranslation()
  const rootClassName = cx(s.menucardBadge, disabled && s.timeDisabled, needAttention && s.attention, className)
  if (disabled) {
    return <div className={rootClassName}>{t('waitingTimeBadge.outOfStock')}</div>
  }

  if (label) {
    return <div className={rootClassName}>{label}</div>
  }

  if (time) {
    return <div className={rootClassName}>{time}</div>
  }

  return null
}
