import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'src/state/hooks'
import { motion, useMotionValue } from 'framer-motion'
import { getActivityViewCurrent } from '../../state/ui/selectors'
import { cycleActivityView } from '../../state/ui/actions'
import { clearAddingAnim } from '../../state/addingAnim'
import { NEW_SHOPPING_CART_INIT_POSITION_Y } from 'src/constants'
import styles from './styles.module.scss'
import DraggerIndicator from '../DraggerIndicator'
import useShoppingCartStatus from 'src/hooks/ShoppingCart/useShoppingCartStatus'
import { SHOPPING_CART_STATE } from 'src/types'
import useUnpaidOrders from 'src/hooks/useUnpaidOrders'

export interface IActivityViewProps {
  classNames: string[]
  style?: any
  id: number
  elevation?: number
  initPositionY: number
  openPositionY: number
  closePositionY?: number
  closeHandler?: () => void
  shaderTapHandler: () => void
  shader: boolean
  closeState?: boolean
  tappable?: boolean
  children: any
}

const ActivityView: React.FC<IActivityViewProps> = ({
  classNames,
  id,
  elevation = 4,
  style = {},
  initPositionY,
  openPositionY,
  closeHandler = () => {},
  shaderTapHandler = () => {},
  shader = true,
  closeState = false,
  closePositionY = window.innerHeight,
  tappable = true,
  children
}) => {
  const dispatch = useDispatch()
  const y = useMotionValue(window.outerHeight)
  const [dragStatus, setDragStatus] = useState(false)
  const [tapStatus, setTapStatus] = useState(false)
  const [contentHeight, setContentHeight] = useState(0)
  const [{ orders }] = useUnpaidOrders()
  const [draggerStatus, setDraggerStatus] = useState<boolean>(false)
  const { current } = useSelector(getActivityViewCurrent)(id)
  const [{ status }] = useShoppingCartStatus()
  const { activeServiceType } = useSelector(store => store.shoppingCard)
  const contentRef = useRef<any>()
  // const contentHeight = contentRef.current && contentRef.current.getBoundingClientRect().height
  const timeoutRef = useRef<any>()

  const trashold = 50

  // console.log(contentHeight)

  useEffect(() => {
    // const currHeight = contentRef.current && contentRef.current.getBoundingClientRect().height
    // console.log({ current })
    // if (currHeight) {
    //   console.log(contentRef.current)
    //   console.log(contentRef.current.getBoundingClientRect().height)
    //   setContentHeight(current)
    // }
  }, [current, contentRef?.current]) // eslint-disable-line

  useLayoutEffect(() => {
    console.log(contentRef.current.getBoundingClientRect().height)
    const timeout = setTimeout(() => {
      const currHeight = contentRef.current && contentRef.current.getBoundingClientRect().height
      setContentHeight(currHeight)
    }, 100)

    return () => {
      clearTimeout(timeout)
    }
  }, [current, contentRef?.current, orders, activeServiceType])

  const onClickHandler = () => {
    if (current === NEW_SHOPPING_CART_INIT_POSITION_Y) {
      dispatch(cycleActivityView({ id, current: openPositionY }))
      dispatch(clearAddingAnim())
      // y.set(openPositionY)
    }
  }

  return (
    <>
      <motion.div
        className={`${
          current === NEW_SHOPPING_CART_INIT_POSITION_Y ? styles.newActivityViewWrapper : 'activity'
        } ${classNames.join(' ')}`}
        style={{ y, boxShadow: 'none', zIndex: elevation, ...style }}
        onClick={onClickHandler}
        animate={{
          y: current < -contentHeight && status !== SHOPPING_CART_STATE.EMPTY ? -contentHeight : current,
          transition: { ease: 'easeOut', duration: 0.25 }
        }}
        drag={current === NEW_SHOPPING_CART_INIT_POSITION_Y ? false : 'y'}
        dragConstraints={{
          top: -contentHeight,
          bottom: current < -contentHeight ? -contentHeight : current
        }}
        dragElastic={{
          top: 0,
          bottom: 0.5
        }}
        dragTransition={{ timeConstant: 240, power: 0.39 }}
        // onTap={toggleTapHandler}
        onDragStart={(event, info) => {
          if (!dragStatus && !tapStatus) {
            setDragStatus(true)
          } else {
            event.preventDefault()
          }
        }}
        onDrag={(event, info) => {
          if (info.offset.y > trashold && !draggerStatus) {
            setDraggerStatus(true)
          } else if (info.offset.y < trashold && draggerStatus) {
            setDraggerStatus(false)
          }
        }}
        onDragEnd={(offset, info) => {
          setDraggerStatus(false)
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
          }
          timeoutRef.current = setTimeout(() => setDragStatus(false), 250)
          // user dragging up
          if (info.offset.y < 0) {
            switch (current) {
              case openPositionY:
                // console.log('case Up Open')
                if (info.offset.y > -trashold) {
                  dispatch(cycleActivityView({ id, current: openPositionY }))
                  dispatch(clearAddingAnim())
                }
                break
              case initPositionY:
                // console.log('case Up Init')
                if (info.offset.y < -trashold) {
                  dispatch(cycleActivityView({ id, current: openPositionY }))
                  dispatch(clearAddingAnim())
                } else dispatch(cycleActivityView({ id, current: initPositionY }))
                break
              default:
                dispatch(cycleActivityView({ id, current: current }))
            }
            // user dragging down
          } else if (info.offset.y > 0) {
            switch (current) {
              case openPositionY: {
                if (info.offset.y < trashold) {
                  // console.log('case Down Open 2')
                  dispatch(cycleActivityView({ id, current: openPositionY }))
                  dispatch(clearAddingAnim())
                } else if (info.offset.y > trashold && openPositionY < y.get()) {
                  dispatch(cycleActivityView({ id, current: initPositionY }))
                  if (closeHandler) {
                    closeHandler()
                  }
                }
                break
              }

              case initPositionY: {
                // console.log('case Down Init')
                closeState
                  ? dispatch(cycleActivityView({ id, current: closePositionY }))
                  : dispatch(cycleActivityView({ id, current: current }))
                setTimeout(() => closeHandler(), 200)
                break
              }
              default: {
                // dispatch(cycleActivityView({ id, current: current }))
              }
            }
          } else {
            dispatch(cycleActivityView({ id, current: current }))
          }
        }}
      >
        {current !== NEW_SHOPPING_CART_INIT_POSITION_Y && (
          <DraggerIndicator
            inverted={status === SHOPPING_CART_STATE.EMPTY}
            isOnBorder={draggerStatus}
            style={{ width: '64px', top: '8px' }}
          />
        )}
        <div
          className={'tapZone'}
          onClick={e => {
            e.stopPropagation()
            if (tappable && !tapStatus && !dragStatus) {
              setTapStatus(true)
              dispatch(cycleActivityView({ id, current: openPositionY }))
              dispatch(clearAddingAnim())
              setTimeout(() => {
                setTapStatus(false)
              }, 150)
            }
          }}
        />
        <div className='just_content_ref' ref={contentRef}>
          {children}
        </div>
      </motion.div>
      {shader && current === openPositionY && (
        <motion.div
          className={'modalShader'}
          onClick={e => {
            e.stopPropagation()
            if (!dragStatus) {
              shaderTapHandler()
            }
          }}
          style={{ zIndex: elevation - 1 }}
        />
      )}
    </>
  )
}
export default ActivityView
