import React from 'react'
import { motion } from 'framer-motion'

const BtnMain = ({ width = '100%', ...props }) => {
  return (
    <motion.button
      className={'btnMain'}
      style={{ width: width, ...props.style }}
      onClick={event => {
        props.clickHandler()
        event.stopPropagation()
      }}
    >
      {props.children}
    </motion.button>
  )
}
export default BtnMain
