import axios from 'axios'
import { config } from 'src/constants'
import { QR_MENU_MESSAGE_TYPE } from 'src/types'

export const sendMessage = async (
  payload: { type: QR_MENU_MESSAGE_TYPE; tableNum: number; orderAmount?: number },
  venueSlug: string | number
) => {
  return axios
    .post(`${config.API_URL3}/${window.busytable.brandSlug}/${venueSlug}/messages/send`, payload, {
      withCredentials: true,
      headers: { verified: 'yes' }
    })
    .then(resp => {
      if (resp?.data) {
        return resp?.data?.result
      }
    })
    .catch(e => {
      if (e.response?.data) {
        throw Error(e.response.data)
      } else {
        throw Error(e)
      }
    })
}
