import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Routes } from 'src/globals/enumerations'
import DishCard from './DishCard'

const DishCardContainer: React.FC = () => {
  const params = useParams<{ dishId: string }>()
  const history = useHistory()

  function hideDishCard(): void {
    history.push(Routes.mainPage)
  }

  return <DishCard hideDishCard={hideDishCard} dishId={+params.dishId} />
}

export default DishCardContainer
