import { ADD_ERROR, REMOVE_ERROR } from './types'

/* State Shape
{

}
*/

export const initialState = false

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_ERROR: {
      return true
    }

    case REMOVE_ERROR: {
      return false
    }

    default:
      return state
  }
}
