import { SET_HISTORY, FETCH_HISTORY_STARTED, FETCH_HISTORY_ERROR } from './types'

export const setHistory = orders => ({
  type: SET_HISTORY,
  orders
})

export const fetchHistoryStarted = () => ({
  type: FETCH_HISTORY_STARTED
})

export const fetchHistoryError = error => ({
  type: FETCH_HISTORY_ERROR,
  error
})
