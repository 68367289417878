import React from 'react'
import s from '../DishCard/styles.module.scss'
import { useTranslation } from '../../hooks/useTranslation'
import { CaloriesType } from '../../types'

type DishCaloriesProps = {
  calories: CaloriesType | null
}

const DishCalories: React.FC<DishCaloriesProps> = ({ calories }) => {
  /**
   *
   * @param {string or number} input
   * @returns {number}
   * @example '21,23' => 21.23
   */
  const reformatNumber = (input: string | number | null | undefined) => {
    if (!input) {
      return 0
    }
    if (typeof input === 'number') {
      return input
    }

    return +input.replace(',', '.')
  }

  const fat = reformatNumber(calories?.fat)
  const kcal = reformatNumber(calories?.kcal)
  const carbs = reformatNumber(calories?.carbs)
  const protein = reformatNumber(calories?.protein)

  const { t } = useTranslation()
  const summ = fat + carbs + protein
  const size = window.innerWidth > 1025 ? 240 - 18 : window.innerWidth / 2 - 28
  const angle1 = protein / summ
  const angle2 = fat / summ
  const angle3 = carbs / summ
  const radius = size / 2 - 6
  const pCenter = { x: size / 2, y: size / 2 }
  const p1s = {
    x: Math.round(pCenter.x + radius * Math.cos(0)),
    y: Math.round(pCenter.y + radius * Math.sin(0))
  }
  const p1e = {
    x: Math.round(pCenter.x + radius * Math.cos(angle1 * 2 * Math.PI)),
    y: Math.round(pCenter.y + radius * Math.sin(angle1 * 2 * Math.PI))
  }
  const p2s = {
    x: Math.round(pCenter.x + radius * Math.cos((angle1 + 0.015) * 2 * Math.PI)),
    y: Math.round(pCenter.y + radius * Math.sin((angle1 + 0.015) * 2 * Math.PI))
  }
  const p2e = {
    x: Math.round(pCenter.x + radius * Math.cos((angle1 + angle2 - 0.015) * 2 * Math.PI)),
    y: Math.round(pCenter.y + radius * Math.sin((angle1 + angle2 - 0.015) * 2 * Math.PI))
  }
  const pws = {
    x: Math.round(pCenter.x + radius * Math.cos(angle1 * 2 * Math.PI)),
    y: Math.round(pCenter.y + radius * Math.sin(angle1 * 2 * Math.PI))
  }
  const pwe = {
    x: Math.round(pCenter.x + radius * Math.cos((angle1 + angle2) * 2 * Math.PI)),
    y: Math.round(pCenter.y + radius * Math.sin((angle1 + angle2) * 2 * Math.PI))
  }
  const p3s = {
    x: Math.round(pCenter.x + radius * Math.cos((angle1 + angle2) * 2 * Math.PI)),
    y: Math.round(pCenter.y + radius * Math.sin((angle1 + angle2) * 2 * Math.PI))
  }
  const p3e = {
    x: Math.round(pCenter.x + radius * Math.cos((angle1 + angle2 + angle3 - 0.015) * 2 * Math.PI)),
    y: Math.round(pCenter.y + radius * Math.sin((angle1 + angle2 + angle3 - 0.015) * 2 * Math.PI))
  }
  const pbs = {
    x: Math.round(pCenter.x + radius * Math.cos((angle1 + angle2 - 0.015) * 2 * Math.PI)),
    y: Math.round(pCenter.y + radius * Math.sin((angle1 + angle2 - 0.015) * 2 * Math.PI))
  }
  const pbe = {
    x: Math.round(pCenter.x + radius * Math.cos((angle1 + angle2 + angle3) * 2 * Math.PI)),
    y: Math.round(pCenter.y + radius * Math.sin((angle1 + angle2 + angle3) * 2 * Math.PI))
  }

  function getCalories(calories: number) {
    return Math.round(+calories)
  }

  return (
    <>
      <div className={[s['tab-content'], s.tabFlex2].join(' ')}>
        <div style={{ position: 'relative' }} className={['flex1', s.caloryChart].join(' ')}>
          {/* <Diagram /> */}
          <div className={s.diaText}>
            <div>{getCalories(kcal)}</div>
            <div className={s.ccal}>{t('calories.total')}</div>
          </div>
          <svg
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g strokeWidth='10' strokeLinecap='round' fill='none'>
              <path
                className={s.strokeOne}
                d={`M ${p1s.x} ${p1s.y} A${radius},${radius} ${Math.round(angle1 * 360)} ${
                  angle1 * 360 > 180 ? 1 : 0
                } 1 ${p1e.x},${p1e.y}`}
              />
              <path
                className={s.strokeSpacer}
                strokeWidth='15'
                d={`M ${pws.x} ${pws.y} A${radius},${radius} ${Math.round(angle2 * 360)} ${
                  angle2 * 360 > 180 ? 1 : 0
                } 1 ${pwe.x},${pwe.y}`}
              />
              <path
                className={s.strokeTwo}
                d={`M ${p2s.x} ${p2s.y} A${radius},${radius} ${Math.round(angle2 * 360)} ${
                  angle2 * 360 > 180 ? 1 : 0
                } 1 ${p2e.x},${p2e.y}`}
              />
              <path
                className={s.strokeSpacer}
                strokeWidth='15'
                d={`M ${pbs.x} ${pbs.y} A${radius},${radius} ${Math.round(angle3 * 360)} ${
                  angle3 * 360 > 180 ? 1 : 0
                } 1 ${pbe.x},${pbe.y}`}
              />
              <path
                className={s.strokeTri}
                d={`M ${p3s.x} ${p3s.y} A${radius},${radius} ${Math.round(angle3 * 360)} ${
                  angle3 * 360 > 180 ? 1 : 0
                } 1 ${p3e.x},${p3e.y}`}
              />
            </g>
          </svg>
        </div>
        <div className={['flex1', s.caloryList].join(' ')}>
          <div className={s.caloryItem}>
            <div className={[s.caloryPoint, s.proteinPoint].join(' ')} />
            <div className={s.caloryText}>
              <div className={s.caloryName}>{t('calories.protein')}</div>
              <div className={s.caloryValue}>{protein}</div>
            </div>
          </div>
          <div className={s.caloryItem}>
            <div className={[s.caloryPoint, s.fatPoint].join(' ')} />
            <div className={s.caloryText}>
              <div className={s.caloryName}>{t('calories.fat')}</div>
              <div className={s.caloryValue}>{fat}</div>
            </div>
          </div>
          <div className={s.caloryItem}>
            <div className={[s.caloryPoint, s.carbsPoint].join(' ')} />
            <div className={s.caloryText}>
              <div className={s.caloryName}>{t('calories.carbs')}</div>
              <div className={s.caloryValue}>{carbs}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DishCalories
