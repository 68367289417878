// 2 polyfill modules to fix iOS 11 crash
import 'array-flat-polyfill'
import 'intersection-observer'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, Switch, Route } from 'react-router-dom'
import RichMenu from 'src/components/RichMenu'
import VenueSelect from 'src/components/VenueSelect'
import { UserRoles } from 'src/globals/enumerations'
import { useTranslation } from 'src/hooks/useTranslation'
import useClearOrders from 'src/hooks/useClearOrders'
import { addRoles } from 'src/state/roles'
import { useFetchFavorites, useFetchMenu, useFetchPaidOrders, useFetchVenues, useOrdersWebsocket } from './api'
import './styles/App.global.scss'
import About from './components/About'
import { BannerAuth, BannerSelfAuth } from './components/Banners/BannerAuth'
import BannerConfirmNonByer from './components/Banners/BannerConfirmNonByer'
// import BannerIos from './components/BannerIos'
import { Confirmation, UrlParse } from './components/CodeScan/UrlParse'
import { HashProcess } from './components/HashProcess'
import Job from './components/Job'
import Loyalty from './components/Loyalty'
import PaymentConfirmation from './components/PaymentConfirmation'
import PaymentHistory from './components/PaymentHistory'
import Profile from './components/Profile'
import { FreeForm } from './components/Settings/components/FreeForm'
import Settings from './components/Settings'
import NewShoppingCartContainer from './components/NewShoppingCart/NewShoppingCartContainer.tsx'
import ShoppingCartContainer from './components/ShoppingCart/ShoppingCartContainer.tsx'
import DishCardContainer from 'src/components/DishCard'
import TableNumber from './components/TableNumber'
import Work from './components/Work'
import ErrorBoundary from './ErrorBoundary'
import { getVenueId, getVenueOpen, setTableNum, setVenueOpen } from './state/location'
import { getlastChange, getTableNum } from './state/location/selectors'
import { getDishes } from './state/menu'
import { addNewOrder, getTakeout, setTakeout } from './state/orders'
import { setStarted } from './state/status/actions'
import { hideTable } from './state/tableShow'
import { getTableShow } from './state/tableShow/selectors'
import { getVenueById } from './state/venues'
import { isInstagramIOS, isVenueOpen } from './utils'
import { getAppPreferencesThunk } from './state/app-preferences/thunkActions'
import useThemeStyles from './hooks/useThemeStyles'
import { getActiveThemeThunk } from 'src/state/themes/thunkActions'
import useCustomIconSet from './hooks/useCustomIconSet'
import { getServicesStoplistThunk } from './state/services-stoplist/thunkActions'
import useLogRocketInit from 'src/hooks/useLogRocketInit'
import useCustomScripts from 'src/hooks/useCustomScripts'
import useWindowMessageHandler from './hooks/useWindowMessageHandler'
import { setVenuesAppPreferences } from './state/app-preferences/actions'
import TipsFeedback from './containers/LeaveTips/TipsFeedback'
import RateUs from './containers/RateUs'
import TipsView from 'src/containers/LeaveTips'
import TipsThanksView from 'src/containers/LeaveTips/TipsThanksView'
import CustomPage from './components/CustomPage'
import { getUserData } from 'src/state/user'
import { fetchHistoryThunk } from './state/history/thunkActions'
import PageLoader from './components/ui/PageLoader'
import { fetchCustomPages } from './state/pages/thunkActions'
import useAppPreferences from './hooks/useAppPreferences'
import ForegroundAnimation from './components/ui/ForegroundAnimation'
import { getActiveTagsThunk } from './state/tags/thunkAction'
import { AnimatePresence } from 'framer-motion'
import MenuDishCard from './components/MenuDishCard'
import { isDesktop } from 'src/utils'
import { TakeoutReadinessPopup } from './components/CodeScan/ConfirmationView'
import useUnpaidOrders from 'src/hooks/useUnpaidOrders'
import { getOrdersNew } from 'src/state/orders'
import { getOptionsets } from 'src/state/menu'
import PromoPopup from './components/popups/PromoPopup'

export const App = () => {
  const { style } = useThemeStyles()
  const [, iconsStyle] = useCustomIconSet()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const venueId = useSelector(getVenueId)
  const venue = useSelector(getVenueById)(venueId)
  const togo = useSelector(getTakeout)
  const diff = useSelector(getlastChange)
  const dishes = useSelector(getDishes)
  const tableNum = useSelector(getTableNum)
  const user = useSelector(getUserData)
  const [, { filterNewOrders }] = useUnpaidOrders()
  const optionSets = useSelector(getOptionsets)
  const location = useLocation()
  if (Date.now() - new Date(diff).getTime() > 3600 * 2 * 1000 && tableNum !== 0) {
    dispatch(setTableNum(0))
  }
  const venueOpen = useSelector(getVenueOpen)
  const tableShow = useSelector(getTableShow)
  const { holidayModifications, preferences } = useAppPreferences()

  const isNewDesignMode = isDesktop ? false : preferences.designVersion === 1.1
  const enabledForegroundAnimation =
    holidayModifications?.currentVariant &&
    holidayModifications?.variants?.[holidayModifications?.currentVariant]?.foregroundAnimation

  useEffect(() => {
    if (venue) {
      dispatch(getServicesStoplistThunk(venue.slug, new Date().toISOString()))
      dispatch(setVenueOpen(isVenueOpen(venue)))
    }
  }, [venue, dispatch])

  useEffect(() => {
    if (user && user.userId) {
      setTimeout(() => {
        if (window.clarity) {
          window.clarity('set', 'custom_user_id_tag', `${user.userId}`)
          window.clarity('set', 'brandSlug', `${window.busytable.brandSlug}`)
        }
      }, 4000)
    }
  }, [user])

  useEffect(() => {
    const url = new URL(window.location.href)
    const orders = url.searchParams.get('share_order')
    if (orders && dishes.length > 0) {
      try {
        const decOrders = JSON.parse(decodeURIComponent(orders))
        decOrders.map(x => {
          const isDish = dishes.find(y => x.dishId === y.id)
          if (isDish) {
            const obj = {
              tempId: Date.now(),
              id: isDish.id,
              price: isDish.price,
              takeout: togo,
              selectedOptions: x.selectedOptions
            }
            dispatch(addNewOrder(obj))
          }
        })
        url.searchParams.set('share_order', '')
        url.searchParams.delete('share_order')
        window.history.replaceState(null, null, url)
      } catch (e) {
        console.log('err', e)
      }
    }
    // url.searchParams.set('searchType', searchType)
    // url.searchParams.set('searchVal', searchVal)
    // url.searchParams.set('days', days)
  }, [dishes])

  useEffect(() => {
    if (venue) {
      // console.log(venue)
      if (!(venue.here && venue.takeout)) {
        if (venue.here) {
          dispatch(setTakeout(false))
        } else {
          // console.log('takeouttrue 2')
          dispatch(setTakeout(true))
        }
      }
      const isVenueOpenInterval = setInterval(() => {
        const newVenueOpen = isVenueOpen(venue)
        if (venueOpen !== newVenueOpen) {
          dispatch(setVenueOpen(newVenueOpen))
          clearInterval(isVenueOpenInterval)
        }
      }, 3000)
    }
  }, [venue, venueOpen, dispatch])

  useEffect(() => {
    dispatch(setStarted())
    if (isInstagramIOS()) {
      document.body.style.overflow = 'auto'
    }
  }, [])

  useEffect(() => {
    dispatch(getActiveThemeThunk())
    dispatch(getAppPreferencesThunk())
    dispatch(fetchCustomPages())
    dispatch(fetchHistoryThunk())
    dispatch(getActiveTagsThunk())
  }, [])

  useEffect(() => {
    filterNewOrders()
  }, [dishes, optionSets, venueId])

  /**
   *
   * Initiate getting user roles translations from API
   * using @enum UserRoles
   * @used in role selector
   */
  useEffect(() => {
    const newRolesArray = Object.keys(UserRoles).map(roleKey => {
      const roleStringValue = UserRoles[roleKey]

      return {
        key: roleStringValue,
        value: t(`userRoles.${roleStringValue}`)
      }
    })

    dispatch(addRoles(newRolesArray))
  }, [t(`userRoles.${UserRoles.ADMIN}`)])

  useEffect(() => {
    if (venueId && venue) {
      dispatch(setVenuesAppPreferences(venue.appPreferences || {}))
    }
  }, [venueId, venue])

  useFetchVenues()
  useFetchMenu()
  useFetchPaidOrders()
  // useTokenValidator()
  useOrdersWebsocket()
  useFetchFavorites()
  useLogRocketInit()
  useCustomScripts()
  useClearOrders()

  useWindowMessageHandler() // enables setting theme/variables, visible in backoffice preview iframe
  return (
    <div style={{ ...style, ...iconsStyle }}>
      {enabledForegroundAnimation && <ForegroundAnimation variant={holidayModifications.currentVariant} />}
      <ErrorBoundary>
        {/* <BannerIos /> */}
        <PageLoader />
        <HashProcess />
        <RichMenu />
        <PromoPopup />
        <Route path='/auth/remote/:nonce' component={BannerAuth} />
        <Route path='/auth/employee-confirm/:userId' component={BannerConfirmNonByer} />
        <Route path='/auth/self/:token' component={BannerSelfAuth} />
        {tableShow !== false ? (
          <Confirmation
            tableShow={tableShow}
            onClose={() => {
              dispatch(hideTable())
            }}
          />
        ) : (
          <></>
        )}
        <PaymentConfirmation />
        <TakeoutReadinessPopup />
        <Route path='/loyalty' component={Loyalty} />
        <Route path='/settings' component={Settings} />
        <Route path='/payments' component={PaymentHistory} />
        <Route path='/about' component={About} />
        <Route path='/job' component={Job} />
        <Route path='/page/:slug' component={CustomPage} />
        <Route path='/work' component={Work} />
        <Route path='/contact' component={RateUs} />

        <Route path='/q/:qrCodeVal/' component={UrlParse} />
        <Route exact path='/venues' component={VenueSelect} />
        <Route exact path='/register/:venueId' component={FreeForm} />
        <Route exact path='/v/:slug' component={TableNumber} />
        <Route exact path='/profile' component={Profile} />
        <Route path='/rate/:paymentId' component={TipsFeedback} />
        <Route path='/tips/:paymentId?' component={TipsView} />
        <Route path='/tipsThankView' component={TipsThanksView} />
        {isNewDesignMode ? <NewShoppingCartContainer /> : <ShoppingCartContainer />}
        {isNewDesignMode ? (
          <AnimatePresence>
            <Switch location={location} key={location.pathname.includes('dish')}>
              <Route path='/dish/:dishId' component={MenuDishCard} />
            </Switch>
          </AnimatePresence>
        ) : (
          <>
            <Route path='/dish/:dishId'>
              <DishCardContainer />
            </Route>
            <Route path='/favorite/:favoriteId'>
              <DishCardContainer />
            </Route>
          </>
        )}
      </ErrorBoundary>
    </div>
  )
}
