import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FeedbackView from '../../components/ui/FeedbackView'
import { useTranslation } from '../../hooks/useTranslation'
import { ReactComponent as CpIcon } from '../../assets/ico-cp.svg'
import { ReactComponent as TgIcon } from '../../assets/ico-tg.svg'
import { getVenueId } from '../../state/location'
import { getVenueById } from '../../state/venues'
import { activityViewAnimate, cycleActivityView } from '../../state/ui'
import { getActivityViewAnimate } from '../../state/ui/selectors'
import { calculateInit } from '../../utils'
import s from './styles.module.scss'
import ActivityView from '../../components/ui/ActivityView'
import useAppPreferences from '../../hooks/useAppPreferences'

class OperatorClass {
  constructor({ label = '', href = '', icon = '' }) {
    this.label = label
    this.href = href
    this.icon = icon
  }
}

export default function RateUs() {
  const dispatch = useDispatch()

  const { telegram, phone } = useAppPreferences()
  const venueSlug = useSelector(getVenueId)
  const venue = useSelector(getVenueById)(venueSlug)

  const { t } = useTranslation()

  const operators = [
    new OperatorClass({
      label: t('contactUs.callus'),
      href: venue && venue.phone ? `tel:${venue.phone.replace(/ /g, '')}` : `${phone && `tel:${phone}`}`,
      icon: <CpIcon />
    }),
    // new OperatorClass({
    //   label: 'WhatsApp',
    //   href: `whatsapp://send?phone=${strings['contactUsPhone']}`,
    //   icon: <WaIcon />
    // }),
    new OperatorClass({
      label: 'Telegram',
      href: telegram && `tg://resolve?domain=${telegram}`,
      icon: <TgIcon />
    })
    // new OperatorClass({ label: 'Viber', href: `viber://contact?number=${strings['contactUsPhone']}`, icon: <VbIcon /> })
  ]

  const animate = useSelector(getActivityViewAnimate)
  const initPositionY = calculateInit(false)
  const openPositionY = -350

  useEffect(() => {
    const toggleCartOpen = () => {
      dispatch(cycleActivityView({ id: 2, current: initPositionY }))
    }
    toggleCartOpen()
  }, [dispatch, initPositionY])

  useEffect(() => {
    if (animate) {
      dispatch(cycleActivityView({ id: 2, current: initPositionY - 50 }))
      setTimeout(() => {
        dispatch(cycleActivityView({ id: 2, current: initPositionY }))
        dispatch(activityViewAnimate())
      }, 200)
    }
  }, [animate, dispatch, initPositionY])

  return (
    <FeedbackView anonymousLabel contentStyles={{ paddingBottom: '100px' }}>
      <ActivityView
        id={2}
        shader={true}
        closeState={true}
        initPositionY={initPositionY}
        openPositionY={openPositionY}
        shaderTapHandler={() => dispatch(cycleActivityView({ id: 2, current: initPositionY }))}
      >
        <div
          className={s.someGuards}
          onClick={() => {
            dispatch(cycleActivityView({ id: 2, current: openPositionY }))
          }}
        >
          <div className={s.bodyText}>
            <strong>{t('contactUs.otherContact')}</strong>
            {/* {texts.t18} */}
          </div>
          <div>
            <div className={s.list}>
              {operators
                ? operators
                    .filter(contact => contact.href)
                    .map((text, index) => (
                      <a
                        key={index}
                        href={text.href}
                        target='_blank'
                        rel='noopener noreferrer'
                        className={[s.item, 'body1'].join(' ')}
                      >
                        {text.icon}
                        <div className={[s.telegramText].join(' ')}>{text.label}</div>
                      </a>
                    ))
                : ''}
            </div>
          </div>
        </div>
      </ActivityView>
    </FeedbackView>
  )
}
