import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getVenueId } from '../state/location'
import { getVenueById } from '../state/venues'

const isPageAllowed = (venueSlug, allowedSlugs) => {
  if (allowedSlugs.length) {
    return allowedSlugs.includes('-1') || allowedSlugs.includes(venueSlug)
  } else {
    return false
  }
}

export default function useCustomPages(pageSlug) {
  const pages = useSelector(store => store.pages.pages)
  const venueId = useSelector(getVenueId)
  const venue = useSelector(getVenueById)(venueId)

  const [customPages, setCustomPages] = useState([])

  useEffect(() => {
    const filteredPages = pages.filter(page => isPageAllowed(venue, page.venueSlugs))
    setCustomPages(filteredPages)
  }, [pages, venue])

  if (pageSlug) {
    return customPages.find(page => page.slug === pageSlug)
  }

  return customPages
}
