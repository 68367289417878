import React, { useEffect } from 'react'
import { postEvent } from 'src/api'
import StatusLabel from './statusLabel'
import s from '../styles.module.scss'

const ContentClosed = () => {
  useEffect(() => {
    postEvent('cart-close')
  }, [])

  return (
    <div className={s.content}>
      <div style={{ padding: '0 1rem' }}>
        <StatusLabel />
      </div>
    </div>
  )
}

export default React.memo(ContentClosed)
