import React, { useRef, useMemo, useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { useSelector, useDispatch } from 'react-redux'
import { storeMenuCardCoords } from 'src/state/ui'
import { money, isDishInStoplist } from 'src/utils'
import { getStoplist } from 'src/state/stoplist'
import { useTranslation } from 'src/hooks/useTranslation'
import { getOrdersNew } from 'src/state/orders/selectors.ts'
import useAppPreferences from 'src/hooks/useAppPreferences'
import useThemeStyles from 'src/hooks/useThemeStyles'
import useCurrentCategories from 'src/hooks/useCurrentCategories.ts'
import { getOptionsets } from 'src/state/menu/selectors.ts'
import { FAVORITES_CATEGORY_ID } from 'src/constants'
import { IconType } from './IconType'
import { ReactComponent as FavIcon } from 'src/assets/ico-stroke.svg'
import { LikeWrapper } from './LikeWrapper.tsx'
import { WaitingTimeBadge } from './WaitingTimeBadge.tsx'
import styles from '../styles.module.scss'
import DishesTags from 'src/components/DishesTags'
import { isDesktop } from 'src/utils'

export const MenuCard = ({
  dish,
  isFavorite,
  handleCardClick,
  handleCardAdd,
  selectedOptions,
  isOpen,
  handleOpenDishCard,
  searchMode
}) => {
  const { preferences } = useAppPreferences()
  const [optionsCost, setOptionsCost] = useState(0)
  const ordersNew = useSelector(getOrdersNew)
  const { variables } = useThemeStyles()
  const { demoVariables } = useSelector(state => state.demo)
  const { t } = useTranslation()
  const menuCardRef = useRef(null)
  const dispatch = useDispatch()
  const stoplist = useSelector(getStoplist)
  const isDisabledDish = isDishInStoplist(stoplist, dish.id)
  const { categories, getActiveCategory } = useCurrentCategories()
  const { currentCategoryId: activeTab } = getActiveCategory()
  const [isTwoLinesTitle, setIsTwoLinesTitle] = useState(false)
  const isNewDesignMode = isDesktop ? false : preferences.designVersion === 1.1
  const optionSets = useSelector(getOptionsets)
  const dishTitleRef = useRef()
  const isFavoriteTab = activeTab === FAVORITES_CATEGORY_ID && !searchMode
  const favoritesRef = useRef()
  const formatName = t(dish.name)
  const selectedCategory = useMemo(
    () =>
      categories.find(
        category => category?.dishIds?.includes(dish?.id) || category?.dishIds?.includes(dish?.parentSizeId)
      ),
    [categories, dish.id]
  )

  useEffect(() => {
    if (selectedOptions?.length) {
      let acc = 0

      selectedOptions.forEach((option, i) => {
        const currentOptionSet = optionSets.find(set => set.id === option.id)

        if (currentOptionSet) {
          const setValue = currentOptionSet.options.find(setOption => setOption.id === option.value)
          acc += setValue?.price || 0
        }
      })

      setOptionsCost(acc)
    }
  }, [selectedOptions, optionSets])

  const DishVolume = () => {
    if (dish?.volume && dish?.unit) {
      return (
        <div className={styles.volumeWrapper}>
          <span>{dish.volume}</span>
          <span>{t(dish.unit)}</span>
        </div>
      )
    } else {
      return <></>
    }
  }

  const formatTime = time => {
    if (!time) {
      return ''
    }
    if (time < 86400) {
      const minutes = Math.ceil(time / 60)
      if (minutes === 0) {
        return ''
      }
      if (minutes < 60) {
        return `${minutes} ${t('waitingTimeBadge.time')}`
      } else {
        let hours = Math.floor(minutes / 60)
        let leftMinutes = minutes % 60

        if (leftMinutes > 30) {
          hours += 1
          leftMinutes = 0
        }

        return `${hours}${leftMinutes > 0 ? `.5` : ''} ${t('waitingTimeBadge.hours')}`
      }
    } else {
      return `${Math.ceil(time / 86400)} ${t('waitingTimeBadge.days')}`
    }
  }

  const renderDishOptions = () => {
    if (selectedOptions?.length) {
      return selectedOptions.map((option, i) => {
        const currentOptionSet = optionSets.find(set => set.id === option.id)

        if (currentOptionSet) {
          const setValue = currentOptionSet.options.find(setOption => setOption.id === option.value)
          return `${i === 0 ? '' : ','} ${t(currentOptionSet.name)}${setValue ? `: ${t(setValue.name)}` : ''}`
        }
      })
    }
  }

  useEffect(() => {
    if (dishTitleRef && dishTitleRef.current) {
      if (dishTitleRef.current.offsetHeight === 40) {
        setIsTwoLinesTitle(true)
      } else if (isTwoLinesTitle) {
        setIsTwoLinesTitle(false)
      }
    }
  }, [dishTitleRef, isTwoLinesTitle])

  const cardVariants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    hidden: {
      y: 30,
      opacity: 0,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
        when: 'beforeChildren'
      }
    }
  }

  /* const imgVariants = {
    visible: {
      scale: 1,
      transition: {
        scale: { stiffness: 1000, velocity: -100 }
      }
    },
    hidden: {
      scale: 0.75,
      transition: {
        scale: { stiffness: 1000, velocity: -100 }
      }
    }
  } */

  const cardOnClick = e => {
    if (!isNewDesignMode) {
      dispatch(storeMenuCardCoords(menuCardRef.current.getBoundingClientRect()))
    }
    handleCardClick(dish.id)
  }

  const renderDishText = () => {
    if (!selectedOptions?.length || !isFavoriteTab) {
      return (
        <div
          className={`${styles.textArea}`}
          style={{
            '--viewBg': demoVariables?.dishCardTitleBackground || variables?.dishCardTitleBackground || null
          }}
        >
          <div style={{ ...(!isTwoLinesTitle ? { alignItems: 'center' } : {}) }} className={styles.areaInnerWrapper}>
            <div style={{ ...(!isTwoLinesTitle ? { alignItems: 'center' } : {}) }} className={styles.dishTitleWrapper}>
              <IconType
                style={{ backgroundColor: 'var(--fontColor)', marginRight: '8px' }}
                type={dish.icon}
                size={24}
              />
              {isFavorite && <FavIcon className={styles.favoriteDishFavIcon} />}
              <div data-cy={`menuCardDishTitle-${dish.id}`} ref={dishTitleRef} className={styles.dishTitle}>
                {formatName}
              </div>
            </div>
            <div className={styles.priceWrapper}>
              <div className={`${styles.price} ${isDisabledDish ? styles.disabledDishPrice : ''}`}>
                {`${money(dish.price + optionsCost)} ₴`}
              </div>
              <DishVolume />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div
          className={`${styles.textArea}`}
          style={{
            '--viewBg': demoVariables?.dishCardTitleBackground || variables?.dishCardTitleBackground || null
          }}
        >
          <div className={styles.areaInnerWrapper}>
            <div className={styles.dishTitleWithOptionsWrapper}>
              <div className={styles.dishTitleInnerWrapper}>
                <IconType
                  style={{ backgroundColor: 'var(--fontColor)', marginRight: '8px' }}
                  type={dish.icon}
                  size={24}
                />
                {isFavorite && <FavIcon className={styles.favoriteDishFavIcon} />}
                <div data-cy={`menuCardDishTitle-${dish.id}`} ref={dishTitleRef} className={styles.dishTitle}>
                  {formatName}
                </div>
              </div>
              <div className={`${styles.optionsWrapper} ${isOpen ? styles.optionsWrapperOpen : ''}`}>
                {renderDishOptions()}
              </div>
            </div>
            <div className={styles.priceWrapper}>
              <div className={`${styles.price} ${isDisabledDish ? styles.disabledDishPrice : ''}`}>
                {`${money(dish.price + optionsCost)} ₴`}
              </div>
              <DishVolume />
            </div>
          </div>
        </div>
      )
    }
  }

  const renderFavoriteDishDetails = () => {
    if (isFavoriteTab) {
      return (
        <div
          style={{ ...(isOpen ? { height: `${favoritesRef?.current?.offsetHeight}px` } : {}) }}
          className={`${styles.favoriteDishDetailsWrapper}`}
        >
          <div ref={favoritesRef} className={`${styles.favoriteDishDetailsInnerWrapper}`}>
            <div className={styles.buttonsWrapper}>
              <button
                data-cy={`favoritesButtonOrder-${dish.id}`}
                onClick={e => {
                  e.stopPropagation()
                  handleCardAdd()
                }}
              >
                {t('favorites.order')}
              </button>
              <button
                data-cy={`favoritesButtonOpen-${dish.id}`}
                onClick={e => {
                  e.stopPropagation()
                  cardOnClick()
                }}
              >
                {t('favorites.open')}
              </button>
            </div>
          </div>
        </div>
      )
    }
  }

  const getDishBgImage = () => {
    if (dish.previewMedia && dish.previewMedia[0] && dish.previewMedia[0].path) {
      return `url(${dish.previewMedia[0].path})`
    }

    return `url(/${dish.imgWide || dish.img})`
  }
  const isOrderInCart = ordersNew.filter(order => {
    if (parseInt(dish.id) === parseInt(order.dishId)) {
      if (selectedOptions?.length) {
        if (order.selectedOptions.length) {
          return order.selectedOptions.every(option =>
            selectedOptions.some(
              selectedOption => selectedOption.id === option.id && selectedOption.value === option.value
            )
          )
        }

        return false
      }

      return !order.selectedOptions.length
    }
    return false
  })

  const showLikeIcon = useMemo(() => !!((dish.imgWide || dish.img) && activeTab !== FAVORITES_CATEGORY_ID), [
    dish.imgWide,
    dish.img,
    activeTab
  ])

  const isSameCategory = useMemo(() => selectedCategory?.id === activeTab || selectedCategory?.parentId === activeTab, [
    selectedCategory,
    activeTab
  ])

  const handleClick = () => {
    if (isFavoriteTab) {
      handleOpenDishCard()
    } else {
      cardOnClick()
    }
  }

  return (
    <motion.div
      data-cy={`menuCard-${dish.id}`}
      initial='hidden'
      animate='visible'
      ref={menuCardRef}
      variants={cardVariants}
      className={styles.menuCard}
      onClick={handleClick}
    >
      {isFavoriteTab || (!dish.img?.length && !dish.imgWide?.length && !dish.previewMedia?.length) ? null : (
        <motion.div
          className={styles.img}
          style={{
            ...(!preferences?.withFavorites && { justifyContent: 'flex-end' }),
            backgroundImage: getDishBgImage(),
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            position: 'relative'
          }}
        >
          {preferences?.withFavorites ? (
            <LikeWrapper
              options={dish.customOptions ? dish.customOptions : undefined}
              className={styles.newLike}
              dishId={dish.id}
              clickable={true}
            />
          ) : (
            <></>
          )}
          <DishesTags isDisabledDish={isDisabledDish} withoutCategoryTag={isSameCategory} dish={dish} />
          {isOrderInCart?.length ? <WaitingTimeBadge label={isOrderInCart.length} className={styles.iconDish} /> : ''}
        </motion.div>
      )}
      {isFavoriteTab || (!dish.img && !dish?.img?.length && !dish.imgWide && !dish?.imgWide?.length) ? (
        <div style={{ ...(!showLikeIcon && { justifyContent: 'flex-end', height: 'auto' }) }} className={styles.flat}>
          {/** If there is no image, then we should not render the like icon */}
          {showLikeIcon && preferences?.withFavorites ? (
            <LikeWrapper
              options={dish.customOptions ? dish.customOptions : undefined}
              className={styles.newLike}
              dishId={dish.id}
            />
          ) : null}
          <div className={styles.rightSection}>
            {/* {!dish.preorderStart ? '' : <div className={styles.preorder}>ПРЕДЗАКАЗ</div>} */}
            {/**
             * Rendering Badge with Category name if the active category is not dish related
             */}
            {!isSameCategory ? <WaitingTimeBadge label={t(dish.categoryName)} /> : null}
            {!dish.preorderStart ? null : <div className={styles.preorder}>{t('menuCard.preorder')}</div>}
            <WaitingTimeBadge time={formatTime(dish.waitingTime)} disabled={isDisabledDish} />
          </div>
          {isOrderInCart.length ? (
            <WaitingTimeBadge
              label={isOrderInCart.length}
              className={isFavorite ? styles.iconDishFavorite : styles.iconDish}
            />
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
      {renderDishText()}
      {renderFavoriteDishDetails()}
    </motion.div>
  )
}
