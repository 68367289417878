import orders from './reducers'

export {
  setPaidOrders,
  addNewOrder,
  processWebsocketOrder,
  setPaymentType,
  addComment,
  setTakeout,
  addManyOrders,
  clearNewOrders,
  updateOrder
} from './actions'

export {
  getOrdersCooking,
  getOrdersReady,
  getOrdersNew,
  getOrdersPaid,
  getOrders,
  getTakeout,
  getPaymentType,
  getOrdersNewInside,
  getOrdersNewTakeout,
  getComment
} from './selectors'

export default orders
