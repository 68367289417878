import { useMemo, useState } from 'react'
import { useSelector } from 'src/state/hooks'
import { getOrdersNew } from 'src/state/orders'
import { getOptionsets } from 'src/state/menu'
import { DateTime, IDish, IOrder, NewOrderInput, SelectedOptionType } from 'src/types'
import { MIN_ORDER_AMOUNT } from 'src/constants'
import useCurrentDishes from './useCurrentDishes'
import { GroupedOrders, sameOrdersReducer } from '../utils/orders'
import { useDispatch } from 'src/state/hooks'
import { setNewOrders } from 'src/state/orders/actions'
import { getVenueId } from 'src/state/location'
import useOptionSetApi from './useOptionSetApi'

type GetOrderPriceFunc = (order: NewOrderInput) => number
type UnpaidOrdersState = {
  isUnderMinOrderAmount: boolean
  totalPrice: number
  isEmpty: boolean
  groupedOrders: NewOrderInput[]
  orders: NewOrderInput[]
  includesDrinks: boolean
  includesCutleryDishes: boolean
  ordersWithDishes: OrderWithDish[]
  cookingTime: number | undefined
  preorderInfo: PreorderInfo | null
}
type UnpaidOrdersApi = {
  getOrderPrice: GetOrderPriceFunc
  searchOrdersInCart: (dishId: number, options?: SelectedOptionType[]) => NewOrderInput[]
  filterNewOrders: () => void
}
type PreorderInfo = {
  start: DateTime | null
  end: DateTime | null
  preorderStart: DateTime | null
}
type OrderWithDish = NewOrderInput & { dish: IDish | undefined | null }
type UnpaidOrdersReturnValue = [UnpaidOrdersState, UnpaidOrdersApi]

export default function useUnpaidOrders(): UnpaidOrdersReturnValue {
  const dispatch = useDispatch()
  const ordersNew = useSelector(getOrdersNew)
  const [filteredVenueId, setFilteredVenueId] = useState(null)
  const venueId = useSelector(getVenueId)

  const { findDish, dishes } = useCurrentDishes()
  const { isOptionSetExist, isOptionExist, findOptionSet } = useOptionSetApi()

  const filterNewOrders = useMemo(
    () => () => {
      if (ordersNew.length && dishes?.length && filteredVenueId !== venueId) {
        const result = ordersNew.filter(order => {
          const currentDish = dishes?.find(dish => dish.id === order.dishId)
          if (currentDish) {
            return order.selectedOptions?.every(
              orderOption => isOptionSetExist(orderOption.id, currentDish.recipeId) && isOptionExist(orderOption)
            )
          }

          return false
        })

        dispatch(setNewOrders(result))
        setFilteredVenueId(venueId)
      }
    },
    [isOptionExist, isOptionSetExist, ordersNew, dispatch, dishes, filteredVenueId, venueId]
  )

  const getOrderPrice: GetOrderPriceFunc = useMemo(
    () => order => {
      const dish = findDish?.(order.dishId)
      let result = dish?.price || 0

      order.selectedOptions?.forEach(option => {
        const currentOptionSet = findOptionSet(option.id)
        if (currentOptionSet) {
          const optionPrice = currentOptionSet.options?.find(curr => curr.id === option.value)?.price || 0
          result += optionPrice
        }
      })

      return result
    },
    [findOptionSet, findDish]
  )

  const ordersWithDishes: OrderWithDish[] = useMemo(
    () => ordersNew.map(order => ({ ...order, dish: findDish?.(order.dishId) })),
    [ordersNew, findDish]
  )

  const groupedOrders: NewOrderInput[] = useMemo(() => {
    const ordersMap =
      ordersNew.sort((a, b) => (a.dishId || 0) - (b.dishId || 0)).reduce(sameOrdersReducer, {} as GroupedOrders) || {}
    const orders = Object.values(ordersMap) as NewOrderInput[]
    return orders.map(order => ({ ...order, totalPrice: getOrderPrice(order) * Number(order.count) }))
  }, [ordersNew, getOrderPrice])

  const totalPrice = useMemo(() => ordersNew.reduce((sum, order) => sum + getOrderPrice(order), 0), [
    ordersNew,
    getOrderPrice
  ])

  const includesDrinks = useMemo(
    () => ordersWithDishes.some(order => /napitki|matcha|hot-beverage|beverages|coffee/.test(order.dish?.icon || '')),
    [ordersWithDishes]
  )

  const includesCutleryDishes = useMemo(
    () => ordersWithDishes.some(order => !/napitki|matcha|hot-beverage|beverages|coffee/.test(order.dish?.icon || '')),
    [ordersWithDishes]
  )

  const preorderInfo: PreorderInfo | null = useMemo(() => {
    const ordersFound = ordersWithDishes.filter(order => order.dish?.preorderStart)
    if (ordersFound[0]?.dish) {
      const { start, end, preorderStart } = ordersFound[0].dish
      return { start, end, preorderStart } as PreorderInfo
    }
    return null
  }, [ordersWithDishes])

  // const cookingTime = useMemo(() => getCookingTime(dishes, ordersNew, preferences?.maxCookingTime || 0), [
  //   dishes,
  //   ordersNew,
  //   preferences,
  //   preorderInfo
  // ])

  const cookingTime = useMemo(() => {
    return (
      Math.max(
        ...(dishes
          ?.filter(currentDish => ordersNew.some(orderNew => orderNew.dishId === currentDish.id))
          .map(({ waitingTime }) => waitingTime) || [])
      ) / 60
    )
  }, [dishes, ordersNew])

  const searchOrdersInCart = useMemo(
    () => (dishId: number, options?: SelectedOptionType[]) =>
      ordersNew.filter(order => {
        if (dishId === order.dishId) {
          if (options?.length) {
            if (order.selectedOptions?.length) {
              return order.selectedOptions.every(option =>
                options.some(selectedOption => selectedOption.id === option.id && selectedOption.value === option.value)
              )
            }

            return false
          }

          return !order.selectedOptions?.length
        }
        return false
      }),
    [ordersNew]
  )

  const state = useMemo(
    () => ({
      isUnderMinOrderAmount: ordersNew.length && totalPrice > 0 ? totalPrice < MIN_ORDER_AMOUNT : false,
      totalPrice,
      isEmpty: ordersNew.length === 0,
      orders: ordersNew,
      groupedOrders,
      includesDrinks,
      includesCutleryDishes,
      ordersWithDishes,
      isAllTakeoutReady: false,
      cookingTime,
      preorderInfo
    }),
    [
      totalPrice,
      ordersNew,
      groupedOrders,
      includesDrinks,
      includesCutleryDishes,
      ordersWithDishes,
      cookingTime,
      preorderInfo
    ]
  )

  const api = useMemo(
    () => ({
      getOrderPrice,
      searchOrdersInCart,
      filterNewOrders
    }),
    [getOrderPrice, searchOrdersInCart, filterNewOrders]
  )

  return [state, api]
}
